<div class="padding-lg">
  <h3>
    Moje konto
  </h3>
  <div fxLayout="row" fxLayoutAlign="center start" style="height: 100%;">
    <div class="change-password mat-elevation-z2 padding-lg margin-right-lg ep-background-1">
      <form [formGroup]="updatePasswordForm" (ngSubmit)="updatePassword()" autocomplete="off">
        <h2 class="mat-body-1">Zmień hasło</h2>
        <div class="login-content" fxLayout="column" fxLayoutAlign="start stretch">
          <mat-form-field class="full-width">
            <input matInput placeholder="Stare hasło" type="password" name="passwordOld" required
                   [formControl]="updatePasswordForm.controls['oldPlainPassword']">
          </mat-form-field>

          <mat-form-field class="full-width">
            <input matInput placeholder="Hasło" type="password" name="passwordNewFirst" required
                   [formControl]="updatePasswordForm.controls['passwords']['controls']['plainPasswordFirst']">
          </mat-form-field>

          <mat-form-field class="full-width">
            <input matInput placeholder="Potwórz hasło" type="password" name="passwordNewSecond" required
                   [formControl]="updatePasswordForm.controls['passwords']['controls']['plainPasswordSecond']">
          </mat-form-field>
          <mat-error class="margin-bottom-lg">
            <div *ngIf="updatePasswordForm.controls['passwords'].hasError('areEqual')">Hasła nie są takie
              same
            </div>
            <div *ngIf="updatePasswordForm.controls['passwords'].hasError('required')">Pole wymagane</div>
          </mat-error>
          <button color="primary" mat-raised-button [disabled]="!updatePasswordForm.valid">Zmień</button>
        </div>
      </form>
    </div>
    <div class="contact-form mat-elevation-z2 padding-lg margin-left-lg ep-background-1">
      <form [formGroup]="contactForm" (ngSubmit)="submitContact()" autocomplete="off">
        <h2 class="mat-body-1">Kontakt</h2>
        <div class="login-content" fxLayout="column" fxLayoutAlign="start stretch">
          <mat-form-field class="full-width">
            <input matInput placeholder="Temat" name="subject" required
                   [formControl]="contactForm.controls['subject']"/>
          </mat-form-field>
          <mat-form-field class="full-width">
                        <textarea matInput matTextareaAutosize placeholder="Treść" name="content" required
                                  [formControl]="contactForm.controls['content']"></textarea>

          </mat-form-field>
          <button color="primary" mat-raised-button [disabled]="!contactForm.valid">Wyślij</button>
        </div>
      </form>
    </div>
  </div>
</div>
