import { Injectable, Optional } from '@angular/core';
import { IReport } from '../interface/report.interface';

@Injectable()
export class Report implements IReport {
  id: number;
  identification: number;
  reportType: number;
  url: string;

  constructor(@Optional() obj?: any) {
    this.id = obj && obj.id || null;
    this.identification = obj && obj.identification || null;
    this.reportType = obj && obj.reportType || null;
    this.url = obj && obj.url || null;
  }
}
