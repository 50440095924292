<div class="mat-dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
  <div>Dodaj zlecenie</div>
  <button mat-icon-button mat-button matDialogClose>
    <mat-icon class="icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <ep-order-form [dateFrom]="dateFrom" (onValidationChange)="onValidationChange($event)"
                 (onSaved)="onOrderSaved($event)"></ep-order-form>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button matDialogClose>Anuluj</button>
  <button mat-button class="mat-primary mat-raised-button" disabled="{{!isFormValid || !isButtonActive}}" (click)="save()">Dodaj zlecenie
  </button>
  <button mat-button class="mat-primary mat-raised-button" disabled="{{!isFormValid || !isButtonActive}}" (click)="saveDraft()">Zapisz i
    pobierz
  </button>
</mat-dialog-actions>
