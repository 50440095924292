<form [formGroup]="packageForm"
      *ngIf="selectedType && selectedType === 'placement' || selectedType === 'package' || selectedType === 'print'">
  <div *ngIf="selectedType === 'package'">

    <ep-autocomplete-field
      [(selected)]="creationFilter"
      [options]="creationOptions"
      (onChangeSearchText)="onChangeCreationSearchText($event)"
      [optionsStatus]="creationOptionsStatus"
      placeholder="Kreacja"></ep-autocomplete-field>

    <ep-autocomplete-field
      [(selected)]="packageForm.controls['package']"
      [options]="packageOptions"
      (onChangeSearchText)="eventListeners.onChangePackageSearchText($event)"
      [showNoResultButton]="false"
      [optionsStatus]="packageOptionsStatus"
      placeholder="Pakiet"></ep-autocomplete-field>

    <mat-form-field class="full-width" *ngIf="statusOptionsKeys.length && isEdit">
      <mat-select placeholder="Status" [formControl]="packageForm.controls['status']">
        <mat-option *ngFor="let key of statusOptionsKeys" [value]="key">
          {{ statusOptions[key] }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ep-autocomplete-field
      [(selected)]="packageForm.controls['client']"
      [options]="clientOptions"
      (onChangeSearchText)="eventListeners.onChangeClientSearchText($event)"
      (onClickNoResultAction)="eventListeners.onClickClientNoResultAction($event)"
      [showNoResultButton]="false"
      [optionsStatus]="clientOptionsStatus"
      placeholder="Klient"></ep-autocomplete-field>
  </div>

  <div *ngIf="selectedType === 'placement' && packageForm">
    <button mat-button mat-raised-button (click)="showPlacementSelectPopup()">Wybierz placementy</button>
    <mat-error class="error" style="margin-top: 8px;"
               *ngIf="selectedPlacementsPlaceholderControl.hasError('required') && selectedPlacementsPlaceholderControl.touched">
      <div>Pole wymagane</div>
    </mat-error>

    <mat-form-field class="full-width hide cursor-pointer" (click)="showPlacementSelectPopup()">
      <input type="text"
             readonly
             placeholder="Placements"
             aria-label="placeholder"
             [formControl]="selectedPlacementsPlaceholderControl"
             matInput
             class="cursor-pointer">

    </mat-form-field>

    <ol class="placement-labels" *ngIf="placementSelectedLabels && placementSelectedLabels.length">
      <li *ngFor="let labelItem of placementSelectedLabels">
        <p>{{labelItem}}</p>
      </li>
    </ol>
    <div *ngIf="(packageForm.controls['publisher'].value && packageForm.controls['placement'].value) || isEdit"
         fxLayout="column" fxLayoutAlign="start stretch">
      <ep-autocomplete-field
        [(selected)]="packageForm.controls['client']"
        [options]="clientOptions"
        (onChangeSearchText)="eventListeners.onChangeClientSearchText($event)"
        (onClickNoResultAction)="eventListeners.onClickClientNoResultAction($event)"
        [showNoResultButton]="true"
        [optionsStatus]="clientOptionsStatus"
        placeholder="Klient"></ep-autocomplete-field>

      <mat-form-field class="full-width" *ngIf="statusOptionsKeys.length && isEdit">
        <mat-select placeholder="Status" [formControl]="packageForm.controls['status']">
          <mat-option *ngFor="let key of statusOptionsKeys" [value]="key">
            {{ statusOptions[key] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="full-width"></div>
    </div>
  </div>

  <div *ngIf="selectedType === 'print'">
    <ep-autocomplete-field
      [(selected)]="packageForm.controls['client']"
      [options]="clientOptions"
      (onChangeSearchText)="eventListeners.onChangeClientSearchText($event)"
      (onClickNoResultAction)="eventListeners.onClickClientNoResultAction($event)"
      [showNoResultButton]="true"
      [optionsStatus]="clientOptionsStatus"
      placeholder="Klient"></ep-autocomplete-field>
  </div>

  <div *ngIf="selectedType === 'placement' || selectedType === 'package' || selectedType === 'print'">

    <ep-autocomplete-field
      *ngIf="selectedType === 'print'"
      [(selected)]="packageForm.controls['title']"
      [options]="titleOptions"
      (onChangeSearchText)="eventListeners.onChangeTitleSearchText($event)"
      [showNoResultButton]="false"
      [optionsStatus]="titleOptionsStatus"
      placeholder="Tytuł"></ep-autocomplete-field>

    <ep-autocomplete-field
      *ngIf="selectedType === 'print' && packageForm.controls['title'].value?.id"
      [(selected)]="packageForm.controls['format']"
      [options]="formatOptions"
      (onChangeSearchText)="eventListeners.onChangeFormatSearchText($event)"
      [showNoResultButton]="false"
      [optionsStatus]="formatOptionsStatus"
      (onSelected)="eventListeners.onFormatSelect($event)"
      placeholder="Format"></ep-autocomplete-field>

    <ep-autocomplete-field
      [(selected)]="packageForm.controls['principal']"
      [options]="principalOptions"
      (onChangeSearchText)="eventListeners.onChangePrincipalSearchText($event)"
      [showNoResultButton]="false"
      [optionsStatus]="principalOptionsStatus"
      placeholder="Zleceniodawca"></ep-autocomplete-field>

    <ep-autocomplete-field
      [(selected)]="packageForm.controls['trade']"
      [options]="tradeOptions"
      (onChangeSearchText)="eventListeners.onChangeTradeSearchText($event)"
      [showNoResultButton]="false"
      [optionsStatus]="tradeOptionsStatus"
      placeholder="Branża"></ep-autocomplete-field>

    <ep-autocomplete-field
      [(selected)]="packageForm.controls['brand']"
      [options]="brandOptions"
      (onChangeSearchText)="eventListeners.onChangeBrandSearchText($event)"
      (onClickNoResultAction)="eventListeners.onClickBrandNoResultAction($event)"
      [showNoResultButton]="true"
      [optionsStatus]="brandOptionsStatus"
      *ngIf="packageForm.controls['trade'].value?.id"
      placeholder="Brand"></ep-autocomplete-field>

    <div class="margin-top-md">
      <div fxFlex="50" class="margin-right-sm">

        <mat-form-field class="full-width" *ngIf="selectedType !== 'print'">
          <input matInput [matDatepicker]="dateFrom" placeholder="Data od"
                 [formControl]="packageForm.controls['dateFrom']">
          <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
          <mat-datepicker #dateFrom></mat-datepicker>
          <mat-error>
            <div *ngIf="packageForm.controls['dateFrom'].hasError('required')">Pole wymagane</div>
            <div *ngIf="packageForm.controls['dateFrom'].hasError('matDatepickerMin')">Data do jest
              wcześniejsza niż data od
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" class="margin-right-sm">
        <mat-form-field class="full-width" *ngIf="selectedType !== 'print'">
          <input matInput [matDatepicker]="dateTo" [min]="packageForm.controls['dateFrom'].value" placeholder="Data do"
                 [formControl]="packageForm.controls['dateTo']">
          <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
          <mat-datepicker #dateTo></mat-datepicker>
          <mat-error>
            <div *ngIf="packageForm.controls['dateTo'].hasError('required')">Pole wymagane</div>
            <div *ngIf="packageForm.controls['dateTo'].hasError('matDatepickerMin')">Data do jest wcześniejsza niż data
              od
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="selectedType === 'print'">
      <mat-form-field class="full-width">
        <input
          placeholder="Termin dostarczenia materiałów"
          aria-label="Termin dostarczenia materiałów"
          matInput
          [formControl]="packageForm.controls['materialsDeliveryTime']">
        <mat-error>
          <div *ngIf="packageForm.controls['materialsDeliveryTime'].hasError('required')">Pole wymagane</div>
        </mat-error>
      </mat-form-field>
    </div>

    <div>
      <div class="margin-right-sm">
        <mat-form-field *ngIf="selectedType === 'print'" class="full-width">
          <mat-select placeholder="Numer wydania" [formControl]="packageForm.controls['editionNumber']">
            <mat-option *ngFor="let edition of editions" [value]="edition">
              {{ edition.editionNumber }}/{{edition.editionYear}}
            </mat-option>
          </mat-select>
          <mat-error>
            <div *ngIf="packageForm.controls['editionNumber'].hasError('required')">Pole wymagane</div>
            <div *ngIf="packageForm.controls['editionNumber'].hasError('pattern')">Wartość musi być liczbą całkowitą
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="clr"></div>

    <mat-form-field class="full-width" *ngIf="selectedType === 'print'">
      <mat-select placeholder="Typ" [formControl]="packageForm.controls['type']">
        <mat-option *ngFor="let printType of printTypeOptions" [value]="printType">
          {{ printType.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="clr"></div>

    <div
      *ngIf="selectedType === 'print' && packageForm && packageForm.controls['type'] && packageForm.controls['type'].value?.id === 'custom_cost'">
      <div fxFlex="50" class="margin-right-sm">
        <mat-form-field class="full-width">
          <input matInput
                 pattern="^([,. \d]+)([,.]\d{2})?$"
                 [epAutoNumeric]="2"
                 type="text"
                 placeholder="Planowane koszty techniczne"
                 [formControl]="packageForm.controls['technicalCosts']">
          <mat-error>
            <div *ngIf="packageForm.controls['technicalCosts'].hasError('pattern')">Podaj liczbę np. 100 lub 100.20
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <ep-autocomplete-field
      [(selected)]="packageForm.controls['paymentType']"
      [options]="paymentOptions"
      (onChangeSearchText)="eventListeners.onChangePaymentSearchText($event)"
      [showNoResultButton]="false"
      [optionsStatus]="paymentOptionsStatus"
      placeholder="Typ płatności"></ep-autocomplete-field>


    <ep-autocomplete-field
      *ngIf="selectedType !== 'print'"
      [(selected)]="packageForm.controls['orderForm']"
      [options]="orderFormOptions"
      (onChangeSearchText)="eventListeners.onChangeOrderFormSearchText($event)"
      [showNoResultButton]="false"
      [optionsStatus]="orderFormOptionsStatus"
      placeholder="Grupa Produktów"></ep-autocomplete-field>

    <div>
      <div fxFlex="50" class="margin-right-sm">
        <mat-form-field *ngIf="selectedType !== 'print'" class="full-width">
          <input matInput
                 pattern="^[ 0-9]+$"
                 epAutoNumeric
                 placeholder="Ilość"
                 [formControl]="packageForm.controls['emissionsNumber']">
          <mat-error>
            <div *ngIf="packageForm.controls['emissionsNumber'].hasError('pattern')">Podaj liczbę np. 100</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" class="margin-right-sm">
        <mat-form-field *ngIf="selectedType !== 'print'" class="full-width">
          <input matInput
                 pattern="^[ 0-9]+$"
                 epAutoNumeric
                 placeholder="Capping"
                 [formControl]="packageForm.controls['capping']">
          <mat-error>
            <div *ngIf="packageForm.controls['capping'].hasError('pattern')">Podaj liczbę np. 100</div>
            <div *ngIf="packageForm.controls['capping'].hasError('required')">To pole jest wymagane</div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-form-field class="full-width" *ngIf="selectedType !== 'print'">
      <input matInput
             placeholder="Link docelowy"
             [formControl]="packageForm.controls['targetUrl']">
      <mat-error>
        <div *ngIf="packageForm.controls['targetUrl'].hasError('required')">To pole jest wymagane</div>
      </mat-error>
    </mat-form-field>

    <ep-autocomplete-field
      *ngIf="selectedType !== 'print'"
      [(selected)]="packageForm.controls['campaignType']"
      [options]="campaignTypeOptions"
      (onChangeSearchText)="eventListeners.onChangeCampaignTypeSearchText($event)"
      [showNoResultButton]="false"
      [optionsStatus]="campaignTypeOptionsStatus"
      placeholder="Typ kampanii"></ep-autocomplete-field>

    <ep-autocomplete-field
      [(selected)]="packageForm.controls['salesman']"
      [options]="salesmanOptions"
      (onChangeSearchText)="eventListeners.onChangeSalesmanSearchText($event)"
      [showNoResultButton]="false"
      [optionsStatus]="salesmanOptionsStatus"
      placeholder="Sprzedawca"></ep-autocomplete-field>

    <ep-autocomplete-field
      *ngIf="selectedType !== 'print'"
      [(selected)]="packageForm.controls['salesModel']"
      [options]="salesModelOptions"
      (onChangeSearchText)="eventListeners.onChangeSalesModelSearchText($event)"
      [showNoResultButton]="false"
      [optionsStatus]="salesModelOptionsStatus"
      placeholder="Model sprzedaży"></ep-autocomplete-field>

    <mat-form-field class="full-width">
            <textarea type="text"
                      placeholder="Opis kampanii"
                      aria-label="placeholder"
                      [formControl]="packageForm.controls['comment']"
                      matInput matTextareaAutosize rows="4" style="height: 100px"></textarea>
      <mat-error>
        <div *ngIf="packageForm.controls['comment'].hasError('required')">Pole wymagane</div>
      </mat-error>
    </mat-form-field>

    <div class="margin-top-md">
      <div fxFlex="50" class="margin-right-sm">
        <mat-form-field *ngIf="showPriceListPrice" class="full-width">
          <input matInput
                 pattern="^([,. \d]+)([,.]\d{2})?$"
                 [epAutoNumeric]="2"
                 placeholder="Cena cennikowa"
                 [formControl]="packageForm.controls['priceListPrice']">
          <mat-error>
            <div *ngIf="packageForm.controls['priceListPrice'].hasError('pattern')">Podaj liczbę np. 100 lub 100.20
            </div>
            <div *ngIf="packageForm.controls['priceListPrice'].hasError('required')">To pole jest wymagane</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" class="margin-left-sm">
        <mat-form-field *ngIf="showFixedPrice" class="full-width">
          <input matInput
                 pattern="^([,. \d]+)([,.]\d{2})?$"
                 [epAutoNumeric]="2"
                 placeholder="Cena ustalona"
                 [formControl]="packageForm.controls['fixedPrice']">
          <mat-error>
            <div *ngIf="packageForm.controls['fixedPrice'].hasError('pattern')">Podaj liczbę np. 100 lub 100.20</div>
            <div *ngIf="packageForm.controls['fixedPrice'].hasError('required')">To pole jest wymagane</div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="padding-left-md margin-bottom-lg">
      <p>Rabat</p>
      <mat-form-field class="full-width">
        <mat-select placeholder="Typ" [formControl]="discountTypeControl" multiple>
          <mat-option *ngFor="let discountType of discountTypeOptions" [value]="discountType">
            {{ discountType.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="showDiscountFields">
        <div *ngFor="let discount of discounts">
          <mat-form-field>
            <input matInput
                   pattern="^([,. \d]+)([,.]\d{2})?$"
                   [epAutoNumeric]="2"
                   [maximumValue]="(discount.type === '0' || discount.type === '2') ? 100 : null"
                   placeholder="{{ discount.name }}"
                   [formControl]="discount.formControl">
            <mat-error>
              <div *ngIf="discount.formControl.hasError('pattern')">Podaj liczbę np. 100 lub 0.40</div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <button mat-button (click)="calculateDiscount($event)"
              *ngIf="discountTypeControl.value && discountTypeControl.value.length">Przelicz
      </button>
    </div>

    <div class="clr"></div>

    <div>
      <div fxFlex="50">
        <mat-form-field *ngIf="showValuePrice" class="full-width">
          <input matInput
                 pattern="^([,. \d]+)([,.]\d{2})?$"
                 [epAutoNumeric]="2"
                 placeholder="Kwota rezerwacji (netto PLN)"
                 [formControl]="packageForm.controls['value']">
          <mat-error>
            <div *ngIf="packageForm.controls['value'].hasError('pattern')">Podaj liczbę np. 100 lub 100.20</div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="payment-partition">
      <h3>Częściowa kwota rezerwacji</h3>
      <ep-reservation-payment [manualSplit]="true"
                              [cost]="packageForm.controls['value'].value"
                              [paymentPartition]="reservationItemData?.paymentPartition"
                              (changeData)="paymentDataChange($event)"></ep-reservation-payment>
    </div>
  </div>
</form>
