import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';

import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

export interface Credentials {
  token: string;
}

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

const credentialsKey = 'credentials';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {
  private _credentials: Credentials;

  constructor(private http: HttpClient, private router: Router) {
    if (sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey)) {
      if (!this._credentials) {
        this._credentials = {token: null};
      }
      this._credentials.token = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    }
  }


  login(username: string, password: string, remember: boolean): any {
    const data = {
      token: ''
    };
    let apiUrl = `${environment.apiUrl}/security/token`;

    let request = {
      _username: username,
      _password: password,
      _client: environment.apiClient,
      _secret: environment.apiSecret
    };
    this.setCredentials();
    return this.http.post(apiUrl, request).pipe(map(response => {
      data.token = response['token'];
      this.setCredentials(data, remember);
      return response;
    }));
  }

  /**
   * Logs out the user and clear credentials.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.setCredentials();
    return observableOf(true);
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials && !!this.credentials.token;
  }

  /**
   * Gets the user credentials.
   * @return {Credentials} The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param {Credentials=} credentials The user credentials.
   * @param {boolean=} remember True to remember credentials across sessions.
   */
  private setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, credentials.token);
    } else {
      sessionStorage.setItem(credentialsKey, '');
      localStorage.setItem(credentialsKey, '');
    }
  }

}
