export class PaymentPartitionModel {
  public date_from: string;
  public date_to: string;
  public value?: number;
  public reservation_id?: number;

  constructor(data: PaymentPartitionModel) {
    this.date_from = data.date_from;
    this.date_to = data.date_to;
    this.value = data.value;
    this.reservation_id = data.reservation_id;
  }
}
