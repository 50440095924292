<div class="mat-dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
  <div>{{ data.date | date: 'dd.MM.yyyy' }}</div>
  <button mat-icon-button mat-button (click)="cancel()">
    <mat-icon class="icon">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-dialog-content">
  <ep-calendar-reservation-list [reservationList]="this.reservationList"></ep-calendar-reservation-list>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <!-- Can optionally provide a result for the closing dialog. -->
  <button mat-button class="mat-primary mat-raised-button" (click)="openReservationCreatePopup()">Dodaj rezerwację
  </button>
  <button mat-button class="mat-primary mat-raised-button" (click)="openOrderCreatePopup()">Dodaj zlecenie</button>
</mat-dialog-actions>
