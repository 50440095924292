import { Component, Input, OnInit } from '@angular/core';
import { ICalendarReservationListItem } from '../interface/calendar-reservation-list-item';

@Component({
  selector: 'ep-calendar-reservation-list',
  templateUrl: './calendar-reservation-list.component.html',
  styleUrls: ['./calendar-reservation-list.component.scss']
})
export class CalendarReservationListComponent implements OnInit {

  @Input()
  reservationList: ICalendarReservationListItem[];
  selectedReservation: ICalendarReservationListItem;

  constructor() {
  }

  ngOnInit() {
  }

  onShowDetails($event) {
    this.selectedReservation = $event;
  }

  showMain() {
    this.selectedReservation = null;
  }
}
