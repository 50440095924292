<form [formGroup]="reservationForm">
  <mat-form-field class="full-width">
    <input
      placeholder="Nazwa kampanii"
      aria-label="Nazwa kampanii"
      matInput
      [formControl]="reservationForm.controls['name']">
    <mat-error>
      <div *ngIf="reservationForm.controls['name'].hasError('required')">Pole wymagane</div>
    </mat-error>
  </mat-form-field>

  <ep-autocomplete-field
    [selected]="reservationForm.controls['client']"

    [options]="clientOptions"
    (onChangeSearchText)="eventListeners.onChangeClientSearchText($event)"
    [showNoResultButton]="false"
    [optionsStatus]="clientOptionsStatus"
    placeholder="Klient"></ep-autocomplete-field>

  <ep-autocomplete-field
    [selected]="reservationForm.controls['principal']"
    [options]="principalOptions"
    (onChangeSearchText)="eventListeners.onChangePrincipalSearchText($event)"
    [showNoResultButton]="false"
    [optionsStatus]="principalOptionsStatus"
    placeholder="Zleceniodawca"></ep-autocomplete-field>

  <ep-autocomplete-field
    [selected]="reservationForm.controls['trade']"
    [options]="tradeOptions"
    (onChangeSearchText)="eventListeners.onChangeTradeSearchText($event)"
    [showNoResultButton]="false"
    [optionsStatus]="tradeOptionsStatus"
    placeholder="Branża"></ep-autocomplete-field>

  <ep-autocomplete-field
    [selected]="reservationForm.controls['brand']"
    [options]="brandOptions"
    (onChangeSearchText)="eventListeners.onChangeBrandSearchText($event)"
    (onClickNoResultAction)="eventListeners.onClickBrandNoResultAction($event)"
    [showNoResultButton]="true"
    [optionsStatus]="brandOptionsStatus"
    *ngIf="reservationForm.controls['trade'].value"
    placeholder="Brand"></ep-autocomplete-field>

  <div class="margin-top-md">
    <div fxFlex="50" class="margin-right-sm">
      <mat-form-field class="full-width">
        <input matInput [matDatepicker]="dateFrom" placeholder="Data od"
               [formControl]="reservationForm.controls['dateFrom']">
        <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
        <mat-datepicker #dateFrom></mat-datepicker>
        <mat-error>
          <div *ngIf="reservationForm.controls['dateFrom'].hasError('required')">Pole wymagane</div>
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="50" class="margin-right-sm">
      <mat-form-field class="full-width">
        <input matInput [matDatepicker]="dateTo" [min]="reservationForm.controls['dateFrom'].value"
               placeholder="Data do" [formControl]="reservationForm.controls['dateTo']">
        <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
        <mat-datepicker #dateTo></mat-datepicker>
        <mat-error>
          <div *ngIf="reservationForm.controls['dateTo'].hasError('required')">Pole wymagane</div>
          <div *ngIf="reservationForm.controls['dateTo'].hasError('matDatepickerMin')">Data do jest
            wcześniejsza niż data od

          </div>
        </mat-error>
      </mat-form-field>
    </div>
  </div>


  <ep-autocomplete-field
    [selected]="reservationForm.controls['paymentType']"
    [options]="paymentOptions"
    (onChangeSearchText)="eventListeners.onChangePaymentSearchText($event)"
    [showNoResultButton]="false"
    [optionsStatus]="paymentOptionsStatus"
    placeholder="Typ płatności"></ep-autocomplete-field>

  <ep-autocomplete-field
    [selected]="reservationForm.controls['campaignType']"
    [options]="campaignTypeOptions"
    (onChangeSearchText)="eventListeners.onChangeCampaignTypeSearchText($event)"
    [showNoResultButton]="false"
    [optionsStatus]="campaignTypeOptionsStatus"
    placeholder="Typ kampanii"></ep-autocomplete-field>

  <ep-autocomplete-field
    [selected]="reservationForm.controls['salesman']"
    [options]="salesmanOptions"
    (onChangeSearchText)="eventListeners.onChangeSalesmanSearchText($event)"
    [showNoResultButton]="false"
    [optionsStatus]="salesmanOptionsStatus"
    placeholder="Sprzedawca"></ep-autocomplete-field>

  <ep-autocomplete-field
    [selected]="reservationForm.controls['salesModel']"
    [options]="salesModelOptions"
    (onChangeSearchText)="eventListeners.onChangeSalesModelSearchText($event)"
    [showNoResultButton]="false"
    [optionsStatus]="salesModelOptionsStatus"
    placeholder="Model sprzedaży"></ep-autocomplete-field>

  <mat-form-field class="full-width">
        <textarea type="text"
                  placeholder="Opis kampanii"
                  aria-label="placeholder"
                  [formControl]="reservationForm.controls['comment']"
                  matInput matTextareaAutosize rows="4" style="height: 100px"></textarea>
    <mat-error>
      <div *ngIf="reservationForm.controls['comment'].hasError('required')">Pole wymagane</div>
    </mat-error>
  </mat-form-field>

  <div>
    <div fxFlex="50" class="margin-right-sm">
      <mat-form-field class="full-width">
        <input matInput
               currencyMask
               placeholder="Planowane koszty produkcji"
               pattern="^([,. \d]+)([,.]\d{2})?$"
               [epAutoNumeric]="2"
               [formControl]="reservationForm.controls['agencyFee']">
        <mat-error>
          <div *ngIf="reservationForm.controls['agencyFee'].hasError('required')">Pole wymagane</div>
          <div *ngIf="reservationForm.controls['agencyFee'].hasError('pattern')">Podaj liczbę np. 100 lub
            100.20
          </div>
        </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="50" class="margin-right-sm">
      <mat-form-field class="full-width">
        <input matInput
               currencyMask
               placeholder="Koszty techniczne (do umieszczenia na zleceniu)"
               pattern="^([,. \d]+)([,.]\d{2})?$"
               [epAutoNumeric]="2"
               [formControl]="reservationForm.controls['technicalCosts']">
        <mat-error>
          <div *ngIf="reservationForm.controls['technicalCosts'].hasError('required')">Pole wymagane</div>
          <div *ngIf="reservationForm.controls['technicalCosts'].hasError('pattern')">Podaj liczbę np. 100 lub
            100.20
          </div>
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <ep-autocomplete-field
    [selected]="reservationForm.controls['publisher']"
    [options]="publisherOptions"
    (onChangeSearchText)="eventListeners.onChangePublisherSearchText($event)"
    [showNoResultButton]="false"
    [optionsStatus]="publisherOptionsStatus"
    placeholder="Serwis"></ep-autocomplete-field>

  <ep-autocomplete-field
    [selected]="reservationForm.controls['orderForm']"
    [options]="orderFormOptions"
    (onChangeSearchText)="eventListeners.onChangeOrderFormSearchText($event)"
    [showNoResultButton]="false"
    [optionsStatus]="orderFormOptionsStatus"
    placeholder="Grupa Produktów"></ep-autocomplete-field>

  <ep-autocomplete-field
    [selected]="reservationForm.controls['priceList']"
    [options]="priceListOptions"
    (onChangeSearchText)="eventListeners.onChangePriceListSearchText($event)"
    [showNoResultButton]="false"
    [optionsStatus]="priceListOptionsStatus"
    placeholder="Usługa"></ep-autocomplete-field>


  <div class="margin-top-md">
    <div fxFlex="50" class="margin-right-sm">
      <mat-form-field *ngIf="showPriceListPrice" class="full-width">
        <input matInput
               pattern="^([,. \d]+)([,.]\d{2})?$"
               [epAutoNumeric]="2"
               placeholder="Cena cennikowa"
               [formControl]="reservationForm.controls['priceListPrice']">
        <mat-error>
          <div *ngIf="reservationForm.controls['priceListPrice'].hasError('pattern')">Podaj liczbę np. 100 lub
            100.20
          </div>
          <div *ngIf="reservationForm.controls['priceListPrice'].hasError('required')">To pole jest wymagane
          </div>
        </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="50" class="margin-left-sm">
      <mat-form-field *ngIf="showFixedPrice" class="full-width">
        <input matInput
               pattern="^([,. \d]+)([,.]\d{2})?$"
               [epAutoNumeric]="2"
               placeholder="Cena ustalona"
               [formControl]="reservationForm.controls['fixedPrice']">
        <mat-error>
          <div *ngIf="reservationForm.controls['fixedPrice'].hasError('pattern')">Podaj liczbę np. 100 lub
            100.20
          </div>
          <div *ngIf="reservationForm.controls['fixedPrice'].hasError('required')">To pole jest wymagane</div>
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="padding-left-md margin-bottom-lg">
    <p>Rabat</p>
    <mat-form-field class="full-width">
      <mat-select placeholder="Typ" [formControl]="discountTypeControl" multiple>
        <mat-option *ngFor="let discountType of discountTypeOptions" [value]="discountType">
          {{ discountType.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="showDiscountFields">
      <div *ngFor="let discount of discounts">
        <mat-form-field>
          <input matInput
                 pattern="^([,. \d]+)([,.]\d{2})?$"
                 [epAutoNumeric]="2"
                 [maximumValue]="(discount.type === '0' || discount.type === '2') ? 100 : null"
                 placeholder="{{ discount.name }}"
                 [formControl]="discount.formControl">
          <mat-error>
            <div *ngIf="discount.formControl.hasError('pattern')">Podaj liczbę np. 100 lub 0.40</div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <button mat-button (click)="calculateDiscount($event)"
            *ngIf="discountTypeControl.value && discountTypeControl.value.length">Przelicz
    </button>
  </div>

  <div class="clr"></div>

  <div class="margin-top-md">
    <div fxFlex="50" class="margin-right-sm">
      <mat-form-field *ngIf="showValuePrice" class="full-width">
        <input matInput
               currencyMask
               pattern="^([,. \d]+)([,.]\d{2})?$"
               [epAutoNumeric]="2"
               placeholder="Kwota rezerwacji (netto PLN)"
               [formControl]="reservationForm.controls['value']">
        <mat-error>
          <div *ngIf="reservationForm.controls['value'].hasError('pattern')">Podaj liczbę np. 100 lub 100.20
          </div>
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="payment-partition">
    <h3>Częściowa kwota rezerwacji</h3>
    <ep-reservation-payment [dateFrom]="this.reservationForm?.controls['dateFrom'].value"
                            [dateTo]="this.reservationForm?.controls['dateTo'].value"
                            [cost]="reservationForm.controls['value'].value"
                            [paymentPartition]="reservationItemData?.paymentPartition"
                            (changeData)="paymentDataChange($event)"></ep-reservation-payment>
  </div>
</form>
