import { Injectable, Optional } from '@angular/core';

@Injectable()
export class DictionaryFiltersRequestModel {
  name: string;
  kind?: string;
  title?: number;
  publisher?: number;
  orderForm?: number;

  constructor(@Optional() obj?: any) {
    this.name = obj && obj.name || null;
    this.kind = obj && obj.kind || null;
    this.title = obj && obj.title || undefined;
    this.publisher = obj && obj.publisher || undefined;
    this.orderForm = obj && obj.orderForm || undefined;
  }
}
