import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import * as _ from 'lodash';
import { ToasterService } from './toaster.service';

@Injectable()
export class ApiErrorManagerService {
  errors: Array<string> = [];

  constructor(private snackBar: MatSnackBar,
              private toaster: ToasterService) {

  }

  public setError(errors) {
    this.errors = errors;
  }

  public displayError(errors: Array<string>): void {
    this.setError(errors);
    this.displayMessage();
  }

  private displayMessage() {
    this.toaster.displayMessage(this.prepareMessage());
  }

  private prepareMessage(): string {
    let message: string;
    if (this.errors && _.isArray(this.errors) && this.errors.length) {
      message = this.errors.join('. ');
    } else if (this.errors && !_.isArray(this.errors) && this.errors['detail']) {
      message = this.errors['detail'];
    } else {
      message = 'Nieznany błąd';
    }

    return message;
  }
}
