import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ClientAddPopupComponent } from '../../../../main/client/client-add-popup/client-add-popup.component';
import { OrderCreatePopupComponent } from '../../../../main/order/order-create-popup/order-create-popup.component';
import { ProfileService } from '../../../../main/profile/profile.service';
import { ReservationCreatePopupComponent } from '../../../../main/reservation/reservation-create/reservation-create-popup/reservation-create-popup.component';
import { ReservationEditPopupComponent } from '../../../../main/reservation/reservation-edit-popup/reservation-edit-popup.component';
import { ReservationService } from '../../../../main/reservation/reservation.service';
import { User } from '../../../../main/user/user.model';

import { IToolbarDropdownIsOpen } from './interface/toolbar-dropdown-is-open-interface';

@Component({
  selector: 'ep-toolbar-main-menu',
  templateUrl: './toolbar-main-menu.component.html',
  styleUrls: ['./toolbar-main-menu.component.scss']
})
export class ToolbarMainMenuComponent implements OnInit {
  isOpen: IToolbarDropdownIsOpen;
  menuReservation: any;
  menuClients: any;
  isManager: boolean = false;
  isMerchant: boolean = false;
  isCoordinator: boolean = false;

  @Input() user: User;

  constructor(public dialog: MatDialog,
              private reservationService: ReservationService,
              private profileService: ProfileService) {
    this.setIsOpenDefault();
  }

  ngOnInit() {
    this.isManager = this.profileService
      && this.profileService.currentUser
      && this.profileService.currentUser.type === 'manager';

    this.isMerchant = this.profileService
      && this.profileService.currentUser
      && this.profileService.currentUser.type === 'merchant';

    this.isCoordinator = this.profileService
      && this.profileService.currentUser
      && this.profileService.currentUser.type === 'coordinator';
  }

  setIsOpenDefault() {
    this.isOpen = {
      reservation: false,
      clients: false,
      orders: false
    }
  }

  toggleDropdown(name) {
    let copyIsOpen = this.isOpen[name];
    this.setIsOpenDefault();
    if (!this.isOpen[name]) {
      this.isOpen[name] = false;
    }
    this.isOpen[name] = !copyIsOpen;
  }

  onClickOutside() {
    this.setIsOpenDefault();
  }

  openReservationCreatePopup(): void {
    let dialogRef = this.dialog.open(ReservationCreatePopupComponent, {
      width: '640px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data && data.type && data.type === 'edit') {
        this.openReservationEditPopup(data.reservation);
      }
    });
  }

  openReservationEditPopup($event): void {
    let dialogRef = this.dialog.open(ReservationEditPopupComponent, {
      width: '640px',
      data: {
        reservationItemData: $event
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      this.reservationService.onCreateOrEdit$.emit(true);
    });
  }

  openOrderCreatePopup(): void {
    let dialogRef = this.dialog.open(OrderCreatePopupComponent, {
      width: '640px',
      data: {
        dateFrom: null
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe();
  }

  openClientAddPopup(): void {
    let dialogRef = this.dialog.open(ClientAddPopupComponent, {
      width: '440px',
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }
}
