import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiErrorManagerService } from '../../shared/utils/api-error-manager.service';
import { ToasterService } from '../../shared/utils/toaster.service';
import { UserService } from '../user/user.service';


@Component({
  selector: 'ep-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  updatePasswordForm: FormGroup;
  contactForm: FormGroup;
  request: object;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private errorManager: ApiErrorManagerService,
    private toaster: ToasterService) {

    this.createUpdatePasswordForm();
    this.createContactForm();
  }

  ngOnInit() {
  }

  private createUpdatePasswordForm() {
    this.updatePasswordForm = this.formBuilder.group({
      oldPlainPassword: ['', Validators.required],
      'passwords': this.formBuilder.group({
        plainPasswordFirst: ['', Validators.required],
        plainPasswordSecond: ['', Validators.required]
      }, {validator: this.areEqual})
    });
  }

  areEqual(formGroup) {
    if (formGroup.controls['plainPasswordSecond'].value && formGroup.controls['plainPasswordFirst'].value !== formGroup.controls['plainPasswordSecond'].value) {
      return {
        areEqual: true
      };
    } else if (formGroup.controls['plainPasswordFirst'].value === formGroup.controls['plainPasswordSecond'].value) {
      return null;
    }
  }

  private createContactForm() {
    this.contactForm = this.formBuilder.group({
      subject: ['', Validators.required],
      content: ['', Validators.required]
    });
  }

  updatePassword(): void {
    if (this.updatePasswordForm.valid) {
      this.request = {
        change_password: {
          old_plain_password: this.updatePasswordForm.controls['oldPlainPassword'].value,
          plain_password: {
            first: this.updatePasswordForm.controls['passwords']['controls']['plainPasswordFirst'].value,
            second: this.updatePasswordForm.controls['passwords']['controls']['plainPasswordSecond'].value
          }
        }
      };

      this.userService.changePassword(this.request).subscribe(response => {
        this.toaster.displayMessage('Hasło zostało zmienione');
        this.createUpdatePasswordForm();
        this.updatePasswordForm.markAsPristine();
        this.updatePasswordForm.markAsUntouched();
      }, err => {
        this.errorManager.displayError(err.error);
      })
    } else {
      this.toaster.displayMessage('Wypełnij poprawnie formularz');
    }

  }

  submitContact(): void {
    this.request = {
      contact_form: {
        subject: this.contactForm.controls['subject'].value,
        content: this.contactForm.controls['content'].value,
      }
    };
    this.userService.contact(this.request).subscribe(response => {
      this.toaster.displayMessage('Wiadomość została wysłana');
      this.createContactForm();
      this.contactForm.markAsPristine();
      this.contactForm.markAsUntouched();
    }, err => {
      this.errorManager.displayError(err.error);
    })
  }
}
