import { Injectable, Optional } from '@angular/core';
import { OrderFormItemsDictionaryModel } from './order-form-items-dictionary.model';

@Injectable()
export class OrderFormDictionaryModel {
  items: Array<OrderFormItemsDictionaryModel>;
  types: any;

  constructor(@Optional() obj?: any) {
    this.items = obj && obj.items ? obj.items.map(item => new OrderFormItemsDictionaryModel(item)) : [];
    this.types = obj && obj.types || null;
  }
}
