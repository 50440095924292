<ep-reservation-form (onSaved)="onReservationSaved($event)" [reservationItemData]="reservationItemData"
                     [dateFrom]="dateFrom" [formType]="'package'"
                     *ngIf="selectedType === 'package'"></ep-reservation-form>
<ep-reservation-form (onSaved)="onReservationSaved($event)" [reservationItemData]="reservationItemData"
                     [dateFrom]="dateFrom" [formType]="'placement'"
                     *ngIf="selectedType === 'placement'"></ep-reservation-form>
<ep-reservation-custom-create (onSaved)="onReservationSaved($event)" [reservationItemData]="reservationItemData"
                              [dateFrom]="dateFrom" *ngIf="selectedType === 'custom'"></ep-reservation-custom-create>
<ep-reservation-form (onSaved)="onReservationSaved($event)" [reservationItemData]="reservationItemData"
                     [dateFrom]="dateFrom" [formType]="'print'" *ngIf="selectedType === 'print'"></ep-reservation-form>



