<div class="mat-dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
  <div>Filtry</div>
  <button mat-icon-button mat-button (click)="cancel()">
    <mat-icon class="icon">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="margin-bottom-lg">
    <mat-form-field class="full-width">
      <mat-select class="full-width" placeholder="Wybierz pakiet" multiple="true"
                  [formControl]="selectedPackageControl" *ngIf="packages && packages.length">

        <mat-option *ngFor="let item of packages" [value]="item.placementCategoryId">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="margin-bottom-lg">
    <mat-form-field class="full-width">
      <mat-select class="full-width" placeholder="Wybierz serwis" multiple="true"
                  [formControl]="selectedPublisherControl">
        <mat-option *ngFor="let item of publishers" [value]="item.publisherId">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button matDialogClose>Anuluj</button>
  <button mat-button class="mat-primary mat-raised-button" (click)="ok()">Filtruj</button>
</mat-dialog-actions>
