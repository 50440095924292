<!--<div class="main-toolbar mat-elevation-z2" fxLayout="row" fxLayoutAlign="end center">-->

<!--<div fxLayout="row" fxLayoutAlign="start center" fxFlex="500px">-->
<!--<ep-toolbar-main-menu></ep-toolbar-main-menu>-->
<!--</div>-->

<!--<div class="user-button-container">-->
<!--<ep-toolbar-user-button></ep-toolbar-user-button>-->
<!--</div>-->
<!--</div>-->
<div class="main-toolbar mat-elevation-z2">
  <div class="main-toolbar-container">
    <div class="ep-toolbar-logo">
      <a [routerLink]="['/home']">
        <img class="ep-toolbar-logo" src="assets/EDIPRESSEpolska2_logo_CMYK_vector.svg">
      </a>
    </div>
    <div class="ep-toolbar-content">
      <ep-toolbar-main-menu [user]="user" *ngIf="user"></ep-toolbar-main-menu>
    </div>
  </div>
</div>
