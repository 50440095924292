import { Injectable, Optional } from '@angular/core';

@Injectable()
export class TitleEditionDictionaryModel {
  id: number;
  editionDate: string;
  editionNumber: number;
  editionYear: number;

  constructor(@Optional() obj?: any) {
    this.id = obj && obj.id || null;
    this.editionDate = obj && obj.editionDate || null;
    this.editionNumber = obj && obj.editionNumber || null;
    this.editionYear = obj && obj.editionYear || null;
  }
}
