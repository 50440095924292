import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class UserService {

  constructor(private http: HttpClient) {
  }

  public changePassword(request: object): any {
    let apiUrl = `${environment.apiUrl}/users/password`;
    return this.http.put(apiUrl, request);
  }

  public contact(request: object): any {
    let apiUrl = `${environment.apiUrl}/users/contact`;
    return this.http.post(apiUrl, request);
  }
}
