import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AutocompleteFieldOptionStatusEnum } from '../autocomplete-field/autocomplete-field-option-status.enum';
import { IAutocompleteMultiselectOption } from './autocomplete-multiselect-option.interface';

@Component({
  selector: 'ep-autocomplete-multiselect-field',
  templateUrl: './autocomplete-multiselect-field.component.html',
  styleUrls: ['./autocomplete-multiselect-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteMultiselectFieldComponent implements OnInit, OnChanges {
  @Input() placeholder: string;
  @Input() options: Array<IAutocompleteMultiselectOption>;
  @Input() selectedControl: FormControl;

  @Input() set optionsStatus($event) {
    this.setHintText($event);
    this.optionsStatusValue = $event;
  }

  @Input() disableSearch?: boolean;
  @Output() onSelected?: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeSearchText?: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickNoResultAction?: EventEmitter<any> = new EventEmitter<any>();

  optionsStatusValue: number;
  autocompleteFieldOptionStatusEnum: any = AutocompleteFieldOptionStatusEnum;

  hintText: string;
  isLoading: boolean = true;
  optionsValue: Array<IAutocompleteMultiselectOption>;
  searchControl = new FormControl();
  firstOpenDropdown = true;

  constructor() {

  }

  ngOnInit() {
    this.searchControl.valueChanges.subscribe(
      (fieldValue: any) => {
        this.onChangeSearchTextValue(fieldValue);

      }
    );
  }

  ngOnChanges(args) {
    let options = args.options && args.options.currentValue;
    if (args.options) {
      const selected = this.selectedControl.value;
      this.optionsValue = options;

      setTimeout(() => {
        this.selectedControl.setValue(selected);
      });
    }
  }

  onOpenSelect() {
    if (!this.firstOpenDropdown) {
      this.onChangeSearchText.emit(undefined);
    } else {
      this.firstOpenDropdown = false;
    }
  }

  setHintText(status) {
    this.isLoading = (status === AutocompleteFieldOptionStatusEnum.loading);

    if (status === AutocompleteFieldOptionStatusEnum.noResults) {
      this.hintText = 'Brak wyników spełniających kryteria';
      this.optionsValue = [];
    }
    if (status === AutocompleteFieldOptionStatusEnum.tooManyResults) {
      this.hintText = 'Za dużo wyników, podaj więcej danych';
      this.optionsValue = [];
    }
    if (status === AutocompleteFieldOptionStatusEnum.needMoreChars) {
      this.hintText = 'aby wyszukiwać wprowadź minimum 3 znaki';
    }
    if (status === AutocompleteFieldOptionStatusEnum.ok) {
      this.hintText = null;
    }
  }

  displaySelectedLabel(item) {
    return (item) ? item.label : null;
  }

  onChangeSearchTextValue($event?) {
    this.onChangeSearchText.emit($event);
  }

  setSelected($event) {
    this.selectedControl.setValue($event);
    this.onSelected.emit(this.selectedControl.value);
  }


  identify(index, item) {
    return item.id
  }
}
