import { Injectable } from '@angular/core';

@Injectable()
export class Package {
  allPlacementsNum: any;
  name: string;
  placementCategoryId: number;
  publisherName: string;
  publisherId: number;
  remoteId: number;
  isParent: boolean;
  parentCategoryId: number;

  constructor(obj?: any) {
    this.allPlacementsNum = obj && obj.allPlacementsNum || null;
    this.name = obj && obj.name || null;
    this.placementCategoryId = obj && obj.placementCategoryId || null;
    this.publisherName = obj && obj.publisherName || null;
    this.remoteId = obj && obj.remoteId || null;
    this.publisherId = obj && obj.publisherId || null;
    this.isParent = obj && obj.isParent || false;
    this.parentCategoryId = obj && obj.parentCategoryId || null;
  }
}
