<div (epClickOutside)="onClickOutside()">
  <div class="menu-button-container" #menuReservation>
    <button class="menu-button" mat-button (click)="toggleDropdown('reservation')"
            [class.open]="isOpen.reservation">
      Rezerwacje
    </button>
    <div class="dropdown mat-elevation-z1" [class.open]="isOpen.reservation">
      <div class="content">
        <div class="list">
          <div class="item" matRipple
               (click)="openReservationCreatePopup();onClickOutside()">
            <mat-icon class="icon">add</mat-icon>
            <span class="title">Dodaj</span>
          </div>
          <div class="item" fxLayout="row" fxLayoutAlign="start center" matRipple
               [routerLink]="['/reservations']" (click)="onClickOutside()">
            <mat-icon class="icon">list</mat-icon>
            <span class="title">lista</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="menu-button-container" #menuOrders>
    <button class="menu-button" mat-button (click)="toggleDropdown('orders')" [class.open]="isOpen.orders">
      Zlecenia
    </button>
    <div class="dropdown mat-elevation-z1" [class.open]="isOpen.orders">
      <div class="content">
        <div class="list">
          <div class="item" fxLayout="row" fxLayoutAlign="start center" matRipple
               (click)="openOrderCreatePopup();onClickOutside()">
            <mat-icon class="icon">add</mat-icon>
            <span class="title">Dodaj</span>
          </div>
          <div class="item" fxLayout="row" fxLayoutAlign="start center" matRipple [routerLink]="['/orders']"
               (click)="onClickOutside()">
            <mat-icon class="icon">list</mat-icon>
            <span class="title">lista</span>
          </div>
          <div class="item" fxLayout="row" fxLayoutAlign="start center" matRipple
               [routerLink]="['/orders-manager']" (click)="onClickOutside()" *ngIf="isManager">

            <mat-icon class="icon">list</mat-icon>
            <span class="title">do akceptacji</span>
          </div>

          <div
            class="item orders-coordinator-item"
            fxLayout="row"
            fxLayoutAlign="start center"
            matRipple
            [routerLink]="['/orders-coordinator']"
            (click)="onClickOutside()"
            *ngIf="isCoordinator"
          >
            <mat-icon class="icon">list</mat-icon>
            <span class="title">akcja wymagana</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button mat-button routerLink="/clients" (click)="onClickOutside()" *ngIf="!(isMerchant || isManager)">
    Klienci
  </button>

  <button mat-button routerLink="/reports" (click)="onClickOutside()">
    Raporty
  </button>

  <button mat-icon-button style="padding: 9px;" class="icon-button" type="button" mat-button [class.open]="isOpen">
    <mat-icon class="icon">notifications_active</mat-icon>
  </button>

  <div class="user-button-container">
    <ep-toolbar-user-button [user]="user"></ep-toolbar-user-button>
  </div>
</div>
