import { Injectable } from '@angular/core';

@Injectable()
export class Profile {

  constructor() {

  }

}
