import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { BrandFormComponent } from '../brand-form/brand-form.component';


@Component({
  selector: 'ep-brand-create-popup',
  templateUrl: './brand-create-popup.component.html',
  styleUrls: ['./brand-create-popup.component.scss']
})
export class BrandCreatePopupComponent {
  isFormValid: boolean = false;
  @ViewChild(BrandFormComponent) childActions: any;

  constructor(
    public dialogRef: MatDialogRef<BrandCreatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toaster: ToasterService) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaved($event): void {
    this.toaster.displayMessage('Brand został utworzony');
    this.dialogRef.close($event);
  }

  onValidationChange($event): void {
    this.isFormValid = $event;
  }

  save() {
    this.childActions.saveBrand();
  }
}
