import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs/Rx';
import { ConfirmPopupComponent } from '../../../shared/popup/confirm-popup/confirm-popup.component';
import { IConfirmPopup } from '../../../shared/popup/confirm-popup/confirm-popup.interface';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { ReservationCustomDataPage } from '../../reservation-custom/model/reservation-custom-data-page';
import { ReservationDataPageFilters } from '../model/reservation-data-page-filters';
import { ReservationDataPageRequest } from '../model/reservation-data-page-request';
import { ReservationEditPopupComponent } from '../reservation-edit-popup/reservation-edit-popup.component';
import { ReservationService } from '../reservation.service';

@Component({
  selector: 'ep-reservation-custom-list',
  templateUrl: './reservation-print-list.component.html',
  styleUrls: ['./reservation-print-list.component.scss']
})
export class ReservationPrintListComponent implements OnInit, OnDestroy {
  loadingIndicator: boolean = true;
  reservationData: ReservationCustomDataPage = new ReservationCustomDataPage();
  dataPageRequest: ReservationDataPageRequest;
  currentOffset: number = 0;
  filterForm: FormGroup;
  statuses: object[] = [];
  types: object[] = [
    {
      value: 'single',
      label: 'Single'
    },
    {
      value: 'package',
      label: 'Package'
    }
  ];
  statusesObject: any;
  typesObject: any;

  private filterFormSubscription: Subscription;

  constructor(private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private router: Router,
              private toaster: ToasterService,
              private reservationService: ReservationService) {
    this.setDataPageRequest();
    this.getList();
    this.createFilterForm();
  }

  ngOnInit() {
    this.reservationService.getStatuses().subscribe((response: Response) => {
      if (response && response['data']) {
        this.statusesObject = response['data'];
        this.statuses = [];
        _.each(response['data'].statuses, (val, key) => {
          if (val) {
            this.statuses.push({
              value: key,
              label: val
            });
          }
        })
      }
    });

    this.reservationService.getTypeList().subscribe((response) => {
      if (response) {
        const typesData = response;
        this.typesObject = {};
        _.each(typesData, (val, key) => {
          this.typesObject[val] = key;
        });
        this.types = [];
        _.each(response, (val, key) => {
          if (val) {
            this.types.push({
              value: val,
              label: key
            });
          }
        })
      }
    });


    this.reservationService.onCreateOrEdit$.subscribe(() => {
      this.getList();
    });
  }

  public ngOnDestroy(): void {
    this.filterFormSubscription.unsubscribe();
  }

  getList() {
    this.loadingIndicator = true;
    this.reservationService.getList(this.getDataPageRequest())
      .subscribe((result: ReservationCustomDataPage) => {
        this.reservationData = result;
        this.loadingIndicator = false;
      });
  }

  private createFilterForm() {
    this.filterForm = this.formBuilder.group({
      identification: [''],
      owner: [''],
      publisher: [''],
      editionNumber: [null],
      editionYear: [null],
      title: [''],
      client: [''],
      placementCategory: [''],
      dateFrom: [null],
      dateTo: [null],
      status: [''],
      salesman: [''],
      comment: [''],
      type: ['']
    });

    this.filterFormSubscription = this.filterForm
      .valueChanges
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.updateFilter(null);
      });
  }

  setDataPageRequest() {
    this.currentOffset = 0;
    this.dataPageRequest = new ReservationDataPageRequest({limit: 20, filters: new ReservationDataPageFilters({platform: 'print'})});
  }

  getDataPageRequest(): ReservationDataPageRequest {
    return this.dataPageRequest;
  }

  setPage($event) {
    this.dataPageRequest.setPageByOffset($event.offset);
    this.dataPageRequest.setLimit($event.limit);
    this.currentOffset = $event.offset;
    this.getList();
  }

  onSort($event) {
  }

  onListTypeChange($event) {
    if ($event.index === 0) {
      this.router.navigate(['/reservations']);
    }
  }

  updateFilter($event, type?: any) {
    const filterData = {
      identification: this.filterForm.controls['identification'].value,
      publisher: this.filterForm.controls['publisher'].value,
      comment: this.filterForm.controls['comment'].value,
      client: this.filterForm.controls['client'].value,
      owner: this.filterForm.controls['owner'].value,
      title: this.filterForm.controls['title'].value,
      editionNumber: this.filterForm.controls['editionNumber'].value,
      editionYear: this.filterForm.controls['editionYear'].value,
      dateFrom: this.filterForm.controls['dateFrom'].value,
      dateTo: this.filterForm.controls['dateTo'].value,
      salesman: this.filterForm.controls['salesman'].value,
      status: this.filterForm.controls['status'].value,
      type: this.filterForm.controls['type'].value,
      platform: 'print'
    };
    if (type === 'dateFrom') {
      filterData.dateFrom = $event;
    }
    if (type === 'dateTo') {
      filterData.dateTo = $event;
    }
    this.dataPageRequest.filters = new ReservationDataPageFilters(filterData);
    if (filterData.dateFrom) {
      this.dataPageRequest.filters.setDateFromToBeginDay();
    }
    if (filterData.dateTo) {
      this.dataPageRequest.filters.setDateToToEndDay();
    }
    this.dataPageRequest.setPage(1);
    this.getList();
  }

  openReservationEditPopup($event): void {
    let dialogRef = this.dialog.open(ReservationEditPopupComponent, {
      width: '640px',
      data: {
        reservationItemData: $event
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getList();
      }
    });
  }

  deleteReservation($event) {
    let confirmPopupData: IConfirmPopup = {
      title: 'Usuwanie',
      message: null
    };
    confirmPopupData.message = `Czy chcesz usunąć rezerwację?`;

    if ($event.identification) {
      confirmPopupData.message = `Czy chcesz usunąć rezerwację o nr ${$event.identification}?`;
    }

    let dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '440px',
      data: confirmPopupData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reservationService.deleteReservation($event.id).subscribe(() => {
          this.toaster.displayMessage('Rezerwacja została usunięta');
          this.getList();
        });
      }

    });
  }
}
