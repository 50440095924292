import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { ReservationDiscountModel } from './reservation-discount.model';

export class ReservationDiscountBuilder {
  constructor(private discountTypes: Array<any>, private discounts) {

  }

  buildDiscounts() {
    let discounts = [];

    if (this.discounts && !this.discounts.length || !this.discounts) {

      _.each(this.discountTypes, val => {
        const discount = new ReservationDiscountModel({
          name: val.label,
          id: val.id,
          type: val.id,
          percentValue: null,
          amountValue: null,
          formControl: null
        });

        if (val.id === '0') {
          const discount1: ReservationDiscountModel = _.clone(discount);
          const discount2: ReservationDiscountModel = _.clone(discount);
          const discount3: ReservationDiscountModel = _.clone(discount);

          discount1.name = `${discount1.name} 1`;
          discount1.id = `${discount1.id}_1`;
          discount1.formControl = new FormControl();

          discount2.name = `${discount2.name} 2`;
          discount2.id = `${discount2.id}_2`;
          discount2.formControl = new FormControl();

          discount3.name = `${discount3.name} 3`;
          discount3.id = `${discount3.id}_3`;
          discount3.formControl = new FormControl();

          discounts.push(discount1);
          discounts.push(discount2);
          discounts.push(discount3);
        } else {
          discount.formControl = new FormControl();
          discounts.push(discount);
        }

      })
    } else {

      _.each(this.discountTypes, val => {
        let discountValue = null;

        const discount = new ReservationDiscountModel({
          name: val.label,
          id: `${val.id}`,
          type: val.id,
          percentValue: (val.id === '0' || val.id === '2') ? discountValue : null,
          amountValue: (val.id === '1' || val.id === '3') ? discountValue : null,
          formControl: null
        });

        if (val.id === '0') {
          const discount1: ReservationDiscountModel = _.clone(discount);
          const discount2: ReservationDiscountModel = _.clone(discount);
          const discount3: ReservationDiscountModel = _.clone(discount);

          discount1.name = `${discount1.name} 1`;
          discount1.id = `${discount1.type}_1`;
          const discount1Current: ReservationDiscountModel = _.find(this.discounts, (discountItem: ReservationDiscountModel) => {
            if (discountItem.id === discount1.id) {
              return true;
            }
          });
          discount1.formControl = new FormControl();
          discount1.formControl.setValue(discount1Current ? discount1Current.percentValue : null);

          discount2.name = `${discount2.name} 2`;
          discount2.id = `${discount2.type}_2`;
          const discount2Current: ReservationDiscountModel = _.find(this.discounts, (discountItem: ReservationDiscountModel) => {
            if (discountItem.id === discount2.id) {
              return true;
            }
          });
          discount2.formControl = new FormControl();
          discount2.formControl.setValue(discount2Current ? discount2Current.percentValue : null);

          discount3.name = `${discount3.name} 3`;
          discount3.id = `${discount3.type}_3`;
          const discount3Current: ReservationDiscountModel = _.find(this.discounts, (discountItem: ReservationDiscountModel) => {
            if (discountItem.id === discount3.id) {
              return true;
            }
          });
          discount3.formControl = new FormControl();
          discount3.formControl.setValue(discount3Current ? discount3Current.percentValue : null);

          discounts.push(discount1);
          discounts.push(discount2);
          discounts.push(discount3);
        } else {

          const discountCurrent: ReservationDiscountModel = _.find(this.discounts, (discountItem: ReservationDiscountModel) => {
            if (discountItem.id === discount.id) {
              return true;
            }
          });
          discount.formControl = new FormControl();
          if (val.id === '1' || val.id === '3') {
            discount.formControl.setValue(discountCurrent ? discountCurrent.amountValue : null);
          } else if (val.id === '2') {
            discount.formControl.setValue(discountCurrent ? discountCurrent.percentValue : null);
          }

          discounts.push(discount);
        }
      })
    }
    return discounts;
  }
}
