import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Route } from '../core/route.service';

import { AccountComponent } from './account/account.component';
import { ClientListComponent } from './client/client-list/client-list.component';
import { HomeComponent } from './home/home.component';
import { OrderListPrintComponent } from './order/order-list-print/order-list-print.component';
import { OrderListComponent } from './order/order-list/order-list.component';
import { ReportComponent } from './report/report/report.component';
import { ReservationListComponent } from './reservation/reservation-list/reservation-list.component';
import { ReservationPrintListComponent } from './reservation/reservation-print-list/reservation-print-list.component';
import { CoordinatorOrderListComponent} from './coordinator/coordinator-order-list/coordinator-order-list.component';
import {CoordinatorOrderListPrintComponent} from './coordinator/coordinator-order-list-print/coordinator-order-list-print.component';

const routes: Routes = Route.setAsChildrenRoutesForMain([
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'account',
    component: AccountComponent,
    children: []
  },
  {
    path: 'home',
    component: HomeComponent,
    children: []
  },
  {
    path: 'reservations',
    component: ReservationListComponent,
    children: []
  },
  {
    path: 'reservationsPrint',
    component: ReservationPrintListComponent,
    children: []
  },
  {
    path: 'clients',
    component: ClientListComponent,
    children: []
  },
  {
    path: 'orders',
    component: OrderListComponent,
    children: []
  },
  {
    path: 'orders-manager',
    component: OrderListComponent,
    children: [],
    data: {
      managerMode: true
    }
  },
  {
    path: 'orders-coordinator',
    component: CoordinatorOrderListComponent,
    children: [],
    data: {
      isActionRequired: true
    }
  },
  {
    path: 'ordersPrint',
    component: OrderListPrintComponent,
    children: []
  },
  {
    path: 'ordersPrint-manager',
    component: OrderListPrintComponent,
    children: [],
    data: {
      managerMode: true
    }
  },
  {
    path: 'ordersPrint-coordinator',
    component: CoordinatorOrderListPrintComponent,
    children: [],
    data: {
      isActionRequired: true
    }
  },
  {
    path: 'reports',
    component: ReportComponent,
    children: []
  }
]);

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class MainRoutingModule {
}
