import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../main/profile/profile.service';
import { User } from '../../../main/user/user.model';

@Component({
  selector: 'ep-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  // @HostBinding('attr.class') cssClass = 'mat-elevation-z2';
  public user: User;

  constructor(private profileService: ProfileService) {
    this.profileService.getLoggedUser().subscribe(response => {
      this.user = response;
    });
  }

  ngOnInit() {
  }

}
