<div class="mat-dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
  <div>Dodaj rezerwację</div>
  <button mat-icon-button mat-button matDialogClose>
    <mat-icon class="icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <mat-radio-group (change)="onSelectTypeChange($event)"
                   [(ngModel)]="selectedType">
    <p>
      <mat-radio-button value="clone">Klon</mat-radio-button>
    </p>
    <p>
      <mat-radio-button value="package">Pakiet</mat-radio-button>
    </p>
    <p>
      <mat-radio-button value="placement">Placement</mat-radio-button>
    </p>
    <p>
      <mat-radio-button value="custom">Niestandardowa</mat-radio-button>
    </p>
    <p>
      <mat-radio-button value="print">Print</mat-radio-button>
    </p>
  </mat-radio-group>

  <div *ngIf="selectedType && selectedType === 'clone'">
    <mat-form-field class="full-width">
      <input type="text"
             placeholder="Wybierz rezerwację"
             aria-label="Wybierz rezerwację"
             matInput
             [formControl]="selectedReservationCloneControl"
             [matAutocomplete]="auto"
             (input)="onChangeReservationAutoComplete($event)">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayReservationName"
                        (optionSelected)="onReservationSelect($event)">
        <mat-progress-bar mode="indeterminate" *ngIf="isLoadingReservationList"></mat-progress-bar>
        <mat-option class="error" *ngIf="hintText !== null">{{ hintText }}</mat-option>
        <mat-option *ngFor="let reservation of reservationOptions" [value]="reservation">
          {{ reservation.label }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div *ngIf="showReservationCreate && selectedType && selectedType === 'clone' && reservationCloneItemData">
    <ep-reservation-create (onSaved)="onReservationSaved($event)"
                           [reservationItemData]="reservationCloneItemData"></ep-reservation-create>
  </div>
  <div *ngIf="showReservationCreate && selectedType && selectedType !== 'clone'">
    <ep-reservation-create (onSaved)="onReservationSaved($event)" [dateFrom]="dateFrom"
                           [selectedType]="selectedType"></ep-reservation-create>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button matDialogClose>Anuluj</button>
  <!-- Can optionally provide a result for the closing dialog. -->
  <button mat-button class="mat-primary mat-raised-button" (click)="clone()"
          *ngIf="selectedType && selectedType === 'clone'">Klonuj
  </button>
  <button mat-button class="mat-primary mat-raised-button" (click)="save()"
          *ngIf="selectedType && selectedType !== 'clone'">Dodaj rezerwację
  </button>
</mat-dialog-actions>
