import { Injectable, Optional } from '@angular/core';
import { DictionaryFiltersRequestModel } from './dictionary-filters-request.model';

@Injectable()
export class DictionaryRequestModel {
  filters: DictionaryFiltersRequestModel;

  constructor(@Optional() obj?: any) {
    this.filters = obj && obj.filters ? new DictionaryFiltersRequestModel(obj.filters) : null;
  }

  setFilters(filters) {
    this.filters = new DictionaryFiltersRequestModel(filters);
  }
}
