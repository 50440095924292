import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialComponentsModule } from '../../../core/material/material-components.module';
import { ClickOutsideDirective } from '../../utils/click-outside.directive';
import { ToolbarMainMenuComponent } from './toolbar-main-menu/toolbar-main-menu.component';
import { ToolbarUserButtonComponent } from './toolbar-user-button/toolbar-user-button.component';
import { ToolbarComponent } from './toolbar.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    RouterModule
  ],
  declarations: [
    ToolbarComponent,
    ToolbarUserButtonComponent,
    ToolbarMainMenuComponent,
    ClickOutsideDirective
  ],
  exports: [
    ToolbarComponent,
    ToolbarUserButtonComponent,
    ToolbarMainMenuComponent
  ]
})
export class ToolbarModule {
}
