import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

export function objectToParams(object, dateFormat?: string): string {
  if (object) {
    return Object.keys(object).map((key) => {
        if (_.isObject(object[key])) {
          return subObjectToParams(encodeURIComponent(key), object[key], dateFormat)
        } else {
          if (object[key]) {
            return `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`
          } else {
            return '';
          }
        }
      }
    ).join('&');
  } else {
    return '';
  }
}

function subObjectToParams(key, object, dateFormat?: string): string {
  if (!dateFormat) {
    dateFormat = 'yyyy-MM-dd HH:mm:ss'
  }
  return Object.keys(object).map((childKey) => {
    if (_.isObject(object[childKey] && !(object[childKey] instanceof Date))) {
      return subObjectToParams(`${key}[${encodeURIComponent(childKey)}]`, object[childKey])
    } else {
      if (object[childKey]) {
        if (object[childKey] instanceof Date) {
          if (Object.prototype.toString.call(object[childKey]) === '[object Date]') {
            // it is a date
            if (isNaN(object[childKey].getTime())) {
              return undefined;
            } else {
              let date = new DatePipe('pl-PL').transform(object[childKey], dateFormat);
              return `${key}[${encodeURIComponent(childKey)}]=${encodeURIComponent(date)}`
            }
          }

        } else if (_.isObject(object[childKey])) {
          let paramString = [];
          _.each(object[childKey], (val, keyItem) => {
            paramString.push(`${key}[${encodeURIComponent(childKey)}][${encodeURIComponent(keyItem + '')}]=${encodeURIComponent(val)}`)
          });
          let h = paramString.join('&');
          return h;
        } else {
          return `${key}[${encodeURIComponent(childKey)}]=${encodeURIComponent(object[childKey])}`
        }
      } else {
        return undefined;
      }
    }
  }).filter(function (item) {
    return (item)
  }).join('&');
}
