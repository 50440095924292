import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IConfirmPopup } from './confirm-popup.interface';

@Component({
  selector: 'ep-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent {

  constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IConfirmPopup) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ok(): void {
    this.dialogRef.close(true);
  }
}
