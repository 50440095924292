<div class="mat-dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
  <div>{{ data.title }}</div>
  <button mat-icon-button mat-button matDialogClose>
    <mat-icon class="icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <p>
    {{ data.message }}
  </p>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button class="mat-primary mat-raised-button" (click)="ok()">Zamknij</button>
</mat-dialog-actions>
