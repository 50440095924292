import { DatePipe } from '@angular/common';
import { Injectable, Optional } from '@angular/core';
import * as _ from 'lodash';

import { Client } from '../../client/client.model';
import { User } from '../../user/user.model';
import { IOrderRequestData } from '../interface/order-request-data.interface';

@Injectable()
export class OrderRequest {
  id: number;
  name: string;
  client: Client;
  dateFrom: Date;
  dateTo: Date;
  comment: string;
  orderDocument: any;
  additionalDocuments: string[];
  reservations: number[];
  kind: string;
  status: string;
  platform: string;
  clone?: number;
  pdfPriceVisible?: number;
  pdfDiscountVisible?: number;
  pdfQuantityVisible?: number;
  owner?: User;
  salesman: number;
  principal: number;
  principalPerson: string;
  saveAndSend?: number;

  constructor(@Optional() obj?: any) {
    this.id = obj && obj.id || null;
    this.name = obj && obj.name || null;
    this.client = obj && obj.client ? new Client(obj.client) : null;
    this.dateFrom = obj && obj.dateFrom ? new Date(obj.dateFrom) : null;
    this.dateTo = obj && obj.dateTo ? new Date(obj.dateTo) : null;
    this.comment = obj && obj.comment || null;
    this.orderDocument = obj && obj.orderDocument || null;
    this.additionalDocuments = obj && obj.additionalDocuments || null;
    this.status = obj && obj.status || null;
    this.platform = obj && obj.platform || undefined;
    this.kind = obj && obj.kind || null;
    this.salesman = obj && obj.salesman || null;
    this.principal = obj && obj.principal || null;
    this.principalPerson = obj && obj.principalPerson || null;

    this.pdfPriceVisible = obj && obj.pdfPriceVisible ? 1 : 0;
    this.pdfDiscountVisible = obj && obj.pdfDiscountVisible ? 1 : 0;

    this.pdfQuantityVisible = obj && obj.pdfQuantityVisible ? 1 : 0;
    this.saveAndSend = obj && obj.saveAndSend ? 1 : undefined;

    this.owner = (obj && obj.owner) ? new User(obj.owner) : null;
    this.reservations = obj && obj.reservations || [];
  }

  mergeData(obj: OrderRequest) {
    this.id = obj && obj.id || this.id;
    this.name = obj && obj.name || this.name;
    this.client = obj && obj.client ? new Client(obj.client) : this.client;
    this.dateFrom = obj && obj.dateFrom ? new Date(obj.dateFrom) : this.dateFrom;
    this.dateTo = obj && obj.dateTo ? new Date(obj.dateTo) : this.dateTo;
    this.comment = obj && obj.comment || this.comment;
    this.salesman = obj && obj.salesman || this.salesman;
    this.principal = obj && obj.principal || this.principal;
    this.principalPerson = obj && obj.principalPerson || this.principalPerson;
    this.orderDocument = obj && !_.isUndefined(obj.orderDocument) ? obj.orderDocument : this.orderDocument;
    this.additionalDocuments = obj && !_.isUndefined(obj.additionalDocuments) ? obj.additionalDocuments : this.additionalDocuments;
    this.status = obj && obj.status || this.status;
    this.pdfPriceVisible = obj && obj.pdfPriceVisible ? 1 : 0;
    this.pdfDiscountVisible = obj && obj.pdfDiscountVisible ? 1 : 0;
    this.pdfQuantityVisible = obj && obj.pdfQuantityVisible ? 1 : 0;
    this.saveAndSend = obj && obj.saveAndSend ? 1 : undefined;

    this.platform = obj && !_.isUndefined(obj.platform) ? obj.platform : this.platform;
    this.kind = obj && obj.kind || this.kind;
    this.owner = (obj && obj.owner) ? new User(obj.owner) : this.owner;
    if (!_.isArray(this.reservations)) {
      this.reservations = [];
    }
    this.reservations = obj && obj.reservations || this.reservations;
  }

  getRequestData(): any {
    const requestData: IOrderRequestData = {
      id: this.id || undefined,
      name: this.name || null,
      dateFrom: null,
      dateTo: null,
      reservations: this.reservations,
      client: this.client ? this.client.id : null,
      comment: this.comment ? this.comment.replace(/(\n)/g, '\\n') : null,
      orderDocument: null,
      additionalDocuments: null,
      status: this.status || null,
      platform: this.platform || undefined,
      kind: this.kind || null,
      pdfPriceVisible: this.pdfPriceVisible ? 1 : 0,
      pdfDiscountVisible: this.pdfDiscountVisible ? 1 : 0,
      pdfQuantityVisible: this.pdfQuantityVisible ? 1 : 0,
      owner: null,
      clone: this.clone ? 1 : 0,
      publisherStatistics: null,
      principalPerson: this.principalPerson,
      salesman: this.salesman,
      principal: this.principal,
      saveAndSend: undefined,
      validateStatistics: undefined
    };


    if (this.owner && this.owner.id) {
      requestData.owner = this.owner.id;
    }

    if (this.orderDocument && this.orderDocument.id) {
      requestData.orderDocument = this.orderDocument.id;
    }

    if (this.additionalDocuments && _.isArray(this.additionalDocuments) && this.additionalDocuments.length) {
      requestData.additionalDocuments = _.map(this.additionalDocuments, 'id');
    }

    if (this.platform !== 'print') {
      requestData.dateFrom = this.dateFrom ? new DatePipe('pl-PL').transform(this.dateFrom, 'yyyy-MM-dd') : null;
      requestData.dateTo = this.dateTo ? new DatePipe('pl-PL').transform(this.dateTo, 'yyyy-MM-dd') : null;

      const reservationTypes = _.uniq(_.compact(_.map(requestData.reservations, 'type')));
      if (reservationTypes.length > 1) {
        requestData.kind = 'mixed';
      }
    }

    if (this.status === null) {
      requestData.status = undefined;
    } else {
      requestData.status = this.status;
    }

    requestData.reservations = _.uniq(requestData.reservations);
    return requestData;
  }
}
