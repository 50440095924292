import { Injectable, Optional } from '@angular/core';
import * as _ from 'lodash';
import { TreeItemButtonModel } from './tree-item-button.model';

@Injectable()
export class TreeItemModel {
  id?: string;
  type: string;
  label: string;
  item: any;
  children: Array<TreeItemModel>;
  parentItem: TreeItemModel;
  buttons: Array<TreeItemButtonModel>;
  icon: string;
  addEnabled?: string;
  expanded?: boolean;
  onExpandAction?: string;
  selected?: boolean;
  hasChildrenItem?: boolean;

  constructor(@Optional() obj?: any) {
    this.id = '_' + Math.random().toString(36).substr(2, 9);
    this.type = obj && obj.type || null;
    this.label = obj && obj.label || null;
    this.item = obj && obj.item || null;
    this.children = obj && obj.children || null;
    this.buttons = obj && obj.buttons || [];
    this.icon = obj && obj.icon || null;
    this.addEnabled = obj && obj.addEnabled || null;
    this.expanded = obj && obj.expanded || false;
    this.parentItem = obj && obj.parentItem || false;
    this.onExpandAction = obj && obj.onExpandAction || false;
    this.selected = obj && obj.selected || false;
    this.hasChildrenItem = obj && obj.hasChildrenItem || false;
  }
}
