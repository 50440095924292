import { AfterContentInit, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NgModel } from '@angular/forms';
import AutoNumeric from '../../../../../node_modules/autonumeric/src/main.js';

@Directive({
  selector: '[epAutoNumeric]',
  providers: [NgModel]
})
export class AutoNumericDirective implements ControlValueAccessor, OnInit, AfterContentInit {
  propagateChange: any = () => {
    console.log('ddd');
  };
  anElement: any;
  value: any;
  model: any;
  @Input() epAutoNumeric: number;
  @Input() formControl: FormControl;
  @Input() maximumValue: string;

  constructor(private el: ElementRef, private ngModel: NgModel) {
    this.model = ngModel;
  }

  ngOnInit() {

  }

  ngAfterContentInit(): void {
    if (!this.epAutoNumeric) {
      this.epAutoNumeric = 0;
    }
    const autoNumericOptionsEuro = {
      modifyValueOnWheel: false,
      digitGroupSeparator: ' ',
      decimalCharacter: ',',
      decimalCharacterAlternative: '.',
      minimumValue: AutoNumeric.options.minimumValue.zero,
      maximumValue: '9999999999999.99',
      decimalPlaces: this.epAutoNumeric,
    };

    if (this.maximumValue) {
      autoNumericOptionsEuro.maximumValue = this.maximumValue
    }

    this.anElement = new AutoNumeric(this.el.nativeElement, autoNumericOptionsEuro);
  }

  writeValue(value) {
    if (value) {
      this.value = value;
      this.anElement.clear();
    }
  }

  registerOnChange(fn): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }
}
