<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%;">
  <div class="forgot mat-elevation-z12">
    <div class="forgot-header" fxLayout="column" fxLayoutAlign="center center">
      <img src="assets/img/icon.png">
    </div>
    <form #form="ngForm" (ngSubmit)="send()">
      <div class="forgot-content" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-form-field>
          <input matInput placeholder="E-Mail" type="text" name="email" required [(ngModel)]="email">
        </mat-form-field>
        <button color="primary" mat-raised-button [disabled]="!form.valid">RECOVER PASSWORD</button>
        <p class="note"><a [routerLink]="['/login']">Back to login</a></p>
      </div>
    </form>
  </div>
</div>
