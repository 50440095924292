export class OrderDataPageFilters {
  identification: number;
  name: string;
  client: string;
  salesman: string;
  creator: string;
  title: string;
  comment: string;
  owner: string;
  manager: string;
  platform: string;
  editionNumber: number;
  editionYear: number;
  dateFrom: Date;
  dateTo: Date;
  status: any;

  constructor(obj?: any) {
    this.identification = obj && obj.identification || null;
    this.name = obj && obj.name || null;
    this.creator = obj && obj.creator || null;
    this.title = obj && obj.title || null;
    this.comment = obj && obj.comment || null;
    this.client = obj && obj.client || null;
    this.salesman = obj && obj.salesman || null;
    this.owner = obj && obj.owner || null;
    this.manager = obj && obj.manager || null;
    this.platform = obj && obj.platform || null;
    this.editionNumber = obj && obj.editionNumber || null;
    this.editionYear = obj && obj.editionYear || null;
    this.dateFrom = obj && obj.dateFrom ? new Date(obj.dateFrom) : null;
    this.dateTo = obj && obj.dateTo ? new Date(obj.dateTo) : null;
    this.status = obj && obj.status || null;
  }

  setDateFromToBeginDay() {
    let dateFrom = new Date(this.dateFrom);
    dateFrom.setHours(0, 0, 0, 0);

    this.dateFrom = dateFrom;
  }

  setDateToToEndDay() {
    let dateTo = new Date(this.dateTo);
    dateTo.setHours(23, 59, 59, 999);

    this.dateTo = dateTo;
  }
}
