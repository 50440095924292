import { IPublisherDataPageFiltersRequest } from '../interface/publisher-data-page-filters-request.interface';
import { PublisherDataPageFilters } from './publisher-data-page-filters';

export class PublisherDataPageRequest {
  public filters: IPublisherDataPageFiltersRequest;

  constructor(obj?: any) {
    this.filters = (obj && obj.filters) ? new PublisherDataPageFilters(obj.filters) : null;
  }

  setFilters(filters) {
    this.filters = new PublisherDataPageFilters(filters);
  }
}
