import { Injectable, Optional } from '@angular/core';

@Injectable()
export class SalesModelDictionaryModel {
  id: number;
  name: string;

  constructor(@Optional() obj?: any) {
    this.id = obj && obj.id || null;
    this.name = obj && obj.name || null;
  }
}
