import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class TransformerService {

  constructor() {

  }

  public toDouble(value: any) {
    if (value && _.isString(value)) {
      value = value.split(',').join('');
      return parseFloat(value);
    } else {
      return value
    }
  }
}
