import { Injectable, Optional } from '@angular/core';
import { PaymentPartitionModel } from '../../../shared/model/payment-partition.model';
import { TransformerService } from '../../../shared/utils/transformer.service';


@Injectable()
export class ReservationRequestModel {
  id?: number;
  client: number;
  dateFrom: string;
  dateTo: string;
  editionNumber: number;
  editionYear: number;
  placementCategory: number;
  placements: string;
  publisher: number;
  publishers: string;
  status: string;
  emissionsNumber: number;
  emissionValue: number;
  brand: number;
  orderForm: number;
  campaignType: number;
  paymentType: number;
  salesModel?: number;
  salesman: number;
  title: number;
  reservationId: number;
  trade: number;
  principal: number;
  comment: string;
  capping: number;
  targetUrl: string;
  technicalCosts: number;
  value: number;
  agencyFee: number;
  priceListPrice: number;
  fixedPrice: number;
  type: string;
  discounts: any;
  format: number;
  creationId: number;
  name: string;
  selectedPlacementsText: Array<string>;
  materialsDeliveryTime: string;
  priceList: number;
  paymentPartition: Array<PaymentPartitionModel>;

  constructor(@Optional() obj: any,
              private transformerService: TransformerService) {


    this.id = obj && obj.id || undefined;
    this.client = obj && obj.client ? obj.client : obj && obj.client === 0 ? 0 : null;
    this.dateFrom = obj && obj.dateFrom || null;
    this.dateTo = obj && obj.dateTo || null;

    this.placementCategory = obj && obj.placementCategory || undefined;
    this.placements = obj && obj.placements || undefined;
    this.publisher = obj && obj.publisher || null;
    this.publishers = obj && obj.publishers || undefined;
    this.status = obj && obj.status || undefined;

    this.paymentPartition = obj && obj.paymentPartition || null;

    this.creationId = obj && obj.creationId || undefined;
    this.emissionsNumber = obj && obj.emissionsNumber ? obj.emissionsNumber : obj && obj.emissionsNumber === 0 ? 0 : null;
    this.emissionsNumber = this.transformerService.toDouble(this.emissionsNumber);


    this.emissionValue = obj && obj.emissionValue ? obj.emissionValue : obj && obj.emissionValue === 0 ? 0 : null;
    this.emissionValue = this.transformerService.toDouble(this.emissionValue);

    this.brand = obj && obj.brand ? obj.brand : obj && obj.brand === 0 ? 0 : null;
    this.orderForm = obj && obj.orderForm ? obj.orderForm : obj && obj.orderForm === 0 ? 0 : null;
    this.campaignType = obj && obj.campaignType ? obj.campaignType : obj && obj.campaignType === 0 ? 0 : null;
    this.paymentType = obj && obj.paymentType ? obj.paymentType : obj && obj.paymentType === 0 ? 0 : null;
    this.salesModel = obj && obj.salesModel ? obj.salesModel : obj && obj.salesModel === 0 ? 0 : null;
    this.salesman = obj && obj.salesman ? obj.salesman : obj && obj.salesman === 0 ? 0 : null;
    this.trade = obj && obj.trade ? obj.trade : obj && obj.trade === 0 ? 0 : null;
    this.principal = obj && obj.principal ? obj.principal : obj && obj.principal === 0 ? 0 : null;
    this.comment = obj && obj.comment.replace(/(\n)/g, '\\n') || null;
    this.capping = obj && obj.capping ? obj.capping : obj && obj.capping === 0 ? 0 : undefined;
    this.title = obj && obj.title ? obj.title : obj && obj.title === 0 ? 0 : null;
    this.reservationId = obj && obj.reservationId ? obj.reservationId : undefined;
    this.capping = this.transformerService.toDouble(this.capping);

    this.targetUrl = obj && obj.targetUrl || null;
    this.name = obj && obj.name || null;

    this.technicalCosts = obj && obj.technicalCosts || 0;
    this.technicalCosts = this.transformerService.toDouble(this.technicalCosts);

    this.value = obj && obj.value ? obj.value : obj && obj.value === 0 ? 0 : null;
    this.value = this.transformerService.toDouble(this.value);

    this.agencyFee = obj && obj.agencyFee || 0;
    this.agencyFee = this.transformerService.toDouble(this.agencyFee);

    this.type = obj && obj.type || null;
    this.discounts = obj && obj.discounts || [];

    this.priceListPrice = obj && obj.priceListPrice ? obj.priceListPrice : obj && obj.priceListPrice === 0 ? 0 : null;
    this.priceListPrice = this.transformerService.toDouble(this.priceListPrice);

    this.fixedPrice = obj && obj.fixedPrice ? obj.fixedPrice : obj && obj.fixedPrice === 0 ? 0 : null;
    this.fixedPrice = this.transformerService.toDouble(this.fixedPrice);

    this.format = obj && obj.format ? obj.format : obj && obj.format === 0 ? 0 : null;

    this.editionNumber = obj && obj.editionNumber ? obj.editionNumber : obj && obj.editionNumber === 0 ? 0 : null;
    this.editionYear = obj && obj.editionYear ? obj.editionYear : obj && obj.editionYear === 0 ? 0 : null;
    this.selectedPlacementsText = obj && obj.selectedPlacementsText || [];

    this.materialsDeliveryTime = obj && obj.materialsDeliveryTime || null;
    this.priceList = obj && obj.priceList || null;
  }

  getRequestModel(type: string) {
    let requestObj = {
      id: this.id,
      client: this.client,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      placementCategory: this.placementCategory,
      placements: this.placements,
      publisher: undefined,
      publishers: undefined,
      emissionsNumber: undefined,
      brand: this.brand,
      orderForm: undefined,
      campaignType: undefined,
      paymentType: this.paymentType,
      salesModel: undefined,
      salesman: this.salesman,
      trade: this.trade,
      principal: this.principal,
      comment: this.comment.replace(/(\n)/g, '\\n'),
      materialsDeliveryTime: undefined,
      capping: undefined,
      targetUrl: undefined,
      value: this.value,
      agencyFee: undefined,
      title: undefined,
      status: undefined,
      technicalCosts: undefined,
      reservationId: undefined,
      type: undefined,
      discounts: undefined,
      priceListPrice: undefined,
      fixedPrice: undefined,
      format: undefined,
      name: undefined,
      editionNumber: undefined,
      editionYear: undefined,
      creation: undefined,
      selectedPlacementsText: undefined,
      priceList: undefined,
      paymentPartition: undefined
    };

    if (type === 'placement') {
      requestObj.publishers = undefined; // please refactor - delete getPublisherIds from component create form
      requestObj.status = this.status;
      requestObj.reservationId = this.reservationId;
      requestObj.targetUrl = this.targetUrl;
      requestObj.capping = this.capping;
      requestObj.salesModel = this.salesModel;
      requestObj.campaignType = this.campaignType;
      requestObj.orderForm = this.orderForm;
      requestObj.emissionsNumber = this.emissionsNumber;
      requestObj.creation = this.creationId || null;
      requestObj.selectedPlacementsText = this.selectedPlacementsText || null;
      requestObj.priceListPrice = this.priceListPrice;
      requestObj.fixedPrice = this.fixedPrice;
      requestObj.discounts = this.discounts;
      requestObj.paymentPartition = this.paymentPartition;

    }
    if (type === 'package') {
      requestObj.status = this.status;
      requestObj.reservationId = this.reservationId;
      requestObj.targetUrl = this.targetUrl;
      requestObj.capping = this.capping;
      requestObj.salesModel = this.salesModel;
      requestObj.campaignType = this.campaignType;
      requestObj.orderForm = this.orderForm;
      requestObj.emissionsNumber = this.emissionsNumber;
      requestObj.creation = this.creationId || null;
      requestObj.priceListPrice = this.priceListPrice;
      requestObj.fixedPrice = this.fixedPrice;
      requestObj.discounts = this.discounts;
      requestObj.paymentPartition = this.paymentPartition;

    }
    if (type === 'custom') {
      requestObj.reservationId = this.reservationId;
      requestObj.placements = undefined;
      requestObj.title = undefined;
      requestObj.publishers = this.publishers;
      requestObj.agencyFee = this.agencyFee;
      requestObj.value = this.value;
      requestObj.technicalCosts = this.technicalCosts;
      requestObj.salesModel = this.salesModel;
      requestObj.campaignType = this.campaignType;
      requestObj.orderForm = this.orderForm;
      requestObj.status = this.status;
      requestObj.name = this.name;
      requestObj.priceListPrice = this.priceListPrice;
      requestObj.fixedPrice = this.fixedPrice;
      requestObj.discounts = this.discounts;
      requestObj.priceList = this.priceList;
      requestObj.paymentPartition = this.paymentPartition;

    }

    if (type === 'print') {
      requestObj.id = this.reservationId;
      requestObj.title = this.title;
      requestObj.format = this.format;
      requestObj.priceListPrice = this.priceListPrice;
      requestObj.fixedPrice = this.fixedPrice;
      requestObj.type = this.type;
      requestObj.discounts = this.discounts;
      requestObj.status = this.status;
      requestObj.editionNumber = this.editionNumber;
      requestObj.editionYear = this.editionYear;
      requestObj.dateFrom = undefined;
      requestObj.dateTo = undefined;
      requestObj.technicalCosts = this.technicalCosts;
      requestObj.materialsDeliveryTime = this.materialsDeliveryTime;
    }
    return requestObj;
  }
}
