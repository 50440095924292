import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as _ from 'lodash';
import { TreeItemModel } from '../../../shared/component/tree/tree-item.model';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { DictionaryService } from '../../dictionary/dictionary.service';
import { DictionaryRequestModel } from '../../dictionary/model/dictionary-request.model';
import { PublisherGroupDictionaryModel } from '../../dictionary/model/publisher-group-dictionary.model';
import { PlacementService } from '../../placement/placement.service';
import { PublisherService } from '../../publisher/publisher.service';


@Component({
  selector: 'ep-publisher-tree-popup',
  templateUrl: './publisher-tree-popup.component.html',
  styleUrls: ['./publisher-tree-popup.component.scss']
})
export class PublisherTreePopupComponent {
  publisherGroupTree: Array<TreeItemModel>;
  publisherGroupList: Array<PublisherGroupDictionaryModel>;
  selectedPublishers: Array<any>;
  selectedPlacements: Array<any>;
  selectedPlacementsLabel: string;
  isLoading: boolean = true;
  isLoadingTreeChildrenItems: any = {};

  placementDataPageRequest = {
    filters: {
      creation: null,
      placementId: null
    }
  };

  constructor(public dialogRef: MatDialogRef<PublisherTreePopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private toaster: ToasterService,
              private placementService: PlacementService,
              private publisherService: PublisherService,
              private dictionaryService: DictionaryService) {
    this.getPublisherGroupList();
    this.setUpPlacementTree();
  }

  onNoClick(): void {
    this.dialogRef.close(this.data.placementTreeCopy);
  }

  getPublisherGroupList(dictionaryRequest?: DictionaryRequestModel): void {
    this.dictionaryService.getPublisherGroups(dictionaryRequest)
      .subscribe(publishers => {
        this.publisherGroupList = _.cloneDeep(publishers);
        this.publisherGroupTree = this.buildTreeItems(publishers);

        this.isLoading = false;
      });
  }

  setUpPlacementTree() {
    // this.selectedPublishers = _.cloneDeep(this.data.selectedPublishers);
    // this.selectedPlacements = _.cloneDeep(this.data.selectedPlacements);
    // if (this.data.placementTreeCopy) {
    //     this.placementItems = _.cloneDeep(this.data.placementTreeCopy);
    //     this.isLoading = false;
    // } else {
    //     this.getPublisherDataPage();
    // }
  }

  buildTreeItems(items, selectedItems?): Array<TreeItemModel> {

    return items;
  }

  treeActions = {};

  selectedItems() {
    this.selectedPublishers = this.getSelectedItemsBy(this.publisherGroupTree, 'id', 'publisherGroup');
    this.selectedPlacementsLabel = this.getSelectedItemsBy(this.publisherGroupTree, 'label').join(', ');
  }

  getSelectedItemsBy(items: Array<TreeItemModel>, property: string, itemProperty?: string) {
    let a = _.map(items, (item: TreeItemModel) => {
      if (item.selected) {
        if (!itemProperty) {
          return item[property];
        } else {
          if (item.item[itemProperty]) {
            if (property) {
              return item.item[itemProperty][property]
            } else {
              return item.item[itemProperty]
            }
          }
        }
      }
    });
    _.each(items, (item: TreeItemModel) => {
      if (item.children && _.isArray(item.children) && item.children.length) {
        a = _.concat(a, this.getSelectedItemsBy(item.children, property, itemProperty));
      }
    });

    return _.compact(a);
  }

  treeAction($event) {
    if ($event.selectedItem.hasChildrenItem) {
      if ($event.actionName === 'getPlacementsByPublisher') {
        this.treeActions[$event.actionName]($event.selectedItem.item.publisher.publisherId, $event.selectedItem.id);
      }
      if ($event.actionName === 'getPlacementsByPlacement') {
        this.treeActions[$event.actionName]($event.rootItem.item.publisher.publisherId, $event.selectedItem.item.placement.placementId, $event.selectedItem.id);
      }
    }
  }

  closeWithSelected() {

  }

  cancel() {
    this.dialogRef.close({
      selectedPlacements: _.cloneDeep(this.data.selectedPlacements),
      selectedPublishers: _.cloneDeep(this.data.selectedPublishers),
      selectedPlacementsLabel: this.getSelectedItemsBy(this.data.placementTreeCopy, 'label').join(', '),
      treeItems: _.cloneDeep(this.data.placementTreeCopy)
    });
  }
}
