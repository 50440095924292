import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import * as _ from 'lodash';
import { OrderCreatePopupComponent } from '../../order/order-create-popup/order-create-popup.component';
import { ReservationCreatePopupComponent } from '../../reservation/reservation-create/reservation-create-popup/reservation-create-popup.component';
import { ICalendarReservationListItem } from '../interface/calendar-reservation-list-item';

@Component({
  selector: 'ep-calendar-details-popup',
  templateUrl: './calendar-details-popup.component.html',
  styleUrls: ['./calendar-details-popup.component.scss']
})
export class CalendarDetailsPopupComponent {
  reservationList: ICalendarReservationListItem[] = [];

  constructor(public dialogRef: MatDialogRef<CalendarDetailsPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog) {


  }

  ngOnInit() {
    if (this.data && this.data.events && _.isArray(this.data.events) && this.data.events.length) {
      if (this.data.events[0].itemData && this.data.events[0].itemData.package) {
        _.each(this.data.events[0].itemData.package.items, (value, key) => {
          let reservationItem: ICalendarReservationListItem = {
            icon: 'photo_size_select_large',
            title: value['name'],
            subTitle: '122x344',
            occupancy: value['occupancy'],
            type: 'package',
            publisherName: value['publisherName'],
            children: []
          };

          _.each(value['placements'], placement => {
            let placementItem: ICalendarReservationListItem = {
              icon: 'photo',
              title: placement['name'],
              subTitle: '122x344',
              occupancy: placement['occupancy'],
              type: 'placement',
              publisherName: placement['publisherName'],
              children: []
            };

            reservationItem.children.push(placementItem);
          });

          this.reservationList.push(reservationItem);
        });
      }
      if (this.data.events[0].itemData && this.data.events[0].itemData.placement) {
        _.each(this.data.events[0].itemData.placement.items, (value, key) => {
          let reservationItem: ICalendarReservationListItem = {
            icon: 'photo',
            title: value['name'],
            subTitle: '522x874',
            occupancy: value['occupancy'],
            type: 'placement',
            publisherName: value['publisherName'],
            children: []
          };

          this.reservationList.push(reservationItem);
        });
      }
    }
  }

  openReservationCreatePopup(): void {
    let dialogRef = this.dialog.open(ReservationCreatePopupComponent, {
      width: '440px',
      data: {
        dateFrom: this.data.date
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe();

    this.cancel();
  }

  openOrderCreatePopup(): void {
    let dialogRef = this.dialog.open(OrderCreatePopupComponent, {
      width: '440px',
      data: {
        dateFrom: this.data.date
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe();

    this.cancel();
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
