<mat-form-field class="full-width" [class.mat-form-field-disabled]="isDisabled">
  <input type="text"
         placeholder="{{ placeholder }}"
         aria-label="placeholder"
         matInput
         [formControl]="selected"
         [matAutocomplete]="auto"
         [attr.disabled]="isDisabled ? true : null"
         [attr.readonly]="isDisabled ? true : null"
         (blur)="onChangeSearchTextValue($event)">
  <mat-error>
    <div *ngIf="selected.hasError('required')">Pole wymagane</div>
  </mat-error>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displaySelectedLabel"
    (optionSelected)="onSelectItem($event)">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <!--<mat-option class="error" *ngIf="isLoading"></mat-option>-->
    <mat-option class="error" *ngIf="hintText && isObject(selected.value) === '[object String]'">{{ hintText }}
      <button mat-button (click)="onClickNoResultAction.emit(true)"
              *ngIf="optionsStatusValue === autocompleteFieldOptionStatusEnum.noResults && showNoResultButton">
        Dodaj
      </button>
    </mat-option>
    <mat-option [value]="null" *ngIf="isObject(selected.value) === '[object Object]'">
      <em>--- anuluj wybór ---</em>
    </mat-option>
    <mat-option *ngFor="let item of options" [value]="item" title="{{ item.label }}">
      <span *ngIf="item.isIndentation && item.additionalData.isParent"><strong>{{ item.label }}</strong></span>
      <span *ngIf="item.isIndentation && !item.additionalData.isParent" class="indent">{{ item.label }}</span>
      <span *ngIf="!item.isIndentation">{{ item.label }}</span>
    </mat-option>

  </mat-autocomplete>
</mat-form-field>
