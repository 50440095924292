import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs/Rx';
import { ConfirmPopupComponent } from '../../../shared/popup/confirm-popup/confirm-popup.component';
import { IConfirmPopup } from '../../../shared/popup/confirm-popup/confirm-popup.interface';
import { ApiErrorManagerService } from '../../../shared/utils/api-error-manager.service';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { DictionaryService } from '../../dictionary/dictionary.service';
import { ManagerDictionaryModel } from '../../dictionary/model/manager-dictionary.model';
import { ProfileService } from '../../profile/profile.service';
import { OrderDataPage } from '../model/order-data-page';
import { OrderDataPageFilters } from '../model/order-data-page-filters';
import { IOrderDataPageRequest, OrderDataPageRequest } from '../model/order-data-page-request';
import { OrderEditPopupComponent } from '../order-edit-popup/order-edit-popup.component';
import { OrderService } from '../order.service';

@Component({
  selector: 'ep-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit, OnDestroy {
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  managers: Array<ManagerDictionaryModel> = [];
  orderData: OrderDataPage = new OrderDataPage();
  dataPageRequest: OrderDataPageRequest;
  currentOffset: number = 0;
  filterForm: FormGroup;
  statuses: object[] = [];
  statusesObject: any;
  isUserMerchant: boolean;
  showOnlyMyOrders: boolean = true;
  showOnlyMyOrdersControl: FormControl = new FormControl(true);

  noopFormControl: FormControl = new FormControl('', Validators.required);
  noopFormControl2: FormControl = new FormControl('', Validators.required);
  @Input() type?: string;

  private filterFormSubscription: Subscription;

  get managerMode(): boolean {
    return this.route && this.route.snapshot.data['managerMode']
      && this.profileService
      && this.profileService.currentUser
      && this.profileService.currentUser.type === 'manager';
  }

  get coordinatorMode(): boolean {
    return this.profileService
      && this.profileService.currentUser
      && this.profileService.currentUser.type === 'coordinator';
  }

  get coordinatorManagerId(): number {
    return this.profileService
    && this.profileService.currentUser
    && this.profileService.currentUser.assigned_manager
    && this.profileService.currentUser.assigned_manager.id ? this.profileService.currentUser.assigned_manager.id : undefined;
  }

  get isShowOnlyMyOrders(): boolean {
    return this.profileService
      && this.profileService.currentUser
      && this.profileService.currentUser.assigned_manager
      && this.coordinatorMode
      && this.showOnlyMyOrders;
  }

  get isActionRequired(): boolean {
    return this.route && this.route.snapshot.data['isActionRequired'];
  }

  constructor(private orderService: OrderService,
              private profileService: ProfileService,
              private dictionaryService: DictionaryService,
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private http: HttpClient,
              private errorManager: ApiErrorManagerService,
              private toaster: ToasterService,
              private router: Router,
              private route: ActivatedRoute) {
    this.createFilterForm();
    this.showOnlyMyOrdersControl.valueChanges.subscribe((show: boolean) => {
      this.toggleShowOnlyMyOrders(show);
    });
  }

  onRowClick($event) {
    if ($event.type === 'click' && $event.column.name !== 'actions') {
      this.openOrderEditPopup($event.row);
    }
  }

  ngOnInit() {
    if (!this.type) {
      if (this.coordinatorMode) {
        this.type = 'coordinatorList';
      } else if (this.managerMode) {
        this.type = 'managerList';
      } else {
        this.type = 'standardList';
      }
    }

    this.setDataPageRequest();
    this.getStatusList();
    this.getList();

    this.orderService.onOrderCreateOrEdit$.subscribe(() => {
      this.getList();
    });

    this.initUserType();
  }

  public ngOnDestroy(): void {
    this.filterFormSubscription.unsubscribe();
  }

  private initUserType(): void {
    this.isUserMerchant = (this.profileService.currentUser.type === 'merchant');
  }

  getList() {
    this.loadingIndicator = true;

    this.orderService.getList(this.getDataPageRequest(), this.isActionRequired).subscribe((result: OrderDataPage) => {
      this.orderData = result;
      this.loadingIndicator = false;
    });
  }

  getStatusList() {
    this.orderService.getStatuses(null, this.isActionRequired).subscribe((response: Response) => {
      if (response && response['data']) {
        this.statusesObject = response['data'];
        _.each(response['data'].statuses, (val, key) => {
          if (val) {
            this.statuses.push({
              value: key,
              label: val
            });
          }
        });
      }
    });
  }

  toggleShowOnlyMyOrders(isShow: boolean) {
    this.showOnlyMyOrders = isShow;
    this.setDataPageRequest();
    this.getList();
  }

  private createFilterForm() {
    this.filterForm = this.formBuilder.group({
      identification: [''],
      client: [''],
      creator: [''],
      name: [''],
      owner: [''],
      placementCategory: [''],
      dateFrom: [null],
      dateTo: [null],
      status: [''],
    });

    this.filterFormSubscription = this.filterForm.valueChanges
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.updateFilter(null);
      });
  }

  onListTypeChange($event) {
    if ($event.index === 1) {
      if (this.coordinatorMode && this.isActionRequired) {
        this.router.navigate(['/ordersPrint-coordinator']);
      } else if (this.managerMode) {
        this.router.navigate(['/ordersPrint-manager']);
      } else {
        this.router.navigate(['/ordersPrint']);
      }
    }
  }

  setDataPageRequest() {
    this.currentOffset = 0;
    this.dataPageRequest = new OrderDataPageRequest({
      filters: new OrderDataPageFilters({
        platform: 'digital',
        manager: this.isShowOnlyMyOrders ? this.coordinatorManagerId : undefined
      })
    });

    if (this.managerMode) {
      this.dataPageRequest.filters.status = 'not_accepted';
    }
  }

  getDataPageRequest(): IOrderDataPageRequest {
    return this.dataPageRequest;
  }

  setPage($event) {
    this.dataPageRequest.setPageByOffset($event.offset);
    this.dataPageRequest.setLimit($event.limit);
    this.currentOffset = $event.offset;
    this.getList();
  }

  onSort($event) {
  }

  updateFilter($event, type?: any) {
    setTimeout(() => {
      const filterData = {
        identification: this.filterForm.controls['identification'].value,
        client: this.filterForm.controls['client'].value,
        creator: this.filterForm.controls['creator'].value,
        name: this.filterForm.controls['name'].value,
        owner: this.filterForm.controls['owner'].value,
        dateFrom: this.filterForm.controls['dateFrom'].value,
        dateTo: this.filterForm.controls['dateTo'].value,
        status: this.filterForm.controls['status'].value,
        platform: 'digital'
      };
      if (type === 'dateFrom') {
        filterData.dateFrom = $event;
      }
      if (type === 'dateTo') {
        filterData.dateTo = $event;
      }
      this.dataPageRequest.filters = new OrderDataPageFilters(filterData);
      this.dataPageRequest.setPage(1);
      this.getList();
    }, 700);
  }

  openOrderEditPopup($event): void {
    const isEditable: boolean = !this.isUserMerchant || (this.isUserMerchant && $event.editable);
    const dialogRef = this.dialog.open(OrderEditPopupComponent, {
      width: '100%',
      height: '100%',
      maxWidth: null,
      data: {
        order: _.cloneDeep($event),
        isCoordinatorView: this.type === 'coordinatorList',
        isManagerView: this.managerMode,
        isEditable: isEditable
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getList();
      }
    });
  }

  printPdf($event): void {
    this.orderService.getPdfUrl($event.id).subscribe((response: Response) => {
      this.http.get(response['data'].url, {
        responseType: 'blob'
      }).subscribe((response) => {
        const file = response;
        saveAs(file, 'doc.pdf');
      });
    });
  }

  deleteOrder($event): void {
    const confirmPopupData: IConfirmPopup = {
      title: 'Usuwanie',
      message: null
    };
    confirmPopupData.message = `Czy chcesz usunąć zlecenie?`;

    if ($event.name) {
      confirmPopupData.message = `Czy chcesz usunąć zlecenie "${$event.name}"?`;
    }

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '440px',
      data: confirmPopupData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.deleteOrder($event.id).subscribe(() => {
          this.toaster.displayMessage('Zlecenie zostało usunięte');
          this.getList();
        }, err => {
          this.errorManager.displayError(err.error);
        });
      }
    });
  }

  acceptOrder($event): void {
    const confirmPopupData: IConfirmPopup = {
      title: 'Akceptacja',
      message: null
    };
    confirmPopupData.message = `Czy chcesz zaakceptować zlecenie?`;

    if ($event.name) {
      confirmPopupData.message = `Czy chcesz zaakceptować zlecenie "${$event.name}"?`;
    }

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '440px',
      data: confirmPopupData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.acceptOrder($event.id).subscribe(() => {
          this.toaster.displayMessage('Zlecenie zostało zaakceptowane');
          this.getList();
        }, err => {
          this.errorManager.displayError(err.error);
        });
      }
    });
  }

  rejectOrder($event): void {
    const confirmPopupData: IConfirmPopup = {
      title: 'Odrzucenie',
      message: null
    };
    confirmPopupData.message = `Czy chcesz odrzucić zlecenie?`;

    if ($event.name) {
      confirmPopupData.message = `Czy chcesz odrzucić zlecenie "${$event.name}"?`;
    }

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '440px',
      data: confirmPopupData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.rejectOrder($event.id).subscribe(() => {
          this.toaster.displayMessage('Zlecenie zostało odrzucone');
          this.getList();
        }, err => {
          this.errorManager.displayError(err.error);
        });
      }
    });
  }

  getManagers(): void {
    this.dictionaryService.getManagers().subscribe((data: Array<ManagerDictionaryModel>) => {
      console.log(data);
    });
  }
}
