import { Injectable, Optional } from '@angular/core';

@Injectable()
export class FormatDictionaryModel {
  id: number;
  area: string;
  type: string;
  name: string;
  shortName: string;
  unit: string;
  width: number;
  height: number;
  price: number;

  constructor(@Optional() obj?: any) {
    this.id = obj && obj.id || null;
    this.area = obj && obj.area || null;
    this.type = obj && obj.type || null;
    this.name = obj && obj.name || null;
    this.shortName = obj && obj.shortName || null;
    this.unit = obj && obj.unit || null;
    this.width = obj && obj.width || null;
    this.height = obj && obj.height || null;
    this.price = obj && obj.price || null;
  }
}
