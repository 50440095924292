import { Injectable, Optional } from '@angular/core';
import * as _ from 'lodash';

import { PaymentPartitionModel } from '../../../shared/model/payment-partition.model';

import { Client } from '../../client/client.model';
import { BrandDictionaryModel } from '../../dictionary/model/brand-dictionary.model';
import { CampaignTypeDictionaryModel } from '../../dictionary/model/campaign-type-dictionary.model';
import { FormatDictionaryModel } from '../../dictionary/model/format-dictionary.model';
import { OrderFormItemsDictionaryModel } from '../../dictionary/model/order-form-items-dictionary.model';
import { PaymentTypeDictionaryModel } from '../../dictionary/model/payment-type-dictionary.model';
import { PriceListDictionaryModel } from '../../dictionary/model/price-list-dictionary.model';
import { PrincipalDictionaryModel } from '../../dictionary/model/principal-dictionary.model';
import { SalesModelDictionaryModel } from '../../dictionary/model/sales-model-dictionary.model';
import { TradeDictionaryModel } from '../../dictionary/model/trade-dictionary.model';
import { DiscountModel } from '../../discount/model/discount.model';
import { Publisher } from '../../publisher/model/publisher.model';
import { User } from '../../user/user.model';

export interface IReservationCustom {
  id: number;
  description: string;
  name: string;
  dateFrom: Date;
  dateTo: Date;
  publishers?: Array<Publisher>;
  value: number;
  client: Client;
  salesModel: SalesModelDictionaryModel;
  campaignType: CampaignTypeDictionaryModel;
  orderForm: OrderFormItemsDictionaryModel;
  paymentType: PaymentTypeDictionaryModel;
  salesman: SalesModelDictionaryModel;
  brand: BrandDictionaryModel;
  trade: TradeDictionaryModel;
  formats: Array<FormatDictionaryModel>;
  principal: PrincipalDictionaryModel;
  technicalCosts: number;
  agencyFee: number;
  comment: string;

  type: string;
  identification: number;
  status: string;
  owner: User;
  platform: string;
  priceList: PriceListDictionaryModel;
  paymentPartition: Array<PaymentPartitionModel>
}

@Injectable()
export class ReservationCustom implements IReservationCustom {
  id: number;
  description: string;
  name: string;
  dateFrom: Date;
  dateTo: Date;
  publishers?: Array<Publisher>;
  value: number;
  client: Client;
  salesModel: SalesModelDictionaryModel;
  campaignType: CampaignTypeDictionaryModel;
  orderForm: OrderFormItemsDictionaryModel;
  paymentType: PaymentTypeDictionaryModel;
  salesman: SalesModelDictionaryModel;
  brand: BrandDictionaryModel;
  trade: TradeDictionaryModel;
  formats: Array<FormatDictionaryModel>;
  principal: PrincipalDictionaryModel;
  technicalCosts: number;
  agencyFee: number;
  comment: string;

  type: string;
  identification: number;
  status: string;
  owner: User;
  platform: string;

  priceListPrice: number;
  fixedPrice: number;
  discounts: Array<DiscountModel>;
  priceList: PriceListDictionaryModel;
  paymentPartition: Array<PaymentPartitionModel>;

  constructor(@Optional() obj?: any) {
    this.id = obj && obj.id || null;
    this.description = obj && obj.description || null;
    this.value = obj && obj.value ? obj && obj.value : obj && obj.value === 0 ? 0 : null;
    this.agencyFee = obj && obj.agencyFee || 0;
    this.dateFrom = obj && obj.dateFrom || null;
    this.dateTo = obj && obj.dateTo || null;
    this.client = obj && obj.client ? new Client(obj.client) : null;
    this.publishers = (obj && obj.publishers) ? [] : null;
    if (obj && obj.publishers && _.isArray(obj.publishers) && obj.publishers.length) {
      this.publishers = _.map(obj.publishers, publisher => new Publisher(publisher));
    }
    this.name = (obj && obj.name) ? obj.name : null;
    this.brand = (obj && obj.brand) ? new BrandDictionaryModel(obj.brand) : null;
    this.trade = (obj && obj.trade) ? new TradeDictionaryModel(obj.trade) : null;
    this.technicalCosts = obj && obj.technicalCosts || 0;
    this.comment = obj && obj.comment || null;
    this.salesModel = (obj && obj.salesModel) ? new SalesModelDictionaryModel(obj.salesModel) : null;
    this.campaignType = (obj && obj.campaignType) ? new CampaignTypeDictionaryModel(obj.campaignType) : null;
    this.orderForm = (obj && obj.orderForm) ? new OrderFormItemsDictionaryModel(obj.orderForm) : null;
    this.paymentType = (obj && obj.paymentType) ? new PaymentTypeDictionaryModel(obj.paymentType) : null;
    this.principal = (obj && obj.principal) ? new PrincipalDictionaryModel(obj.principal) : null;
    this.salesman = (obj && obj.salesman) ? new SalesModelDictionaryModel(obj.salesman) : null;

    this.type = obj && obj.type || null;
    this.identification = obj && obj.identification || 0;
    this.status = obj && obj.status || null;
    this.owner = (obj && obj.owner) ? new User(obj.owner) : null;
    this.platform = obj && obj.platform || null;

    this.priceListPrice = obj && obj.priceListPrice ? obj.priceListPrice : obj && obj.priceListPrice === 0 ? 0 : null;
    this.fixedPrice = obj && obj.fixedPrice ? obj.fixedPrice : obj && obj.fixedPrice === 0 ? 0 : null;
    this.discounts = obj && obj.discounts ? obj.discounts : null;
    this.priceList = (obj && obj.priceList) ? new PriceListDictionaryModel(obj.priceList) : null;
    this.paymentPartition = (obj && obj.paymentPartition) ?
      _.map(obj.paymentPartition, (item: PaymentPartitionModel) => new PaymentPartitionModel(item)) : null;
  }
}
