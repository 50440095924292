<div class="mat-dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
  <div>Wybierz tytuły</div>
  <button mat-icon-button mat-button matDialogClose>
    <mat-icon class="icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div>
    <mat-form-field class="full-width">
      <input
        placeholder="Wpisz aby szukać"
        aria-label="Filtrowanie tytułów"
        matInput
        [formControl]="searchFormControl">

    </mat-form-field>

    <mat-list>
      <mat-list-item *ngFor="let title of titlesData | filterTitles: titleQuery">
        <div>
          <mat-checkbox [(ngModel)]="title.selected"></mat-checkbox>
        </div>
        <h3 matLine>{{ title.name }}</h3>
      </mat-list-item>
    </mat-list>
    <div class="full-width margin-vertical-lg" *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button (click)="cancel()">Anuluj</button>
  <button mat-button class="mat-primary mat-raised-button" [disabled]="isLoading" (click)="closeWithSelected()">Wybierz
  </button>
</mat-dialog-actions>
