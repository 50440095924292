import { IDataPageRequest } from '../../../core/interface/data-page-request';
import { ClientDataPageFilters } from './client-data-page-filters';

export interface IClientDataPageRequest extends IDataPageRequest {
  filters?: ClientDataPageFilters
}

export class ClientDataPageRequest implements IClientDataPageRequest {
  public page: number;
  public limit: number;
  public filters?: ClientDataPageFilters;
  private offset: number;

  constructor(obj?: any) {
    this.page = obj && obj.page || 1;
    this.limit = obj && obj.limit || 200;
  }

  setPageByOffset(offset) {
    this.page = offset + 1;
  }

  setPage(page: number) {
    this.page = page;
    this.offset = page - 1;
  }

  setLimit(limit: number) {
    this.limit = limit;
  }

  setFilters(filters) {
    this.filters = new ClientDataPageFilters(filters);
  }
}
