import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { getDatePeriodByMonth } from '../../shared/utils/date-cool-helper';
import { OrderService } from '../order/order.service';
import { ReservationService } from '../reservation/reservation.service';
import { CalendarDetailsPopupComponent } from './calendar-details-popup/calendar-details-popup.component';
import { CalendarFiltersPopupComponent } from './calendar-filters-popup/calendar-filters-popup.component';
import { CalendarService } from './calendar.service';
import { ICalendarEvent } from './interface/calendar-event';
import { ICalendarEventColor } from './interface/calendar-event-color';
import { CalendarDataRequest } from './model/calendar-data-request.model';

@Component({
  selector: 'ep-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  view: string;
  selectedFilters: any;

  refresh: Subject<any> = new Subject();

  filters: any;
  viewDate: Date = new Date();
  events: ICalendarEvent[];
  calendarDataRequest: CalendarDataRequest = new CalendarDataRequest({
    viewType: 1,
    filters: {
      date: getDatePeriodByMonth(new Date().getFullYear(), new Date().getMonth() + 1)
    }
  });

  isLoadingCalendarData: boolean = false;
  CalendarView = CalendarView;
  activeDayIsOpen: boolean;

  constructor(private reservationService: ReservationService,
              private orderService: OrderService,
              private calendarService: CalendarService,
              public dialog: MatDialog) {
    this.view = CalendarView.Month;
    this.activeDayIsOpen = false;
    this.getCalendarData();
  }

  dayClicked({date, events}: { date: Date, events: CalendarEvent[] }): void {

    if (moment(this.viewDate).isSame(date, 'month')) {
      this.openCalendarDetailsPopup({date, events});


      if (
        (moment(this.viewDate).isSame(date, 'day') && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  getCalendarData() {
    this.isLoadingCalendarData = true;
    this.calendarService.getCalendarData(this.calendarDataRequest).pipe(map(this.responseData().convertToEventDataList)).subscribe((response: Response) => {
      this.events = response['data'].calendar;
      this.filters = response['data'].filters;
      this.isLoadingCalendarData = false;
    }, () => {
      this.isLoadingCalendarData = false;
    });
  }

  viewDateChange($event) {
    this.calendarDataRequest.filters.date = getDatePeriodByMonth(new Date($event).getFullYear(), new Date($event).getMonth() + 1);
    this.getCalendarData();
  }

  responseData(): any {
    return {
      convertToEventDataList: (response) => {
        let eventList: ICalendarEvent[] = [];
        let calendarData = response['data'].calendar;

        _.each(calendarData, (itemValue, itemKey) => {
          let item: ICalendarEvent;
          let color: ICalendarEventColor = {primary: 'red', secondary: 'green'};
          item = {
            start: new Date(itemKey),
            end: new Date(itemKey),
            title: '',
            color: color,
            actions: null,
            occupancy: parseInt(itemValue['occupancy']),
            itemData: itemValue
          };
          eventList.push(item);
        });
        response['data'].calendar = eventList;
        return response;
      }
    }
  };

  showFilterPopup() {
    let dialogRef = this.dialog.open(CalendarFiltersPopupComponent, {
      width: '440px',
      data: {
        filters: this.filters,
        selectedFilters: this.selectedFilters
      },
      panelClass: 'calendar-filters-popup',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.selectedFilters = result;
        this.calendarDataRequest.filters.publisher = null;
        if (result.publishers && _.isArray(result.publishers)) {
          this.calendarDataRequest.filters.publisher = `${result.publishers.join(',')}`;
        }
        this.calendarDataRequest.filters.package = null;
        if (result.packages && _.isArray(result.packages)) {
          this.calendarDataRequest.filters.package = `${result.packages.join(',')}`;
        }
        this.getCalendarData();
      }
    });
  }

  openCalendarDetailsPopup(data): void {
    let dialogRef = this.dialog.open(CalendarDetailsPopupComponent, {
      width: '440px',
      data: data,
      panelClass: 'calendar-details-popup',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe();
  }

  ngOnInit() {
    this.orderService.onOrderCreateOrEdit$.subscribe(() => {
      this.getCalendarData();
    });

    this.reservationService.onCreateOrEdit$.subscribe(() => {
      this.getCalendarData();
    });
  }

}
