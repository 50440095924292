<div class="padding-lg">
  <div class="ep-background-1 mat-elevation-z1 padding-lg margin-bottom-lg">
    Rezerwacje
  </div>

  <div class="ep-background-1">
    <mat-tab-group class="mat-accent" (selectedTabChange)="onListTypeChange($event)" [selectedIndex]="0">
      <mat-tab label="Digital">
        <form [formGroup]="filterForm" novalidate autocomplete="off">
          <ngx-datatable
            class="material"
            [rows]="reservationData.items"
            [columns]="columns"
            [columnMode]="'force'"
            [headerHeight]="100"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [externalPaging]="true"
            [loadingIndicator]="loadingIndicator"
            [messages]="{ emptyMessage: 'Brak danych', totalMessage: 'wszystkich' }"
            [count]="reservationData.total_items"
            [offset]="reservationData.offset"
            [limit]="dataPageRequest.limit"
            (page)='setPage($event)'
            (sort)="onSort($event)">
            <ngx-datatable-column name="identification">
              <ng-template let-column="column" sortable="true" ngx-datatable-header-template>
                <div>
                  <div title="EBR">EBR</div>
                  <div title="EBR" class="ngx-filter-template">
                    <mat-form-field>
                      <input
                        [formControl]="filterForm.controls['identification']"
                        matInput
                        placeholder='filtruj'
                      />
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div title="{{value}}">{{value}}</div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="placementCategory">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div>
                  <div title="Kategoria">Kategoria</div>
                  <div title="Kategoria" class="ngx-filter-template">
                    <mat-form-field>
                      <input
                        [formControl]="filterForm.controls['placementCategory']"
                        matInput
                        placeholder='filtruj'
                      />
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div title="{{value.name}}">{{value.name}}</div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="comment">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div>
                  <div title="Opis kampanii">Opis kampanii</div>
                  <div title="Opis kampanii" class="ngx-filter-template">
                    <mat-form-field>
                      <input
                        [formControl]="filterForm.controls['comment']"
                        matInput
                        placeholder='filtruj'
                      />
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div title="{{value}}">{{value}}</div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="client">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div>
                  <div title="Klient">Klient</div>
                  <div title="Klient" class="ngx-filter-template">
                    <mat-form-field>
                      <input
                        [formControl]="filterForm.controls['client']"
                        matInput
                        placeholder='filtruj'
                      />
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div title="{{value.name}}">{{value.name}}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="salesman">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div>
                  <div title="Handlowiec">Handlowiec</div>
                  <div title="Handlowiec" class="ngx-filter-template">
                    <mat-form-field>
                      <input
                        [formControl]="filterForm.controls['salesman']"
                        matInput
                        placeholder='filtruj'
                      />
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div title="{{value?.name}}">{{value?.name}}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="dateFrom">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div>
                  <div title="Od">Od</div>
                  <div title="Od" class="ngx-filter-template">
                    <mat-form-field>
                      <input matInput (click)="dateFrom.open()" [matDatepicker]="dateFrom"
                             placeholder="Data od"
                             [formControl]="filterForm.controls['dateFrom']">
                      <div class="datepicker-reset-icon"
                           (click)="filterForm.controls['dateFrom'].setValue(''); updateFilter($event, 'dateFrom')"
                           *ngIf="filterForm.controls['dateFrom'].value">
                        <i class="material-icons">delete</i>
                      </div>
                      <mat-datepicker #dateFrom></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div title="{{value  | date: 'dd.MM.yyyy' }}">{{value  | date: 'dd.MM.yyyy' }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="dateTo">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div>
                  <div title="Do">Do</div>
                  <div title="Do" class="ngx-filter-template">
                    <mat-form-field>
                      <input matInput (click)="dateTo.open()" [matDatepicker]="dateTo"
                             placeholder="Data do" [formControl]="filterForm.controls['dateTo']">

                      <div class="datepicker-reset-icon"
                           (click)="filterForm.controls['dateTo'].setValue(''); updateFilter($event, 'dateTo')"
                           *ngIf="filterForm.controls['dateTo'].value"
                      >
                        <i class="material-icons">delete</i>
                      </div>
                      <mat-datepicker #dateTo></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div title="{{value  | date: 'dd.MM.yyyy' }}">{{value  | date: 'dd.MM.yyyy' }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="status">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div>
                  <div title="Status">Status</div>
                  <div title="Status" class="ngx-filter-template">
                    <mat-form-field>
                      <mat-select placeholder="Wybierz"
                                  [formControl]="filterForm.controls['status']"
                                  #select="matSelect">

                        <mat-select-trigger>{{ select.selected?.viewValue }}</mat-select-trigger>
                        <mat-option>Wszystkie</mat-option>
                        <mat-option *ngFor="let status of statuses" [value]="status.value">
                          {{ status.label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div
                  title="{{statusesObject?.statuses[value]}}">{{statusesObject?.statuses[value]}}</div>

              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="type">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div>
                  <div title="Typ">Typ</div>
                  <div title="Typ" class="ngx-filter-template">
                    <mat-form-field>
                      <mat-select placeholder="Wybierz"
                                  [formControl]="filterForm.controls['type']"
                                  #select="matSelect">
                        <mat-select-trigger>{{ select.selected?.viewValue }}</mat-select-trigger>
                        <mat-option>Wszystkie</mat-option>
                        <mat-option *ngFor="let type of types" [value]="type.value">
                          {{ type.label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div title="{{ typesObject[value]}}">{{ typesObject[value]}}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="id">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div title="Akcje">Akcje</div>
                <div title="Akcje" class="ngx-filter-template" style="min-height: 49px"></div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="data-table-action-icons">
                  <button
                    mat-icon-button
                    mat-button
                    class="icon-button"
                    type="button"
                    (click)="openReservationEditPopup(row)">
                    <mat-icon class="icon">edit</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    mat-button
                    class="icon-button"
                    type="button"
                    (click)="deleteReservation(row)">
                    <mat-icon class="icon">delete</mat-icon>
                  </button>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </form>
      </mat-tab>
      <mat-tab label="Print"></mat-tab>
    </mat-tab-group>
  </div>

</div>
