import { CommonModule, registerLocaleData } from '@angular/common';
// import {FlexLayoutModule} from '@angular/flex-layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePlCaExtra from '@angular/common/locales/extra/pl';
import localePlCa from '@angular/common/locales/pl';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DateAdapter } from '@angular/material';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter as CalendarDateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SortablejsModule, SortablejsOptions } from 'angular-sortablejs';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig } from 'ng2-currency-mask/src/currency-mask.config';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationService } from './core/authentication/authentication.service';

import { CoreModule } from './core/core.module';
import { MainRoutingModule } from './main/main-routing.module';
import { MainModule } from './main/main.module';
import { ReservationCreatePopupComponent } from './main/reservation/reservation-create/reservation-create-popup/reservation-create-popup.component';
import { ReservationCreateComponent } from './main/reservation/reservation-create/reservation-create.component';
import { ReservationEditPopupComponent } from './main/reservation/reservation-edit-popup/reservation-edit-popup.component';
import { MyDateAdapter } from './my-date-adapter.service';
import { ForgotPasswordComponent } from './pre/forgot-password/forgot-password.component';
import { LoginComponent } from './pre/login/login.component';
import { PreRoutingModule } from './pre/pre-routing.module';

import { AuthInterceptor } from './shared/interceptor/auth/auth.service';
import { SharedModule } from './shared/shared.module';

// import {NgProgressModule} from "@ngx-progressbar/core";
// import {NgProgressHttpModule} from "@ngx-progressbar/http";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


const sortablejsConfig: SortablejsOptions = {
  animation: 300
};

const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: ' '
};

registerLocaleData(localePlCa, localePlCaExtra);


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ReservationCreatePopupComponent,
    ReservationCreateComponent,
    ReservationEditPopupComponent
  ],
  entryComponents: [
    ReservationCreatePopupComponent,
    ReservationCreateComponent,
    ReservationEditPopupComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PreRoutingModule,
    MainRoutingModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    MainModule,
    SortablejsModule,
    PerfectScrollbarModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    CurrencyMaskModule,
    // NgProgressModule.forRoot(),
    // NgProgressHttpModule
    CalendarModule.forRoot({
      provide: CalendarDateAdapter,
      useFactory: adapterFactory
    })
  ],
  exports: [
    FlexLayoutModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MyDateAdapter
    },
    {provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig},
    AuthenticationService
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule {
}
