import { IDataPage } from '../../../core/interface/data-page';
import { Order } from './order.model';

export interface IOrderDataPage extends IDataPage {
  items: Order[]
}

export class OrderDataPage implements IOrderDataPage {
  items: Order[] = [];
  current_page: number;
  items_per_page: number;
  total_items: number;
  offset: number;

  constructor(obj?: any) {
    if (obj && obj.items) {
      obj.items.forEach(val => {
        this.items.push(new Order(val));
      });
    }
    this.current_page = obj && obj.current_page || 0;
    this.items_per_page = obj && obj.items_per_page || 0;
    this.total_items = obj && obj.total_items || 0;
    this.offset = obj && obj.current_page - 1 || 0;
  }
}
