<div class="padding-lg">
  <div class="ep-background-1 mat-elevation-z1 padding-lg margin-bottom-lg" fxLayout="row"
       fxLayoutAlign="space-between center">
    <div>
      Klienci
    </div>
    <div>
    </div>
  </div>
  <ngx-datatable
    class="material"
    [rows]="clientDataPageData.items"
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="'auto'"
    [externalPaging]="true"
    [messages]="{ emptyMessage: 'Brak danych', totalMessage: 'wszystkich' }"
    [loadingIndicator]="loadingIndicator"
    [count]="clientDataPageData.total_items"
    [offset]="clientDataPageData.offset"
    [limit]="dataPageRequest.limit"
    (page)='setPage($event)'>
    <ngx-datatable-column name="name">
      <ng-template let-column="column" ngx-datatable-header-template>
        <div title="Nazwa">Nazwa</div>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div title="{{value}}">{{value}}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="street">
      <ng-template let-column="column" ngx-datatable-header-template>
        <div title="Adres">Adres</div>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div title="{{value}}">{{value}}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="postalCode">
      <ng-template let-column="column" ngx-datatable-header-template>
        <div title="Kod pocztowy">Kod pocztowy</div>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div title="{{value}}">{{value}}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="city">
      <ng-template let-column="column" ngx-datatable-header-template>
        <div title="Miasto">Miasto</div>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div title="{{value}}">{{value}}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="nip">
      <ng-template let-column="column" ngx-datatable-header-template>
        <div title="NIP">NIP</div>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div title="{{value}}">{{value}}</div>
      </ng-template>
    </ngx-datatable-column>
    <!--<ngx-datatable-column name="id">-->
    <!--<ng-template let-column="column" ngx-datatable-header-template>-->
    <!--Akcje-->
    <!--</ng-template>-->
    <!--<ng-template let-value="value" ngx-datatable-cell-template>-->
    <!--<div class="data-table-action-icons">-->
    <!--<button mat-icon-button class="icon-button" type="button" mat-button>-->
    <!--<mat-icon class="icon">edit</mat-icon>-->
    <!--</button>-->
    <!--<button mat-icon-button class="icon-button" type="button" mat-button>-->
    <!--<mat-icon class="icon">delete</mat-icon>-->
    <!--</button>-->
    <!--</div>-->
    <!--</ng-template>-->
    <!--</ngx-datatable-column>-->
  </ngx-datatable>
</div>
