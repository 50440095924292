import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { objectToParams } from '../../shared/utils/object-to-params';
import { Placement } from './placement.model';

@Injectable()
export class PlacementService {

  constructor(private http: HttpClient) {

  }

  public getList(request, withLimit?: number): Observable<any> {
    withLimit = !withLimit ? 0 : 1;
    let params = objectToParams(request, 'yyyy-MM-dd');
    let apiUrl = `${environment.apiUrl}/placements/${withLimit}?${params}`;

    return this.http.get(apiUrl).pipe(map((response: Response) => {
      return response;
    }));
  }

  public getListChildren(publisherId: number, request): Observable<any> {
    let params = objectToParams(request, 'yyyy-MM-dd');
    let apiUrl = `${environment.apiUrl}/placements/children/${publisherId}?${params}`;

    return this.http.get(apiUrl).pipe(map((response: Response) => {
      let responsePlacements: Array<Placement> = [];

      if (response && response['data']) {
        _.each(response['data'], item => {
          responsePlacements.push(new Placement(item))
        })
      }
      return responsePlacements;
    }));
  }

  public getTreePlacements(publisherId: number, creationId?: number) {
    let apiUrl = `${environment.apiUrl}/placements/tree/${publisherId}/${creationId}`;

    return this.http.get(apiUrl).pipe(map((response: Response) => {
      let responsePlacements: Array<Placement> = [];

      if (response && response['data']) {
        let data = response['data'];
        _.each(data.children, item => {
          responsePlacements.push(item);
        })
      }
      return responsePlacements;
    }));
  }
}
