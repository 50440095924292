import { Injectable, Optional } from '@angular/core';
import * as _ from 'lodash';
import { Client } from '../../client/client.model';
import { PrincipalDictionaryModel } from '../../dictionary/model/principal-dictionary.model';
import { SalesModelDictionaryModel } from '../../dictionary/model/sales-model-dictionary.model';
import { ReservationCustom } from '../../reservation-custom/model/reservation-custom.model';
import { Reservation } from '../../reservation/model/reservation.model';
import { User } from '../../user/user.model';

export interface IOrder {
  id?: number;
  ebr?: number;
  name: string;
  client: Client;
  owner: User;
  creator: User;
  dateFrom: Date;
  dateTo: Date;
  userCanAccept: boolean;
  editable: boolean;
  editablePartial: boolean;
  editionNumber: number;
  editionYear: number;
  orderType: string;
  status: string;
  kind: string;
  comment: string;
  edited: boolean;
  orderDocument: any;
  orderStatistic: any;
  paymentSchedule: any;
  publisherStatistics: any;
  additionalDocuments: string[];
  reservations: Reservation[];
  reservationsCustom: ReservationCustom[];
  clone?: boolean;
  platform?: string;
  pdfPriceVisible?: boolean;
  pdfDiscountVisible?: boolean;
  pdfQuantityVisible?: boolean;
  principalPerson?: string;
  salesman: SalesModelDictionaryModel;
  principal: PrincipalDictionaryModel;
  titles: string[];
  isActionRequired: boolean;
}

@Injectable()
export class Order implements IOrder {
  id: number;
  ebr?: number;
  name: string;
  client: Client;
  owner: User;
  creator: User;
  dateFrom: Date;
  dateTo: Date;
  userCanAccept: boolean;
  editable: boolean;
  editablePartial: boolean;
  editionNumber: number;
  editionYear: number;
  orderType: string;
  status: string;
  kind: string;
  comment: string;
  edited: boolean;
  orderDocument: any;
  paymentSchedule: any;
  orderStatistic: any;
  publisherStatistics: any;
  additionalDocuments: string[];
  reservations: Reservation[];
  reservationsCustom: ReservationCustom[];
  platform: string;
  principalPerson?: string;
  pdfPriceVisible: boolean;
  pdfDiscountVisible: boolean;
  pdfQuantityVisible: boolean;
  salesman: SalesModelDictionaryModel;
  principal: PrincipalDictionaryModel;
  value: number;
  titles: string[];
  isActionRequired: boolean;

  constructor(@Optional() obj?: any) {
    this.id = obj && obj.id || null;
    this.ebr = obj && obj.ebr || null;
    this.name = obj && obj.name || null;
    this.client = obj && obj.client ? new Client(obj.client) : null;
    this.owner = obj && obj.owner ? new User(obj.owner) : null;
    this.creator = obj && obj.creator ? new User(obj.creator) : null;
    this.dateFrom = obj && obj.dateFrom ? new Date(obj.dateFrom) : null;
    this.userCanAccept = obj && obj.userCanAccept || null;
    this.editable = obj && obj.editable || null;
    this.editablePartial = obj && obj.editable_partial || false;
    this.dateTo = obj && obj.dateTo ? new Date(obj.dateTo) : null;
    this.editionNumber = obj && obj.editionNumber || null;
    this.editionYear = obj && obj.editionYear || null;
    this.orderType = obj && obj.orderType || null;
    this.status = obj && obj.status || null;
    this.kind = obj && obj.kind || null;
    this.comment = obj && obj.comment ? obj.comment.replace(/(\\n)/g, '\n') : null;
    this.pdfPriceVisible = (obj && obj.pdfPriceVisible);
    this.pdfDiscountVisible = (obj && obj.pdfDiscountVisible);
    this.pdfQuantityVisible = (obj && obj.pdfQuantityVisible);
    this.edited = obj && obj.edited || null;
    this.orderDocument = obj && obj.orderDocument || null;
    this.orderStatistic = obj && obj.orderStatistic || null;
    this.paymentSchedule = obj && obj.paymentSchedule || null;
    this.publisherStatistics = obj && obj.publisherStatistics || null;
    this.additionalDocuments = obj && obj.additionalDocuments || null;
    this.reservations = [];
    if (obj.reservations && _.isArray(obj.reservations)) {
      _.each(obj.reservations, val => {
        this.reservations.push(new Reservation(val));
      });
    }
    this.reservationsCustom = [];
    if (obj.reservationsCustom && _.isArray(obj.reservationsCustom)) {
      _.each(obj.reservationsCustom, val => {
        this.reservationsCustom.push(new ReservationCustom(val));
      });
    }

    this.platform = obj && obj.platform || null;
    this.principalPerson = obj && obj.principalPerson || null;
    this.principal = (obj && obj.principal) ? new PrincipalDictionaryModel(obj.principal) : null;
    this.salesman = (obj && obj.salesman) ? new SalesModelDictionaryModel(obj.salesman) : null;
    this.value = obj && obj.value || null;
    this.titles = obj && obj.titles || null;
    this.isActionRequired = obj && obj.actionRequired;
  }
}
