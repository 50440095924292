<div class="icon">
  <i class="material-icons {{ item.type }}">{{ item.icon }}</i>
</div>
<div class="title">
  {{ item.title }}
  <small class="subTitle">{{ item.subTitle }}</small>
  <small class="subTitle subTitlePublisher">{{ item.publisherName }}</small>
</div>

<div class="occupancy">{{ item.occupancy }}</div>
<div class="action">
  <button mat-icon-button mat-button matRipple *ngIf="item.children.length" (click)="showDetails()">
    <i class="material-icons">chevron_right</i>
  </button>
</div>
