import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver'
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { IDataPageRequest } from '../../core/interface/data-page-request';

import { objectToParams } from '../../shared/utils/object-to-params';
import { ReportDataPage } from './model/report-data-page.model';

@Injectable()
export class ReportService {

  constructor(private http: HttpClient) {
  }

  public getList(request: IDataPageRequest): any {
    let params = objectToParams(request, 'yyyy-MM-dd');
    let apiUrl = `${environment.apiUrl}/reports/?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        return new ReportDataPage(response);
      }));

  }

  public getStatuses(request: any): any {
    let params = objectToParams(request, 'yyyy-MM-dd');
    let apiUrl = `${environment.apiUrl}/reports/statuses?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        return response['data'];
      }));

  }


  public getTypes(request: any): any {
    let params = objectToParams(request, 'yyyy-MM-dd');
    let apiUrl = `${environment.apiUrl}/reports/types?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        return response['data'];
      }));

  }


  public getFilters(): any {
    let apiUrl = `${environment.apiUrl}/reports/filters`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        return response['data'];
      }));

  }

  public saveReport(request: any): any {
    let apiUrl = `${environment.apiUrl}/reports/`;

    return this.http.post(apiUrl, request);

  }

  public deleteReport(id: number): any {
    let apiUrl = `${environment.apiUrl}/reports/${id}`;

    return this.http.delete(apiUrl);
  }

  public getPdfUrl(ids: Array<number>): any {
    let idsString = ids.join(',');
    let apiUrl = `${environment.apiUrl}/reports/download/${idsString}`;

    return this.http.get(apiUrl);
  }

  public downloadFile(url: string, type?: string) {
    this.http.get(url, {
      responseType: 'blob'
    }).subscribe((response) => {
      if (type === 'zip') {
        saveAs(response, 'report.zip');
      } else {
        saveAs(response, 'report.csv');
      }
    });
  }

  public getReportData(reportType, reportFilters) {
    if (reportFilters.reportType) {
      reportFilters.reportType = null;
    }

    let params = objectToParams({
      filters: reportFilters
    });

    let apiUrl = `${environment.apiUrl}/reports/data/${reportType}?${params}`;

    return this.http.get(apiUrl).pipe(map((reportData) => {
      return reportData;
    }));
  }

  public getReportFilterTypeValues() {
    return this.getReportFilterValues('type');
  }

  public getReportFilterDesktopMobileValues() {
    return this.getReportFilterValues('desktopOrMobile');
  }

  public getReportFilterIdentificationValues() {
    return this.getReportFilterValues('identification');
  }

  private getReportFilterValues(filter): any {
    let apiUrl = `${environment.apiUrl}/reports/filters/pivot/${filter}`;

    return this.http.get(apiUrl).pipe(map((response: Response) => {
      if (response && response['data']) {
        return response['data'];
      }
    }));
  }
}
