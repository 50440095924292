<div class="report padding-lg">
  <div class="ep-background-1 padding-lg margin-bottom-lg">
    <div>Raporty</div>
  </div>

  <div class="ep-background-1 padding-lg">
    <div class="report-form">
      <ep-report-generate-form
        (onValidationChange)="onReportFormValidationChange($event)"
        (onReportChange)="onReportChange()"
        (onReportCreate)="onReportCreated($event)"
        (onReportFileGenerate)="onReportFileGenerated($event)"
      ></ep-report-generate-form>

      <div class="form-buttons" id="form-buttons">
        <div class="button-download-container">
          <mat-spinner class="button-spinner" [diameter]="20" *ngIf="downloadInProgress"></mat-spinner>

          <button
            class="mat-primary mat-raised-button"
            type="button"
            disabled="{{ !isFormValid }}"
            (click)="generateReportFile()"
            mat-button
          >Pobierz raport</button>
        </div>

        <button
          class="mat-primary mat-raised-button"
          disabled="{{ !isFormValid }}"
          (click)="createReport()"
          mat-button
        >
          Generuj
        </button>
      </div>
    </div>

    <div id="report-data-container">
      <div class="loader-container" *ngIf="reportInProgress">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>

      <div id="report-data" class="report-data" *ngIf="!reportInProgress && isReportData()">
        <table class="report-data-table">
          <thead>
          <tr>
            <th class="cell head-cell" *ngFor="let headerCellName of reportData.header">{{ headerCellName }}</th>
          </tr>
          </thead>

          <tbody *ngIf="isNotReportBodyEmpty()">
          <tr *ngFor="let row of reportData.body">
            <td class="cell" *ngFor="let cell of row | keys">{{ cell.value }}</td>
          </tr>
          </tbody>

          <tbody *ngIf="isReportBodyEmpty()">
          <tr>
            <td class="cell no-results" [attr.colspan]="getReportBodyColspan()">Brak danych dla tego raportu</td>
          </tr>
          </tbody>

          <tfoot *ngIf="isNotReportBodyEmpty() && isReportDataSummary()">
          <tr>
            <td class="cell foot-cell" *ngFor="let summaryKey of reportData.summary | keys">{{ summaryKey.value }}</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>

  <!--<ep-report-service-order-list [report]="report" *ngIf="isReportByReportType(1)"></ep-report-service-order-list>-->

  <!--<ep-report-service-profit-month [report]="report" *ngIf="isReportByReportType(2)"></ep-report-service-profit-month>-->

  <!--<ep-report-service-profit-quarter [report]="report" *ngIf="isReportByReportType(3)"></ep-report-service-profit-quarter>-->

  <!--<ep-report-agency-ranking [report]="report" *ngIf="isReportByReportType(4)"></ep-report-agency-ranking>-->

  <!--<ep-report-clients-ranking [report]="report" *ngIf="isReportByReportType(5)"></ep-report-clients-ranking>-->
</div>
