import { FormControl, FormGroup } from '@angular/forms';

export class FormExtrasUtil {
  static clearStateField(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: false});
      } else if (control instanceof FormGroup) {
        FormExtrasUtil.clearStateField(control);
      }
    });
  }

  static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        FormExtrasUtil.validateAllFormFields(control);
      }
    });
  }

  static disableAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.disable();
      } else if (control instanceof FormGroup) {
        FormExtrasUtil.validateAllFormFields(control);
      }
    });
  }


  constructor() {
  }

  /**
   * @deprecated since version 2.0, instead use static version of this method
   */
  validateAllFormFields(formGroup: FormGroup) {
    return FormExtrasUtil.validateAllFormFields(formGroup);
  }
}
