import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { TreeItemModel } from './tree-item.model';

@Component({
  selector: 'ep-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit {
  @Input() set items(items: Array<TreeItemModel>) {
    // this.itemsData = this.buildIconDefault(items);
    this.itemsData = items;
    this.selectedAll = this.checkSelectedAll(this.itemsData);
    if (this.selectedAll) {

    }
  }

  @Input() parentItem?: TreeItemModel;
  @Input() loadingIndication?: any;
  @Input() selectAllEnabled?: any;

  @Output() action?: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectedChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output() childAction?: EventEmitter<any> = new EventEmitter<any>();

  itemsData: Array<TreeItemModel>;
  selectedAll: boolean = false;

  constructor() {
  }

  ngOnInit() {

  }

  buildIconDefault(items: Array<TreeItemModel>): Array<TreeItemModel> {
    return _.map(items, (item: TreeItemModel) => {
      if (!item.icon) {
        if (item.hasChildrenItem) {
          item.icon = 'folder';
          item.children = this.buildIconDefault(item.children)
        } else {
          item.icon = null;
        }
      }
      item.parentItem = this.parentItem;
      return item;
    })
  }

  onTreeButtonClick($event, item, actionName) {
    this.action.emit({
      selectedItem: $event.selectedItem ? $event.selectedItem : item,
      rootItem: item,
      actionName: $event.actionName ? $event.actionName : actionName
    });
  }

  onLocalAction($event, parameters, actionName) {
    this.onTreeButtonClick($event, parameters, actionName);
  }

  onSelectedChangeClick($event, item) {
    if ($event.selectedItem) {
      item.selected = true;
    }
    this.onSelectedChange.emit({selectedItem: $event.selectedItem ? $event.selectedItem : item, rootItem: item});
  }


  onLocalSelectedChange($event, parameters) {
    if ($event.checked && parameters.item.publisher) {

    }
    if (parameters.children) {
      parameters.children = this.selectChildrenItems(parameters.children, parameters.selected);
    }
    this.onSelectedChangeClick($event, parameters);
  }

  selectChildrenItems(items, value) {
    if (this.parentItem) {
      this.parentItem = this.setSelectedParentItem(value, this.parentItem);
    }
    return _.map(items, (item: TreeItemModel) => {
      item.selected = value;
      if (item.children && _.isArray(item.children) && item.children.length) {
        item.children = this.selectChildrenItems(item.children, value);
      }
      return item;
    });
  }

  setSelectedParentItem(value, parentItem): TreeItemModel {
    if (!value) {
      if (parentItem.parentItem) {

        parentItem.selected = this.checkIsSelectedAnyChild(parentItem.children);
      }
    } else {
      parentItem.selected = value;
    }
    if (parentItem.parentItem) {
      parentItem.parentItem = this.setSelectedParentItem(value, parentItem.parentItem);
    }

    return parentItem;
  }

  checkIsSelectedAnyChild(items): boolean {
    let selectedAll = false;

    _.each(items, item => {
      if (item.selected) {
        selectedAll = true;
        return true;
      }
      if (item.children && _.isArray(item.children) && item.children.length) {
        return this.checkSelectedAll(item.children);
      }
    });


    return selectedAll;
  }

  checkSelectedAll(items): boolean {
    let selectedAll = true;
    if (!this.parentItem) {
      _.each(items, item => {
        if (!item.selected) {
          selectedAll = false;
          return false;
        }
        if (item.children && _.isArray(item.children) && item.children.length) {
          return this.checkSelectedAll(item.children);
        }
      })
    }

    return selectedAll;
  }

  onSelectAll($event): void {
    const selected = !this.selectedAll;
    this.itemsData = this.selectAll(this.itemsData, selected);
    this.selectedAll = this.checkSelectedAll(this.itemsData);
    this.onSelectedChange.emit(this.itemsData);
    $event.stopPropagation();
    $event.preventDefault();
  }

  selectAll(items: Array<TreeItemModel>, selected: boolean): Array<TreeItemModel> {
    if (!this.parentItem) {
      this.selectedAll = this.checkSelectedAll(items);
      items = _.map(items, (item: TreeItemModel) => {
        item.selected = selected;
        if (item.children && _.isArray(item.children) && item.children.length) {
          item.children = this.selectAll(item.children, selected);
        }
        return item;
      });
    }
    return items;
  }

  expandItem(item: TreeItemModel, $event) {
    if (!item.expanded && (!item.children || item.children && _.isArray(item.children) && !item.children.length)) {
      let actionName = item.onExpandAction;
      this.onLocalAction($event, item, actionName);
    }
  }
}
