import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { MaterialComponentsModule } from '../core/material/material-components.module';
import { AutocompleteFieldComponent } from './component/autocomplete-field/autocomplete-field.component';
import { AutocompleteMultiselectFieldComponent } from './component/autocomplete-multiselect-field/autocomplete-multiselect-field.component';
import { FileUploadComponent } from './component/file-upload/file-upload.component';
import { ToolbarModule } from './component/toolbar/toolbar.module';
import { TreeComponent } from './component/tree/tree.component';
import { AutoNumericDirective } from './directive/auto-numeric/auto-numeric.directive';
import { AmountConverterPipe } from './pipes/amount-converter.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { AlertPopupComponent } from './popup/alert-popup/alert-popup.component';
import { ConfirmPopupComponent } from './popup/confirm-popup/confirm-popup.component';
import { ApiErrorManagerService } from './utils/api-error-manager.service';
import { ToasterService } from './utils/toaster.service';
import { TransformerService } from './utils/transformer.service';

@NgModule({
  imports: [
    MaterialComponentsModule,
    FlexLayoutModule,
    CommonModule,
    ToolbarModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ToolbarModule,
    FileUploadComponent,
    AutocompleteFieldComponent,
    AutocompleteMultiselectFieldComponent,
    TreeComponent,
    AutoNumericDirective,
    OrderByPipe,
    AmountConverterPipe
  ],
  entryComponents: [
    ConfirmPopupComponent,
    AlertPopupComponent
  ],
  declarations: [
    ConfirmPopupComponent,
    AlertPopupComponent,
    FileUploadComponent,
    AutocompleteFieldComponent,
    AutocompleteMultiselectFieldComponent,
    TreeComponent,
    AutoNumericDirective,
    OrderByPipe,
    AmountConverterPipe
  ],
  providers: [
    MatSnackBar,
    ApiErrorManagerService,
    ToasterService,
    TransformerService
  ]
})
export class SharedModule {
}
