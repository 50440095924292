import { Injectable, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DiscountModel } from '../../discount/model/discount.model';

@Injectable()
export class ReservationDiscountModel extends DiscountModel {
  id: string;
  name: string;
  type: string;
  percentValue: number;
  amountValue: number;
  formControl: FormControl;

  constructor(@Optional() obj?: any) {
    super(obj);
    this.formControl = obj && obj.formControl || null;
  }
}
