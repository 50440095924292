export interface IDataPageRequest {
  page: number,
  limit: number,
  filters?: any,
}

export class DataPageRequest implements IDataPageRequest {
  public page: number;
  public limit: number;
  public filters: any;
  private offset: number;

  constructor(obj?: any) {
    this.page = obj && obj.page || 1;
    this.limit = obj && obj.limit || 200;
    if (obj && obj.filters) {
      this.filters = obj.filters;
    }
  }

  setPageByOffset(offset) {
    this.page = offset + 1;
  }

  setPage(page: number) {
    this.page = page;
    this.offset = page - 1;
  }

  setLimit(limit: number) {
    this.limit = limit;
  }
}
