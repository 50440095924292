import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { ClientAddComponent } from '../client-add/client-add.component';


@Component({
  selector: 'ep-client-add-popup',
  templateUrl: './client-add-popup.component.html',
  styleUrls: ['./client-add-popup.component.scss']
})
export class ClientAddPopupComponent {
  isFormValid: boolean = false;
  @ViewChild(ClientAddComponent) childActions: any;

  constructor(
    public dialogRef: MatDialogRef<ClientAddPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toaster: ToasterService) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaved($event): void {
    this.toaster.displayMessage('Klient został zapisany');
    this.dialogRef.close($event);
  }

  onValidationChange($event): void {
    this.isFormValid = $event;
  }

  save() {
    this.childActions.saveClient();
  }
}
