import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { objectToParams } from '../../shared/utils/object-to-params';
import { IPublisherDataPageRequest } from './interface/publisher-data-page-request.interface';
import { Publisher } from './model/publisher.model';

@Injectable()
export class PublisherService {

  constructor(private http: HttpClient) {
  }

  public getList(request: IPublisherDataPageRequest): any {
    let params = objectToParams(request, 'yyyy-MM-dd');
    let apiUrl = `${environment.apiUrl}/publishers/?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'].map(val => {
            return new Publisher(val);
          })
        }
        return response;
      }));
  }
}
