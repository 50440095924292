import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ClientService } from '../client.service';
import { ClientDataPage } from '../interface/client-data-page';
import { ClientDataPageRequest } from '../interface/client-data-page-request';

@Component({
  selector: 'ep-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {
  loadingIndicator: boolean = true;
  clientDataPageData: ClientDataPage = new ClientDataPage();
  dataPageRequest: ClientDataPageRequest;
  currentOffset: number = 0;

  constructor(private clientService: ClientService,
              private dialog: MatDialog) {
    this.setDataPageRequest();
    this.getList();
  }

  ngOnInit() {
    this.clientService.onClientCreate$.subscribe(response => {
      this.getList();
    })
  }

  getList() {
    this.loadingIndicator = true;
    this.clientService.getList(this.getDataPageRequest()).subscribe((result: ClientDataPage) => {
      this.clientDataPageData = result;
      this.loadingIndicator = false;
    });
  }

  setDataPageRequest() {
    this.currentOffset = 0;
    this.dataPageRequest = new ClientDataPageRequest();
  }

  getDataPageRequest(): ClientDataPageRequest {
    return this.dataPageRequest;
  }

  setPage($event) {
    this.dataPageRequest.setPageByOffset($event.offset);
    this.dataPageRequest.setLimit($event.limit);
    this.currentOffset = $event.offset;
    this.getList();
  }
}
