import { DatePipe } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import * as _ from 'lodash';
import { DataPageRequest } from '../../../../core/interface/data-page-request';
import { IAutocompleteMultiselectOption } from '../../../../shared/component/autocomplete-multiselect-field/autocomplete-multiselect-option.interface';
import { AlertPopupComponent } from '../../../../shared/popup/alert-popup/alert-popup.component';
import { IAlertPopup } from '../../../../shared/popup/alert-popup/alert-popup.interface';
import { ToasterService } from '../../../../shared/utils/toaster.service';
import { ReservationDataPage } from '../../model/reservation-data-page';
import { Reservation } from '../../model/reservation.model';
import { ReservationService } from '../../reservation.service';
import { ReservationCreateComponent } from '../reservation-create.component';

@Component({
  selector: 'ep-reservation-create-popup',
  templateUrl: './reservation-create-popup.component.html',
  styleUrls: ['./reservation-create-popup.component.scss']
})
export class ReservationCreatePopupComponent {
  selectedType: string;
  reservationCloneItemData: any;
  selectedReservationToClone: any;
  selectedReservationCloneControl: FormControl = new FormControl();
  dateFrom: any = null;
  hintText: string;
  isLoadingReservationList: boolean;
  showReservationCreate: boolean;
  reservationDataPageRequest: DataPageRequest;
  reservationOptions: Array<IAutocompleteMultiselectOption> = [];
  @ViewChild(ReservationCreateComponent) private reservationCreateComponent: ReservationCreateComponent;

  constructor(
    public dialogRef: MatDialogRef<ReservationCreatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reservationService: ReservationService,
    private toaster: ToasterService,
    private dialog: MatDialog) {

    if (this.data && this.data.dateFrom) {
      this.dateFrom = this.data.dateFrom;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onReservationSaved($event): void {
    if ($event.length > 1) {
      this.showAlert($event[1].notice);
      this.toaster.displayMessage(`Rezerwacja została utworzona.`);
    } else {
      this.toaster.displayMessage('Rezerwacja została utworzona.');
    }
    this.dialogRef.close($event);
  }

  onChangeReservationAutoComplete() {
    this.hintText = null;
    if (this.selectedReservationCloneControl.value.length >= 3 || this.selectedReservationCloneControl.value.length === 0) {
      this.reservationDataPageRequest = new DataPageRequest({
        filters: {
          name: this.selectedReservationCloneControl.value
        }
      });

      this.getReservationDataPage()
    } else {
      this.hintText = 'minimum 3 znaki, aby wyszukać';
    }
  }

  displayReservationName(reservation: IAutocompleteMultiselectOption): string {
    if (reservation && reservation.label) {
      return reservation.label;
    } else {
      return null;
    }
  }

  onReservationSelect(reservation: any) {
    this.selectedReservationToClone = null;
    this.reservationCloneItemData = null;
    setTimeout(() => {
      if (reservation.option && reservation.option.value) {
        this.selectedReservationToClone = reservation.option.value.id;
        this.reservationCloneItemData = reservation.option.value.additionalData;
      } else {
        this.selectedReservationToClone = null;
        this.reservationCloneItemData = null;
      }
    });
  }

  clone() {
    this.save();
    // if (this.selectedReservationToClone) {
    //     this.reservationService.cloneReservation(this.selectedReservationToClone).subscribe(data => {
    //         this.reservationCloneItemData = data.data;
    //         this.toaster.displayMessage('Sklonowano rezerwację');
    //         this.dialogRef.close({
    //             type: 'edit',
    //             reservation: this.reservationCloneItemData
    //         });
    //     });
    // }
  }

  getReservationDataPage() {
    this.isLoadingReservationList = true;
    const reservationOptions: Array<IAutocompleteMultiselectOption> = [];
    this.reservationOptions = [];
    this.reservationService.getList(this.reservationDataPageRequest).subscribe((result: ReservationDataPage) => {
      if (result && result.items && result.items.length) {
        _.each(result.items, (val: Reservation) => {
          let label: string = '';
          const dateFrom = new DatePipe('pl-PL').transform(val.dateFrom, 'dd.MM.yyyy');
          const dateTo = new DatePipe('pl-PL').transform(val.dateTo, 'dd.MM.yyyy');
          if (val.platform !== 'print') {
            label = `${dateFrom} - ${dateTo} ${val.comment ? val.comment : ''}`
          } else {
            label = `${val.editionNumber ? val.editionNumber + '/' : ''}${val.editionYear ? val.editionYear : ''} ${val.title ? val.title.name : ''}`
          }

          reservationOptions.push({
            id: val.id,
            label: label,
            additionalData: val
          });
        });

        this.reservationOptions = reservationOptions;
      }
      this.isLoadingReservationList = false;
    }, () => {
      this.isLoadingReservationList = false;
    });
  }

  onSelectTypeChange($event): void {
    this.showReservationCreate = false;
    this.selectedReservationToClone = null;
    this.reservationCloneItemData = null;

    this.selectedType = $event.value;
    this.selectedReservationCloneControl.setValue(null);

    if (this.selectedType === 'clone') {
      this.getReservationDataPage();
    }

    setTimeout(() => {
      this.showReservationCreate = true;
    }, 10);
  }

  showAlert(message): void {
    const alertPopupData: IAlertPopup = {
      title: 'Uwaga!',
      message: `${message}`
    };

    const dialogRef = this.dialog.open(AlertPopupComponent, {
      width: '440px',
      data: alertPopupData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe();
  }

  save() {
    this.reservationCreateComponent.save();
  }
}
