import { HttpClient } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { saveAs } from 'file-saver';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { OrderFormComponent } from '../order-form/order-form.component';
import { OrderService } from '../order.service';

@Component({
  selector: 'ep-order-create-popup',
  templateUrl: './order-create-popup.component.html',
  styleUrls: ['./order-create-popup.component.scss']
})
export class OrderCreatePopupComponent {

  dateFrom: any = null;
  isFormValid: boolean = false;
  isButtonActive: boolean = true;

  @ViewChild(OrderFormComponent) saveInChild: any;

  constructor(public dialogRef: MatDialogRef<OrderCreatePopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private toaster: ToasterService,
              private orderService: OrderService,
              private http: HttpClient) {
    if (this.data && this.data.dateFrom) {
      this.dateFrom = this.data.dateFrom;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOrderSaved($event): void {
    this.toaster.displayMessage('Zlecenie zostało utworzone');

    if ($event.downloadFile) {
      this.orderService.getPdfUrl($event.response['id']).subscribe((response: Response) => {
        this.http.get(response['data'].url, {
          responseType: 'blob'
        }).subscribe((response) => {
          let file = response;
          saveAs(file, 'doc.pdf');
        });
        this.toaster.displayMessage('Dokument został pobrany');
        this.dialogRef.close($event);
        this.isButtonActive = true;
      });
    } else {
      this.dialogRef.close($event);
      this.isButtonActive = true;
    }
  }

  onValidationChange($event): void {
    this.isFormValid = $event;
  }

  save() {
    this.isButtonActive = false;

    this.saveInChild.save();
  }

  saveDraft() {
    this.isButtonActive = false;

    this.saveInChild.saveDraft();
  }

  onFormSaved($event) {
    this.onNoClick();
  }
}
