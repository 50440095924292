import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CalendarModule } from 'angular-calendar';
import { SortablejsModule } from 'angular-sortablejs';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MaterialComponentsModule } from '../core/material/material-components.module';

import { KeysPipe } from '../shared/pipes/keys.pipe';
import { SharedModule } from '../shared/shared.module';
import { AccountComponent } from './account/account.component';
import { BrandCreatePopupComponent } from './brand/brand-create-popup/brand-create-popup.component';
import { BrandFormComponent } from './brand/brand-form/brand-form.component';
import { BrandService } from './brand/brand.service';
import { CalendarDetailsPopupComponent } from './calendar/calendar-details-popup/calendar-details-popup.component';
import { CalendarFiltersPopupComponent } from './calendar/calendar-filters-popup/calendar-filters-popup.component';
import { CalendarReservationListItemComponent } from './calendar/calendar-reservation-list-item/calendar-reservation-list-item.component';
import { CalendarReservationListComponent } from './calendar/calendar-reservation-list/calendar-reservation-list.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarService } from './calendar/calendar.service';
import { ClientAddPopupComponent } from './client/client-add-popup/client-add-popup.component';
import { ClientAddComponent } from './client/client-add/client-add.component';
import { ClientListComponent } from './client/client-list/client-list.component';
import { ClientComponent } from './client/client.component';
import { ClientService } from './client/client.service';
import { CoordinatorOrderListComponent } from './coordinator/coordinator-order-list/coordinator-order-list.component';
import { CoordinatorOrderListPrintComponent } from './coordinator/coordinator-order-list-print/coordinator-order-list-print.component';
import { DictionaryService } from './dictionary/dictionary.service';
import { DiscountService } from './discount/discount.service';
import { FileService } from './file/file.service';
import { HomeComponent } from './home/home.component';
import { MainComponent } from './main.component';
import { MerchantService } from './merchant/merchant.service';
import { OrderCreatePopupComponent } from './order/order-create-popup/order-create-popup.component';
import { OrderEditPopupComponent } from './order/order-edit-popup/order-edit-popup.component';
import { OrderFormComponent } from './order/order-form/order-form.component';
import { PublisherStatisticsPopupComponent } from './order/order-form/publisher-statistics-popup/publisher-statistics-popup.component';
import { ReservationMultiselectFieldComponent } from './order/order-form/reservation-multiselect-field/reservation-multiselect-field.component';
import { FilterTitlesPipe, SelectTitlePopupComponent } from './order/order-form/select-title-popup/select-title-popup.component';
import { OrderListPrintComponent } from './order/order-list-print/order-list-print.component';
import { OrderListComponent } from './order/order-list/order-list.component';
import { OrderService } from './order/order.service';
import { PackageService } from './package/package.service';
import { PlacementService } from './placement/placement.service';
import { PlacementCategoryService } from './placementCategory/placement-category.service';
import { Profile } from './profile/profile.model';
import { ProfileService } from './profile/profile.service';
import { PublisherService } from './publisher/publisher.service';
import { PublisherTreePopupComponent } from './report/publisher-tree-popup/publisher-tree-popup.component';
import { ReportAgencyRankingComponent } from './report/report-agency-ranking/report-agency-ranking.component';
import { ReportClientsRankingComponent } from './report/report-clients-ranking/report-clients-ranking.component';
import { ReportGenerateFormComponent } from './report/report-generate-form/report-generate-form.component';
import { ReportGeneratePopupComponent } from './report/report-generate-popup/report-generate-popup.component';
import { ReportListComponent } from './report/report-list/report-list.component';
import { ReportServiceOrderListComponent } from './report/report-service-order-list/report-service-order-list.component';
import { ReportServiceProfitMonthComponent } from './report/report-service-profit-month/report-service-profit-month.component';
import { ReportServiceProfitQuarterComponent } from './report/report-service-profit-quarter/report-service-profit-quarter.component';
import { ReportService } from './report/report.service';
import { ReportComponent } from './report/report/report.component';
import { ReservationCustomService } from './reservation-custom/reservation-custom.service';
import { ReservationCustomCreateComponent } from './reservation/reservation-custom-create/reservation-custom-create.component';
import { ReservationFormComponent } from './reservation/reservation-form/reservation-form.component';
import { ReservationListComponent } from './reservation/reservation-list/reservation-list.component';

import { ReservationPaymentComponent } from './reservation/reservation-payment/reservation-payment.component';

import { ReservationPrintListComponent } from './reservation/reservation-print-list/reservation-print-list.component';
import { ReservationService } from './reservation/reservation.service';
import { SelectPlacementPopupComponent } from './reservation/select-placement-popup/select-placement-popup.component';
import { UserService } from './user/user.service';
import { OrderPublisherStatisticsComponent } from './order/order-publisher-statistics/order-publisher-statistics.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SortablejsModule,
    ScrollToModule.forRoot(),
    SharedModule,
    CalendarModule,
    PerfectScrollbarModule,
    NgxDatatableModule,
    MaterialComponentsModule,
    BrowserAnimationsModule
  ],
  entryComponents: [
    CalendarDetailsPopupComponent,
    CalendarFiltersPopupComponent,
    OrderCreatePopupComponent,
    OrderEditPopupComponent,
    OrderFormComponent,
    ReportGeneratePopupComponent,
    ClientAddPopupComponent,
    ReservationFormComponent,
    ReservationCustomCreateComponent,
    BrandCreatePopupComponent,
    SelectPlacementPopupComponent,
    PublisherTreePopupComponent,
    SelectTitlePopupComponent,
    PublisherStatisticsPopupComponent
  ],
  declarations: [
    MainComponent,
    AccountComponent,
    HomeComponent,
    ReservationListComponent,
    ClientComponent,
    ClientAddComponent,
    ClientListComponent,
    CalendarComponent,
    CalendarDetailsPopupComponent,
    CalendarFiltersPopupComponent,
    CalendarReservationListComponent,
    CalendarReservationListItemComponent,
    OrderListComponent,
    OrderListPrintComponent,
    OrderCreatePopupComponent,
    OrderEditPopupComponent,
    OrderFormComponent,
    ReservationMultiselectFieldComponent,
    ReportListComponent,
    ReportGeneratePopupComponent,
    ReportGenerateFormComponent,
    ClientAddPopupComponent,
    ReservationFormComponent,
    ReservationCustomCreateComponent,
    ReservationPrintListComponent,
    BrandCreatePopupComponent,
    BrandFormComponent,
    SelectPlacementPopupComponent,
    CoordinatorOrderListComponent,
    CoordinatorOrderListPrintComponent,
    PublisherTreePopupComponent,
    ReportComponent,
    ReportServiceOrderListComponent,
    ReportServiceProfitMonthComponent,
    ReportServiceProfitQuarterComponent,
    ReportAgencyRankingComponent,
    ReportClientsRankingComponent,
    SelectTitlePopupComponent,
    KeysPipe,
    FilterTitlesPipe,
    ReservationPaymentComponent,
    OrderPublisherStatisticsComponent,
    PublisherStatisticsPopupComponent
  ],
  exports: [ReservationMultiselectFieldComponent, ReservationFormComponent, ReservationCustomCreateComponent],
  providers: [
    Profile,
    ProfileService,
    ReservationService,
    PackageService,
    ClientService,
    PlacementService,
    CalendarService,
    DictionaryService,
    OrderService,
    {
      provide: LOCALE_ID,
      useValue: 'pl',
    },
    UserService,
    FileService,
    MerchantService,
    PublisherService,
    ReportService,
    PlacementCategoryService,
    ReservationCustomService,
    BrandService,
    DiscountService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    DecimalPipe
  ],
  schemas: []
})
export class MainModule {
}
