import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { objectToParams } from '../../shared/utils/object-to-params';
import { OrderDataPage } from './model/order-data-page';
import { IOrderDataPageRequest } from './model/order-data-page-request';
import { Order } from './model/order.model';

@Injectable()
export class OrderService {

  public onOrderCreateOrEdit$: EventEmitter<any>;

  constructor(private http: HttpClient) {
    this.onOrderCreateOrEdit$ = new EventEmitter();
  }

  public getList(request: IOrderDataPageRequest, isActionRequired?: boolean): any {
    let params = objectToParams(request, 'yyyy-MM-dd');
    let apiUrl = `${environment.apiUrl}/orders/?${params}`;

    if (isActionRequired) {
      apiUrl = `${environment.apiUrl}/orders/list/coordinator?${params}`;
    }

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        return new OrderDataPage(response);
      }));
  }

  public save(request: any): any {
    let apiUrl = `${environment.apiUrl}/orders/`;

    return this.http.post(apiUrl, request);

  }

  public update(request: any): any {
    let apiUrl = `${environment.apiUrl}/orders/`;

    return this.http.put(apiUrl, request);
  }

  public saveDraft(request: any): any {
    let apiUrl = `${environment.apiUrl}/orders/draft`;

    return this.http.post(apiUrl, request);
  }

  public updateDraft(request: any): any {
    const apiUrl = `${environment.apiUrl}/orders/draft/digital`;

    return this.http.put(apiUrl, request);
  }

  public orderExport(id: number): any {
    const apiUrl = `${environment.apiUrl}/orders/export/${id}`;

    return this.http.put(apiUrl, {});
  }

  public getStatuses(status?: string, isActionRequired?: boolean): any {
    let params: string = '';

    if (status) {
      params = `?status=${status}`
    }

    if (isActionRequired) {
      if (params === '') {
        params += `?`
      } else {
        params += '&'
      }

      params += `coordinatorList=${isActionRequired.toString()}`;
    }

    const apiUrl = `${environment.apiUrl}/orders/statuses/${params}`;

    return this.http.get(apiUrl).pipe(map(data => {
      if (data && data['data'] && data['data'].currentStatus) {
        data['data'].statuses[Object.keys(data['data'].currentStatus)[0]] = data['data'].currentStatus[Object.keys(data['data'].currentStatus)[0]];
      }

      return data;
    }));
  }

  public getPdfUrl(id: number): any {
    const apiUrl = `${environment.apiUrl}/orders/pdf/${id}`;

    return this.http.get(apiUrl);
  }

  public deleteOrder(id: number): any {
    const apiUrl = `${environment.apiUrl}/orders/${id}`;

    return this.http.delete(apiUrl);
  }

  public acceptOrder(id: number): any {
    const apiUrl = `${environment.apiUrl}/orders/accept/${id}`;

    return this.http.put(apiUrl, {});
  }

  public rejectOrder(id: number): any {
    const apiUrl = `${environment.apiUrl}/orders/restore/${id}`;

    return this.http.put(apiUrl, {});
  }


  public getById(id: number): any {
    const apiUrl = `${environment.apiUrl}/orders/${id}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        return new Order(response['data']);
      }));
  }

}
