import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiErrorManagerService } from '../../../shared/utils/api-error-manager.service';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { BrandService } from '../brand.service';
import { BrandRequestModel } from '../model/brand-request.model';

@Component({
  selector: 'ep-brand-form',
  templateUrl: './brand-form.component.html',
  styleUrls: ['./brand-form.component.scss']
})
export class BrandFormComponent implements OnInit {
  brandForm: FormGroup;
  request: object;
  @Input() tradeId: number;
  @Output() onValidationChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSaved: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,
              private brandService: BrandService,
              private errorManager: ApiErrorManagerService,
              private toaster: ToasterService) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.brandForm = this.formBuilder.group({
      name: ['', Validators.required]
    });

    this.brandForm.valueChanges.subscribe(() => {
      this.onValidationChange.emit(this.brandForm.valid);
    })
  }

  saveBrand(): void {
    const brandRequestModel: BrandRequestModel = {
      name: this.brandForm.controls['name'].value,
      trade: this.tradeId
    };

    this.request = {
      create_brand_form: brandRequestModel
    };

    this.brandService.save(this.request).subscribe(response => {
      this.toaster.displayMessage('Brand został utworzony');
      this.onSaved.emit(response['data']);
      this.brandService.onBrandCreate$.emit(true);
    }, err => {
      this.errorManager.displayError(err.error);
    })
  }
}
