import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { objectToParams } from '../../shared/utils/object-to-params';
import { Package } from './package.model';

@Injectable()
export class PackageService {

  constructor(private http: HttpClient) {

  }

  public getList(request?: any): any {
    let params = objectToParams(request, 'yyyy-MM-dd');
    let apiUrl = `${environment.apiUrl}/packages/?${params}`;

    return this.http.get(apiUrl).pipe(map((response: Response) => {
      if (response && response['data']) {
        response['data'].map(item => {
          return new Package(item);
        });
      }

      return response;
    }));
  }
}
