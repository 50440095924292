import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: Array<{}>, args: any): Array<string> | Array<{}> {

    array = array || [];

    if (typeof args === 'undefined' || args.length !== 2) {
      return array;
    }

    const [key, direction] = args;

    if (direction !== 'ASC' && direction !== 'DESC') {
      return array;
    }

    return _.orderBy(array, (item: any) => item[key], direction.toLowerCase());
  }
}
