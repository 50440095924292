import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiErrorManagerService } from '../../../shared/utils/api-error-manager.service';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { ClientService } from '../client.service';

@Component({
  selector: 'ep-client-add',
  templateUrl: './client-add.component.html',
  styleUrls: ['./client-add.component.scss']
})
export class ClientAddComponent implements OnInit {
  clientAddForm: FormGroup;
  request: object;
  @Output() onValidationChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSaved: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,
              private clientService: ClientService,
              private errorManager: ApiErrorManagerService,
              private toaster: ToasterService) {
    this.createClientAddForm();
  }

  ngOnInit() {
  }

  createClientAddForm() {
    this.clientAddForm = this.formBuilder.group({
      name: ['', Validators.required],
      postalCode: ['', Validators.required],
      street: ['', Validators.required],
      city: ['', Validators.required],
      nip: ['', Validators.required],
    });

    this.clientAddForm.valueChanges.subscribe(res => {
      this.onValidationChange.emit(this.clientAddForm.valid);
    })
  }

  saveClient(): void {
    this.request = {
      create_client_form: {
        name: this.clientAddForm.controls['name'].value,
        postalCode: this.clientAddForm.controls['postalCode'].value,
        street: this.clientAddForm.controls['street'].value,
        city: this.clientAddForm.controls['city'].value,
        nip: this.clientAddForm.controls['nip'].value
      }
    };
    this.clientService.save(this.request).subscribe(response => {
      this.toaster.displayMessage('Klient został utworzony');
      this.onSaved.emit(response['data']);
      this.clientService.onClientCreate$.emit(true);
    }, err => {
      this.errorManager.displayError(err.error);
    })
  }
}
