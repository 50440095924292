import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from './profile/profile.service';

@Component({
  selector: 'ep-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MainComponent implements OnInit {
  @ViewChild('sidenav') sidenav;

  constructor(private router: Router, public profileService: ProfileService) {
    // this.router.navigate(['/home']);
  }

  ngOnInit() {
  }

  onActivate(e, scrollContainer) {
    scrollContainer.scrollTop = 0;
  }
}
