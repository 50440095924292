<div class="padding-lg">
  <div class="ep-background-1 mat-elevation-z1 padding-lg margin-bottom-lg">
    Zlecenia
  </div>
  <div class="ep-background-1">
    <mat-tab-group class="mat-accent" (selectedTabChange)="onListTypeChange($event)" [selectedIndex]="1">
      <mat-tab label="Digital">
      </mat-tab>
      <mat-tab label="Print">
        <form [formGroup]="filterForm" novalidate autocomplete="off">
          <ngx-datatable
            class="material"
            [rows]="orderData.items"
            [columnMode]="'force'"
            [headerHeight]="100"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [externalPaging]="true"
            (activate)="onRowClick($event)"
            [messages]="{ emptyMessage: 'Brak danych', totalMessage: 'wszystkich' }"
            [loadingIndicator]="loadingIndicator"
            [count]="orderData.total_items"
            [offset]="orderData.offset"
            [limit]="dataPageRequest.limit"
            (page)='setPage($event)'
            (sort)="onSort($event)">
            <ngx-datatable-column name="isActionRequired" [maxWidth]="44">
              <ng-template let-column="column" sortable="true" ngx-datatable-header-template></ng-template>

              <ng-template let-value="value" ngx-datatable-cell-template>
                <span class="order-status is-action-required" title="Gotowe do przekazania" *ngIf="value">
                    <mat-icon class="icon">priority_high</mat-icon>
                </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="ebr">
              <ng-template let-column="column" sortable="true" ngx-datatable-header-template>
                <div>
                  <div title="EBR">EBR</div>
                  <div title="EBR" class="ngx-filter-template">
                    <mat-form-field>
                      <input
                        [formControl]="filterForm.controls['identification']"
                        matInput
                        placeholder='filtruj'
                      />
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div title="{{value}}">
                  <span>{{value}}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="name">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div>
                  <div title="Nazwa kampanii">Nazwa kampanii</div>
                  <div title="Nazwa kampanii" class="ngx-filter-template">
                    <mat-form-field>
                      <input
                        [formControl]="filterForm.controls['name']"
                        matInput
                        placeholder='filtruj'
                      />
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div title="{{value}}">{{value}}</div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="titles">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div>
                  <div title="Tytuł">Tytuł</div>
                  <div title="Tytuł" class="ngx-filter-template">
                    <mat-form-field>
                      <input
                        [formControl]="filterForm.controls['title']"
                        matInput
                        placeholder='filtruj'
                      />
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div *ngFor="let title of value" title="{{ title }}">{{ title }}</div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="client">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div>
                  <div title="Klient">Klient</div>
                  <div title="Klient" class="ngx-filter-template">
                    <mat-form-field>
                      <input
                        [formControl]="filterForm.controls['client']"
                        matInput
                        placeholder='filtruj'
                      />
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div title="{{value.name}}">{{value.name}}</div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="salesman">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div>
                  <div title="Handlowiec">Handlowiec</div>
                  <div title="Handlowiec" class="ngx-filter-template">
                    <mat-form-field>
                      <input
                        [formControl]="filterForm.controls['salesman']"
                        matInput
                        placeholder='filtruj'
                      />
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div title="{{ value?.name }}">{{ value?.name }}</div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="reservations">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div>
                  <div title="Rezerwacje">Rezerwacje</div>
                  <div title="Rezerwacje" class="ngx-filter-template">
                    <div style="height: 56px;"></div>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div
                  *ngFor="let reservation of value | orderBy: ['editionNumber', 'ASC']  | orderBy: ['editionYear', 'ASC']"
                  title="{{ reservation.editionNumber }}/{{ reservation.editionYear }}">{{ reservation.editionNumber }}
                  /{{ reservation.editionYear }}</div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="status">
              <ng-template let-column="column" ngx-datatable-header-template>
                <div>
                  <div title="Status">Status</div>
                  <div title="Status" class="ngx-filter-template">
                    <mat-form-field>
                      <mat-select placeholder="Wybierz" [formControl]="filterForm.controls['status']"
                                  #select="matSelect">
                        <mat-select-trigger>{{ select.selected?.viewValue }}</mat-select-trigger>
                        <mat-option>Wszystkie</mat-option>
                        <mat-option *ngFor="let status of statuses" [value]="status.value">
                          {{ status.label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div title="{{statusesObject?.statuses[value]}}">{{statusesObject?.statuses[value]}}</div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="actions">
              <ng-template ngx-datatable-header-template>
                <div title="Akcje">Akcje</div>
                <div title="Akcje" class="ngx-filter-template" style="min-height: 49px;"></div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="data-table-action-icons" *ngIf="type === 'standardList'">
                  <button mat-icon-button class="icon-button" type="button" mat-button
                          (click)="openOrderEditPopup(row)">
                    <mat-icon class="icon">edit</mat-icon>
                  </button>
                  <button mat-icon-button class="icon-button" type="button" mat-button
                          (click)="printPdf(row)">
                    <mat-icon class="icon">file_download</mat-icon>
                  </button>
                  <button mat-icon-button class="icon-button" type="button" mat-button
                          (click)="deleteOrder(row)">
                    <mat-icon class="icon">delete</mat-icon>
                  </button>
                </div>
                <div class="data-table-action-icons" *ngIf="type === 'coordinatorList'">
                  <button mat-icon-button class="icon-button" type="button" mat-button
                          (click)="openOrderEditPopup(row)">
                    <mat-icon class="icon">edit</mat-icon>
                  </button>
                  <!--<button mat-icon-button class="icon-button" type="button" mat-button-->
                  <!--(click)="openOrderStatsPopup(row)">-->
                  <!--<mat-icon class="icon">multiline_chart</mat-icon>-->
                  <!--</button>-->
                </div>
                <div class="data-table-action-icons" *ngIf="type === 'managerList'">
                  <button mat-icon-button class="icon-button" type="button" mat-button
                          (click)="acceptOrder(row)"
                          *ngIf="row.status === 'not_accepted'">
                    <mat-icon class="icon">done_all</mat-icon>
                  </button>
                  <button mat-icon-button class="icon-button" type="button" mat-button
                          (click)="rejectOrder(row)"
                          *ngIf="row.status !== 'not_accepted'">
                    <mat-icon class="icon">eject</mat-icon>
                  </button>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-totalMessage="Razem"
                let-offset="offset">

              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </form>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
