import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../user/user.model';

@Injectable()
export class ProfileService {
  currentUser: User;

  constructor(private http: HttpClient) {

  }

  public getLoggedUser(): Observable<User> {
    let apiUrl = `${environment.apiUrl}/profile/me`;

    return this.http.get(apiUrl).pipe(map((response: Response) => {
      this.currentUser = new User(response['data']);
      return this.currentUser;
    }));
  }
}
