import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { objectToParams } from '../../shared/utils/object-to-params';
import { ReservationDataPageRequest } from '../reservation/model/reservation-data-page-request';
import { ReservationCustomDataPage } from './model/reservation-custom-data-page';

@Injectable()
export class ReservationCustomService {

  public onCreateOrEdit$: EventEmitter<any>;

  constructor(private http: HttpClient) {
    this.onCreateOrEdit$ = new EventEmitter();
  }

  public getList(request: ReservationDataPageRequest): any {
    let params = objectToParams(request);
    let apiUrl = `${environment.apiUrl}/customReservations/?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        return new ReservationCustomDataPage(response);
      }));

  }

  public save(request): any {
    let requestData = {
      'create_custom': request
    };

    let apiUrl = `${environment.apiUrl}/customReservations/`;

    return this.http.post(apiUrl, requestData);
  }


  public update(request): any {
    let requestData = {
      'edit_custom': request
    };

    let apiUrl = `${environment.apiUrl}/customReservations/`;

    return this.http.put(apiUrl, requestData);
  }

  public deleteReservation(id: number): any {
    let apiUrl = `${environment.apiUrl}/customReservations/${id}`;

    return this.http.delete(apiUrl);
  }
}
