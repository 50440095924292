<div class="mat-dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
  <div>Utwórz klienta</div>
  <button mat-icon-button mat-button matDialogClose>
    <mat-icon class="icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <ep-client-add
    (onValidationChange)="onValidationChange($event)"
    (onSaved)="onSaved($event)"></ep-client-add>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button matDialogClose>Anuluj</button>
  <button mat-button class="mat-primary mat-raised-button" disabled="{{!isFormValid}}" (click)="save()">Utwórz klienta
  </button>
</mat-dialog-actions>
