import { IDataPageRequest } from '../../../core/interface/data-page-request';
import { OrderDataPageFilters } from './order-data-page-filters';

export interface IOrderDataPageRequest extends IDataPageRequest {

}

export class OrderDataPageRequest implements IOrderDataPageRequest {
  public page: number;
  public limit: number;
  public filters: OrderDataPageFilters;
  private offset: number;

  constructor(obj?: any) {
    this.page = obj && obj.page || 1;
    this.limit = obj && obj.limit || 200;
    this.filters = new OrderDataPageFilters(obj && obj.filters || {});

  }

  setPageByOffset(offset) {
    this.page = offset + 1;
  }

  setPage(page: number) {
    this.page = page;
    this.offset = page - 1;
  }

  setLimit(limit: number) {
    this.limit = limit;
  }

  setFilters(filters) {
    this.filters = new OrderDataPageFilters(filters);
  }
}
