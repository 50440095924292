import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiErrorManagerService } from '../../../shared/utils/api-error-manager.service';
import { Reservation } from '../model/reservation.model';
import { ReservationCustomCreateComponent } from '../reservation-custom-create/reservation-custom-create.component';
import { ReservationFormComponent } from '../reservation-form/reservation-form.component';
import { ReservationService } from '../reservation.service';

@Component({
  selector: 'ep-reservation-create',
  templateUrl: './reservation-create.component.html',
  styleUrls: ['./reservation-create.component.scss']
})
export class ReservationCreateComponent implements OnInit {
  @Input() selectedType: string;
  @Output() onSaved: EventEmitter<any> = new EventEmitter<any>();
  @Input() dateFrom: any;
  @Input() reservationItemData: Reservation;
  @ViewChild(ReservationFormComponent) reservationFormComponent: any;
  @ViewChild(ReservationCustomCreateComponent) reservationCustomFormComponent: any;
  isEdit: boolean;

  constructor(private reservationService: ReservationService,
              private errorManager: ApiErrorManagerService) {
  }

  ngOnInit() {
    if (this.reservationItemData) {
      let type: string;
      if (this.reservationItemData && this.reservationItemData.type === 'package') {
        type = 'package';
      } else if (this.reservationItemData && this.reservationItemData.type === 'single') {
        type = 'placement';
      } else if (this.reservationItemData && this.reservationItemData.platform === 'print') {
        type = 'print';
      } else {
        type = 'custom';
      }
      this.isEdit = true;
      this.selectedType = type;
    }
  }

  public isReservationPaymentNotValid(): boolean {
    if (this.reservationFormComponent) {
      return this.reservationFormComponent.isReservationPaymentNotValid()
    }

    if (this.reservationCustomFormComponent) {
      return this.reservationCustomFormComponent.isReservationPaymentNotValid();
    }
  }

  onReservationSaved($event) {
    this.onSaved.emit($event);
  }

  save(): void {
    if (this.selectedType === 'custom') {
      this.reservationCustomFormComponent.save();
    } else {
      this.reservationFormComponent.save();
    }
  }

  update(): void {
    if (this.selectedType === 'custom') {
      this.reservationCustomFormComponent.update();
    } else {
      this.reservationFormComponent.update();
    }
  }
}
