import { IDataPage } from '../../../core/interface/data-page';
import { Reservation } from './reservation.model';

export interface IReservationDataPage extends IDataPage {
  items: Reservation[]
}

export class ReservationDataPage implements IReservationDataPage {
  items: Reservation[] = [];
  current_page: number;
  items_per_page: number;
  total_items: number;
  offset: number;

  constructor(obj?: any) {
    if (obj && obj.items) {
      obj.items.forEach(val => {
        this.items.push(new Reservation(val));
      })
    } else {
      this.items = [];
    }
    this.current_page = obj && obj.current_page || 0;
    this.items_per_page = obj && obj.items_per_page || 0;
    this.total_items = obj && obj.total_items || 0;
    this.offset = obj && obj.current_page - 1 || 0;
  }
}
