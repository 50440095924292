import { NgModule } from '@angular/core';
import { AuthenticationGuard } from './authentication/authentication.guard';

import { AuthenticationService } from './authentication/authentication.service';

import { MaterialComponentsModule } from './material/material-components.module';

@NgModule({
  imports: [],
  exports: [
    MaterialComponentsModule
  ],
  declarations: [],
  providers: [
    AuthenticationService,
    AuthenticationGuard
  ]
})

export class CoreModule {
}
