import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class FileService {

  constructor(private http: HttpClient) {

  }

  public upload(request: any): Observable<any> {

    let apiUrl = `${environment.apiUrl}/files/`;

    return this.http.post(apiUrl, request, {
      headers: new HttpHeaders().delete('Content-Type')
    });
  }

  public deleteFile(id: number): Observable<any> {

    let apiUrl = `${environment.apiUrl}/files/${id}`;

    return this.http.delete(apiUrl);
  }
}
