import { Injectable, Optional } from '@angular/core';
import { IPublisher } from '../interface/publisher';

@Injectable()
export class Publisher implements IPublisher {
  remoteId: number;
  publisherId: number;
  name: string;
  comments: string;
  URL: string;
  blocked: boolean;

  constructor(@Optional() obj?: any) {
    this.remoteId = obj && obj.remoteId || null;
    this.publisherId = obj && obj.publisherId || null;
    this.name = obj && obj.name || null;
    this.comments = obj && obj.comments || null;
    this.URL = obj && obj.URL || null;
    this.blocked = obj && obj.blocked || false;

  }
}
