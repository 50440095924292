import { Injectable, Optional } from '@angular/core';
import { IMerchant } from '../interface/merchant';

@Injectable()
export class Merchant implements IMerchant {
  id: number;
  name: string;
  email: string;
  username: string;

  constructor(@Optional() obj?: any) {
    this.id = obj && obj.id || null;
    this.name = obj && obj.name || null;
    this.email = obj && obj.email || null;
    this.username = obj && obj.username || null;

  }
}
