<div class="margin-top-md">
  <div class="margin-right-sm split-form">
    <div>
      <mat-form-field *ngIf="manualSplit">
        <input matInput type="number" placeholder="ilość miesięcy"
               [formControl]="manualSplitPaymentSizeControl"/>
      </mat-form-field>
    </div>
    <button mat-flat-button class="mat-primary" (click)="splitCosts()">Podziel na miesiące</button>
  </div>
</div>
<div class="payment-months" *ngIf="form?.controls['month']['controls'].length">
  <div class="payment-header">
    <div class="header-month">Miesiąc</div>
    <div class="header-value">Kwota PLN</div>
    <div class="header-action"></div>
  </div>
  <div class="payment" *ngFor="let payment of form?.controls['month']['controls']; let index = index">
    <div class="payment-month">
      <mat-form-field>
        <input matInput
               placeholder="Rok i miesiąc"
               [formControl]="form.controls['month']['controls'][index]['controls']['month']"
               (ngModelChange)="formDataChanged()"/>
        <mat-error>
          <div *ngIf="form.controls['month']['controls'][index]['controls']['month'].hasError('required')">
            To pole jest wymagane
          </div>
          <div *ngIf="form.controls['month']['controls'][index]['controls']['month'].hasError('pattern')">
            Wymagana wartość w formacie YYYY-MM np. 2019-11
          </div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="payment-value">
      <mat-form-field>
        <input matInput
               placeholder="Kwota PLN"
               pattern="^([,. \d]+)([,.]\d{2})?$"
               [formControl]="form.controls['month']['controls'][index]['controls']['value']"
               [epAutoNumeric]="2"
               (ngModelChange)="formDataChanged()"/>
        <mat-error>
          <div *ngIf="form.controls['month']['controls'][index]['controls']['value'].hasError('required')">
            To pole jest wymagane
          </div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="payment-action">
      <button mat-icon-button (click)="deleteRow(index)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>
<p class="error" *ngIf="isReservationPaymentNotValid()">Suma kosztów w miesiącach nie
  jest równa z kwotą rezerwacji.</p>
<div *ngIf="form?.controls['month']['controls'].length">
  <span class="add-row-btn" (click)="addRow()">dodaj kolejny</span>
</div>
