import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICalendarReservationListItem } from '../interface/calendar-reservation-list-item';

@Component({
  selector: 'ep-calendar-reservation-list-item',
  templateUrl: './calendar-reservation-list-item.component.html',
  styleUrls: ['./calendar-reservation-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarReservationListItemComponent implements OnInit {
  @Input() item: ICalendarReservationListItem;
  @Output() onShowDetails?: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  showDetails() {
    if (this.onShowDetails) {
      this.onShowDetails.emit(this.item);
    }
  }
}
