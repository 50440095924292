import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { objectToParams } from '../../shared/utils/object-to-params';
import { Client } from './client.model';
import { ClientDataPage } from './interface/client-data-page';
import { ClientDataPageRequest } from './interface/client-data-page-request';

@Injectable()
export class ClientService {
  public onClientCreate$: EventEmitter<any>;

  constructor(private http: HttpClient) {
    this.onClientCreate$ = new EventEmitter();
  }

  public getList(request?: ClientDataPageRequest): any {
    let params: string = '';
    if (request) {
      params = '?' + objectToParams(request);
    }

    let apiUrl = `${environment.apiUrl}/clients/${params}`;
    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        return new ClientDataPage(response);
      }));
  }

  public save(request): any {
    let apiUrl = `${environment.apiUrl}/clients/`;

    return this.http.post(apiUrl, request).pipe(map((response: Response) => {
      if (response && response['data']) {
        response['data'] = new Client(response['data']);
      }
      return response;
    }));
  }
}
