import { HttpClient } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { saveAs } from 'file-saver';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { Order } from '../model/order.model';
import { OrderFormComponent } from '../order-form/order-form.component';
import { OrderService } from '../order.service';

@Component({
  selector: 'ep-order-edit-popup',
  templateUrl: './order-edit-popup.component.html',
  styleUrls: ['./order-edit-popup.component.scss']
})
export class OrderEditPopupComponent {

  dateFrom: any = null;
  isFormValid: boolean;
  isCoordinatorView: boolean;
  isManagerView: boolean;
  isEditable: boolean;
  orderData: Order;
  @ViewChild(OrderFormComponent) saveInChild: any;

  constructor(public dialogRef: MatDialogRef<OrderEditPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private orderService: OrderService,
              private http: HttpClient,
              private toaster: ToasterService) {
    this.isCoordinatorView = data.isCoordinatorView;
    this.isManagerView = data.isManagerView;
    this.isEditable = data.isEditable;
    this.orderData = null;
    this.getOrder(data.order);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOrderSaved($event): void {
    this.toaster.displayMessage('Zlecenie zostało zapisane');
    this.dialogRef.close($event);
  }

  onValidationChange($event): void {
    this.isFormValid = $event;
  }

  save() {
    this.saveInChild.save();
  }

  updateOrder(type?: string, sendEmail?: boolean, sendToSAP?: boolean) {
    this.saveInChild.updateOrder(type, sendEmail, sendToSAP);
  }

  acceptOrder() {
    this.saveInChild.acceptOrder();
  }

  rejectOrder() {
    this.saveInChild.rejectOrder();
  }

  onFormSaved($event) {
    this.dialogRef.close(true);
  }

  getOrder(order: Order) {
    this.orderService.getById(order.id).subscribe((data: Order) => {
      this.orderData = data;
      this.orderData.isActionRequired = order.isActionRequired;
    });
  }

  print(): void {
    this.orderService.getPdfUrl(this.orderData ? this.orderData.id : null).subscribe((response: Response) => {
      this.http.get(response['data'].url, {
        responseType: 'blob'
      }).subscribe(responseFile => {
        saveAs(responseFile, 'doc.pdf');
      });
      this.toaster.displayMessage('Dokument został pobrany');
    });
  }
}
