<div class="mat-dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
  <div>Wybierz placementy</div>
  <button mat-icon-button mat-button matDialogClose>
    <mat-icon class="icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div>
    <div *ngIf="!isLoading">
      <ep-tree
        [items]="publisherGroupTree"
        (action)="treeAction($event)"
        [loadingIndication]="isLoadingTreeChildrenItems"
        (onSelectedChange)="selectedItems()"></ep-tree>
    </div>
    <div class="full-width margin-vertical-lg" *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button (click)="cancel()">Anuluj</button>
  <button mat-button class="mat-primary mat-raised-button" (click)="closeWithSelected()">Wybierz
  </button>
</mat-dialog-actions>
