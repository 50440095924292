import * as moment from 'moment';

import { DateFromToModel } from '../model/date-from-to.model';

/** Get first day and last day of the month in year. */
export function getDatePeriodByMonth(year: number, month: number): any {
  const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();

  let monthString: any = month;
  if (month < 10) {
    monthString = `0${month}`
  }
  return {
    from: `${year}-${monthString}-01`,
    to: `${year}-${monthString}-${daysInMonth}`
  }
}

export function generateDaysRange(startDate: moment.Moment, endDate: moment.Moment): Promise<Array<DateFromToModel>> {
  return new Promise((resolve, reject) => {
    const dayObj: DateFromToModel = {} as DateFromToModel;
    const finalArray = [];

    const datetime1 = moment(startDate);
    const datetime2 = moment(endDate);
    if (datetime1.format('D') !== datetime2.format('D')
      || datetime1.format('M') !== datetime2.format('M')
      || datetime1.format('YYYY') !== datetime2.format('YYYY')) {
      const duration = moment.duration(endDate.startOf('month').diff(startDate.startOf('month')));
      let totalMonths = duration.asMonths();
      totalMonths = Math.ceil(totalMonths) - 1;


      dayObj.startDate = startDate.startOf('month').format('YYYY-MM-DD');
      dayObj.endDate = startDate.endOf('month').format('YYYY-MM-DD');
      dayObj.monthLabel = startDate.endOf('month').format('YYYY-MM');
      finalArray.push(dayObj);

      let i = 1;
      if (totalMonths > 1) {
        for (i = 1; i <= totalMonths; i++) {
          const dayObj1: any = {};
          dayObj1.startDate = startDate.add(1, 'month').startOf('month').format('YYYY-MM-DD');
          dayObj1.endDate = startDate.endOf('month').format('YYYY-MM-DD');
          dayObj1.monthLabel = startDate.endOf('month').format('YYYY-MM');

          finalArray.push(dayObj1);
        }
      }

    } else {
      dayObj.startDate = Object.assign(startDate).startOf('month').format('YYYY-MM-DD');
      dayObj.endDate = Object.assign(endDate).endOf('month').format('YYYY-MM-DD');
      dayObj.monthLabel = Object.assign(endDate).endOf('month').format('YYYY-MM');

      finalArray.push(dayObj);
    }

    resolve(finalArray);
  });
}
