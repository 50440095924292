import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DiscountCalculateBasePriceDigitalRequestModel } from './model/discount-calculate-base-price-digital-request.model';
import { DiscountCalculateRequestModel } from './model/discount-calculate-request.model';
import { DiscountModel } from './model/discount.model';

@Injectable()
export class DiscountService {

  public onCreateOrEdit$: EventEmitter<any>;

  constructor(private http: HttpClient) {
    this.onCreateOrEdit$ = new EventEmitter();
  }

  public getList(): Observable<Array<DiscountModel>> {
    let apiUrl = `${environment.apiUrl}/discounts`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        return _.map(response['data'], data => {
          return new DiscountModel(data);
        })
      }));
  }


  public getTypeList(): Observable<any> {
    let apiUrl = `${environment.apiUrl}/discounts/types`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        return response['data'];
      }));
  }

  public calculateDiscounts(request: DiscountCalculateRequestModel): Observable<any> {
    let apiUrl = `${environment.apiUrl}/discounts/`;

    let requestObject = {
      recount_discount_form: request
    };

    return this.http.put(apiUrl, requestObject).pipe(
      map((response: Response) => {
        return response['data'];
      }));
  }

  public calculateBasePriceDigital(request: DiscountCalculateBasePriceDigitalRequestModel): Observable<any> {
    let apiUrl = `${environment.apiUrl}/discounts/digital`;

    let requestObject = {
      recount_digital_discount_form: request
    };

    return this.http.put(apiUrl, requestObject).pipe(
      map((response: Response) => {
        return response ? response['data'] : null;
      }));
  }
}
