import { DatePipe } from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import * as _ from 'lodash';
import { auditTime, debounceTime, map } from 'rxjs/operators';
import { AutocompleteFieldOptionStatusEnum } from '../../../shared/component/autocomplete-field/autocomplete-field-option-status.enum';
import { IAutocompleteFieldOption } from '../../../shared/component/autocomplete-field/autocomplete-field-option.interface';
import { IAutocompleteMultiselectOption } from '../../../shared/component/autocomplete-multiselect-field/autocomplete-multiselect-option.interface';

import { DateFromToModel } from '../../../shared/model/date-from-to.model';
import { PaymentPartitionModel } from '../../../shared/model/payment-partition.model';

import { ApiErrorManagerService } from '../../../shared/utils/api-error-manager.service';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { TransformerService } from '../../../shared/utils/transformer.service';
import { BrandCreatePopupComponent } from '../../brand/brand-create-popup/brand-create-popup.component';
import { ClientAddPopupComponent } from '../../client/client-add-popup/client-add-popup.component';
import { Client } from '../../client/client.model';
import { ClientService } from '../../client/client.service';
import { ClientDataPage } from '../../client/interface/client-data-page';
import { ClientDataPageFilters } from '../../client/interface/client-data-page-filters';
import { ClientDataPageRequest } from '../../client/interface/client-data-page-request';
import { DictionaryService } from '../../dictionary/dictionary.service';
import { BrandDictionaryModel } from '../../dictionary/model/brand-dictionary.model';
import { CampaignTypeDictionaryModel } from '../../dictionary/model/campaign-type-dictionary.model';
import { DictionaryFiltersRequestModel } from '../../dictionary/model/dictionary-filters-request.model';
import { DictionaryRequestModel } from '../../dictionary/model/dictionary-request.model';
import { OrderFormDictionaryModel } from '../../dictionary/model/order-form-dictionary.model';
import { OrderFormItemsDictionaryModel } from '../../dictionary/model/order-form-items-dictionary.model';
import { PaymentTypeDictionaryModel } from '../../dictionary/model/payment-type-dictionary.model';
import { PriceListDictionaryModel } from '../../dictionary/model/price-list-dictionary.model';
import { PrincipalDictionaryModel } from '../../dictionary/model/principal-dictionary.model';
import { SalesModelDictionaryModel } from '../../dictionary/model/sales-model-dictionary.model';
import { SalesmanDictionaryWrapperModel } from '../../dictionary/model/salesman-dictionary-wrapper.model';
import { SalesmanDictionaryModel } from '../../dictionary/model/salesman-dictionary.model';
import { TradeDictionaryModel } from '../../dictionary/model/trade-dictionary.model';
import { DiscountService } from '../../discount/discount.service';
import { DiscountCalculateRequestModel } from '../../discount/model/discount-calculate-request.model';
import { DiscountModel } from '../../discount/model/discount.model';
import { PackageService } from '../../package/package.service';
import { ProfileService } from '../../profile/profile.service';
import { PublisherDataPageFilters } from '../../publisher/model/publisher-data-page-filters';
import { PublisherDataPageRequest } from '../../publisher/model/publisher-data-page-request';
import { Publisher } from '../../publisher/model/publisher.model';
import { PublisherService } from '../../publisher/publisher.service';
import { ReservationCustom } from '../../reservation-custom/model/reservation-custom.model';
import { ReservationCustomService } from '../../reservation-custom/reservation-custom.service';
import { ReservationRequestModel } from '../model/reservation-request.model';
import { BasePriceDigitalCalculate } from '../reservation-form/base-price-digital-calculate';
import { ReservationDiscountBuilder } from '../reservation-form/reservation-discount-builder';
import { ReservationDiscountModel } from '../reservation-form/reservation-discount.model';
import { ReservationService } from '../reservation.service';
import * as moment from 'moment';
import {ReservationPaymentComponent} from '../reservation-payment/reservation-payment.component';

@Component({
  selector: 'ep-reservation-custom-create',
  templateUrl: './reservation-custom-create.component.html',
  styleUrls: ['./reservation-custom-create.component.scss']
})
export class ReservationCustomCreateComponent implements OnInit {
  reservationForm: FormGroup;

  publisherDataPageRequest: PublisherDataPageRequest = new PublisherDataPageRequest();
  publisherOptions: IAutocompleteFieldOption[] = [];
  publisherOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  brandRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  brandOptions: IAutocompleteFieldOption[] = [];
  brandOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  campaignTypeRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  campaignTypeOptions: IAutocompleteFieldOption[] = [];
  campaignTypeOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  clientDataPageRequest: ClientDataPageRequest = new ClientDataPageRequest();
  clientOptions: IAutocompleteFieldOption[] = [];
  clientOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  discountTypeOptions: IAutocompleteFieldOption[] = [];
  discountTypeOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  orderFormRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  orderFormOptions: IAutocompleteFieldOption[] = [];
  orderFormOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  paymentRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  paymentOptions: IAutocompleteFieldOption[] = [];
  paymentOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  priceListRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  priceListOptions: IAutocompleteFieldOption[] = [];
  priceListOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  principalRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  principalOptions: IAutocompleteFieldOption[] = [];
  principalOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  salesmanRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  salesmanOptions: IAutocompleteFieldOption[] = [];
  salesmanOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  salesModelRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  salesModelOptions: IAutocompleteFieldOption[] = [];
  salesModelOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  tradeRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  tradeOptions: IAutocompleteFieldOption[] = [];
  tradeOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  isEdit: boolean = false;

  discountTypeControl: FormControl = new FormControl();
  discounts: Array<ReservationDiscountModel> = [];
  showDiscountFields: boolean;

  showValuePrice: boolean = true;
  showFixedPrice: boolean = true;
  showPriceListPrice: boolean = true;

  public paymentData: Array<PaymentPartitionModel>;
  public isPaymentValid: boolean;

  @Output() onSaved: EventEmitter<any> = new EventEmitter<any>();
  @Input() dateFrom: any;
  @Input() reservationItemData: ReservationCustom;


  dataSubscribers: any = {
    getBrandListData: (response: Array<BrandDictionaryModel>) => {
      if (response && response.length) {
        let brandOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response, (val: BrandDictionaryModel) => {
          brandOptions.push({
            id: val.id,
            label: val.name
          });
        });

        this.brandOptions = brandOptions;
        this.brandOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.brandOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getCampaignTypeListData: (response: Array<CampaignTypeDictionaryModel>) => {
      if (response && response.length) {
        _.each(response, (val: CampaignTypeDictionaryModel) => {
          if (!_.isArray(this.campaignTypeOptions)) {
            this.campaignTypeOptions = [];
          }

          this.campaignTypeOptions.push({
            id: val.id,
            label: val.name
          });
        });

        this.campaignTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.campaignTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getClientListData: (response: ClientDataPage) => {
      if (response && response.items && response.items.length) {
        _.each(response.items, (val: Client) => {
          if (!_.isArray(this.clientOptions)) {
            this.clientOptions = [];
          }

          this.clientOptions.push({
            id: val.id,
            label: val.name
          });
        });
        if (this.reservationItemData && this.reservationItemData.client) {
          this.clientOptions.push({
            id: this.reservationItemData.client.id,
            label: this.reservationItemData.client.name
          });
        }
        this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getDiscountTypeListData: (response: Array<DiscountModel>) => {
      let discountTypeOptions: Array<IAutocompleteMultiselectOption> = [];

      if (response && _.isObject(response)) {
        _.each(response, (val: any, key: any) => {
          discountTypeOptions.push({
            id: key,
            label: val
          });
        });
        this.discountTypeOptions = discountTypeOptions;

        if (this.reservationItemData && this.reservationItemData.discounts && _.isArray(this.reservationItemData.discounts) && this.reservationItemData.discounts.length) {
          let discountSelected = [];
          _.each(this.reservationItemData.discounts, (val: any, key: any) => {
            discountSelected.push({
              id: `${val.type}`,
              label: val.name
            });

          });
          let discountSelectedReduced = _.map(discountTypeOptions, (discount: IAutocompleteMultiselectOption) => {
            if (_.find(discountSelected, {id: discount.id})) {
              return discount;
            } else {
              return undefined;
            }
          });

          discountSelectedReduced = _.compact(discountSelectedReduced);
          this.discountTypeControl.setValue(discountSelectedReduced);
        }

        this.discountTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.discountTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getOrderFormListData: (response: OrderFormDictionaryModel) => {
      if (response && response.items && response.items.length) {
        let orderFormOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response.items, (val: OrderFormItemsDictionaryModel) => {
          orderFormOptions.push({
            id: val.id,
            label: val.name
          });
        });

        this.orderFormOptions = orderFormOptions;
        this.orderFormOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.orderFormOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getPaymentListData: (response?: Array<PaymentTypeDictionaryModel>): void => {
      if (response && response && response.length) {
        let paymentOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response, (val: PaymentTypeDictionaryModel) => {
          paymentOptions.push({
            id: val.id,
            label: val.name
          });
        });

        this.paymentOptions = paymentOptions;
        this.paymentOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.paymentOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getSalesmanListData: (response?: SalesmanDictionaryWrapperModel): void => {
      if (response && response.items && _.isArray(response.items) && response.items.length) {
        let salesmanOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response.items, (val: SalesmanDictionaryModel) => {
          salesmanOptions.push({
            id: val.id,
            label: val.name
          });
        });
        if (this.isEdit && this.reservationItemData.salesman && this.reservationItemData.salesman.id) {
          let currentSalesman: any = _.find(salesmanOptions, {id: this.reservationItemData.salesman.id});
          if (!currentSalesman) {
            currentSalesman = {
              id: this.reservationItemData.salesman.id,
              label: this.reservationItemData.salesman.name
            };
            salesmanOptions.push(currentSalesman);
          }
        } else if (this.reservationItemData && !this.reservationItemData.salesman && response.currentUser) {
          const currentSalesman = _.find(response.items, (salesmanItem: SalesmanDictionaryModel) => {
            if (salesmanItem.id === response.currentUser.id) {
              return true;
            }
          });
          if (currentSalesman) {
            this.salesmanOptions.push({
              id: currentSalesman.id,
              label: `${currentSalesman.name}`
            });
          }
        }

        this.salesmanOptions = salesmanOptions;
        this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getSalesModelListData: (response?: Array<SalesModelDictionaryModel>): void => {
      if (response && response && response.length) {
        let salesModelOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response, (val: SalesModelDictionaryModel) => {
          salesModelOptions.push({
            id: val.id,
            label: val.name
          });
        });

        this.salesModelOptions = salesModelOptions;
        this.salesModelOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.salesModelOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getTradeListData: (response?: Array<TradeDictionaryModel>): void => {
      if (response && response && response.length) {
        let tradeOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response, (val: TradeDictionaryModel) => {
          tradeOptions.push({
            id: val.id,
            label: val.name
          });
        });


        this.tradeOptions = tradeOptions;
        this.tradeOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.tradeOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getPublisherListData: (response: any) => {
      if (response && response.data && response.data.length) {
        let publisherOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response.data, (val: Publisher) => {
          publisherOptions.push({
            id: val.publisherId,
            label: val.name
          });
        });

        this.publisherOptions = publisherOptions;
        this.publisherOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.publisherOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getPriceListData: (response?: Array<PriceListDictionaryModel>): void => {
      if (response && response && response.length) {
        const options: Array<IAutocompleteMultiselectOption> = [];
        _.each(response, (val: PriceListDictionaryModel) => {
          options.push({
            id: val.id,
            label: `${val.name} ${val.productGroupName ? '- ' + val.productGroupName : ''}`
          });
        });

        if (this.isEdit && this.reservationItemData.priceList && this.reservationItemData.priceList.id) {
          let currentItem: any = _.find(options, {id: this.reservationItemData.priceList.id});

          if (!_.isEqual(this.reservationForm.controls['priceList'].value, currentItem)) {
            this.reservationForm.controls['priceList'].setValue(currentItem);
          }
        }
        if (this.reservationForm.controls['priceList']) {
          this.reservationForm.controls['priceList'].valueChanges.pipe(debounceTime(500)).subscribe(() => {
            this.calculateBasePrice();
          });
        }
        this.priceListOptions = options;
        this.priceListOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.priceListOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getPrincipalListData: (response?: Array<PrincipalDictionaryModel>): void => {
      if (response && response && response.length) {
        const options: Array<IAutocompleteMultiselectOption> = [];
        _.each(response, (val: PrincipalDictionaryModel) => {
          options.push({
            id: val.id,
            label: val.name
          });
        });

        if (this.isEdit && this.reservationItemData.principal && this.reservationItemData.principal.id) {
          let currentItem: any = _.find(options, {id: this.reservationItemData.principal.id});

        }

        this.principalOptions = options;
        this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    defaultErrorListData: (context, err) => {
      switch (context) {
        default:
          this[`${context}OptionsStatus`] = AutocompleteFieldOptionStatusEnum.tooManyResults;
          break;
      }
    }
  };
  dataActions: any = {
    getBrandList: (brandRequest?: any): void => {
      this.brandOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.brandOptions = [];
      this.dictionaryService.getBrandsByTrade(this.reservationForm.controls['trade'].value.id, brandRequest)
        .subscribe(
          response => this.dataSubscribers.getBrandListData(response),
          err => this.dataSubscribers.defaultErrorListData('brand', err));
    },
    getCampaignTypeListData: (campaignTypeRequest?: any): void => {
      this.campaignTypeOptions = [];
      this.campaignTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getCampaignTypes(campaignTypeRequest)
        .subscribe(
          response => this.dataSubscribers.getCampaignTypeListData(response),
          err => this.dataSubscribers.defaultErrorListData('campaign', err));
    },
    getClientList: (clientDataPageRequest?: ClientDataPageRequest): void => {
      this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.clientOptions = [];
      this.clientService.getList(clientDataPageRequest)
        .subscribe(
          response => this.dataSubscribers.getClientListData(response),
          err => this.dataSubscribers.defaultErrorListData('client', err));
    },
    getDiscountTypeList: (): void => {
      this.discountTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.discountTypeOptions = [];
      this.discountService.getTypeList().pipe(map(response => this.dataSubscribers.getDiscountTypeListData(response)))
        .subscribe(
          d => d,
          err => this.dataSubscribers.defaultErrorListData('discountType', err));
    },
    getOrderFormList: (orderFormRequest?: DictionaryRequestModel): void => {
      this.orderFormOptions = [];
      this.orderFormOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getOrderForms(orderFormRequest, 'niestandard')
        .subscribe(
          response => this.dataSubscribers.getOrderFormListData(response),
          err => this.dataSubscribers.defaultErrorListData('orderForm', err));
    },
    getPaymentListData: (paymentRequest?: any): void => {
      this.paymentOptions = [];
      this.paymentOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getPaymentTypes(paymentRequest)
        .subscribe(
          response => this.dataSubscribers.getPaymentListData(response),
          err => this.dataSubscribers.defaultErrorListData('payment', err));
    },
    getPublisherDataPage: (publisherDataPageRequest?: PublisherDataPageRequest): void => {
      this.publisherOptions = [];
      this.publisherOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.publisherService.getList(publisherDataPageRequest)
        .subscribe(
          response => this.dataSubscribers.getPublisherListData(response),
          err => this.dataSubscribers.defaultErrorListData('publisher', err));
    },
    getPrincipalList: (principalRequest?: any): void => {
      this.principalOptions = [];
      this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getPrincipals(principalRequest)
        .subscribe(
          response => this.dataSubscribers.getPrincipalListData(response),
          err => this.dataSubscribers.defaultErrorListData('principal', err));
    },
    getPriceList: (priceListRequest?: any): void => {
      this.priceListOptions = [];
      this.priceListOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getPriceListNonStandard(priceListRequest)
        .subscribe(
          response => this.dataSubscribers.getPriceListData(response),
          err => this.dataSubscribers.defaultErrorListData('priceList', err));
    },
    getSalesmanList: (salesModelRequest?: any): void => {
      this.salesmanOptions = [];
      this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getSalesmans(salesModelRequest)
        .subscribe(
          response => this.dataSubscribers.getSalesmanListData(response),
          err => this.dataSubscribers.defaultErrorListData('salesman', err));
    },
    getSalesModelList: (salesModelRequest?: any): void => {
      this.salesModelOptions = [];
      this.salesModelOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getSalesModels(salesModelRequest)
        .subscribe(
          response => this.dataSubscribers.getSalesModelListData(response),
          err => this.dataSubscribers.defaultErrorListData('salesModel', err));
    },
    getTradeList: (tradeRequest?: any): void => {
      this.tradeOptions = [];
      this.tradeOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getTrades(tradeRequest)
        .subscribe(
          response => this.dataSubscribers.getTradeListData(response),
          err => this.dataSubscribers.defaultErrorListData('trade', err));
    }
  };
  eventListeners: any = {
    onChangeBrandSearchText: (text) => {
      this.brandOptions = [];
      if (text && (text.length >= 3) || !text || text === '') {
        this.brandRequest.filters.name = text;
        this.dataActions.getBrandList(this.brandRequest);
      } else {
        this.brandOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeCampaignTypeSearchText: (text) => {
      this.campaignTypeOptions = [];
      if (text && (text.length >= 3) || !text || text === '') {
        this.campaignTypeRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getCampaignTypeListData(this.campaignTypeRequest);
      } else {
        this.campaignTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeClientSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.clientDataPageRequest.setFilters(new ClientDataPageFilters({name: text}));
        this.dataActions.getClientList(this.clientDataPageRequest);
      } else {
        this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeOrderFormSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.orderFormRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getOrderFormList(this.orderFormRequest);
      } else {
        this.orderFormOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangePaymentSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.paymentRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getPaymentListData(this.paymentRequest);
      } else {
        this.paymentOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangePublisherSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.publisherDataPageRequest.setFilters(new PublisherDataPageFilters({name: text}));
        this.dataActions.getPublisherDataPage(this.publisherDataPageRequest);
      } else {
        this.publisherOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangePriceListSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        const publisherId = this.reservationForm.controls['publisher'].value ? this.reservationForm.controls['publisher'].value.id : null;
        const orderFormId = this.reservationForm.controls['orderForm'].value ? this.reservationForm.controls['orderForm'].value.id : null;

        this.priceListRequest.setFilters(new DictionaryFiltersRequestModel({name: text, publisher: publisherId, orderForm: orderFormId}));
      } else {
        this.priceListOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangePrincipalSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.principalRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getPrincipalList(this.principalRequest);
      } else {
        this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeSalesmanSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.salesmanRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getSalesmanList(this.salesmanRequest);
      } else {
        this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeSalesModelSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.salesModelRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getSalesModelList(this.salesModelRequest);
      } else {
        this.salesModelOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeTradeSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.tradeRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));

        this.dataActions.getTradeList(this.tradeRequest);
      } else {
        this.tradeOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onClickClientNoResultAction: (): void => {
      this.openClientAddPopup();
    },
    onClickBrandNoResultAction: (): void => {
      this.openBrandAddPopup();
    }
  };

  @ViewChild(ReservationPaymentComponent) public reservationPaymentComponent: ReservationPaymentComponent;

  constructor(private formBuilder: FormBuilder,
              private errorManager: ApiErrorManagerService,
              private dictionaryService: DictionaryService,
              private discountService: DiscountService,
              private publisherService: PublisherService,
              private reservationCustomService: ReservationCustomService,
              private packageService: PackageService,
              private clientService: ClientService,
              private profileService: ProfileService,
              private toaster: ToasterService,
              private transformerService: TransformerService,
              private dialog: MatDialog,
              private reservationService: ReservationService) {

    this.dataActions.getPublisherDataPage();
    this.dataActions.getClientList();
    this.dataActions.getPaymentListData();
    this.dataActions.getOrderFormList();
    this.dataActions.getCampaignTypeListData();
    this.dataActions.getSalesmanList();
    this.dataActions.getSalesModelList();
    this.dataActions.getTradeList();
    this.dataActions.getPrincipalList();
    this.dataActions.getDiscountTypeList();
  }

  ngOnInit() {
    if (this.reservationItemData) {
      this.isEdit = true;
      this.createForm(this.reservationItemData);
    } else {
      this.createForm();
    }
  }

  public isReservationPaymentNotValid(): boolean {
    return this.reservationPaymentComponent && this.reservationPaymentComponent.isReservationPaymentNotValid();
  }

  openClientAddPopup(): void {
    let dialogRef = this.dialog.open(ClientAddPopupComponent, {
      width: '440px',
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientOptions.push({
          id: result.id,
          label: result.name
        });
        this.reservationForm.controls['client'].setValue({
          id: result.id,
          label: result.name
        });
        this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      }
    });
  }

  openBrandAddPopup(): void {
    let dialogRef = this.dialog.open(BrandCreatePopupComponent, {
      width: '440px',
      data: {
        tradeId: this.reservationForm.controls['trade'].value.id,
        clientId: this.reservationForm.controls['trade'].value ? this.reservationForm.controls['trade'].value.id : null
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.brandOptions.push({
          id: result.id,
          label: result.name
        });
        this.reservationForm.controls['brand'].setValue({
          id: result.id,
          label: result.name
        });
        this.brandOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      }
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  removeSpaces(val: string): number {
    if (val && _.isString(val)) {
      const value = val.replace(',', '.').split(' ').join('');
      return +value;
    } else if (_.isNumber(val)) {
      return val;
    } else {
      return 0;
    }
  }

  watchChangesForBasePrice() {
    if (this.reservationForm.controls['salesModel']) {
      this.reservationForm.controls['salesModel'].valueChanges.pipe(debounceTime(500)).subscribe(() => {
        this.calculateBasePrice();
      });

    }
    let request: DiscountCalculateRequestModel = {
      basePrice: this.reservationForm.controls['priceListPrice'].value,
      discounts: []
    };

    if (this.reservationForm.controls['publisher']) {
      this.reservationForm.controls['publisher'].valueChanges.pipe(debounceTime(500)).subscribe((data: IAutocompleteFieldOption) => {
        this.calculateBasePrice();
        this.dataActions.getPriceList({
          filters: {
            publisher: data ? data.id : null,
            orderForm: this.reservationForm.controls['orderForm'].value ? this.reservationForm.controls['orderForm'].value.id : undefined
          }
        });
        this.reservationForm.controls['priceList'].setValue(null);
      });
    }

    // if (this.reservationForm.controls['creation']) {
    //     this.reservationForm.controls['creation'].valueChanges.debounceTime(500).subscribe(() => {
    //         this.calculateBasePrice();
    //     });
    // }

    if (this.reservationForm.controls['orderForm']) {
      this.reservationForm.controls['orderForm'].valueChanges.pipe(debounceTime(500)).subscribe((data: IAutocompleteFieldOption) => {
        this.dataActions.getPriceList({
          filters: {
            publisher: this.reservationForm.controls['publisher'].value ? this.reservationForm.controls['publisher'].value.id : undefined,
            orderForm: data ? data.id : null
          }
        });
        this.reservationForm.controls['priceList'].setValue(null);
        this.calculateBasePrice();
      });
    }
  }

  calculateBasePrice() {
    const basePriceCalculate = new BasePriceDigitalCalculate();

    basePriceCalculate.setPublisher(this.reservationForm.controls['publisher'].value ? [this.reservationForm.controls['publisher'].value.id] : null);
    // basePriceCalculate.setCreation(this.reservationForm.controls['creation'] && this.reservationForm.controls['creation'].value ? this.reservationForm.controls['creation'].value.id : this.creation ? this.creation.id : null);
    basePriceCalculate.setOrderForm(this.reservationForm.controls['orderForm'].value);
    basePriceCalculate.setSalesModel(this.reservationForm.controls['salesModel'].value);
    // basePriceCalculate.setEmissionNumber(this.reservationForm.controls['emissionsNumber'].value);
    basePriceCalculate.setType('1');

    const priceListId: number = this.reservationForm.controls['priceList'].value ? this.reservationForm.controls['priceList'].value.id : null;
    basePriceCalculate.setPriceList(priceListId);

    if (basePriceCalculate.validateRequest('custom')) {
      this.discountService.calculateBasePriceDigital(basePriceCalculate.getRequest()).subscribe(data => {
        this.showPriceListPrice = false;

        if (data && data['base_price']) {
          this.reservationForm.controls['priceListPrice'].setValue(data['base_price']);
        } else if (data && data['basePrice']) {
          this.reservationForm.controls['priceListPrice'].setValue(data['basePrice']);
        }
        setTimeout(() => {
          this.showPriceListPrice = true;
        });
        // this.reservationForm.controls['fixedPrice'].setValue(0);
        this.reservationForm.controls['value'].setValue(null);
        this.discounts = [];
        this.discountTypeControl.setValue(null);
      });
    }
  }

  save(): void {
    if (this.reservationForm.valid && this.isPaymentValid) {
      let request = {
        title: undefined,
        name: this.reservationForm.controls['name'].value,
        dateFrom: new DatePipe('pl-PL').transform(this.reservationForm.controls['dateFrom'].value, 'yyyy-MM-dd HH:mm:ss'),
        dateTo: new DatePipe('pl-PL').transform(this.reservationForm.controls['dateTo'].value, 'yyyy-MM-dd HH:mm:ss'),
        publishers: this.reservationForm.controls['publisher'].value.id,
        value: this.removeSpaces(this.reservationForm.controls['value'].value),
        client: this.reservationForm.controls['client'].value.id,
        principal: this.reservationForm.controls['principal'].value.id,
        trade: this.reservationForm.controls['trade'].value.id,
        brand: this.reservationForm.controls['brand'].value.id,
        salesman: this.reservationForm.controls['salesman'].value.id,
        paymentType: this.reservationForm.controls['paymentType'].value.id,
        orderForm: this.reservationForm.controls['orderForm'].value.id,
        campaignType: this.reservationForm.controls['campaignType'].value.id,
        salesModel: this.reservationForm.controls['salesModel'].value.id,
        comment: this.reservationForm.controls['comment'].value,
        agencyFee: this.removeSpaces(this.reservationForm.controls['agencyFee'].value),
        technicalCosts: this.removeSpaces(this.reservationForm.controls['technicalCosts'].value),
        fixedPrice: this.removeSpaces(this.reservationForm.controls['fixedPrice'].value),
        priceListPrice: this.removeSpaces(this.reservationForm.controls['priceListPrice'].value),
        discounts: undefined,
        priceList: this.reservationForm.controls['priceList'].value.id,
        paymentPartition: this.paymentData
      };

      if (this.discountTypeControl.value && this.discountTypeControl.value.length) {
        request.discounts = _.map(this.discounts, discount => {
          if (discount.type === '0' || discount.type === '2') {
            discount.percentValue = discount.formControl.value;
          } else {
            discount.amountValue = this.removeSpaces(discount.formControl.value);
          }

          return new DiscountModel(discount);
        });
      }

      const requestModel = new ReservationRequestModel(request, this.transformerService).getRequestModel('custom');

      this.reservationCustomService.save(requestModel).subscribe((response: Response) => {
        this.onSaved.emit(response['data']);
        this.reservationService.onCreateOrEdit$.emit(true);
      }, err => {
        this.errorManager.displayError(err.error);
      });

    } else {
      this.validateAllFormFields(this.reservationForm);
    }
  }

  update(): void {
    if (this.reservationForm.valid && this.isPaymentValid) {
      let request = {
        id: null,
        name: this.reservationForm.controls['name'].value,
        description: this.reservationForm.controls['description'].value,
        dateFrom: new DatePipe('pl-PL').transform(this.reservationForm.controls['dateFrom'].value, 'yyyy-MM-dd HH:mm:ss'),
        dateTo: new DatePipe('pl-PL').transform(this.reservationForm.controls['dateTo'].value, 'yyyy-MM-dd HH:mm:ss'),
        publishers: this.reservationForm.controls['publisher'].value.id,
        value: this.removeSpaces(this.reservationForm.controls['value'].value),
        client: this.reservationForm.controls['client'].value.id,
        principal: this.reservationForm.controls['principal'].value.id,
        trade: this.reservationForm.controls['trade'].value.id,
        brand: this.reservationForm.controls['brand'].value.id,
        salesman: this.reservationForm.controls['salesman'].value.id,
        paymentType: this.reservationForm.controls['paymentType'].value.id,
        orderForm: this.reservationForm.controls['orderForm'].value.id,
        campaignType: this.reservationForm.controls['campaignType'].value.id,
        salesModel: this.reservationForm.controls['salesModel'].value.id,
        comment: this.reservationForm.controls['comment'].value,
        agencyFee: this.removeSpaces(this.reservationForm.controls['agencyFee'].value),
        technicalCosts: this.removeSpaces(this.reservationForm.controls['technicalCosts'].value),
        fixedPrice: this.removeSpaces(this.reservationForm.controls['fixedPrice'].value),
        priceListPrice: this.removeSpaces(this.reservationForm.controls['priceListPrice'].value),
        discounts: undefined,
        priceList: this.reservationForm.controls['priceList'].value.id,
        paymentPartition: this.paymentData
      };

      if (this.discountTypeControl.value && this.discountTypeControl.value.length) {
        request.discounts = _.map(this.discounts, discount => {
          if (discount.type === '0' || discount.type === '2') {
            discount.percentValue = discount.formControl.value;
          } else {
            discount.amountValue = this.removeSpaces(discount.formControl.value);
          }

          return new DiscountModel(discount);
        });
      }

      if (this.isEdit) {
        request.id = this.reservationItemData.id;
      }

      let requestModel = new ReservationRequestModel(request, this.transformerService).getRequestModel('custom');
      this.reservationCustomService.update(requestModel).subscribe((response: Response) => {
        this.onSaved.emit(response['data']);
        this.reservationService.onCreateOrEdit$.emit(true);
      }, err => {
        this.errorManager.displayError(err.error);
      });

    } else {
      this.validateAllFormFields(this.reservationForm);
    }
  }

  buildDiscounts(discountType, isEditBuild?) {
    this.showDiscountFields = false;

    const discountsCurrent = this.reservationItemData && this.reservationItemData.discounts ? this.reservationItemData.discounts : null;
    const discountBuilder = new ReservationDiscountBuilder(discountType, discountsCurrent);


    let discounts: any = discountBuilder.buildDiscounts();


    if (!isEditBuild) {
      // this.reservationForm.controls['fixedPrice'].setValue(0);
    }
    if (!discountType || discountType && !discountType.length) {
      this.showFixedPrice = false;
      this.showValuePrice = false;
      const priceListPrice = this.reservationForm.controls['priceListPrice'].value;
      this.reservationForm.controls['value'].setValue(priceListPrice);


      setTimeout(() => {
        this.showFixedPrice = true;
        this.showValuePrice = true;
      }, 200)
    }
    this.discounts = discounts;
    setTimeout(() => {
      this.showDiscountFields = true;

    }, 200);
  }

  paymentDataChange($event): void {
    const dates: Array<DateFromToModel> = $event.dates;
    this.isPaymentValid = $event.isValid;

    if ($event.isValid) {
      this.paymentData = _.map(dates, (date: DateFromToModel) => {
        return {
          date_from: moment(date.monthLabel + '-01').format('YYYY-MM-DD'),
          date_to: moment(date.monthLabel + '-01').endOf('month').format('YYYY-MM-DD'),
          value: ReservationService.removeSpaces(`${date.value}`),
          reservation_id: this.reservationItemData && this.reservationItemData.id ? this.reservationItemData.id : null
        } as PaymentPartitionModel
      });
    }
  }

  calculateDiscount($event) {
    if ($event && $event.preventDefault) {
      $event.preventDefault();
    }
    let request: DiscountCalculateRequestModel = {
      basePrice: this.reservationForm.controls['priceListPrice'].value,
      discounts: []
    };

    if (parseFloat(this.reservationForm.controls['fixedPrice'].value)) {
      request.basePrice = this.removeSpaces(this.reservationForm.controls['fixedPrice'].value);
    }

    _.each(this.discounts, discount => {
      if (discount.type === '0' || discount.type === '2') {
        discount.percentValue = this.removeSpaces(discount.formControl.value);
        if (discount.percentValue) {
          request.discounts.push(new DiscountModel(discount));
        }
      } else {
        discount.amountValue = this.removeSpaces(discount.formControl.value);
        if (discount.amountValue) {
          request.discounts.push(new DiscountModel(discount));
        }
      }
    });

    this.showFixedPrice = false;
    this.showValuePrice = false;
    if (request.discounts && request.discounts.length) {
      this.discountService.calculateDiscounts(request).subscribe(data => {
        this.reservationForm.controls.value.setValue(data && data.basePrice && data.basePrice > 0 ? data.basePrice : 0);

        this.showFixedPrice = true;
        this.showValuePrice = true;

      }, err => {
        this.errorManager.displayError(err.error);
        this.showFixedPrice = true;
        this.showValuePrice = true;
      })
    } else {
      this.reservationForm.controls.value.setValue(this.reservationForm.controls['priceListPrice'].value);
      setTimeout(() => {
        this.showFixedPrice = true;
        this.showValuePrice = true;
      }, 200);
    }
  }

  private createForm(reservation?: ReservationCustom) {
    let dateFrom: Date;
    let dateTo: Date;

    if (reservation && reservation.dateFrom) {
      dateFrom = new Date(reservation.dateFrom);
    } else {
      dateFrom = this.dateFrom ? new Date(this.dateFrom) : new Date()
    }
    if (reservation && reservation.dateTo) {
      dateTo = new Date(reservation.dateTo);
    } else {
      dateTo = this.dateFrom ? new Date(this.dateFrom) : new Date()
    }

    this.reservationForm = this.formBuilder.group({
      name: [reservation ? reservation.name : '', Validators.required],
      description: [reservation ? reservation.description : ''],
      dateFrom: [dateFrom, Validators.required],
      dateTo: [dateTo, Validators.required],
      value: [reservation ? reservation.value : 0, Validators.required],
      publisher: [null, Validators.required],
      client: [null, Validators.required],
      principal: [null, Validators.required],
      trade: [null, Validators.required],
      brand: [null, Validators.required],
      salesman: [null, Validators.required],
      salesModel: [null, Validators.required],
      paymentType: [null, Validators.required],
      orderForm: [null, Validators.required],
      campaignType: [null, Validators.required],
      comment: [reservation ? reservation.comment : null, Validators.required],
      agencyFee: [reservation ? reservation.agencyFee : 0, Validators.required],
      technicalCosts: [reservation ? reservation.technicalCosts : 0, Validators.required],
      priceListPrice: [reservation ? reservation.priceListPrice : '0', Validators.required],
      fixedPrice: [reservation ? reservation.fixedPrice : '0', Validators.required],
      discounts: [''],
      priceList: [null, Validators.required]
    });

    if (reservation) {
      const currentItem: any = {};
      if (reservation.brand) {
        currentItem['brand'] = {
          id: reservation.brand.id,
          label: reservation.brand.name
        };

        this.reservationForm.controls['brand'].setValue(currentItem['brand']);
      }

      if (reservation.campaignType) {
        currentItem['campaignType'] = {
          id: reservation.campaignType.id,
          label: reservation.campaignType.name
        };

        this.reservationForm.controls['campaignType'].setValue(currentItem['campaignType']);
      }

      if (reservation.client) {
        currentItem['client'] = {
          id: reservation.client.id,
          label: reservation.client.name
        };

        this.reservationForm.controls['client'].setValue(currentItem['client']);
      }

      if (reservation.orderForm) {
        currentItem['orderForm'] = {
          id: reservation.orderForm.id,
          label: reservation.orderForm.name
        };

        this.reservationForm.controls['orderForm'].setValue(currentItem['orderForm']);
      }

      if (reservation.paymentType) {
        currentItem['paymentType'] = {
          id: reservation.paymentType.id,
          label: reservation.paymentType.name
        };

        this.reservationForm.controls['paymentType'].setValue(currentItem['paymentType']);
      }

      if (reservation.salesModel) {
        currentItem['salesModel'] = {
          id: reservation.salesModel.id,
          label: reservation.salesModel.name
        };

        this.reservationForm.controls['salesModel'].setValue(currentItem['salesModel']);
      }

      if (reservation.publishers && reservation.publishers.length) {
        currentItem['publisher'] = {
          id: reservation.publishers[0].publisherId,
          label: reservation.publishers[0].name
        };

        this.reservationForm.controls['publisher'].setValue(currentItem['publisher']);
      }

      if (reservation.principal) {
        currentItem['principal'] = {
          id: reservation.principal.id,
          label: reservation.principal.name
        };

        this.reservationForm.controls['principal'].setValue(currentItem['principal']);
      }

      if (reservation.salesman) {
        currentItem['salesman'] = {
          id: reservation.salesman.id,
          label: reservation.salesman.name
        };

        this.reservationForm.controls['salesman'].setValue(currentItem['salesman']);
      }

      if (reservation.trade) {
        currentItem['trade'] = {
          id: reservation.trade.id,
          label: reservation.trade.name
        };

        this.reservationForm.controls['trade'].setValue(currentItem['trade']);
        this.dataActions.getBrandList(this.brandRequest);
      }

      const request: any = {filters: {}};
      if (currentItem['orderForm'] && currentItem['orderForm'].id) {
        request.filters.orderForm = currentItem['orderForm'].id;
      }
      if (currentItem['publisher'] && currentItem['publisher'].id) {
        request.filters.publisher = currentItem['publisher'].id;
      }
      this.dataActions.getPriceList(request);
    }

    let firstRun = true;
    this.discountTypeControl.valueChanges.pipe(auditTime(700)).subscribe(discountType => {
      this.buildDiscounts(discountType, firstRun);
      if (!firstRun) {
        this.calculateDiscount(null);
      }
      firstRun = false;
    });

    this.reservationForm.controls.priceListPrice.disable();
    this.reservationForm.controls.value.disable();

    let isChangingSameFixedPriceValue = false;
    this.reservationForm.controls['fixedPrice'].valueChanges.subscribe(fieldValue => {
      if (fieldValue === '' && !isChangingSameFixedPriceValue) {
        this.showFixedPrice = false;
        setTimeout(() => {
          this.reservationForm.controls['fixedPrice'].setValue(0);
          this.showFixedPrice = true;
        });
        this.showValuePrice = false;
        this.reservationForm.controls['value'].setValue(this.reservationForm.controls['priceListPrice'].value);
        setTimeout(() => {
          this.showValuePrice = true;
        });
        isChangingSameFixedPriceValue = true;
      } else if (!isChangingSameFixedPriceValue) {
        this.showValuePrice = false;
        this.reservationForm.controls['value'].setValue(fieldValue);
        setTimeout(() => {
          this.showValuePrice = true;
        });
        isChangingSameFixedPriceValue = false;
      } else {
        isChangingSameFixedPriceValue = false;
      }
    });

    this.reservationForm.controls['trade'].valueChanges.subscribe(tradeFieldValue => {
      if (_.isObject(tradeFieldValue)) {
        this.reservationForm.controls['brand'].setValue(null);
        this.dataActions.getBrandList(this.brandRequest);
      }
    });

    this.watchChangesForBasePrice();
  }
}
