import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import * as _ from 'lodash';

import { AlertPopupComponent } from '../../../shared/popup/alert-popup/alert-popup.component';
import { IAlertPopup } from '../../../shared/popup/alert-popup/alert-popup.interface';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { Reservation } from '../model/reservation.model';
import { ReservationCreateComponent } from '../reservation-create/reservation-create.component';

@Component({
  selector: 'ep-reservation-edit-popup',
  templateUrl: './reservation-edit-popup.component.html',
  styleUrls: ['./reservation-edit-popup.component.scss']
})
export class ReservationEditPopupComponent {
  @ViewChild(ReservationCreateComponent) private reservationCreateComponent: ReservationCreateComponent;

  dateFrom: any = null;
  reservationItemData: Reservation;

  constructor(
    public dialogRef: MatDialogRef<ReservationEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toaster: ToasterService,
    private dialog: MatDialog) {
    if (this.data && this.data.reservationItemData) {
      this.reservationItemData = this.data.reservationItemData;
    }
  }

  public isReservationPaymentNotValid(): boolean {
    return this.reservationCreateComponent && this.reservationCreateComponent.isReservationPaymentNotValid();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onReservationSaved($event): void {
    let reservation: Reservation;

    if ($event && _.isArray($event) && $event.length > 1) {
      reservation = new Reservation($event[0]);

      this.showAlert($event[1].notice);
      this.toaster.displayMessage(`Rezerwacja została zapisana.`);
    } else {
      reservation = new Reservation($event);

      this.toaster.displayMessage('Rezerwacja została zapisana.');
    }

    this.dialogRef.close(reservation);
  }

  showAlert(message): void {
    let alertPopupData: IAlertPopup = {
      title: 'Uwaga!',
      message: `${message}`
    };

    let dialogRef = this.dialog.open(AlertPopupComponent, {
      width: '440px',
      data: alertPopupData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe();
  }

  update() {
    this.reservationCreateComponent.update();
  }
}
