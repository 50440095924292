import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import AutoNumeric from 'autonumeric/src/main';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { DateFromToModel } from '../../../shared/model/date-from-to.model';
import { PaymentPartitionModel } from '../../../shared/model/payment-partition.model';
import { generateDaysRange } from '../../../shared/utils/date-cool-helper';
import { ReservationService } from '../reservation.service';

@Component({
  selector: 'ep-reservation-payment',
  templateUrl: './reservation-payment.component.html',
  styleUrls: ['./reservation-payment.component.scss']
})
export class ReservationPaymentComponent {
  @Input() public dateFrom: Date;
  @Input() public dateTo: Date;
  @Input() public cost: string;
  @Input() public manualSplit: boolean;

  @Input()
  public set paymentPartition(paymentPartition: Array<PaymentPartitionModel>) {
    this.form = this.formBuilder.group(
      {
        month: this.formBuilder.array([])
      }
    );

    _.each(paymentPartition, (item: PaymentPartitionModel, i: number) => {
      const monthLabel: string = moment(item.date_from).format('YYYY-MM');
      const costPerDay: string = AutoNumeric.format(+ReservationService.removeSpaces(`${item.value}`), this.autoNumericOptionsEuro);

      this.form.controls['month']['controls'][i] = this.createFormGroupRow(monthLabel, costPerDay);
    });
    this.formDataChanged();
  };


  @Output() public changeData: EventEmitter<any>;

  public form: FormGroup;
  public manualSplitPaymentSizeControl: FormControl;
  public sumNotEqualCost: boolean;

  private autoNumericOptionsEuro: any;

  constructor(private formBuilder: FormBuilder) {
    this.autoNumericOptionsEuro = {
      modifyValueOnWheel: false,
      digitGroupSeparator: ' ',
      decimalCharacter: ',',
      decimalCharacterAlternative: '.',
      minimumValue: AutoNumeric.options.minimumValue.zero,
      maximumValue: '9999999999999.99',
      decimalPlaces: 2,
    };

    this.changeData = new EventEmitter<any>();
    this.manualSplitPaymentSizeControl = new FormControl();
  }

  public splitPayment(): void {
    const dateFrom: moment.Moment = moment(this.dateFrom);
    const dateTo: moment.Moment = moment(this.dateTo);

    generateDaysRange(dateFrom, dateTo).then((data: Array<DateFromToModel>) => {
      this.form = this.formBuilder.group(
        {
          month: this.formBuilder.array([])
        }
      );

      const costPerDay: string = AutoNumeric.format(+ReservationService.removeSpaces(this.cost) / data.length, this.autoNumericOptionsEuro);
      _.each(data, (item: DateFromToModel, i: number) => {
        this.form.controls['month']['controls'][i] = this.createFormGroupRow(item.monthLabel, costPerDay);
      });
      this.formDataChanged();
    });
  }

  public manualSplitPayment(): void {
    this.form = this.formBuilder.group(
      {
        month: this.formBuilder.array([])
      }
    );

    for (let i = 1; i <= this.manualSplitPaymentSizeControl.value; i++) {
      this.addRow();
    }
    this.formDataChanged();
  }

  public splitCosts() {
    if (this.manualSplit) {
      this.manualSplitPayment();
    } else {
      this.splitPayment();
    }
  }

  public addRow(): void {
    this.form.controls['month']['controls'].push(this.createFormGroupRow('', AutoNumeric.format(0, this.autoNumericOptionsEuro)));
    this.formDataChanged();
  }

  public deleteRow(index: number): void {
    this.form.controls['month']['controls'].splice(index, 1);
    this.formDataChanged();
  }

  public formDataChanged(): void {
    setTimeout(() => {
      let isValid = true;
      const dates: Array<DateFromToModel> = _.map(this.form.controls['month']['controls'], (control: FormGroup) => {
        if (control.controls['value'].invalid || control.controls['month'].invalid) {
          isValid = false;
        }
        return {monthLabel: control.controls['month'].value, value: control.controls['value'].value} as DateFromToModel;
      });

      const sum = _.sumBy(dates, (date: DateFromToModel) => +ReservationService.removeSpaces(`${date.value}`));
      this.sumNotEqualCost = (+ReservationService.removeSpaces(`${this.cost}`) !== sum);

      if (this.sumNotEqualCost) {
        isValid = false;
      }

      this.changeData.emit({dates, isValid})
    });
  }

  public isReservationPaymentNotValid(): boolean {
    return this.form && this.form.controls['month']['controls'].length && this.sumNotEqualCost;
  }

  private createFormGroupRow(month: string, value: string): FormGroup {
    return this.formBuilder.group({
      month: new FormControl(month, [Validators.required, Validators.pattern('\\d{4}-\\d{2}')]),
      value: new FormControl(value, [Validators.required])
    })
  }
}
