import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import * as _ from 'lodash';
import { PublisherStatisticLocalModel } from '../order-form/model/publisher-statistic-local.model';
import { PublisherStatisticPartialReservationModel } from '../order-form/model/publisher-statistic-partial-reservation.model';
import { PublisherStatisticPublisherModel } from '../order-form/model/publisher-statistic-publisher.model';
import { SelectTitlePopupComponent } from '../order-form/select-title-popup/select-title-popup.component';

@Component({
  selector: 'ep-order-publisher-statistics',
  templateUrl: './order-publisher-statistics.component.html',
  styleUrls: ['./order-publisher-statistics.component.scss']
})
export class OrderPublisherStatisticsComponent {
  @Input() public isCoordinatorView: boolean;
  @Input() set orderItemData(orderItemData: any) {
    this.orderItemDataLocal = _.merge({}, orderItemData);
    this.publisherStatistics = this.createPublisherStatistics(orderItemData.publisherStatistics);
  }

  @Output() private changeData: EventEmitter<Array<PublisherStatisticLocalModel>>;

  publisherStatisticsFormControl: any;
  publisherPartitionStatisticsFormControl: any;
  publisherStatisticsTitle: any = {};
  orderItemDataLocal: any;
  publisherStatistics: Array<PublisherStatisticLocalModel>;


  get orderItemData() {
    return this.orderItemDataLocal;
  }

  constructor(private dialog: MatDialog) {
    this.changeData = new EventEmitter<Array<PublisherStatisticLocalModel>>();
  }

  createPublisherStatistics(statistics): Array<PublisherStatisticLocalModel> {
    this.publisherStatistics = [];
    this.publisherStatisticsFormControl = {};
    this.publisherPartitionStatisticsFormControl = {
      externalId: null,
      formControlValue: null
    };
    const publisherStatistics: Array<PublisherStatisticLocalModel> = [];

    const currentStatistics = statistics || this.orderItemData.publisherStatistics;
    _.each(currentStatistics, (statData: any, reservationId) => {
      const reservationPublisherStatistics: PublisherStatisticLocalModel = {
        reservationName: statData.reservationName,
        reservationId: +reservationId,
        partialReservations: []
      };

      _.each(statData.partialReservations, (partialReservationsData: any, partialReservationMonth) => {
        const partialReservation: PublisherStatisticPartialReservationModel = {
          publishers: [],
          partialReservationMonth: partialReservationMonth,
          partialReservationId: partialReservationsData.partialReservationId
        };

        _.each(partialReservationsData.publishers, (publisherData: any, publisherName) => {
          const publisherStatistic: PublisherStatisticPublisherModel = {
            partition: publisherData.partition,
            publisherName: publisherName,
            editable: publisherData.editable,
            emissions: publisherData.emissions,
            exported: publisherData.exported,
            partial_value: publisherData.partial_value,
          };
          partialReservation.publishers.push(publisherStatistic);
        });

        reservationPublisherStatistics.partialReservations.push(partialReservation);
      });

      _.each(reservationPublisherStatistics.partialReservations, (partialReservation: PublisherStatisticPartialReservationModel) => {
        partialReservation.publishers = _.map(partialReservation.publishers, (publisher: PublisherStatisticPublisherModel, publisherKey) => {
          const formControlKey: string = partialReservation.partialReservationMonth
            + '|' + partialReservation.partialReservationId + '|' + publisher.publisherName;


          const partitions = [];
          _.each(publisher.partition, (partition, titleKey) => {
            partitions.push({
              name: titleKey,
              value: partition,
              id: titleKey
            });

            if (!this.publisherPartitionStatisticsFormControl[formControlKey + '|' + titleKey]) {
              this.publisherPartitionStatisticsFormControl[formControlKey + '|' + titleKey] = {
                externalId: null,
                formControlValue: null
              };
            }
            this.publisherPartitionStatisticsFormControl[formControlKey + '|' + titleKey].externalId = titleKey;
            this.publisherPartitionStatisticsFormControl[formControlKey + '|' + titleKey].formControlValue = new FormControl();
            this.publisherPartitionStatisticsFormControl[formControlKey + '|' + titleKey].formControlValue.setValue(partition);
          });

          // const publisherData: PublisherStatisticPublisherModel = {
          //   publisherName: publisher.publisherName;
          //   name: ,
          //   emissions: publisher.emissions,
          //   value: publisher.partial_value,
          //   partition: partitions,
          //   id: formControlKey
          // };

          publisher.partition = partitions;

          this.publisherStatisticsFormControl[formControlKey] = new FormControl();
          this.publisherStatisticsFormControl[formControlKey].setValue(publisher.partial_value);


          return publisher;
        });
        return partialReservation;
      });


      publisherStatistics.push(reservationPublisherStatistics);
    });
    return publisherStatistics;
  }

  removeSpaces(val: string): number {
    if (val && _.isString(val)) {
      const value = val.replace(',', '.').split(' ').join('');
      return +value;
    } else if (_.isNumber(val)) {
      return val;
    } else {
      return 0;
    }
  }

  onTitleSelect(title, reservationId: number, partialReservationId: string, publisherName: string): void {
    this.publisherStatistics = _.map(this.publisherStatistics, (publisherStatistic: PublisherStatisticLocalModel) => {
      if (publisherStatistic.reservationId === reservationId) {
        publisherStatistic.partialReservations =
          _.map(publisherStatistic.partialReservations, (partialReservation: PublisherStatisticPartialReservationModel) => {
            if (partialReservation.partialReservationId === partialReservationId) {
              partialReservation.publishers = _.map(partialReservation.publishers, (partialPublisher: PublisherStatisticPublisherModel) => {
                if (partialPublisher.publisherName === publisherName) {
                  partialPublisher.partition.push({
                    name: title.externalId,
                    value: 0,
                    id: title.externalId
                  });
                  const formControlKey: string = partialReservation.partialReservationMonth
                    + '|' + partialReservation.partialReservationId + '|' + partialPublisher.publisherName;

                  if (!this.publisherPartitionStatisticsFormControl[formControlKey + '|' + title.externalId]) {
                    this.publisherPartitionStatisticsFormControl[formControlKey + '|' + title.externalId] = {
                      externalId: null,
                      formControlValue: null
                    };
                  }
                  this.publisherPartitionStatisticsFormControl[formControlKey + '|' + title.externalId].externalId = title.externalId;
                  this.publisherPartitionStatisticsFormControl[formControlKey + '|' + title.externalId].formControlValue = new FormControl();
                  this.publisherPartitionStatisticsFormControl[formControlKey + '|' + title.externalId].formControlValue.setValue(0);
                }

                return partialPublisher;
              });
            }
            return partialReservation;
          });
      }
      return publisherStatistic;
    });
  }

  public buildPublisherStatisticsBasedOnControls(): void {
    this.publisherStatistics = _.map(this.publisherStatistics, (publisherStatistic: PublisherStatisticLocalModel) => {
        publisherStatistic.partialReservations =
          _.map(publisherStatistic.partialReservations, (partialReservation: PublisherStatisticPartialReservationModel) => {
            partialReservation.publishers = _.map(partialReservation.publishers, (partialPublisher: PublisherStatisticPublisherModel) => {
              const formControlKey: string = partialReservation.partialReservationMonth
                + '|' + partialReservation.partialReservationId + '|' + partialPublisher.publisherName;

              partialPublisher.partition = _.map(partialPublisher.partition, (partition: any) => {
                 partition.value = this.removeSpaces(`${this.publisherPartitionStatisticsFormControl[formControlKey + '|' + partition.id].formControlValue.value}`);
                 return partition;
              });

              partialPublisher.partial_value = this.removeSpaces(`${this.publisherStatisticsFormControl[formControlKey].value}`);
              return partialPublisher;
            });

            return partialReservation;
          });
      return publisherStatistic;
    });
  }

  getCurrentPublisherStatistics() {
    this.buildPublisherStatisticsBasedOnControls();
    return this.publisherStatistics;
  }

  openSelectTitlePopup(reservationId: any, publisherStatistics: any, partialReservationId, publisherName: string) {
    const data = {
      reservationId,
      publisherStatisticsTitles: this.publisherStatisticsTitle,
      publisherStatistics,
      partialReservationId
    };

    const dialogRef = this.dialog.open(SelectTitlePopupComponent, {
      width: '540px',
      data: Object.assign({}, data),
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        _.each(result.selectedTitles, selectedTitle => {
          this.onTitleSelect(selectedTitle, reservationId, partialReservationId, publisherName)
        });

        this.changeData.emit(this.publisherStatistics);
      }
    });
  }

  public deletePartition(pubStatIndex, partialIndex, publisherIndex, partitionIndex): void {
    this.publisherStatistics[pubStatIndex].partialReservations[partialIndex].publishers[publisherIndex].partition.splice(partitionIndex, 1);

    this.updatePublisherStatisticsValidState();
  }

  public canNotEditReservation(publisher): boolean {
    return !this.orderItemData.editablePartial;
  }

  public publisherFormControlValueChanged(partialReservation: PublisherStatisticPartialReservationModel, publisher: PublisherStatisticPublisherModel): void {
    _.forEach(publisher.partition, (partition: {id: string, value: number }) => {
      const partitionFormKey: string = partialReservation.partialReservationMonth + '|' + partialReservation.partialReservationId + '|' + publisher.publisherName + '|' + partition.id;

      (this.publisherPartitionStatisticsFormControl[partitionFormKey].formControlValue as FormControl).setErrors({'value': null});
      (this.publisherPartitionStatisticsFormControl[partitionFormKey].formControlValue as FormControl).updateValueAndValidity();
    });
  }

  public isNotPublisherStatisticsValid(): boolean {
    return this.updatePublisherStatisticsValidState();
  }

  private setPublisherValidState(partialReservation: PublisherStatisticPartialReservationModel, publisher: PublisherStatisticPublisherModel, isValid: boolean): void {
    _.forEach(publisher.partition, (partition: {id: string, value: number }) => {
      const partitionFormKey: string = partialReservation.partialReservationMonth + '|' + partialReservation.partialReservationId + '|' + publisher.publisherName + '|' + partition.id;
      const formControl: FormControl = (this.publisherPartitionStatisticsFormControl[partitionFormKey].formControlValue as FormControl);

      if (isValid) {
        formControl.setErrors({'value': null});
        formControl.markAsUntouched();
      } else {
        formControl.setErrors({'value': true});
        formControl.markAsTouched();
      }
    });
  }

  private updatePublisherStatisticsValidState(): boolean {
    let isNotValid: boolean = false;
    const publisherStatistics: Array<PublisherStatisticLocalModel> = this.getCurrentPublisherStatistics();

    _.forEach(publisherStatistics, (publisherStatistic: PublisherStatisticLocalModel) => {
      _.forEach(publisherStatistic.partialReservations, (partialReservation: PublisherStatisticPartialReservationModel) => {
        _.forEach(partialReservation.publishers, (publisher: PublisherStatisticPublisherModel) => {
          const partialValue: number = parseFloat(publisher.partial_value.toString());
          let partitionsSummaryValue: number = 0;

          _.forEach(publisher.partition, (partition: {id: string, value: number }) => {
            partitionsSummaryValue += parseFloat(partition.value.toString());
          });

          if (partitionsSummaryValue > partialValue) {
            isNotValid = true;

            this.setPublisherValidState(partialReservation, publisher, false);
          } else {
            this.setPublisherValidState(partialReservation, publisher, true);
          }
        });
      });
    });

    return isNotValid;
  }
}
