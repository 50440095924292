<div class="user-button-container" #userButton (epClickOutside)="onClickOutside()"
     title="Zalogowany jako {{ user.username }}">
  <button class="user-button" mat-button (click)="toggleDropdown()" [class.open]="isOpen">
    <span class="user-button-container-data">
      <img class="avatar" src="assets/img/avatars/noavatar.png">
      <span class="name">{{ user.username }}</span>
      <mat-icon class="icon">keyboard_arrow_down</mat-icon>
    </span>
  </button>

  <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
    <div class="content">
      <div class="list">
        <div class="item" matRipple [routerLink]="['/account']">
          <mat-icon class="icon">settings</mat-icon>
          <span class="title">Ustawienia</span>
        </div>
        <mat-divider></mat-divider>
        <div class="item" matRipple (click)="logout()">
          <mat-icon class="icon">exit_to_app</mat-icon>
          <span class="title">Wyloguj</span>
        </div>
      </div>
    </div>
  </div>
</div>
