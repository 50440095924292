import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { objectToParams } from '../../shared/utils/object-to-params';
import { BrandDictionaryModel } from './model/brand-dictionary.model';
import { CampaignTypeDictionaryModel } from './model/campaign-type-dictionary.model';
import { CreationDictionaryModel } from './model/creation-dictionary.model';
import { DictionaryRequestModel } from './model/dictionary-request.model';
import { FormatDictionaryModel } from './model/format-dictionary.model';
import { ManagerDictionaryModel } from './model/manager-dictionary.model';
import { OrderFormDictionaryModel } from './model/order-form-dictionary.model';
import { PaymentTypeDictionaryModel } from './model/payment-type-dictionary.model';
import { PrincipalDictionaryModel } from './model/principal-dictionary.model';
import { PublisherGroupDictionaryModel } from './model/publisher-group-dictionary.model';
import { SalesModelDictionaryModel } from './model/sales-model-dictionary.model';
import { SalesmanDictionaryWrapperModel } from './model/salesman-dictionary-wrapper.model';
import { TitleDictionaryModel } from './model/title-dictionary.model';
import { TradeDictionaryModel } from './model/trade-dictionary.model';
import {TitleEditionDictionaryModel} from './model/title-edition-dictionary.model';

@Injectable()
export class DictionaryService {

  public onOrderCreateOrEdit$: EventEmitter<any>;

  constructor(private http: HttpClient) {
    this.onOrderCreateOrEdit$ = new EventEmitter();
  }

  public getBrandsByTrade(trade: number, request?: DictionaryRequestModel): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/brand/${trade}?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'] = response['data'].map(res => new BrandDictionaryModel(res));
          return response['data'];
        }
        return null;
      }));
  }

  public getCampaignTypes(request?: DictionaryRequestModel): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/campaignType?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'] = response['data'].map(res => new CampaignTypeDictionaryModel(res));
          return response['data'];
        }
        return null;
      }));
  }

  public getCreations(request?: DictionaryRequestModel): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/creation?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'] = response['data'].map(res => new CreationDictionaryModel(res));
          return response['data'];
        }
        return null;
      }));
  }

  public getFormats(request?: DictionaryRequestModel): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/format?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'] = response['data'].map(res => new FormatDictionaryModel(res));
          return response['data'];
        }
        return null;
      }));
  }

  public getOrderForms(request: DictionaryRequestModel, type: string): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/orderForm/${type}?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'] = response['data'] = new OrderFormDictionaryModel(response['data']);
          return response['data'];
        }
        return null;
      }));
  }

  public getPaymentTypes(request?: DictionaryRequestModel): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/paymentType?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'] = response['data'].map(res => new PaymentTypeDictionaryModel(res));
          return response['data'];
        }
        return null;
      }));
  }

  public getPriceListNonStandard(request?: DictionaryRequestModel): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/price-list/nonstandard?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'] = response['data'].map(res => new PrincipalDictionaryModel(res));
          return response['data'];
        }
        return null;
      }));
  }

  public getPrincipals(request?: DictionaryRequestModel): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/principal?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'] = response['data'].map(res => new PrincipalDictionaryModel(res));
          return response['data'];
        }
        return null;
      }));
  }

  public getPublisherGroups(request?: DictionaryRequestModel): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/publisherGroup?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'] = response['data'].map(res => new PublisherGroupDictionaryModel(res));
          return response['data'];
        }
        return null;
      }));
  }

  public getSalesmans(request?: DictionaryRequestModel): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/salesman?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'] = new SalesmanDictionaryWrapperModel(response['data']);
          return response['data'];
        }
        return null;
      }));
  }

  public getSalesModels(request?: DictionaryRequestModel): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/salesModel?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'] = response['data'].map(res => new SalesModelDictionaryModel(res));
          return response['data'];
        }
        return null;
      }));
  }

  public getTitles(request?: DictionaryRequestModel): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/title?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: any) => {
        if (response && response['data']) {
          response['data'] = response['data'].map(res => new TitleDictionaryModel(res));
          return response['data'];
        }
        return null;
      }));
  }

  public getTitleEdition(titleId: number, request?: DictionaryRequestModel): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/title-edition/${titleId}?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'] = response['data'].map(res => new TitleEditionDictionaryModel(res));
          return response['data'];
        }
        return null;
      }));
  }

  public getTitleGroup(request?: DictionaryRequestModel): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/titleGroup?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          return response['data'];
        }
        return null;
      }));
  }

  public getTrades(request?: DictionaryRequestModel): any {
    const params = objectToParams(request, 'yyyy-MM-dd');
    const apiUrl = `${environment.apiUrl}/dictionaries/trade?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'] = response['data'].map(res => new TradeDictionaryModel(res));
          return response['data'];
        }
        return null;
      }));
  }

  public getManagers(request?: DictionaryRequestModel): any {
    return this.http.get(`${environment.apiUrl}/dictionaries/manager`).pipe(
      map((response: Response) => {
        if (response && response['data']) {
          response['data'] = response['data'].map(res => new ManagerDictionaryModel(res));
          return response['data'];
        }
        return null;
      }));
  }
}
