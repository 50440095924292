import { Injectable, Optional } from '@angular/core';

@Injectable()
export class SalesmanDictionaryModel {
  id: number;
  name: string;
  postalCode: string;
  street: string;
  city: string;
  externalId: string;

  constructor(@Optional() obj?: any) {
    this.id = obj && obj.id || null;
    this.name = obj && obj.name || null;
    this.postalCode = obj && obj.postalCode || null;
    this.street = obj && obj.street || null;
    this.city = obj && obj.city || null;
    this.externalId = obj && obj.externalId || null;
  }
}
