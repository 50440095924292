import { Component, Inject, Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { DictionaryService } from '../../../dictionary/dictionary.service';
import { DictionaryRequestModel } from '../../../dictionary/model/dictionary-request.model';
import { TitleDictionaryModel } from '../../../dictionary/model/title-dictionary.model';


@Pipe({
  name: 'filterTitles',
  pure: false
})
export class FilterTitlesPipe implements PipeTransform {
  transform(array: Array<TitleDictionaryModel>, args: string): Array<string> | Array<{}> {
    array = array || [];

    if (typeof args === 'undefined' || args === '' || !args) {
      return array;
    }

    return array.filter(item => {
      let titleNameLower = item.name.toLowerCase();
      let match = new RegExp(args.toLowerCase(), 'gi');
      return titleNameLower.match(match);
    });
  }
}

@Component({
  selector: 'ep-select-placement-popup',
  templateUrl: './select-title-popup.component.html',
  styleUrls: ['./select-title-popup.component.scss']
})
export class SelectTitlePopupComponent {
  titleListRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null, kind: 'digital'}});
  isLoading: boolean;
  titlesData: Array<any>;
  selectedTitles: Array<any>;
  searchFormControl: FormControl = new FormControl();
  titleQuery: string = null;

  constructor(public dialogRef: MatDialogRef<SelectTitlePopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dictionaryService: DictionaryService) {

    this.getTitles();
    this.searchFormControl.valueChanges.pipe(debounceTime(300)).subscribe(searchString => {
      this.titleQuery = searchString;
    })
  }

  getTitles() {
    this.isLoading = true;
    this.dictionaryService.getTitles(this.titleListRequest)
      .subscribe(titles => {
        this.isLoading = false;
        this.titlesData = titles;
        _.each(this.data.publisherStatistics.partition, partition => {
          this.titlesData = _.map(this.titlesData, item => {
            if (item.externalId === partition.name) {
              item.selected = true;
            }
            return item;
          });
        });
      }, () => {
        this.isLoading = false;
      });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  closeWithSelected() {
    this.dialogRef.close({
      selectedTitles: _.filter(this.titlesData, (titleData: any) => {
        return titleData.selected;
      })
    });
  }
}
