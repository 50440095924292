import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs/Rx';
import { ConfirmPopupComponent } from '../../../shared/popup/confirm-popup/confirm-popup.component';
import { IConfirmPopup } from '../../../shared/popup/confirm-popup/confirm-popup.interface';
import { ApiErrorManagerService } from '../../../shared/utils/api-error-manager.service';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { ProfileService } from '../../profile/profile.service';
import { OrderDataPage } from '../model/order-data-page';
import { OrderDataPageFilters } from '../model/order-data-page-filters';
import { IOrderDataPageRequest, OrderDataPageRequest } from '../model/order-data-page-request';
import { OrderEditPopupComponent } from '../order-edit-popup/order-edit-popup.component';
import { OrderService } from '../order.service';

@Component({
  selector: 'ep-order-list-print',
  templateUrl: './order-list-print.component.html',
  styleUrls: ['./order-list-print.component.scss']
})
export class OrderListPrintComponent implements OnInit, OnDestroy {
  loadingIndicator: boolean = true;
  orderData: OrderDataPage = new OrderDataPage();
  dataPageRequest: OrderDataPageRequest = new OrderDataPageRequest({
    filters: new OrderDataPageFilters({
      platform: 'print'
    })
  });
  currentOffset: number = 0;
  filterForm: FormGroup;
  statuses: object[] = [];
  statusesObject: any;
  isUserMerchant: boolean;

  @Input() type?: string;

  private filterFormSubscription: Subscription;

  get managerMode(): boolean {
    return this.route.snapshot.data['managerMode']
      && this.profileService
      && this.profileService.currentUser
      && this.profileService.currentUser.type === 'manager';
  }

  get coordinatorMode(): boolean {
    return this.profileService
      && this.profileService.currentUser
      && this.profileService.currentUser.type === 'coordinator';
  }

  get isActionRequired(): boolean {
    return this.route && this.route.snapshot.data['isActionRequired'];
  }

  constructor(private orderService: OrderService,
              private profileService: ProfileService,
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private http: HttpClient,
              private errorManager: ApiErrorManagerService,
              private toaster: ToasterService,
              private router: Router,
              private route: ActivatedRoute) {
    this.createFilterForm();
  }

  onRowClick($event) {
    if ($event.type === 'click' && $event.column.name !== 'actions') {
      this.openOrderEditPopup($event.row);
    }
  }

  ngOnInit() {
    if (!this.type) {
      if (this.coordinatorMode) {
        this.type = 'coordinatorList';
      } else if (this.managerMode) {
        this.type = 'managerList';
      } else {
        this.type = 'standardList';
      }
    }

    this.setDataPageRequest();
    this.getStatusList();
    this.getList();

    this.orderService.onOrderCreateOrEdit$.subscribe(() => {
      this.getList();
    });

    this.initUserType();
  }

  public ngOnDestroy(): void {
    this.filterFormSubscription.unsubscribe();
  }

  getList() {
    this.loadingIndicator = true;
    this.orderService.getList(this.getDataPageRequest(), this.isActionRequired).subscribe((result: OrderDataPage) => {
      this.orderData = result;
      this.orderData.items = _.map(this.orderData.items, order => {
        order.titles = _.map(order.reservations, reservation => {
          if (reservation.title) {
            return reservation.title.name
          }
        });
        order.titles = _.compact(_.uniq(order.titles));
        return order;
      });
      this.loadingIndicator = false;
    });
  }

  getStatusList() {
    this.orderService.getStatuses(null, this.isActionRequired).subscribe((response: Response) => {
      if (response && response['data']) {
        this.statusesObject = response['data'];
        _.each(response['data'].statuses, (val, key) => {
          if (val) {
            this.statuses.push({
              value: key,
              label: val
            });
          }
        });

        if (this.managerMode) {
          this.filterForm.controls['status'].setValue('not_accepted');
        }
      }
    });
  }

  onListTypeChange($event) {
    if ($event.index === 0) {
      if (this.coordinatorMode && this.isActionRequired) {
        this.router.navigate(['/orders-coordinator']);
      } else if (this.managerMode) {
        this.router.navigate(['/orders-manager']);
      } else {
        this.router.navigate(['/orders']);
      }
    }
  }

  private createFilterForm() {
    this.filterForm = this.formBuilder.group({
      identification: [''],
      client: [''],
      creator: [''],
      name: [''],
      owner: [''],
      placementCategory: [''],
      dateFrom: [null],
      dateTo: [null],
      editionNumber: [''],
      editionYear: [''],
      status: [''],
      salesman: [''],
      title: ['']
    });

    this.filterFormSubscription = this.filterForm
      .valueChanges
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.updateFilter(null);
      });
  }

  setDataPageRequest() {
    this.currentOffset = 0;
    this.dataPageRequest = new OrderDataPageRequest({
      filters: new OrderDataPageFilters({
        platform: 'print'
      })
    });

    if (this.managerMode) {
      this.dataPageRequest.filters.status = 'not_accepted';
    }
  }

  getDataPageRequest(): IOrderDataPageRequest {
    return this.dataPageRequest;
  }

  setPage($event) {
    this.dataPageRequest.setPageByOffset($event.offset);
    this.dataPageRequest.setLimit($event.limit);
    this.currentOffset = $event.offset;
    this.getList();
  }

  onSort($event) {
  }

  updateFilter($event, type?: any) {
    const filterData = {
      identification: this.filterForm.controls['identification'].value,
      client: this.filterForm.controls['client'].value,
      creator: this.filterForm.controls['creator'].value,
      name: this.filterForm.controls['name'].value,
      owner: this.filterForm.controls['owner'].value,
      editionNumber: this.filterForm.controls['editionNumber'].value,
      editionYear: this.filterForm.controls['editionYear'].value,
      status: this.filterForm.controls['status'].value,
      title: this.filterForm.controls['title'].value,
      salesman: this.filterForm.controls['salesman'].value,
      platform: 'print'
    };
    this.dataPageRequest.filters = new OrderDataPageFilters(filterData);
    this.dataPageRequest.setPage(1);
    this.getList();
  }

  openOrderEditPopup($event): void {
    const isEditable: boolean = !this.isUserMerchant || (this.isUserMerchant && $event.editable);

    const dialogRef = this.dialog.open(OrderEditPopupComponent, {
      width: '100%',
      height: '100%',
      maxWidth: null,
      data: {
        order: $event,
        isCoordinatorView: this.type === 'coordinatorList',
        isManagerView: this.managerMode,
        isEditable: isEditable
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getList();
      }
    });
  }

  printPdf($event): void {
    this.orderService.getPdfUrl($event.id).subscribe((response: Response) => {
      this.http.get(response['data'].url, {
        responseType: 'blob'
      }).subscribe((responseF) => {
        const file = responseF;
        saveAs(file, 'doc.pdf');
      });
    });
  }

  deleteOrder($event): void {
    const confirmPopupData: IConfirmPopup = {
      title: 'Usuwanie',
      message: null
    };
    confirmPopupData.message = `Czy chcesz usunąć zlecenie?`;

    if ($event.name) {
      confirmPopupData.message = `Czy chcesz usunąć zlecenie "${$event.name}"?`;
    }

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '440px',
      data: confirmPopupData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.deleteOrder($event.id).subscribe(() => {
          this.toaster.displayMessage('Zlecenie zostało usunięte');
          this.getList();
        }, err => {
          this.errorManager.displayError(err.error);
        });
      }
    });
  }

  acceptOrder($event): void {
    const confirmPopupData: IConfirmPopup = {
      title: 'Akceptacja',
      message: null
    };
    confirmPopupData.message = `Czy chcesz zaakceptować zlecenie?`;

    if ($event.name) {
      confirmPopupData.message = `Czy chcesz zaakceptować zlecenie "${$event.name}"?`;
    }

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '440px',
      data: confirmPopupData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.acceptOrder($event.id).subscribe(() => {
          this.toaster.displayMessage('Zlecenie zostało zaakceptowane');
          this.getList();
        }, err => {
          this.errorManager.displayError(err.error);
        });
      }
    });
  }

  rejectOrder($event): void {
    const confirmPopupData: IConfirmPopup = {
      title: 'Odrzucenie',
      message: null
    };
    confirmPopupData.message = `Czy chcesz odrzucić zlecenie?`;

    if ($event.name) {
      confirmPopupData.message = `Czy chcesz odrzucić zlecenie "${$event.name}"?`;
    }

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '440px',
      data: confirmPopupData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.rejectOrder($event.id).subscribe(() => {
          this.toaster.displayMessage('Zlecenie zostało odrzucone');
          this.getList();
        }, err => {
          this.errorManager.displayError(err.error);
        });
      }
    });
  }

  private initUserType(): void {
    this.isUserMerchant = (this.profileService.currentUser.type === 'merchant');
  }
}

