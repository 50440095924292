<mat-radio-group (change)="onSelectTypeChange($event)" *ngIf="!orderItemData">
  <p>
    <mat-radio-button value="{{ formType.clone }}">Kopia</mat-radio-button>
  </p>
  <p>
    <mat-radio-button value="{{ formType.new }}">Nowe Digital lub Cross</mat-radio-button>
  </p>
  <!--<p>-->
  <!--<mat-radio-button value="{{ formType.unstd }}">Nowe niestandardowe</mat-radio-button>-->
  <!--</p>-->
  <p>
    <mat-radio-button value="{{ formType.print }}">Nowe Print</mat-radio-button>
  </p>
</mat-radio-group>
<form [formGroup]="orderCloneForm" *ngIf="formTypeSelected === formType.clone && orderData && orderData.items">
  <mat-form-field class="full-width">
    <input type="text"
           placeholder="Wybierz zlecenie"
           aria-label="Wybierz zlecenie"
           matInput
           [formControl]="orderCloneForm.controls['order']"
           [matAutocomplete]="auto"
           (input)="onChangeOrderAutoComplete($event)">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOrderName" (optionSelected)="onOrderSelect($event)">
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading['order']"></mat-progress-bar>
      <mat-option class="error" *ngIf="hintText['order'] !== null">{{ hintText['order'] }}</mat-option>
      <mat-option *ngFor="let order of orderData.items" [value]="order">
        {{ order.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
<form [formGroup]="orderForm"
      *ngIf="orderForm && formTypeSelected === formType.new || formTypeSelected === formType.cloned || formTypeSelected === formType.print">

  <div class="two-column">
    <div class="left-side">
      <mat-form-field class="full-width">
        <input
          placeholder="Nazwa kampanii"
          aria-label="Nazwa kampanii"
          matInput
          [formControl]="orderForm.controls['name']">
        <mat-error>
          <div *ngIf="orderForm.controls['name'].hasError('required')">Pole wymagane</div>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width" *ngIf="clientData">
        <input type="text"
               placeholder="Klient"
               aria-label="Klient"
               matInput
               [formControl]="orderForm.controls['client']"
               [matAutocomplete]="auto2"
               (input)="onChangeClientAutoComplete($event)">
        <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayClientName"
                          (optionSelected)="onClientSelect($event)">
          <mat-progress-bar mode="indeterminate" *ngIf="isLoading['client']"></mat-progress-bar>
          <mat-option class="error" *ngIf="hintText['client'] !== null">{{ hintText['client'] }}</mat-option>
          <mat-option *ngFor="let client of clientData" [value]="client">
            {{ client.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error>
          <div *ngIf="orderForm.controls['client'].hasError('required')">Pole wymagane</div>
        </mat-error>
      </mat-form-field>
      <div *ngIf="selectedClientId" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-form-field class="full-width">
          <input
            placeholder="Osoba Zlecająca"
            aria-label="Osoba Zlecająca"
            matInput
            [formControl]="orderForm.controls['principalPerson']">
          <mat-error>
            <div *ngIf="orderForm.controls['principalPerson'].hasError('required')">Pole wymagane</div>
          </mat-error>
        </mat-form-field>

        <ep-autocomplete-field
          [(selected)]="orderForm.controls['salesman']"
          [options]="salesmanOptions"
          (onChangeSearchText)="eventListeners.onChangeSalesmanSearchText($event)"
          [showNoResultButton]="false"
          [optionsStatus]="salesmanOptionsStatus"
          placeholder="Handlowiec"></ep-autocomplete-field>

        <ep-autocomplete-field
          [(selected)]="orderForm.controls['principal']"
          [options]="principalOptions"
          (onChangeSearchText)="eventListeners.onChangePrincipalSearchText($event)"
          [showNoResultButton]="false"
          [optionsStatus]="principalOptionsStatus"
          placeholder="Zleceniodawca"></ep-autocomplete-field>

        <ep-autocomplete-multiselect-field
          *ngIf="orderForm.controls['kind'].value"
          [(selectedControl)]="orderForm.controls['reservation']"
          [options]="reservationOptions"
          (onChangeSearchText)="eventListeners.onChangeReservationSearchText($event)"
          [optionsStatus]="reservationOptionsStatus"
          (onSelected)="eventListeners.onSelectReservations($event)"
          placeholder="Rezerwacja"></ep-autocomplete-multiselect-field>
        <mat-error>
          <div *ngIf="!orderForm.controls['reservation'].value && submittedForm['orderForm']">Pole wymagane</div>
        </mat-error>

        <div *ngIf="formTypeSelected !== formType.print && orderItemData && orderItemData.platform !== 'print'">
          <mat-form-field>
            <input matInput [matDatepicker]="dateFrom" placeholder="Data od"
                   [formControl]="orderForm.controls['dateFrom']">
            <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
            <mat-datepicker #dateFrom></mat-datepicker>
            <mat-error>
              <div *ngIf="orderForm.controls['dateFrom'].hasError('required')">Pole wymagane</div>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput [matDatepicker]="dateTo" [min]="orderForm.controls['dateFrom'].value" placeholder="Data do"
                   [formControl]="orderForm.controls['dateTo']">
            <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
            <mat-datepicker #dateTo></mat-datepicker>
            <mat-error>
              <div *ngIf="orderForm.controls['dateTo'].hasError('required')">Pole wymagane</div>
              <div *ngIf="orderForm.controls['dateTo'].hasError('matDatepickerMin')">Data do jest wcześniejsza niż data
                od
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width">
          <textarea matInput matTextareaAutosize rows="4" style="height: 100px"
                    placeholder="Komentarz (do umieszczenia na zleceniu)"
                    [formControl]="orderForm.controls['comment']"></textarea>
        </mat-form-field>

        <mat-form-field class="full-width"
                        *ngIf="statusOptionsKeys.length && orderItemData.status !== 'draft'">
          <mat-select
            placeholder="Status"
            [formControl]="orderForm.controls['status']">
            <mat-option *ngFor="let key of statusOptionsKeys" [value]="key">
              {{ statusOptions[key] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf="isMerchantView || isCoordinatorView || isAdminView">
          <div>
            <div class="margin-bottom-md">
              <strong (click)="selectedTab = 2">Wartość zlecenia:&nbsp;</strong>
              <span *ngIf="orderItemData?.value">{{ orderItemData?.value  | amountConverter: 'pl' }} PLN</span>
              <span *ngIf="!orderItemData?.value">0,00 PLN</span>
            </div>
          </div>

          <div class="payment-schedule">
            <div>
              <strong>Harmonogram płatności wg. miesięcy</strong>
            </div>
            <p *ngFor="let paymentSchedule of orderItemData?.paymentSchedule | keyvalue">
              {{ paymentSchedule.key }}: <strong>{{ paymentSchedule.value  | amountConverter: 'pl' }} PLN</strong>
            </p>
          </div>
        </ng-container>

        <div *ngIf="isCoordinatorView" class="button-split-value">
          <button mat-button
                  class="mat-primary mat-flat-button"
                  (click)="openPublisherStatisticsPopup()">
            Podziel wartość zlecenia
          </button>

          <!--<div class="margin-bottom-md" *ngFor="let pubStat of publisherStatistics; let i = index">-->
          <!--<div class="name">Rezerwacja {{ pubStat.name }}</div>-->
          <!--<div class="month" *ngFor="let pubStatMonth of pubStat.partialReservations">-->
          <!--<div>{{ pubStatMonth.partialReservationMonth }}</div>-->
          <!--<div class="publisher-statistic margin-bottom-md"-->
          <!--*ngFor="let publisherStatistics of pubStatMonth.publishers; let j = index">-->
          <!--<div class="mat-body-2" *ngIf="publisherStatistics.name != '0'">{{ publisherStatistics.name }}</div>-->
          <!--<div>-->
          <!--<small class="mat-caption"><strong>emisje: </strong>{{ publisherStatistics.emissions }}</small>-->
          <!--</div>-->
          <!--<div>-->
          <!--<strong>wartość:</strong> {{publisherStatisticsFormControl[pubStat.reservationId][publisherStatistics.id].value  | amountConverter: 'pl'}}-->
          <!--</div>-->

          <!--<div class="margin-vertical-lg">-->
          <!--<button mat-button mat-raised-button-->
          <!--(click)="openSelectTitlePopup(pubStat.reservationId, publisherStatistics.id, publisherStatistics, j)"-->
          <!--[disabled]="orderItemData.status !== 'issued'">Wybierz tytuły-->
          <!--</button>-->
          <!--</div>-->

          <!--<div class="publisher-statistic margin-top-lg margin-bottom-md"-->
          <!--*ngFor="let partition of publisherStatistics.partition">-->
          <!--<div class="mat-body-2">{{ partition.name }}</div>-->
          <!--<mat-form-field class="full-width">-->
          <!--<input matInput-->
          <!--pattern="^([,. \d]+)([,.]\d{2})?$"-->
          <!--[epAutoNumeric]="2"-->
          <!--placeholder="Wpisz wartość"-->
          <!--[readonly]="orderItemData.status !== 'issued'"-->
          <!--[formControl]="publisherPartitionStatisticsFormControl[pubStat.reservationId][publisherStatistics.id][partition.id]?.formControlValue">-->
          <!--<mat-error>-->
          <!--<div-->
          <!--*ngIf="publisherPartitionStatisticsFormControl[pubStat.reservationId][publisherStatistics.id][partition.id]?.formControlValue.hasError('pattern')">-->
          <!--Podaj liczbę np. 100 lub 0.40-->
          <!--</div>-->
          <!--</mat-error>-->
          <!--</mat-form-field>-->
          <!--</div>-->
          <!--</div>-->
          <!--</div>-->
          <!--</div>-->
        </div>
        <mat-slide-toggle [formControl]="orderForm.controls['pdfPriceVisible']">Ceny widoczne w PDF</mat-slide-toggle>
        <mat-slide-toggle [formControl]="orderForm.controls['pdfDiscountVisible']">Rabaty widoczne w PDF
        </mat-slide-toggle>

        <mat-slide-toggle [formControl]="orderForm.controls['pdfQuantityVisible']">Tylko sumy emisji widoczne w PDF
        </mat-slide-toggle>


        <div class="margin-vertical-lg">
          <div class="margin-bottom-lg">Zlecenie</div>
          <mat-chip-list *ngIf="orderForm.controls['orderDocument'].value">
            <mat-chip>
              {{ orderForm.controls['orderDocument'].value.originalName }}
              <i class="material-icons"
                 (click)="actions.deleteFile(orderForm.controls['orderDocument'].value, 'orderDocument')"
                 *ngIf="!isManagerView">close</i>
            </mat-chip>
          </mat-chip-list>
          <mat-error *ngIf="orderForm.controls['orderDocument'].hasError('required')">Należy dodać plik</mat-error>
          <ep-file-upload (onEncloseFile)="eventListeners.onEncloseMainFile($event)"
                          [buttonLabel]="'Wgraj zlecenie'"
                          *ngIf="!orderForm.controls['orderDocument'].value && !isManagerView"></ep-file-upload>
        </div>

        <div>
          <div class="margin-vertical-lg"
               *ngIf="orderForm.controls['additionalDocuments'].value && orderForm.controls['additionalDocuments'].value.length > 0">
            <div class="margin-bottom-md">Pliki kreacji</div>
            <div class="chip-list" fxLayout="column" fxLayoutAlign="start start">
              <div class="mat-chip" *ngFor="let file of orderForm.controls['additionalDocuments'].value">
                {{ file.originalName }}
                <i class="material-icons"
                   (click)="actions.deleteFile(file, 'additionalDocuments')"
                   *ngIf="!isManagerView">close</i>
              </div>
            </div>
          </div>

          <ep-file-upload (onEncloseFile)="eventListeners.onEncloseAdditionalFile($event)"
                          [buttonLabel]="'Wgraj kreację'"
                          *ngIf="orderForm.controls['orderDocument'].value && !isManagerView"></ep-file-upload>
        </div>
      </div>

    </div>

    <div class="right-side">

      <div class="margin-bottom-md"
           *ngIf="orderForm?.controls['reservation'] && orderForm?.controls['reservation'].value">
        <!--<ep-reservation-summary-list-->
        <!--[reservations]="orderForm.controls['reservation'].value"-->
        <!--[kind]="orderForm.controls['kind'].value"-->
        <!--[platform]="orderForm.controls['platform'].value"-->
        <!--&gt;</ep-reservation-summary-list>-->
        <div class="publisher-statistic" *ngFor="let reservation of selectedReservations">
          <div class="reservation-header">
            <div>
              <h3 class="subheading-1">
                {{ getReservationLabel(reservation) }}
              </h3>
            </div>
            <div class="edit-button-container">
              <button mat-button mat-icon-button class="mat-primary" (click)="openReservationEditPopup(reservation)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </div>

          <div class="mat-body-1" *ngIf="reservation.format?.name">
            <strong>Format: </strong> {{ reservation.format.name }}</div>
          <div class="mat-body-1" *ngIf="reservation.editionNumber"><strong>Numer
            wydania: </strong> {{ reservation.editionNumber }}</div>
          <div class="mat-body-1" *ngIf="reservation.editionYear"><strong>Rok
            wydania: </strong> {{ reservation.editionYear }}</div>
          <div class="mat-body-1" *ngIf="reservation.dateFrom"><strong>Data
            od: </strong> {{ reservation.dateFrom | date: 'dd.MM.yyyy' }}</div>
          <div class="mat-body-1" *ngIf="reservation.dateTo"><strong>Data
            do: </strong> {{ reservation.dateTo | date: 'dd.MM.yyyy' }}</div>
          <div class="mat-body-1" *ngIf="reservation.platform"><strong>Platforma: </strong> {{ reservation.platform }}
          </div>
          <div class="mat-body-1" *ngIf="reservation.principal?.name">
            <strong>Zleceniodawca: </strong> {{ reservation.principal?.name }}</div>
          <div class="mat-body-1" *ngIf="reservation.client?.name">
            <strong>Klient: </strong> {{ reservation.client?.name }}</div>
          <div class="mat-body-1" *ngIf="reservation.package && reservation.package['name']">
            <strong>Pakiet: </strong> {{ reservation.package['name'] }}</div>
          <div class="mat-body-1" *ngIf="reservation.owner && reservation.owner['email']">
            <strong>Właściciel: </strong> {{ reservation.owner['email'] }}</div>
          <div class="mat-body-1" *ngIf="reservation.value"><strong>Wartość
            PLN: </strong> {{ reservation.value | amountConverter: 'pl' }}</div>
          <div class="mat-body-1" *ngIf="reservation.emissionsNumber"><strong>Ilość
            odsłon: </strong> {{ reservation.emissionsNumber }}</div>
          <div class="mat-body-1" *ngIf="reservation.capping"><strong>Capping: </strong> {{ reservation.capping }}</div>
          <div class="mat-body-1" *ngIf="reservation.comment"><strong>Komentarz: </strong> {{ reservation.comment }}
          </div>
          <div class="mat-body-1" *ngIf="reservation.publishers">
            <strong>Serwisy: </strong> {{ showPublishers(reservation.publishers) }}</div>
          <div class="mat-body-1" *ngIf="reservation.salesModel?.name"><strong>Model
            sprzedaży: </strong> {{ reservation.salesModel?.name }}</div>
          <div class="mat-body-1" *ngIf="reservation.orderForm?.name"><strong>Grupa
            Produktów: </strong> {{ reservation.orderForm?.name }}</div>
          <div class="mat-body-1" *ngIf="reservation.priceList?.name">
            <strong>Usługa: </strong> {{ reservation.priceList?.name }}</div>

          <div class="mat-body-1" *ngIf="isMerchantView">
            <strong>Harmonogram płatności wg. miesięcy: </strong>
            <div class="reservation-payment-partitions">
              <div *ngFor="let paymentPartition of reservation.paymentPartition">
                {{ paymentPartition.date_from }} - {{ paymentPartition.date_to }}: <strong>{{ paymentPartition.value  | amountConverter: 'pl' }} PLN</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>




</form>
