import { Injectable } from '@angular/core';

export interface IUser {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  type: string;
  enabled: boolean;
  assigned_manager: User;
}

@Injectable()
export class User implements IUser {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  type: string;
  enabled: boolean;
  assigned_manager: User;

  constructor(obj?: any) {
    this.id = obj && obj.id || null;
    this.username = obj && obj.username || null;
    this.firstName = obj && obj.firstName || null;
    this.lastName = obj && obj.lastName || null;
    this.email = obj && obj.email || null;
    this.phone = obj && obj.phone || null;
    this.type = obj && obj.type || null;
    this.enabled = obj && obj.enabled || null;
    this.assigned_manager = obj && obj.assigned_manager ? new User(obj.assigned_manager) : null;
  }
}
