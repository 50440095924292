import { DatePipe } from '@angular/common';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import * as _ from 'lodash';
import { forkJoin as observableForkJoin } from 'rxjs';

import * as moment from 'moment';

import { auditTime, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AutocompleteFieldOptionStatusEnum } from '../../../shared/component/autocomplete-field/autocomplete-field-option-status.enum';
import { IAutocompleteFieldOption } from '../../../shared/component/autocomplete-field/autocomplete-field-option.interface';
import { IAutocompleteMultiselectOption } from '../../../shared/component/autocomplete-multiselect-field/autocomplete-multiselect-option.interface';
import { TreeItemModel } from '../../../shared/component/tree/tree-item.model';

import { DateFromToModel } from '../../../shared/model/date-from-to.model';
import { PaymentPartitionModel } from '../../../shared/model/payment-partition.model';

import { ApiErrorManagerService } from '../../../shared/utils/api-error-manager.service';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { TransformerService } from '../../../shared/utils/transformer.service';
import { BrandCreatePopupComponent } from '../../brand/brand-create-popup/brand-create-popup.component';
import { ClientAddPopupComponent } from '../../client/client-add-popup/client-add-popup.component';
import { Client } from '../../client/client.model';
import { ClientService } from '../../client/client.service';
import { ClientDataPage } from '../../client/interface/client-data-page';
import { ClientDataPageFilters } from '../../client/interface/client-data-page-filters';
import { ClientDataPageRequest } from '../../client/interface/client-data-page-request';
import { DictionaryService } from '../../dictionary/dictionary.service';
import { BrandDictionaryModel } from '../../dictionary/model/brand-dictionary.model';
import { CampaignTypeDictionaryModel } from '../../dictionary/model/campaign-type-dictionary.model';
import { CreationDictionaryModel } from '../../dictionary/model/creation-dictionary.model';
import { DictionaryFiltersRequestModel } from '../../dictionary/model/dictionary-filters-request.model';
import { DictionaryRequestModel } from '../../dictionary/model/dictionary-request.model';
import { FormatDictionaryModel } from '../../dictionary/model/format-dictionary.model';
import { OrderFormDictionaryModel } from '../../dictionary/model/order-form-dictionary.model';
import { OrderFormItemsDictionaryModel } from '../../dictionary/model/order-form-items-dictionary.model';
import { PaymentTypeDictionaryModel } from '../../dictionary/model/payment-type-dictionary.model';
import { PrincipalDictionaryModel } from '../../dictionary/model/principal-dictionary.model';
import { SalesModelDictionaryModel } from '../../dictionary/model/sales-model-dictionary.model';
import { SalesmanDictionaryWrapperModel } from '../../dictionary/model/salesman-dictionary-wrapper.model';
import { SalesmanDictionaryModel } from '../../dictionary/model/salesman-dictionary.model';
import { TitleDictionaryModel } from '../../dictionary/model/title-dictionary.model';
import { TradeDictionaryModel } from '../../dictionary/model/trade-dictionary.model';
import { DiscountService } from '../../discount/discount.service';
import { DiscountCalculateRequestModel } from '../../discount/model/discount-calculate-request.model';
import { DiscountModel } from '../../discount/model/discount.model';
import { Package } from '../../package/package.model';
import { PackageService } from '../../package/package.service';
import { Placement } from '../../placement/placement.model';
import { PlacementService } from '../../placement/placement.service';
import { ProfileService } from '../../profile/profile.service';
import { Publisher } from '../../publisher/model/publisher.model';
import { PublisherService } from '../../publisher/publisher.service';
import { ReservationRequestModel } from '../model/reservation-request.model';
import { Reservation } from '../model/reservation.model';
import { ReservationService } from '../reservation.service';
import { SelectPlacementPopupComponent } from '../select-placement-popup/select-placement-popup.component';
import { BasePriceDigitalCalculate } from './base-price-digital-calculate';

import { ReservationDiscountBuilder } from './reservation-discount-builder';
import { ReservationDiscountModel } from './reservation-discount.model';
import {ReservationPaymentComponent} from '../reservation-payment/reservation-payment.component';

@Component({
  selector: 'ep-reservation-form',
  templateUrl: './reservation-form.component.html',
  styleUrls: ['./reservation-form.component.scss']
})
export class ReservationFormComponent implements OnInit, OnDestroy {
  packageForm: FormGroup;
  packages: Package[];
  clients: any;
  placements: any;
  creation: any;
  publishers: any;
  publisherListData: any;

  brandRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  brandOptions: IAutocompleteFieldOption[] = [];
  brandOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  campaignTypeRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  campaignTypeOptions: IAutocompleteFieldOption[] = [];
  campaignTypeOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  clientDataPageRequest: ClientDataPageRequest = new ClientDataPageRequest();
  clientOptions: IAutocompleteFieldOption[] = [];
  clientOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;


  creationRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  creationOptions: IAutocompleteFieldOption[] = [];
  creationOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  creationFilter: FormControl = new FormControl();
  creationFilterChangesFirstRun: boolean = true;
  creationFilterChangesSubscriber = this.creationFilter.valueChanges.pipe(distinctUntilChanged()).subscribe(creationFilterData => {
    if (_.isObject(creationFilterData) || !creationFilterData) {
      this.creation = null;
      if (creationFilterData && creationFilterData.id) {
        if (!this.creation) {
          this.creation = {id: null};
        }
        this.creation.id = creationFilterData.id;
      }

      this.dataActions.getPackageDataPage({filters: {creation: this.creation ? this.creation.id : null}});
    }
  });

  discountTypeOptions: IAutocompleteFieldOption[] = [];
  discountTypeOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  orderFormRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  orderFormOptions: IAutocompleteFieldOption[] = [];
  orderFormOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  formatRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null, title: undefined}});
  formatOptions: IAutocompleteFieldOption[] = [];
  formatOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  packageOptions: IAutocompleteFieldOption[] = [];
  packageOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  paymentRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  paymentOptions: IAutocompleteFieldOption[] = [];
  paymentOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  publisherOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  placementOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  principalRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  principalOptions: IAutocompleteFieldOption[] = [];
  principalOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  printTypeOptions: IAutocompleteFieldOption[] = [];
  printTypeOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  salesmanRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  salesmanOptions: IAutocompleteFieldOption[] = [];
  salesmanOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  titleRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  titleOptions: IAutocompleteFieldOption[] = [];
  titleOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  salesModelRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  salesModelOptions: IAutocompleteFieldOption[] = [];
  salesModelOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  statusOptions: Array<any> = [];
  statusOptionsKeys: Array<any> = [];

  tradeRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  tradeOptions: IAutocompleteFieldOption[] = [];
  tradeOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  isEdit: boolean = false;

  value: any;
  selectedType: any;
  showValuePrice: boolean = true;
  showFixedPrice: boolean = true;
  showPriceListPrice: boolean = true;
  placementTreeCopy: Array<TreeItemModel>;

  selectedPlacementsPlaceholderControl: FormControl = new FormControl(null, [Validators.required]);
  discountTypeControl: FormControl = new FormControl();
  placementSelectedLabels: Array<string>;
  printFromTradeFieldSubscriber: any;
  discounts: Array<ReservationDiscountModel> = [];

  showDiscountFields: boolean;

  editions: Array<any>;

  public paymentData: Array<PaymentPartitionModel>;
  public isPaymentValid: boolean;

  @Output() onSaved: EventEmitter<any> = new EventEmitter<any>();
  @Input() dateFrom: any;
  @Input() reservationItemData: Reservation;

  @Input() set formType(type) {
    if (type !== this.selectedType) {
      if (type === 'package' || type === 'placement') {
        this.dataActions.getClientList();
        this.dataActions.getPackageDataPage();
        this.dataActions.getPaymentListData();
        this.dataActions.getOrderFormList();
        this.dataActions.getCampaignTypeListData();
        this.dataActions.getSalesmanList();
        this.dataActions.getSalesModelList();
        this.dataActions.getTradeList();
        this.dataActions.getPrincipalList();
        this.dataActions.getDiscountTypeList();
      }
      if (type === 'print') {
        this.dataActions.getClientList();
        this.dataActions.getPaymentListData();
        this.dataActions.getSalesmanList();
        this.dataActions.getTradeList();
        this.dataActions.getPrincipalList();
        this.dataActions.getPrintTypeList();
        this.dataActions.getTitleList();
        this.dataActions.getDiscountTypeList();
      }
    }
    this.selectedType = type;
  }

  @ViewChild(ReservationPaymentComponent) public reservationPaymentComponent: ReservationPaymentComponent;

  constructor(private packageService: PackageService,
              private formBuilder: FormBuilder,
              private clientService: ClientService,
              private discountService: DiscountService,
              private placementService: PlacementService,
              private reservationService: ReservationService,
              private publisherService: PublisherService,
              private errorManager: ApiErrorManagerService,
              private dictionaryService: DictionaryService,
              private toaster: ToasterService,
              private dialog: MatDialog,
              private profileService: ProfileService,
              private transformerService: TransformerService) {
    this.onSaved = new EventEmitter();
  }

  public isReservationPaymentNotValid(): boolean {
    return this.reservationPaymentComponent && this.reservationPaymentComponent.isReservationPaymentNotValid();
  }

  onChangeCreationSearchText($event) {
    if (_.isString($event) || null) {
      this.getCreations($event);
    }
  }

  getCreations(searchText?: string) {
    if (!searchText || searchText && !searchText['id']) {
      this.creationRequest.filters.name = searchText;

      this.creationOptions = [];
      this.dictionaryService.getCreations(this.creationRequest).subscribe(response => {
        if (response && response.length) {
          let creationOptions = [];
          _.each(response, (val: CreationDictionaryModel) => {
            creationOptions.push({
              id: val.id,
              label: val.name
            });
          });
          this.creationOptions = creationOptions;
          this.creationOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
        } else {
          this.creationOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
        }
      });
    }
  }

  onChangeGetBasePrice() {
    setTimeout(() => {
      this.calculateBasePrice();
    });
  }

  calculateBasePrice() {
    if (this.selectedType !== 'print') {
      const basePriceCalculate = new BasePriceDigitalCalculate();
      basePriceCalculate.setCreation(this.creationFilter && this.creationFilter.value ? this.creationFilter.value : this.creation ? this.creation : null);
      basePriceCalculate.setOrderForm(this.packageForm.controls['orderForm'].value);
      basePriceCalculate.setSalesModel(this.packageForm.controls['salesModel'].value);
      basePriceCalculate.setEmissionNumber(this.removeSpaces(this.packageForm.controls['emissionsNumber'].value));
      basePriceCalculate.setType('1');

      if (basePriceCalculate.validateRequest(this.selectedType)) {
        this.discountService.calculateBasePriceDigital(basePriceCalculate.getRequest()).subscribe(data => {
          this.showPriceListPrice = false;
          if (data && data['base_price']) {
            this.packageForm.controls['priceListPrice'].setValue(data['base_price']);
          } else if (data && data['basePrice']) {
            this.packageForm.controls['priceListPrice'].setValue(data['basePrice']);
          }
          setTimeout(() => {
            this.showPriceListPrice = true;
          });
          // this.packageForm.controls['fixedPrice'].setValue(0);
          this.packageForm.controls['value'].setValue(0);
          this.discounts = [];
          this.discountTypeControl.setValue(null);
        });
      }
    }

  }

  ngOnInit() {
    if (this.reservationItemData) {
      this.reservationService.getStatuses(this.reservationItemData.status).subscribe((response: Response) => {
        this.statusOptions = response['data'].statuses;
        this.statusOptionsKeys = Object.keys(this.statusOptions);
      });
      let type: string;
      if (this.reservationItemData && this.reservationItemData.type === 'package') {
        type = 'package';
        if (this.reservationItemData.creation) {
          this.creation = this.reservationItemData.creation;

          this.creationFilter.setValue({
            id: this.reservationItemData.creation.id,
            label: this.reservationItemData.creation.name
          });
        }
      }
      if (this.reservationItemData && this.reservationItemData.type === 'single') {
        type = 'placement';
        if (this.reservationItemData.creation) {
          this.creation = this.reservationItemData.creation;
        }
      }
      if (this.reservationItemData && this.reservationItemData.platform === 'print') {
        type = 'print';
      }
      this.isEdit = true;
      this.selectedType = type;

      this.createForm(type, this.reservationItemData);
    } else {
      this.createForm(this.selectedType);
    }

    this.getCreations();
  }

  ngOnDestroy() {
    if (this.printFromTradeFieldSubscriber && this.printFromTradeFieldSubscriber.unsubscribe) {
      this.printFromTradeFieldSubscriber.unsubscribe();
    }

    if (this.creationFilterChangesSubscriber && this.creationFilterChangesSubscriber.unsubscribe) {
      this.creationFilterChangesSubscriber.unsubscribe();
    }
  }

  dataActions: any = {
    getBrandList: (brandRequest?: DictionaryRequestModel): void => {
      this.brandOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.brandOptions = [];
      this.dictionaryService.getBrandsByTrade(this.packageForm.controls['trade'].value.id, brandRequest)
        .subscribe(
          response => this.dataSubscribers.getBrandListData(response),
          err => this.dataSubscribers.defaultErrorListData('brand', err));
    },
    getCampaignTypeListData: (campaignTypeRequest?: any): void => {
      this.campaignTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getCampaignTypes(campaignTypeRequest)
        .subscribe(
          response => this.dataSubscribers.getCampaignTypeListData(response),
          err => this.dataSubscribers.defaultErrorListData('campaign', err));
    },
    getClientList: (clientDataPageRequest?: ClientDataPageRequest): void => {
      this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.clientOptions = [];
      this.clientService.getList(clientDataPageRequest)
        .subscribe(
          response => this.dataSubscribers.getClientListData(response),
          err => this.dataSubscribers.defaultErrorListData('client', err));
    },
    getDiscountTypeList: (): void => {
      this.discountTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.discountTypeOptions = [];
      this.discountService.getTypeList()
        .subscribe(
          response => this.dataSubscribers.getDiscountTypeListData(response),
          err => this.dataSubscribers.defaultErrorListData('discountType', err));
    },
    getFormatList: (formatRequest?: DictionaryRequestModel): void => {
      this.formatOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.brandOptions = [];
      this.dictionaryService.getFormats(formatRequest)
        .subscribe(
          response => this.dataSubscribers.getFormatListData(response),
          err => this.dataSubscribers.defaultErrorListData('format', err));
    },
    getOrderFormList: (orderFormRequest?: DictionaryRequestModel): void => {
      this.orderFormOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getOrderForms(orderFormRequest, 'standard')
        .subscribe(
          response => this.dataSubscribers.getOrderFormListData(response),
          err => this.dataSubscribers.defaultErrorListData('orderForm', err));
    },
    getPackageDataPage: (packageDataPageRequest?: any): void => {
      this.packageOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.packageService.getList(packageDataPageRequest)
        .subscribe(
          response => this.dataSubscribers.getPackageListData(response),
          err => this.dataSubscribers.defaultErrorListData('package', err));
    },
    getPaymentListData: (paymentRequest?: any): void => {
      this.paymentOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getPaymentTypes(paymentRequest)
        .subscribe(
          response => this.dataSubscribers.getPaymentListData(response),
          err => this.dataSubscribers.defaultErrorListData('payment', err));
    },
    getPrincipalList: (principalRequest?: any): void => {
      this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getPrincipals(principalRequest)
        .subscribe(
          response => this.dataSubscribers.getPrincipalListData(response),
          err => this.dataSubscribers.defaultErrorListData('principal', err));
    },
    getPrintTypeList: (): void => {
      this.printTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.printTypeOptions = [];
      this.reservationService.getPrintTypeList()
        .subscribe(
          response => this.dataSubscribers.getPrintTypeListData(response),
          err => this.dataSubscribers.defaultErrorListData('printType', err));
    },
    getSalesmanList: (salesModelRequest?: any): void => {
      this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getSalesmans(salesModelRequest)
        .subscribe(
          response => this.dataSubscribers.getSalesmanListData(response),
          err => this.dataSubscribers.defaultErrorListData('salesman', err));
    },
    getSalesModelList: (salesModelRequest?: any): void => {
      this.salesModelOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getSalesModels(salesModelRequest)
        .subscribe(
          response => this.dataSubscribers.getSalesModelListData(response),
          err => this.dataSubscribers.defaultErrorListData('salesModel', err));
    },
    getTitleList: (salesModelRequest?: any): void => {
      this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getTitles(salesModelRequest)
        .subscribe(
          response => this.dataSubscribers.getTitleListData(response),
          err => this.dataSubscribers.defaultErrorListData('title', err));
    },
    getTradeList: (tradeRequest?: any): void => {
      this.tradeOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getTrades(tradeRequest)
        .subscribe(
          response => this.dataSubscribers.getTradeListData(response),
          err => this.dataSubscribers.defaultErrorListData('trade', err));
    },
  };

  dataSubscribers: any = {
    getBrandListData: (response: Array<BrandDictionaryModel>) => {
      if (response && response.length) {
        _.each(response, (val: BrandDictionaryModel) => {
          if (!_.isArray(this.brandOptions)) {
            this.brandOptions = [];
          }

          this.brandOptions.push({
            id: val.id,
            label: val.name
          });
        });

        if (this.isEdit && this.reservationItemData.brand && this.reservationItemData.brand.id) {
          let currentBrand: any = _.find(this.brandOptions, {id: this.reservationItemData.brand.id});
          if (!currentBrand) {
            currentBrand = {
              id: this.reservationItemData.brand.id,
              label: this.reservationItemData.brand.name
            };
            this.brandOptions.push(currentBrand);
          }
        }
        this.brandOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.brandOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getCampaignTypeListData: (response: Array<CampaignTypeDictionaryModel>) => {
      let campaignTypeOptions: Array<IAutocompleteFieldOption> = [];
      if (response && response.length) {
        _.each(response, (val: CampaignTypeDictionaryModel) => {
          campaignTypeOptions.push({
            id: val.id,
            label: val.name
          });
        });

        if (this.isEdit && this.reservationItemData && this.reservationItemData.campaignType && this.reservationItemData.campaignType.id) {
          let currentItem: any = _.find(campaignTypeOptions, {id: this.reservationItemData.campaignType.id});
          if (!currentItem) {
            currentItem = {
              id: this.reservationItemData.campaignType.id,
              label: this.reservationItemData.campaignType.name
            };
            campaignTypeOptions.push(currentItem);
          }
        }
        this.campaignTypeOptions = campaignTypeOptions;
        this.campaignTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.campaignTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getClientListData: (response: ClientDataPage) => {
      if (response && response.items && response.items.length) {
        _.each(response.items, (val: Client) => {
          if (!_.isArray(this.clientOptions)) {
            this.clientOptions = [];
          }

          this.clientOptions.push({
            id: val.id,
            label: val.name
          });
        });
        if (this.reservationItemData && this.reservationItemData.client) {
          this.clientOptions.push({
            id: this.reservationItemData.client.id,
            label: this.reservationItemData.client.name
          });
        }
        this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getDiscountTypeListData: (response: Array<DiscountModel>) => {
      let discountTypeOptions: Array<IAutocompleteMultiselectOption> = [];

      if (response && _.isObject(response)) {
        _.each(response, (val: any, key: any) => {
          discountTypeOptions.push({
            id: key,
            label: val
          });
        });
        this.discountTypeOptions = discountTypeOptions;

        if (this.reservationItemData && this.reservationItemData.discounts && _.isArray(this.reservationItemData.discounts) && this.reservationItemData.discounts.length) {
          let discountSelected = [];
          _.each(this.reservationItemData.discounts, (val: any, key: any) => {
            discountSelected.push({
              id: `${val.type}`,
              label: val.name
            });

          });
          let discountSelectedReduced = _.map(discountTypeOptions, (discount: IAutocompleteMultiselectOption) => {
            if (_.find(discountSelected, {id: discount.id})) {
              return discount;
            } else {
              return undefined;
            }
          });

          discountSelectedReduced = _.compact(discountSelectedReduced);
          this.discountTypeControl.setValue(discountSelectedReduced);

          // _.each(this.reservationItemData.discounts, (val: any, key: any) => {
          //     this.discountsFormControl = {};
          //     _.each(this.reservationItemData.discounts, (val: any, key: any) => {
          //         this.discountsFormControl[`${val.type}`] = new FormControl(['', Validators.required]);
          //         this.discountsFormControl[`${val.type}`].setValue(val.percentValue ? val.percentValue : val.amountValue);
          //     });
          //
          //     this.discountsFormControl[`${val.type}`] = new FormControl(['', Validators.required]);
          //     this.discountsFormControl[`${val.type}`].setValue(val.percentValue ? val.percentValue : val.amountValue);
          //
          //     this.discounts = this.reservationItemData.discounts;
          //     this.buildDiscounts(this.discountTypeControl.value);
          // });
        }

        this.discountTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.discountTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getFormatListData: (response: Array<FormatDictionaryModel>) => {
      let formatOptions: Array<IAutocompleteFieldOption> = [];
      if (response && _.isArray(response) && response.length) {
        _.each(response, (val: FormatDictionaryModel) => {
          formatOptions.push({
            id: val.id,
            label: val.name,
            payload: val
          });
        });

        if (this.isEdit && this.reservationItemData.format && this.reservationItemData.format.id) {
          let currentItem: any = _.find(formatOptions, {id: this.reservationItemData.format.id});
          if (!currentItem) {
            currentItem = {
              id: this.reservationItemData.format.id,
              label: this.reservationItemData.format.name,
              payload: this.reservationItemData.format
            };
            formatOptions.push(currentItem);
          }
        }

        this.formatOptions = formatOptions;
        this.formatOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.formatOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getOrderFormListData: (response: OrderFormDictionaryModel) => {
      if (response && response.items && response.items.length) {
        let orderFormOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response.items, (val: OrderFormItemsDictionaryModel) => {
          orderFormOptions.push({
            id: val.id,
            label: val.name
          });
        });

        if (this.isEdit && this.reservationItemData.orderForm && this.reservationItemData.orderForm.id) {
          let currentItem: any = _.find(orderFormOptions, {id: this.reservationItemData.orderForm.id});
          if (!currentItem) {
            currentItem = {
              id: this.reservationItemData.orderForm.id,
              label: this.reservationItemData.orderForm.name
            };
            orderFormOptions.push(currentItem);
          }
        }

        this.orderFormOptions = orderFormOptions;
        this.orderFormOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.orderFormOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getPackageListData: (response: any) => {
      if (response && response.data && response.data.length) {
        let packageOptions: Array<IAutocompleteFieldOption> = this.buildPackages(response.data)

        if (this.isEdit && this.reservationItemData.placementCategory && this.reservationItemData.placementCategory.placementCategoryId) {
          let currentItem: any = _.find(packageOptions, {id: this.reservationItemData.placementCategory.placementCategoryId});
          if (!currentItem) {
            currentItem = {
              id: this.reservationItemData.placementCategory.placementCategoryId,
              label: this.reservationItemData.placementCategory.name
            };
            packageOptions.push(currentItem);
          }
        }

        this.packageOptions = packageOptions;
        this.packageOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.packageOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getPaymentListData: (response?: Array<PaymentTypeDictionaryModel>): void => {
      if (response && response && response.length) {
        let paymentOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response, (val: PaymentTypeDictionaryModel) => {
          paymentOptions.push({
            id: val.id,
            label: val.name
          });
        });

        if (this.isEdit && this.reservationItemData.paymentType && this.reservationItemData.paymentType.id) {
          let currentItem: any = _.find(paymentOptions, {id: this.reservationItemData.paymentType.id});
          if (!currentItem) {
            currentItem = {
              id: this.reservationItemData.paymentType.id,
              label: this.reservationItemData.paymentType.name
            };
            paymentOptions.push(currentItem);
          }
        }

        this.paymentOptions = paymentOptions;
        this.paymentOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.paymentOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getPrincipalListData: (response?: Array<PrincipalDictionaryModel>): void => {
      if (response && response && response.length) {
        let options: Array<IAutocompleteMultiselectOption> = [];
        _.each(response, (val: PrincipalDictionaryModel) => {
          options.push({
            id: val.id,
            label: val.name
          });
        });

        if (this.isEdit && this.reservationItemData.principal && this.reservationItemData.principal.id) {
          let currentItem: any = _.find(options, {id: this.reservationItemData.principal.id});
          if (!currentItem) {
            currentItem = {
              id: this.reservationItemData.principal.id,
              label: this.reservationItemData.principal.name
            };
            options.push(currentItem);
          }
        }

        this.principalOptions = options;
        this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getPrintTypeListData: (response: any) => {
      let printTypeOptions: Array<IAutocompleteFieldOption> = [];
      if (response) {
        _.each(response, (val: any, key: any) => {
          printTypeOptions.push({
            id: val,
            label: key
          });
        });
        if (this.reservationItemData && this.reservationItemData.platform && this.reservationItemData.platform === 'print' && this.reservationItemData.type) {
          const printType = _.find(printTypeOptions, {id: this.reservationItemData.type});

          this.packageForm.controls['type'].setValue(printType);
        }
        this.printTypeOptions = printTypeOptions;
        this.printTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.printTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getSalesmanListData: (response?: SalesmanDictionaryWrapperModel): void => {
      if (response && response.items && _.isArray(response.items) && response.items.length) {
        let salesmanOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response.items, (val: SalesmanDictionaryModel) => {
          salesmanOptions.push({
            id: val.id,
            label: val.name
          });
        });
        if (this.isEdit && this.reservationItemData.salesman && this.reservationItemData.salesman.id) {
          let currentSalesman: any = _.find(salesmanOptions, {id: this.reservationItemData.salesman.id});
          if (!currentSalesman) {
            currentSalesman = {
              id: this.reservationItemData.salesman.id,
              label: this.reservationItemData.salesman.name
            };
            salesmanOptions.push(currentSalesman);
          }
        } else if (this.reservationItemData && !this.reservationItemData.salesman && response.currentUser) {
          const currentSalesman: SalesmanDictionaryModel = _.find(response.items, (salesmanItem: SalesmanDictionaryModel) => {
            if (salesmanItem.id === response.currentUser.id) {
              return true;
            }
          });
          if (currentSalesman) {
            this.salesmanOptions.push({
              id: currentSalesman.id,
              label: `${currentSalesman.name}`
            });
          }
        }

        this.salesmanOptions = salesmanOptions;
        this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getSalesModelListData: (response?: Array<SalesModelDictionaryModel>): void => {
      if (response && response && response.length) {
        let salesModelOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response, (val: SalesModelDictionaryModel) => {
          salesModelOptions.push({
            id: val.id,
            label: val.name
          });
        });

        if (this.isEdit && this.reservationItemData.salesModel && this.reservationItemData.salesModel.id) {
          let currentItem: any = _.find(salesModelOptions, {id: this.reservationItemData.salesModel.id});
          if (!currentItem) {
            currentItem = {
              id: this.reservationItemData.salesModel.id,
              label: this.reservationItemData.salesModel.name
            };
            salesModelOptions.push(currentItem);
          }
        }

        this.salesModelOptions = salesModelOptions;
        this.salesModelOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.salesModelOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getTitleListData: (response?: TitleDictionaryModel): void => {
      if (response && _.isArray(response) && response.length) {
        let titleOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response, (val: TitleDictionaryModel) => {
          titleOptions.push({
            id: val.id,
            label: val.name
          });
        });
        if (this.isEdit && this.reservationItemData.title && this.reservationItemData.title.id) {
          let currentTitle: any = _.find(titleOptions, {id: this.reservationItemData.title.id});
          if (!currentTitle) {
            currentTitle = {
              id: this.reservationItemData.title.id,
              label: this.reservationItemData.title.name
            };
            titleOptions.push(currentTitle);
          }
        }

        this.titleOptions = titleOptions;
        this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getTradeListData: (response?: Array<TradeDictionaryModel>): void => {
      if (response && response && response.length) {
        let tradeOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response, (val: TradeDictionaryModel) => {
          tradeOptions.push({
            id: val.id,
            label: val.name
          });
        });

        if (this.isEdit && this.reservationItemData.trade && this.reservationItemData.trade.id) {
          let currentItem: any = _.find(tradeOptions, {id: this.reservationItemData.trade.id});
          if (!currentItem) {
            currentItem = {
              id: this.reservationItemData.trade.id,
              label: this.reservationItemData.trade.name
            };
            tradeOptions.push(currentItem);
          }
          this.dataActions.getBrandList(this.brandRequest);
        }

        this.tradeOptions = tradeOptions;
        this.tradeOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.tradeOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    defaultErrorListData: (context) => {
      switch (context) {
        case 'client':
          this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.tooManyResults;
          break;
        case 'package':
          this.packageOptionsStatus = AutocompleteFieldOptionStatusEnum.tooManyResults;
          break;
        case 'publisher':
          this.publisherOptionsStatus = AutocompleteFieldOptionStatusEnum.tooManyResults;
          break;
        case 'placement':
          this.placementOptionsStatus = AutocompleteFieldOptionStatusEnum.tooManyResults;
          break;
        default:
          this[`${context}OptionsStatus`] = AutocompleteFieldOptionStatusEnum.tooManyResults;
          break;
      }
    }
  };

  eventListeners: any = {
    onChangeBrandSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.brandRequest.filters.name = text;
        this.dataActions.getBrandList(this.brandRequest);
      } else {
        this.brandOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeCampaignTypeSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.campaignTypeRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getCampaignTypeListData(this.campaignTypeRequest);
      } else {
        this.campaignTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeClientSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.clientDataPageRequest.setFilters(new ClientDataPageFilters({name: text}));
        this.dataActions.getClientList(this.clientDataPageRequest);
      } else {
        this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeFormatSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.formatRequest.filters.title = null;

        if (this.packageForm.controls['title'] && this.packageForm.controls['title'].value && this.packageForm.controls['title'].value.id) {
          this.formatRequest.filters.title = this.packageForm.controls['title'].value.id;
        }
        this.formatRequest.filters.name = text;
        this.dataActions.getFormatList(this.formatRequest);
      } else {
        this.formatOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeOrderFormSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.orderFormRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getOrderFormList(this.orderFormRequest);
      } else {
        this.orderFormOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangePackageSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        const request = {
          filters: {
            name: text,
            creation: this.creation ? this.creation.id : null
          }
        };
        this.dataActions.getPackageDataPage(request);
      } else {
        this.packageOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangePaymentSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.paymentRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getPaymentListData(this.paymentRequest);
      } else {
        this.paymentOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangePrincipalSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.principalRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getPrincipalList(this.principalRequest);
      } else {
        this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeSalesmanSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.salesmanRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getSalesmanList(this.salesmanRequest);
      } else {
        this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeSalesModelSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.salesModelRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getSalesModelList(this.salesModelRequest);
      } else {
        this.campaignTypeOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeTitleSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.titleRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getTitleList(this.titleRequest);
      } else {
        this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeTradeSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.tradeRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getTradeList(this.tradeRequest);
      } else {
        this.tradeOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onFormatSelect: () => {
      if (this.packageForm.controls['format'].value) {
        let price = this.packageForm.controls['format'].value.payload.price;
        price = price ? price : 0;
        this.packageForm.controls['priceListPrice'].setValue(price);
        if (this.packageForm.controls['fixedPrice'].value == 0) {
          this.showValuePrice = false;
          this.packageForm.controls['value'].setValue(price);
          setTimeout(() => {
            this.showValuePrice = true;
          });
        }
      } else {
        this.packageForm.controls['priceListPrice'].setValue(0);
      }
      this.showPriceListPrice = false;
      setTimeout(() => {
        this.showPriceListPrice = true;
      })
    },
    onClickClientNoResultAction: (): void => {
      this.openClientAddPopup();
    },
    onClickBrandNoResultAction: (): void => {
      this.openBrandAddPopup();
    }
  };

  private createForm(type, reservation?: Reservation) {
    let dateFrom: Date;
    let dateTo: Date;
    let clientAutocomplete: IAutocompleteFieldOption;

    if (reservation && reservation.dateFrom) {
      dateFrom = new Date(reservation.dateFrom);
    } else {
      dateFrom = this.dateFrom ? new Date(this.dateFrom) : new Date()
    }
    if (reservation && reservation.dateTo) {
      dateTo = new Date(reservation.dateTo);
    } else {
      dateTo = this.dateFrom ? new Date(this.dateFrom) : new Date()
    }
    if (reservation && reservation.client) {
      clientAutocomplete = {
        id: this.reservationItemData.client.id,
        label: this.reservationItemData.client.name
      };
    }
    if (type === 'package') {
      this.packageForm = this.formBuilder.group({
        'package': [reservation ? reservation.placementCategory : '', Validators.required],
        client: [reservation && reservation.client ? clientAutocomplete : '', Validators.required],
        range: [0, Validators.required],
        emissionsNumber: [reservation ? reservation.emissionsNumber : 0, Validators.required],
        value: [reservation ? reservation.value : 0, Validators.required],
        dateFrom: [dateFrom, Validators.required],
        dateTo: [dateTo, Validators.required],
        brand: [null, Validators.required],
        paymentType: [null, Validators.required],
        campaignType: [null, Validators.required],
        orderForm: [null, Validators.required],
        salesman: [null, Validators.required],
        salesModel: [null, Validators.required],
        trade: [null, Validators.required],
        capping: [reservation ? reservation.capping : 0],
        targetUrl: [null],
        principal: [null, Validators.required],
        comment: [reservation ? reservation.comment : '', Validators.required],
        priceListPrice: [reservation ? reservation.priceListPrice : '0', Validators.required],
        fixedPrice: [reservation ? reservation.fixedPrice : '0', Validators.required],
        discounts: [''],
        materialsDeliveryTime: [reservation ? reservation.materialsDeliveryTime : '']
      });
    }

    if (type === 'placement') {
      this.packageForm = this.formBuilder.group({
        placement: [''],
        publisher: [''],
        client: [reservation && reservation.client ? clientAutocomplete : '', Validators.required],
        range: [0, Validators.required],
        emissionsNumber: [reservation ? reservation.emissionsNumber : '0', Validators.required],
        value: [reservation ? reservation.value : '0', Validators.required],
        dateFrom: [dateFrom, Validators.required],
        dateTo: [dateTo, Validators.required],
        brand: [null, Validators.required],
        paymentType: [null, Validators.required],
        campaignType: [null, Validators.required],
        orderForm: [null, Validators.required],
        salesman: [null, Validators.required],
        salesModel: [null, Validators.required],
        trade: [null, Validators.required],
        capping: [reservation ? reservation.capping : 0],
        targetUrl: [null],
        principal: [null, Validators.required],
        comment: [reservation ? reservation.comment : '', Validators.required],
        priceListPrice: [reservation ? reservation.priceListPrice : '0', Validators.required],
        fixedPrice: [reservation ? reservation.fixedPrice : '0', Validators.required],
        discounts: [''],
        materialsDeliveryTime: [reservation ? reservation.materialsDeliveryTime : '']
      });
    }

    // if (this.discountTypeControl) {
    //     this.printFromTradeFieldSubscriber = this.packageForm.controls['title'].valueChanges.distinctUntilChanged().subscribe(titleFieldValue => {
    //         if (_.isObject(titleFieldValue)) {
    //             if (titleFieldValue.id) {
    //                 this.brandRequest.filters.title = titleFieldValue.id;
    //             }
    //             this.dataActions.getFormatList(this.brandRequest);
    //             this.packageForm.controls['format'].setValue(null);
    //         }
    //     });
    // }


    if (type === 'print') {
      this.packageForm = this.formBuilder.group({
        client: [reservation && reservation.client ? clientAutocomplete : '', Validators.required],
        value: [reservation ? reservation.value : '0', Validators.required],
        editionNumber: [null, [Validators.required, Validators.pattern]],
        brand: [null, Validators.required],
        paymentType: [null, Validators.required],
        salesman: [null, Validators.required],
        trade: [null, Validators.required],
        principal: [null, Validators.required],
        format: [null, Validators.required],
        type: [null, Validators.required],
        title: [null, Validators.required],
        comment: [reservation ? reservation.comment : '', Validators.required],
        priceListPrice: [reservation ? reservation.priceListPrice : '0', Validators.required],
        fixedPrice: [reservation ? reservation.fixedPrice : '0', Validators.required],
        discounts: [''],
        technicalCosts: [reservation ? reservation.technicalCosts : '0'],
        materialsDeliveryTime: [reservation ? reservation.materialsDeliveryTime : '']
      });


      let firstRunEdit = true;
      this.printFromTradeFieldSubscriber = this.packageForm.controls['title'].valueChanges.pipe(distinctUntilChanged()).subscribe((titleFieldValue: TitleDictionaryModel) => {
        if (_.isObject(titleFieldValue)) {
          if (titleFieldValue.id) {
            this.brandRequest.filters.title = titleFieldValue.id;
            if (this.selectedType === 'print') {
              this.dictionaryService.getTitleEdition(titleFieldValue.id).subscribe(data => {
                this.editions = data;

                if (this.isEdit && firstRunEdit) {
                  const foundTitleEdition = _.find(data, {
                    editionNumber: reservation.editionNumber,
                    editionYear: reservation.editionYear
                  });

                  this.packageForm.controls['editionNumber'].setValue(foundTitleEdition);
                  firstRunEdit = false;
                }
              });
            }
          }
          this.dataActions.getFormatList(this.brandRequest);
          this.packageForm.controls['format'].setValue(null);
        }
      });
    } else {
      if (this.printFromTradeFieldSubscriber && this.printFromTradeFieldSubscriber.unsubscribe) {
        this.printFromTradeFieldSubscriber.unsubscribe();
      }
    }

    let firstRun = true;
    this.discountTypeControl.valueChanges.pipe(auditTime(700)).subscribe(discountType => {
      this.buildDiscounts(discountType, firstRun);
      if (!firstRun) {
        this.calculateDiscount(null);
      }
      firstRun = false;
    });

    this.packageForm.controls.priceListPrice.disable();
    this.packageForm.controls.value.disable();

    let isChangingSameFixedPriceValue = false;
    this.packageForm.controls['fixedPrice'].valueChanges.pipe(distinctUntilChanged()).subscribe(fieldValue => {
      if (fieldValue === '' && !isChangingSameFixedPriceValue) {
        this.showFixedPrice = false;
        setTimeout(() => {
          this.packageForm.controls['fixedPrice'].setValue(0);
          this.showFixedPrice = true;
        });
        this.showValuePrice = false;
        this.packageForm.controls['value'].setValue(this.packageForm.controls['priceListPrice'].value);
        setTimeout(() => {
          this.showValuePrice = true;
        });
        isChangingSameFixedPriceValue = true;
      } else if (!isChangingSameFixedPriceValue) {
        this.showValuePrice = false;
        this.packageForm.controls['value'].setValue(fieldValue);
        setTimeout(() => {
          this.showValuePrice = true;
        });
        isChangingSameFixedPriceValue = false;
      } else {
        isChangingSameFixedPriceValue = false;
      }
    });

    this.packageForm.controls['trade'].valueChanges.pipe(distinctUntilChanged()).subscribe(tradeFieldValue => {
      if (_.isObject(tradeFieldValue)) {
        this.dataActions.getBrandList(this.brandRequest);
        this.packageForm.controls['brand'].setValue(null);
      }
    });

    if (this.isEdit && (type === 'placement' || type === 'package')) {
      this.packageForm.controls['status'] = new FormControl();
      this.packageForm.controls['status'].setValue(reservation.status);
      this.packageForm.controls['capping'].setValue(reservation.capping);
      this.packageForm.controls['targetUrl'].setValue(reservation.targetUrl);

      if (this.isEdit && (type === 'placement')) {
        this.creation = null;
        if (reservation.creation) {
          this.creation = reservation.creation;
        }

        this.publisherService.getList({})
          .pipe(map(data => data['data']))
          .subscribe(publishers => {
            const publisherList = _.cloneDeep(publishers);
            const publisherTreeItems = this.getPlacementTreeItems(publisherList, true, {
              selectedPublishers: _.cloneDeep(this.getPublisherIds(reservation.publishers)),
              selectedPlacements: _.cloneDeep(this.getPlacementIds(reservation.placements))
            });
            const placementsTreeForks = [];

            _.each(publisherTreeItems, publisherTreeItem => {
              if (publisherTreeItem.selected) {
                const req = this.placementService.getTreePlacements(publisherTreeItem.item.publisher.publisherId, this.creation ? this.creation.id : null).pipe(map(data => {
                  const a = {};
                  a[publisherTreeItem.item.publisher.publisherId] = data;
                  return a;
                }));
                placementsTreeForks.push(req);
              }
            });
            observableForkJoin(placementsTreeForks)
              .subscribe(data => {
                let selectedPlacements = {};
                _.each(data, placement => {
                  selectedPlacements = Object.assign({}, selectedPlacements, placement);
                });

                _.each(selectedPlacements, (placements, publisherId) => {
                  const placementTreeItems = this.getPlacementTreeItems(placements, false, {
                    selectedPublishers: _.cloneDeep(this.getPublisherIds(reservation.publishers)),
                    selectedPlacements: _.cloneDeep(this.getPlacementIds(reservation.placements))
                  });

                  let treeItems = _.cloneDeep(publisherTreeItems);

                  _.each(publisherTreeItems, publisherTreeItem => {
                    if (publisherTreeItem.item.publisher.publisherId === +publisherId) {
                      treeItems = this.addChildrenToItem(publisherTreeItems, placementTreeItems, publisherTreeItem.id);
                    }
                  });

                  this.placementTreeCopy = treeItems;
                  this.placementSelectedLabels = this.buildSelectedPlacementsLabels(treeItems);

                });

              });


          });
      }
    }

    if (this.isEdit) {
      let currentItem = {};

      if (reservation.trade) {
        currentItem['trade'] = {
          id: reservation.trade.id,
          label: reservation.trade.name
        };
        this.packageForm.controls['trade'].setValue(currentItem['trade']);
      }

      if (reservation.brand) {
        currentItem['brand'] = {
          id: reservation.brand.id,
          label: reservation.brand.name
        };

        this.packageForm.controls['brand'].setValue(currentItem['brand']);
      }

      if (reservation.title) {
        currentItem['title'] = {
          id: reservation.title.id,
          label: reservation.title.name
        };

        this.packageForm.controls['title'].setValue(currentItem['title']);
      }

      if (reservation.salesModel) {
        currentItem['salesModel'] = {
          id: reservation.salesModel.id,
          label: reservation.salesModel.name
        };

        this.packageForm.controls['salesModel'].setValue(currentItem['salesModel']);
      }

      if (reservation.salesman) {
        currentItem['salesman'] = {
          id: reservation.salesman.id,
          label: reservation.salesman.name
        };

        this.packageForm.controls['salesman'].setValue(currentItem['salesman']);
      }

      if (reservation.principal) {
        currentItem['principal'] = {
          id: reservation.principal.id,
          label: reservation.principal.name
        };

        this.packageForm.controls['principal'].setValue(currentItem['principal']);
      }

      if (reservation.paymentType) {
        currentItem['paymentType'] = {
          id: reservation.paymentType.id,
          label: reservation.paymentType.name
        };

        this.packageForm.controls['paymentType'].setValue(currentItem['paymentType']);
      }

      if (reservation.placementCategory) {
        currentItem['package'] = {
          id: this.reservationItemData.placementCategory.placementCategoryId,
          label: this.reservationItemData.placementCategory.name
        };
        this.packageForm.controls['package'].setValue(currentItem['package']);
      }

      if (reservation.orderForm) {
        currentItem['orderForm'] = {
          id: reservation.orderForm.id,
          label: reservation.orderForm.name
        };

        this.packageForm.controls['orderForm'].setValue(currentItem['orderForm']);
      }

      if (reservation.format) {
        currentItem['format'] = {
          id: reservation.format.id,
          label: reservation.format.name
        };

        this.packageForm.controls['format'].setValue(currentItem['format']);
      }

      if (reservation.client) {
        currentItem['client'] = {
          id: reservation.client.id,
          label: reservation.client.name
        };

        this.packageForm.controls['client'].setValue(currentItem['client']);
      }

      if (reservation.campaignType) {
        currentItem['campaignType'] = {
          id: reservation.campaignType.id,
          label: reservation.campaignType.name
        };

        this.packageForm.controls['campaignType'].setValue(currentItem['campaignType']);
      }

      if (reservation.salesman) {
        currentItem['salesman'] = {
          id: reservation.salesman.id,
          label: reservation.salesman.name
        };

        this.packageForm.controls['salesman'].setValue(currentItem['salesman']);
      }

      if (this.packageForm.controls['placement']) {
        this.packageForm.controls['placement'].setValue(this.getPlacementIds(reservation.placements));
      }
      if (this.packageForm.controls['publisher']) {
        this.packageForm.controls['publisher'].setValue(this.getPublisherIds(reservation.publishers));
      }
    }

    this.watchChangesForBasePrice();
  }


  watchChangesForBasePrice() {
    if (this.packageForm.controls['salesModel']) {
      this.packageForm.controls['salesModel'].valueChanges.pipe(debounceTime(500)).subscribe(salesModel => {
        this.calculateBasePrice();
      });
    }

    if (this.packageForm.controls['publisher']) {
      this.packageForm.controls['publisher'].valueChanges.pipe(debounceTime(500)).subscribe(publisher => {
        this.calculateBasePrice();
      });
    }

    if (this.packageForm.controls['package']) {
      this.packageForm.controls['package'].valueChanges.pipe(debounceTime(500)).subscribe(() => {
        this.calculateBasePrice();
      });
    }

    if (this.creationFilter) {
      this.creationFilter.valueChanges.pipe(debounceTime(500)).subscribe(creation => {
        this.calculateBasePrice();
      });
    }

    if (this.packageForm.controls['orderForm']) {
      this.packageForm.controls['orderForm'].valueChanges.pipe(debounceTime(500)).subscribe(orderForm => {
        this.calculateBasePrice();
      });
    }

    if (this.packageForm.controls['emissionsNumber']) {
      this.packageForm.controls['emissionsNumber'].valueChanges.pipe(debounceTime(500)).subscribe(emissionsNumber => {
        this.calculateBasePrice();
      });
    }
  }

  buildDiscounts(discountType, isEditBuild?) {
    this.showDiscountFields = false;

    const discountsCurrent = this.reservationItemData && this.reservationItemData.discounts ? this.reservationItemData.discounts : null;
    const discountBuilder = new ReservationDiscountBuilder(discountType, discountsCurrent);


    let discounts: any = discountBuilder.buildDiscounts();


    if (!isEditBuild) {
      // this.packageForm.controls['fixedPrice'].setValue('');
    }
    if (!discountType || discountType && !discountType.length) {
      this.showFixedPrice = false;
      this.showValuePrice = false;
      const priceListPrice = this.packageForm.controls['priceListPrice'].value;
      this.packageForm.controls['value'].setValue(priceListPrice);


      setTimeout(() => {
        this.showFixedPrice = true;
        this.showValuePrice = true;
      }, 200)
    }
    this.discounts = discounts;
    setTimeout(() => {
      this.showDiscountFields = true;

    }, 200);
  }

  getPublisherIds(publishers: Array<Publisher>): Array<number> {
    let ids = [];
    if (publishers && _.isArray(publishers) && publishers.length) {
      ids = _.map(publishers, 'publisherId');
    }

    return ids;
  }

  getPlacementIds(placements: Array<Placement>): Array<number> {
    let ids = [];
    if (placements && _.isArray(placements) && placements.length) {
      ids = _.map(placements, 'placementId');
    }
    return ids;
  }

  getValueBy(items: Array<any>, property: string, itemProperty?: string) {
    let a = _.map(items, (item: any) => {

      if (!itemProperty) {
        return item[property];
      } else {
        if (item[itemProperty]) {
          if (property) {
            return item[itemProperty][property]
          } else {
            return item[itemProperty]
          }
        }
      }
    });

    _.each(items, (item: any) => {
      if (item.children && _.isArray(item.children) && item.children.length) {
        a = _.concat(a, this.getValueBy(item.children, property, itemProperty));
      }
    });

    return _.compact(a);
  }

  openClientAddPopup(): void {
    let dialogRef = this.dialog.open(ClientAddPopupComponent, {
      width: '440px',
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientOptions.push({
          id: result.id,
          label: result.name
        });
        this.packageForm.controls['client'].setValue({
          id: result.id,
          label: result.name
        });
        this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      }
    });
  }

  openBrandAddPopup(): void {
    let dialogRef = this.dialog.open(BrandCreatePopupComponent, {
      width: '440px',
      data: {
        tradeId: this.packageForm.controls['trade'].value.id
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.brandOptions.push({
          id: result.id,
          label: result.name
        });
        this.packageForm.controls['brand'].setValue({
          id: result.id,
          label: result.name
        });
        this.brandOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      }
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  removeSpaces(val: string): number {
    if (val && _.isString(val)) {
      const value = val.replace(',', '.').split(' ').join('');
      return +value;
    } else if (_.isNumber(val)) {
      return val;
    } else {
      return 0;
    }
  }

  save(): void {
    this.selectedPlacementsPlaceholderControl.markAsTouched();
    if (this.packageForm.valid) {
      const request = {
        client: this.packageForm.controls['client'].value.id,
        dateFrom: undefined,
        dateTo: undefined,
        placementCategory: null,
        placements: null,
        publisher: undefined,
        publishers: undefined,
        emissionsNumber: undefined,
        brand: this.packageForm.controls['brand'].value.id,
        orderForm: undefined,
        campaignType: undefined,
        paymentType: this.packageForm.controls['paymentType'].value.id,
        salesModel: undefined,
        salesman: this.packageForm.controls['salesman'].value.id,
        trade: this.packageForm.controls['trade'].value.id,
        principal: this.packageForm.controls['principal'].value.id,
        comment: this.packageForm.controls['comment'].value,
        materialsDeliveryTime: this.packageForm.controls['materialsDeliveryTime'].value,
        capping: undefined,
        targetUrl: undefined,
        value: this.removeSpaces(this.packageForm.controls['value'].value),
        format: undefined,
        priceListPrice: undefined,
        fixedPrice: undefined,
        title: undefined,
        type: undefined,
        discounts: undefined,
        technicalCosts: undefined,
        editionNumber: undefined,
        editionYear: undefined,
        creationId: undefined,
        selectedPlacementsText: undefined,
        paymentPartition: undefined
      };

      if (this.selectedType === 'package') {
        request.placementCategory = this.packageForm.controls['package'].value.id;
        request.placements = undefined;
        request.emissionsNumber = this.removeSpaces(this.packageForm.controls['emissionsNumber'].value);
        request.orderForm = this.packageForm.controls['orderForm'].value ? this.packageForm.controls['orderForm'].value.id : null;
        request.salesModel = this.packageForm.controls['salesModel'].value ? this.packageForm.controls['salesModel'].value.id : null;
        request.campaignType = this.packageForm.controls['campaignType'].value ? this.packageForm.controls['campaignType'].value.id : null;
        request.capping = this.removeSpaces(this.packageForm.controls['capping'].value);
        request.targetUrl = this.packageForm.controls['targetUrl'].value;
        request.dateFrom = new DatePipe('pl-PL').transform(this.packageForm.controls['dateFrom'].value, 'yyyy-MM-dd HH:mm:ss');
        request.dateTo = new DatePipe('pl-PL').transform(this.packageForm.controls['dateTo'].value, 'yyyy-MM-dd HH:mm:ss');
        request.creationId = this.creation ? this.creation.id : null;
        request.priceListPrice = this.packageForm.controls['priceListPrice'].value;
        request.fixedPrice = this.removeSpaces(this.packageForm.controls['fixedPrice'].value);
        request.paymentPartition = this.paymentData;

        if (this.discountTypeControl.value && this.discountTypeControl.value.length) {
          request.discounts = _.map(this.discounts, discount => {
            if (discount.type === '0' || discount.type === '2') {
              discount.percentValue = discount.formControl.value;
            } else {
              discount.amountValue = this.removeSpaces(discount.formControl.value);
            }

            return new DiscountModel(discount);
          });
        }

        const requestModel = new ReservationRequestModel(request, this.transformerService).getRequestModel('package');

        this.reservationService.savePackage(requestModel).subscribe((response: Response) => {
          this.onSaved.emit(response['data']);
          this.reservationService.onCreateOrEdit$.emit(true);
        }, err => {
          this.errorManager.displayError(err.error);
        });
      }

      if (this.selectedType === 'placement') {
        const placementsIds = this.packageForm.controls['placement'].value;
        const publisherIds = this.packageForm.controls['publisher'].value;
        request.emissionsNumber = this.removeSpaces(this.packageForm.controls['emissionsNumber'].value);
        request.orderForm = this.packageForm.controls['orderForm'].value ? this.packageForm.controls['orderForm'].value.id : null;
        request.salesModel = this.packageForm.controls['salesModel'].value ? this.packageForm.controls['salesModel'].value.id : null;
        request.campaignType = this.packageForm.controls['campaignType'].value ? this.packageForm.controls['campaignType'].value.id : null;
        request.capping = this.removeSpaces(this.packageForm.controls['capping'].value);
        request.targetUrl = this.packageForm.controls['targetUrl'].value;
        request.dateFrom = new DatePipe('pl-PL').transform(this.packageForm.controls['dateFrom'].value, 'yyyy-MM-dd HH:mm:ss');
        request.dateTo = new DatePipe('pl-PL').transform(this.packageForm.controls['dateTo'].value, 'yyyy-MM-dd HH:mm:ss');

        request.placements = placementsIds.join(',');
        request.placementCategory = undefined;
        request.publishers = publisherIds.join(',');
        request.creationId = this.creation ? this.creation.id : null;
        request.selectedPlacementsText = this.placementSelectedLabels;
        request.priceListPrice = this.packageForm.controls['priceListPrice'].value;
        request.fixedPrice = this.removeSpaces(this.packageForm.controls['fixedPrice'].value);
        request.paymentPartition = this.paymentData;

        if (this.discountTypeControl.value && this.discountTypeControl.value.length) {
          request.discounts = _.map(this.discounts, discount => {
            if (discount.type === '0' || discount.type === '2') {
              discount.percentValue = discount.formControl.value;
            } else {
              discount.amountValue = this.removeSpaces(discount.formControl.value);
            }

            return new DiscountModel(discount);
          });
        }

        const requestModel = new ReservationRequestModel(request, this.transformerService).getRequestModel('placement');

        this.reservationService.saveSingle(requestModel).subscribe((response: Response) => {
          this.onSaved.emit(response['data']);
          this.reservationService.onCreateOrEdit$.emit(true);
        }, err => {
          this.errorManager.displayError(err.error);
        });
      }

      if (this.selectedType === 'print') {
        request.materialsDeliveryTime = this.packageForm.controls['materialsDeliveryTime'].value;
        request.title = this.packageForm.controls['title'].value.id;
        request.format = this.packageForm.controls['format'].value.id;
        request.priceListPrice = this.packageForm.controls['priceListPrice'].value;
        request.fixedPrice = this.removeSpaces(this.packageForm.controls['fixedPrice'].value);
        request.type = this.packageForm.controls['type'].value.id;
        request.technicalCosts = this.removeSpaces(this.packageForm.controls['technicalCosts'].value);
        request.editionNumber = this.packageForm.controls['editionNumber'].value ? this.packageForm.controls['editionNumber'].value.editionNumber : null;
        request.editionYear = this.packageForm.controls['editionNumber'].value ? this.packageForm.controls['editionNumber'].value.editionYear : null;
        if (this.discountTypeControl.value && this.discountTypeControl.value.length) {
          request.discounts = _.map(this.discounts, discount => {
            if (discount.type === '0' || discount.type === '2') {
              discount.percentValue = discount.formControl.value;
            } else {
              discount.amountValue = this.removeSpaces(discount.formControl.value);
            }

            return new DiscountModel(discount);
          });
        }

        const requestModel = new ReservationRequestModel(request, this.transformerService).getRequestModel(this.selectedType);

        this.reservationService.savePrint(requestModel).subscribe((response: Response) => {
          this.onSaved.emit(response['data']);
          this.reservationService.onCreateOrEdit$.emit(true);
        }, err => {
          this.errorManager.displayError(err.error);
        });
      }
    } else {
      this.validateAllFormFields(this.packageForm);
    }
  }

  update(): void {
    if (this.packageForm.valid) {
      const request = {
        reservationId: undefined,
        id: undefined,
        client: this.packageForm.controls['client'].value.id,
        dateFrom: undefined,
        dateTo: undefined,
        placementCategory: null,
        placements: null,
        publisher: undefined,
        emissionsNumber: undefined,
        brand: this.packageForm.controls['brand'].value.id,
        orderForm: undefined,
        campaignType: undefined,
        paymentType: this.packageForm.controls['paymentType'].value.id,
        salesModel: undefined,
        salesman: this.packageForm.controls['salesman'].value.id,
        trade: this.packageForm.controls['trade'].value.id,
        principal: this.packageForm.controls['principal'].value.id,
        comment: this.packageForm.controls['comment'].value,
        materialsDeliveryTime: undefined,
        capping: undefined,
        targetUrl: undefined,
        value: this.removeSpaces(this.packageForm.controls['value'].value),
        format: undefined,
        priceListPrice: undefined,
        fixedPrice: undefined,
        title: undefined,
        type: undefined,
        discounts: undefined,
        publishers: undefined,
        status: undefined,
        technicalCosts: undefined,
        editionNumber: undefined,
        editionYear: undefined,
        creationId: undefined,
        selectedPlacementsText: undefined,
        paymentPartition: undefined
      };

      request.reservationId = this.reservationItemData.id;
      request.status = this.reservationItemData.status;

      if (this.discountTypeControl.value && this.discountTypeControl.value.length) {
        request.discounts = _.map(this.discounts, discount => {
          if (discount.type === '0' || discount.type === '2') {
            discount.percentValue = discount.formControl.value;
          } else {
            discount.amountValue = this.removeSpaces(discount.formControl.value);
          }

          return new DiscountModel(discount);
        });
      }

      if (this.selectedType === 'package') {
        request.placementCategory = this.packageForm.controls['package'].value.id;
        request.placements = undefined;
        request.emissionsNumber = this.removeSpaces(this.packageForm.controls['emissionsNumber'].value);
        request.orderForm = this.packageForm.controls['orderForm'].value ? this.packageForm.controls['orderForm'].value.id : null;
        request.salesModel = this.packageForm.controls['salesModel'].value ? this.packageForm.controls['salesModel'].value.id : null;
        request.campaignType = this.packageForm.controls['campaignType'].value ? this.packageForm.controls['campaignType'].value.id : null;
        request.capping = this.removeSpaces(this.packageForm.controls['capping'].value);
        request.targetUrl = this.packageForm.controls['targetUrl'].value;
        request.dateFrom = new DatePipe('pl-PL').transform(this.packageForm.controls['dateFrom'].value, 'yyyy-MM-dd HH:mm:ss');
        request.dateTo = new DatePipe('pl-PL').transform(this.packageForm.controls['dateTo'].value, 'yyyy-MM-dd HH:mm:ss');
        request.creationId = this.creation ? this.creation.id : null;
        request.priceListPrice = this.packageForm.controls['priceListPrice'].value;
        request.fixedPrice = this.removeSpaces(this.packageForm.controls['fixedPrice'].value);
        request.paymentPartition = this.paymentData;
      }

      if (this.selectedType === 'placement') {
        const placementsIds = this.packageForm.controls['placement'].value;
        request.placements = placementsIds.join(',');
        request.placementCategory = undefined;
        const publishersIds = this.packageForm.controls['publisher'].value;
        request.publishers = publishersIds.join(',');
        request.emissionsNumber = this.removeSpaces(this.packageForm.controls['emissionsNumber'].value);
        request.orderForm = this.packageForm.controls['orderForm'].value ? this.packageForm.controls['orderForm'].value.id : null;
        request.salesModel = this.packageForm.controls['salesModel'].value ? this.packageForm.controls['salesModel'].value.id : null;
        request.campaignType = this.packageForm.controls['campaignType'].value ? this.packageForm.controls['campaignType'].value.id : null;
        request.capping = this.removeSpaces(this.packageForm.controls['capping'].value);
        request.targetUrl = this.packageForm.controls['targetUrl'].value;
        request.dateFrom = new DatePipe('pl-PL').transform(this.packageForm.controls['dateFrom'].value, 'yyyy-MM-dd HH:mm:ss');
        request.dateTo = new DatePipe('pl-PL').transform(this.packageForm.controls['dateTo'].value, 'yyyy-MM-dd HH:mm:ss');
        request.creationId = this.creation ? this.creation.id : null;
        request.selectedPlacementsText = this.placementSelectedLabels;
        request.priceListPrice = this.packageForm.controls['priceListPrice'].value;
        request.fixedPrice = this.removeSpaces(this.packageForm.controls['fixedPrice'].value);
        request.paymentPartition = this.paymentData;
      }

      if (this.selectedType === 'print') {
        request.title = this.packageForm.controls['title'].value.id;
        request.materialsDeliveryTime = this.packageForm.controls['materialsDeliveryTime'].value;
        request.format = this.packageForm.controls['format'].value.id;
        request.priceListPrice = this.removeSpaces(this.packageForm.controls['priceListPrice'].value);
        request.fixedPrice = this.removeSpaces(this.packageForm.controls['fixedPrice'].value);
        request.type = this.packageForm.controls['type'].value.id;
        request.technicalCosts = this.removeSpaces(this.packageForm.controls['technicalCosts'].value);
        request.editionNumber = this.packageForm.controls['editionNumber'].value ? this.packageForm.controls['editionNumber'].value.editionNumber : null;
        request.editionYear = this.packageForm.controls['editionNumber'].value ? this.packageForm.controls['editionNumber'].value.editionYear : null;

        if (this.discountTypeControl.value && this.discountTypeControl.value.length) {
          request.discounts = _.map(this.discounts, discount => {
            if (discount.type === '0' || discount.type === '2') {
              discount.percentValue = discount.formControl.value;
            } else {
              discount.amountValue = this.removeSpaces(discount.formControl.value);
            }

            return new DiscountModel(discount);
          });
        }

        const requestModel = new ReservationRequestModel(request, this.transformerService).getRequestModel(this.selectedType);

        this.reservationService.updatePrint(requestModel).subscribe((response: Response) => {
          this.onSaved.emit(response['data']);
          this.reservationService.onCreateOrEdit$.emit(true);
        }, err => {
          this.errorManager.displayError(err.error);
        });
      } else {
        request.paymentPartition = this.paymentData;

        const requestModel = new ReservationRequestModel(request, this.transformerService).getRequestModel(this.selectedType);

        this.reservationService.updateReservation(requestModel).subscribe((response: Response) => {
          this.onSaved.emit(response['data']);
          this.reservationService.onCreateOrEdit$.emit(true);
        }, err => {
          this.errorManager.displayError(err.error);
        });
      }
    } else {
      this.validateAllFormFields(this.packageForm);
    }
  }

  showPlacementSelectPopup() {
    let dialogRef = this.dialog.open(SelectPlacementPopupComponent, {
      width: '540px',
      data: {
        publishers: this.publisherListData,
        selectedPublishers: this.packageForm.controls['publisher'].value,
        selectedPlacements: this.packageForm.controls['placement'].value,
        placementTreeCopy: this.placementTreeCopy,
        creation: this.reservationItemData ? this.reservationItemData.creation : {id: this.creation ? this.creation.id : null}
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.packageForm.controls['publisher'].setValue(result.selectedPublishers);
        this.packageForm.controls['placement'].setValue(result.selectedPlacements);
        this.placementTreeCopy = result.treeItems;
        if (!this.creation) {
          this.creation = {};
        }
        this.creation.id = result.creationId;

        this.placementSelectedLabels = this.buildSelectedPlacementsLabels(result.treeItems);
      }
    });
  }

  buildSelectedPlacementsLabels(treeItems) {
    const labels = [];
    _.each(treeItems, item => {
      if (item.selected) {
        const label = item.label;
        if (this.checkIsNotSelectedPlacementExists(item.children)) {
          _.each(item.children, itemChild => {
            if (itemChild.selected) {
              const label2 = `${label} :: ${itemChild.label}`;
              if (this.checkIsNotSelectedPlacementExists(itemChild.children)) {
                _.each(itemChild.children, itemChildChild => {
                  if (itemChildChild.selected) {
                    const label3 = `${label2} :: ${itemChildChild.label}`;
                    if (this.checkIsNotSelectedPlacementExists(itemChildChild.children)) {
                      _.each(itemChildChild.children, itemChildChildChild => {
                        if (itemChildChildChild.selected) {
                          const label4 = `${label3} :: ${itemChildChildChild.label}`;
                          labels.push(label4);
                        }
                      });
                      if (!_.find(itemChildChild.children, 'selected')) {
                        labels.push(label3);
                      }
                    } else {
                      labels.push(label3);
                    }
                  }
                });

                if (!_.find(itemChild.children, 'selected')) {
                  labels.push(label2);
                }
              } else {
                labels.push(label2);
              }
            }
          });
        } else {
          labels.push(label);
        }
      }
    });
    return labels;
  };

  checkIsNotSelectedPlacementExists(treeItems) {
    let hasNotSelectedItem = !!_.find(treeItems, (item) => {
      return !item.selected;
    });

    if (hasNotSelectedItem) {
      return hasNotSelectedItem;
    }

    _.each(treeItems, item => {
      hasNotSelectedItem = this.checkIsNotSelectedPlacementExists(item.children);
      if (hasNotSelectedItem) {
        return false;
      }
    });
    return hasNotSelectedItem;
  }

  getPlacementTreeItems(items, isPublisher?: boolean, selectedItems?, setSelectedChildren?: boolean): Array<TreeItemModel> {
    if (isPublisher) {
      items = _.map(items, (item: any) => {
        let children: Array<TreeItemModel> = [];
        if (item.children && _.isArray(item.children) && item.children.length) {
          children = this.getPlacementTreeItems(item.children, false, selectedItems, setSelectedChildren);
        }

        const itemData: TreeItemModel = {
          type: null,
          label: item.name,
          children: children,
          icon: 'web',
          item: {
            publisher: item
          },
          buttons: [],
          hasChildrenItem: true,
          parentItem: null,
          onExpandAction: 'getPlacementsByPublisher'
        };
        if (selectedItems && selectedItems.selectedPublishers && _.isArray(selectedItems.selectedPublishers) && selectedItems.selectedPublishers.length) {
          itemData.selected = !!_.filter(selectedItems.selectedPublishers, publisher => publisher === item.publisherId).length;
          itemData.expanded = itemData.selected;
        }
        item = new TreeItemModel(itemData);
        return item;
      });
    } else {
      items = _.map(items, (item: any) => {
        let children: Array<TreeItemModel> = [];
        if (item.children && _.isArray(item.children) && item.children.length) {
          children = this.getPlacementTreeItems(item.children, false, selectedItems, setSelectedChildren);
        }

        const itemData: TreeItemModel = {
          type: null,
          label: item.name,
          children: children,
          icon: item.kind === 'LEAF' ? null : 'folder',
          item: {
            placement: item
          },
          buttons: [],
          parentItem: null,
          hasChildrenItem: !!item.children.length,
          selected: true
        };

        if (selectedItems && selectedItems.selectedPlacements && _.isArray(selectedItems.selectedPlacements) && selectedItems.selectedPlacements.length) {
          itemData.selected = !!_.filter(selectedItems.selectedPlacements, placement => placement === item.id).length;
          itemData.expanded = itemData.selected;
        }

        item = new TreeItemModel(itemData);
        return item;
      });
    }

    return items;
  }

  addChildrenToItem(items, childrenItems, treeItemId): Array<TreeItemModel> {
    items = _.map(items, (item: TreeItemModel) => {
      if (item.id === treeItemId) {
        item.children = childrenItems;
      } else {
        if (item.children && _.isArray(item.children) && item.children.length) {
          item.children = this.addChildrenToItem(item.children, childrenItems, treeItemId);
        }
      }

      return item;
    });
    return items;
  }

  calculateDiscount($event) {
    if ($event && $event.preventDefault) {
      $event.preventDefault();
    }
    const request: DiscountCalculateRequestModel = {
      basePrice: this.packageForm.controls['priceListPrice'].value,
      discounts: []
    };

    if (parseFloat(this.packageForm.controls['fixedPrice'].value)) {
      request.basePrice = this.removeSpaces(this.packageForm.controls['fixedPrice'].value);
    }

    _.each(this.discounts, discount => {
      if (discount.type === '0' || discount.type === '2') {
        discount.percentValue = this.removeSpaces(discount.formControl.value);
        if (discount.percentValue) {
          request.discounts.push(new DiscountModel(discount));
        }
      } else {
        discount.amountValue = this.removeSpaces(discount.formControl.value);
        if (discount.amountValue) {
          request.discounts.push(new DiscountModel(discount));
        }
      }
    });

    this.showFixedPrice = false;
    this.showValuePrice = false;
    if (request.discounts && request.discounts.length) {
      this.discountService.calculateDiscounts(request).subscribe(data => {
        this.packageForm.controls.value.setValue(data && data.basePrice && data.basePrice > 0 ? data.basePrice : 0);
        this.showFixedPrice = true;
        this.showValuePrice = true;

      }, err => {
        this.errorManager.displayError(err.error);
        this.showFixedPrice = true;
        this.showValuePrice = true;
      })
    } else {
      this.packageForm.controls.value.setValue(this.packageForm.controls['priceListPrice'].value);
      setTimeout(() => {
        this.showFixedPrice = true;
        this.showValuePrice = true;
      }, 200);
    }
  }


  buildPackages(packages): Array<IAutocompleteFieldOption> {
    let packagesData: Array<IAutocompleteFieldOption> = [];
    _.each(packages, (packageItem: Package) => {
      if (packageItem.isParent) {
        packagesData.push({
          id: packageItem.placementCategoryId,
          label: packageItem.name,
          children: [],
          additionalData: {
            publisherId: packageItem.publisherId
          }
        });
      }
    });

    packagesData = _.map(packagesData, item => {
      const children = _.filter(packages, {parentCategoryId: item.id});

      item.children = _.map(children, (child: Package) => {
        return {
          id: child.placementCategoryId,
          label: child.name,
          additionalData: {
            publisherId: child.publisherId
          }
        }
      });
      return item;
    });


    const packagesDataSortedByParent: Array<IAutocompleteFieldOption> = [];

    _.each(packagesData, item => {
      packagesDataSortedByParent.push({
        id: item.id,
        label: item.label,
        isIndentation: true,
        additionalData: {
          isParent: true,
          publisherId: item.additionalData.publisherId
        }
      });
      if (item.children && item.children.length) {
        _.each(item.children, itemChild => {
          packagesDataSortedByParent.push({
            id: itemChild.id,
            label: itemChild.label,
            isIndentation: true,
            additionalData: {
              isParent: false,
              publisherId: itemChild.additionalData.publisherId
            }
          });
        })
      }
    });

    return packagesDataSortedByParent;
  }

  paymentDataChange($event): void {
    const dates: Array<DateFromToModel> = $event.dates;
    this.isPaymentValid = $event.isValid;

    if ($event.isValid) {
      this.paymentData = _.map(dates, (date: DateFromToModel) => {
        return {
          date_from: moment(date.monthLabel + '-01').format('YYYY-MM-DD'),
          date_to: moment(date.monthLabel + '-01').endOf('month').format('YYYY-MM-DD'),
          value: ReservationService.removeSpaces(`${date.value}`),
          reservation_id: this.reservationItemData && this.reservationItemData.id ? this.reservationItemData.id : null
        } as PaymentPartitionModel
      });
    }
  }
}
