import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { objectToParams } from '../../shared/utils/object-to-params';
import { CalendarDataRequest } from './model/calendar-data-request.model';

@Injectable()
export class CalendarService {

  constructor(private http: HttpClient) {

  }

  public getCalendarData(request: CalendarDataRequest): Observable<any> {
    let params = objectToParams(request);
    let apiUrl = `${environment.apiUrl}/calendar/?${params}`;

    return this.http.get(apiUrl).pipe(map((response: Response) => {
      return response;
    }));
  }
}
