<ul class="un-styled" *ngIf="!parentItem && selectAllEnabled">
  <li>
    <div (click)="onSelectAll($event)" class="checkbox-fake-disabled">
      <mat-checkbox [(ngModel)]="selectedAll" readonly>
      </mat-checkbox>
    </div>
    <span (click)="onSelectAll($event)"
          class="select-all-button">{{ selectedAll ? 'Odznacz wszystko' : 'Zaznacz wszystko' }}</span>
  </li>
</ul>

<ul *ngFor="let item of itemsData" class="tree-list" [class.expanded]="item.expanded"
    [class.root-item]="!item.parentItem">
  <li>
    <div class="tree-row-header" [class.expandable]="item.hasChildrenItem"
         [class.collapsed]="!item.expanded && item.hasChildrenItem">
      <div>
        <mat-checkbox [(ngModel)]="item.selected" (change)="onLocalSelectedChange($event, item)">
        </mat-checkbox>
        <mat-icon (click)="expandItem(item, $event); item.expanded = !item.expanded; "
                  class="margin-left-md margin-right-sm" *ngIf="item.icon">{{item.icon}}</mat-icon>
        <span (click)="expandItem(item, $event); item.expanded = !item.expanded"
              [class.margin-left-md]="!item.icon">{{ item.label }}</span>

      </div>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="loadingIndication[item.id]"></mat-progress-bar>
    <ep-tree
      *ngIf="item.addEnabled || (item.children && item.children.length > 0) && item.expanded"
      [items]="item.children"
      [parentItem]="item"
      [loadingIndication]="loadingIndication"
      (action)="onLocalAction($event, item)"
      (onSelectedChange)="onSelectedChangeClick($event, item)">
    </ep-tree>
  </li>
</ul>
