import { DecimalPipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import * as _ from 'lodash';

import { ReportGenerateFormComponent } from '../report-generate-form/report-generate-form.component';

import { ReportService } from '../report.service';

@Component({
  selector: 'ep-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent {
  @ViewChild(ReportGenerateFormComponent) childActions: any;

  reportType: number;
  reportFilters: any;
  reportData: any;
  isFormValid: boolean;
  reportInProgress: boolean;
  downloadInProgress: boolean;

  constructor(private decimalPipe: DecimalPipe, private scrollToService: ScrollToService, private reportService: ReportService) {
    this.isFormValid = false;
    this.reportInProgress = false;
    this.downloadInProgress = false;
  }

  isReportData() {
    return (this.reportData);
  }

  isReportDataSummary() {
    return (Object.keys(this.reportData.summary).length);
  }

  isReportBodyEmpty() {
    return (this.reportData && this.reportData.body.length === 0);
  }

  isNotReportBodyEmpty() {
    return (this.reportData && this.reportData.body.length > 0);
  }

  getReportBodyColspan() {
    return (this.reportData) ? this.reportData.header.length : 0;
  }

  onReportFormValidationChange($event): void {
    this.isFormValid = $event;
  }

  onReportChange() {
    this.reportData = null;
  }

  onReportCreated(data): void {
    if (!data) {
      this.reportInProgress = false;

      return;
    }

    this.reportType = data.reportType;
    this.reportFilters = data.filters;

    this.reportService.getReportData(this.reportType, this.reportFilters).subscribe((reportData: any) => {
      this.reportData = reportData.data;
      const headerNotDecimal = {};
      const bannedHeaderToDecimal = [
        'EBR',
        'Numer EBR',
        'Rok',
        'Miesiąc',
        'Numer zlecenia EBR',
        'id'
      ];
      _.each(this.reportData['header'], (header, headerKey) => {
        headerNotDecimal[headerKey] = !!_.find(bannedHeaderToDecimal, val => val === header);
      });
      console.log(headerNotDecimal);
      this.reportData['body'] = _.map(this.reportData['body'], (reportItem, reportItemKey) => {
        let i = 0;
        reportItem = _.mapValues(reportItem, bodyItem => {
          if (_.isNumber(bodyItem) && !headerNotDecimal[i]) {
            bodyItem = this.decimalPipe.transform(bodyItem, '1.2-2', 'pl');
          }
          i++;
          return bodyItem;
        });
        return reportItem;
      });
      this.reportData['summary'] = _.map(this.reportData['summary'], reportItem => {
        if (_.isNumber(reportItem)) {
          reportItem = this.decimalPipe.transform(reportItem, '1.2-2', 'pl');
        }
        return reportItem;
      });
      this.reportInProgress = false;
      this.scrollToFormButtons();
    }, () => {
      this.reportInProgress = false;
    });
  }

  onReportFileGenerated(data) {
    if (!data) {
      this.downloadInProgress = false;

      return;
    }

    const fileUrl = data.fileUrl;

    this.downloadInProgress = false;
    this.reportService.downloadFile(fileUrl);
  }

  createReport() {
    if (this.reportInProgress) {
      return;
    }

    this.reportInProgress = true;
    this.childActions.createReport();
  }

  generateReportFile() {
    if (this.downloadInProgress) {
      return;
    }

    this.downloadInProgress = true;
    this.childActions.generateReportFile();
  }

  private scrollToFormButtons() {
    const config: ScrollToConfigOptions = {
      target: 'form-buttons',
      offset: -20
    };

    this.scrollToService.scrollTo(config);
  }
}
