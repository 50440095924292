import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { objectToParams } from '../../shared/utils/object-to-params';
import { IMerchantDataPageRequest } from './interface/merchant-data-page-request.interface';
import { Merchant } from './model/merchant.model';

@Injectable()
export class MerchantService {

  constructor(private http: HttpClient) {
  }

  public getList(request?: IMerchantDataPageRequest): any {
    let params = objectToParams(request, 'yyyy-MM-dd');
    let apiUrl = `${environment.apiUrl}/merchants/?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response[]) => {
        if (response && response['data']) {
          response['data'].map(item => {
            return new Merchant(item);
          })
        }
        return response;
      }));
  }
}
