import { Injectable, Optional } from '@angular/core';
import * as _ from 'lodash';
import { Publisher } from '../../publisher/model/publisher.model';

@Injectable()
export class PublisherGroupDictionaryModel {
  id: number;
  name: string;
  publishers: Array<Publisher>;

  constructor(@Optional() obj?: any) {
    this.id = obj && obj.id || null;
    this.name = obj && obj.name || null;
    this.publishers = [];
    if (obj.publishers && _.isArray(obj.publishers) && obj.publishers.length) {
      _.each(obj.publishers, item => {
        this.publishers.push(new Publisher(item));
      });
    }
  }
}
