<div class="mat-elevation-z1 publisher-statistic-container" *ngFor="let pubStat of publisherStatistics; let i = index">
  <div class="table-partials">
    <div>
      <div class="reservation-name">Rezerwacja: {{ pubStat.reservationName }}</div>
      <div class="reservation-months">
        <div class="reservation-month-container" *ngFor="let partialReservation of pubStat.partialReservations; let partialReservationIndex = index">
          <div class="reservation-month">{{ partialReservation.partialReservationMonth }}</div>
          <div class="publishers">
            <div *ngFor="let publisher of partialReservation.publishers; let publisherIndex = index">
              <div><strong>serwis:</strong> {{ publisher.publisherName }}</div>
              <div><strong>emisje:</strong> {{ publisher.emissions }}</div>
              <div><strong>wartość</strong>
                {{orderItemData.status}}
                <mat-form-field class="full-width">
                  <input matInput
                         pattern="^([,. \d]+)([,.]\d{2})?$"
                         [epAutoNumeric]="2"
                         placeholder="Wpisz wartość"
                         [readonly]="canNotEditReservation(publisher)"
                         [formControl]="publisherStatisticsFormControl[partialReservation.partialReservationMonth
            + '|' + partialReservation.partialReservationId + '|' + publisher.publisherName]">
                  <mat-error>
                  <div
                  *ngIf="publisherStatisticsFormControl[partialReservation.partialReservationMonth
            + '|' + partialReservation.partialReservationId + '|' + publisher.publisherName].hasError('pattern')">
                  Podaj liczbę np. 100 lub 0.40
                  </div>
                  </mat-error>
                </mat-form-field>

              </div>
              <div class="margin-vertical-lg">
                <button mat-button mat-raised-button
                  (click)="openSelectTitlePopup(pubStat.reservationId, publisherStatistics, partialReservation.partialReservationId, publisher.publisherName)"
                  [disabled]="canNotEditReservation(publisher)">Wybierz tytuły
                </button>
              </div>
              <div class="publisher-statistic margin-top-lg partition margin-bottom-md"
                   *ngFor="let partition of publisher.partition; let partitionIndex = index">
                <div class="partition-content">
                  <div class="mat-body-2">{{ partition.name }}</div>
                  <mat-form-field class="full-width">
                    <input matInput
                           pattern="^([,. \d]+)([,.]\d{2})?$"
                           [epAutoNumeric]="2"
                           placeholder="Wpisz wartość"
                           [readonly]="canNotEditReservation(publisher)"
                           [formControl]="publisherPartitionStatisticsFormControl[partialReservation.partialReservationMonth + '|' + partialReservation.partialReservationId + '|' + publisher.publisherName + '|' + partition.id]?.formControlValue"
                           (ngModelChange)="publisherFormControlValueChanged(partialReservation, publisher)"
                    >
                    <mat-error>
                      <div
                        *ngIf="publisherPartitionStatisticsFormControl[partialReservation.partialReservationMonth
            + '|' + partialReservation.partialReservationId + '|' + publisher.publisherName + '|' + partition.id]?.formControlValue.hasError('pattern')">
                        Podaj liczbę np. 100 lub 0.40
                      </div>

                      <div
                        *ngIf="publisherPartitionStatisticsFormControl[partialReservation.partialReservationMonth
            + '|' + partialReservation.partialReservationId + '|' + publisher.publisherName + '|' + partition.id]?.formControlValue.hasError('value')">
                        Kwoty dla tytułów są za wysokie
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="action-delete">
                  <button mat-icon-button (click)="deletePartition(i, partialReservationIndex, publisherIndex, partitionIndex)">
                    <mat-icon class="material-icons">delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
