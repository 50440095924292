import { Injectable, Optional } from '@angular/core';
import * as _ from 'lodash';
import { SalesmanDictionaryModel } from './salesman-dictionary.model';

@Injectable()
export class SalesmanDictionaryWrapperModel {
  currentUser: any;
  items: Array<SalesmanDictionaryModel>;

  constructor(@Optional() obj?: any) {
    this.currentUser = obj && obj.currentUser || null;
    this.items = [];
    if (obj.items && _.isArray(obj.items) && obj.items.length) {
      _.each(obj.items, item => {
        this.items.push(new SalesmanDictionaryModel(item));
      });
    }
  }
}
