import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import * as _ from 'lodash';

import { OrderPublisherStatisticsComponent } from '../../order-publisher-statistics/order-publisher-statistics.component';

import { ToasterService } from '../../../../shared/utils/toaster.service';

import { PublisherStatisticLocalModel } from '../model/publisher-statistic-local.model';
import { PublisherStatisticPartialReservationModel } from '../model/publisher-statistic-partial-reservation.model';
import { PublisherStatisticPublisherModel } from '../model/publisher-statistic-publisher.model';


@Component({
  selector: 'ep-publisher-statistics-popup',
  templateUrl: './publisher-statistics-popup.component.html',
  styleUrls: ['./publisher-statistics-popup.component.scss']
})
export class PublisherStatisticsPopupComponent {
  public orderItem: any;
  public isCoordinatorView: boolean;

  private requestStatistics: any;

  @ViewChild(OrderPublisherStatisticsComponent) private orderPublisherStatisticsComponent: OrderPublisherStatisticsComponent;

  constructor(
    public dialogRef: MatDialogRef<PublisherStatisticsPopupComponent>,
    private toasterService: ToasterService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.orderItem = _.merge({}, this.data.orderItem);
    this.isCoordinatorView = this.data.isCoordinatorView;
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public closeWithSelected(): void {
    if (this.orderPublisherStatisticsComponent.isNotPublisherStatisticsValid()) {
      this.toasterService.displayMessage('Niektóre z kwot wprowadzone dla tytułów przekraczają wartość rezerwacji cząstkowej. Popraw kwoty aby zatwierdzić.');

      return;
    }

    this.onChangeOrderItem(this.orderPublisherStatisticsComponent.getCurrentPublisherStatistics());

    this.dialogRef.close({
      requestStatistics: _.merge({}, this.requestStatistics)
    });
  }

  public onChangeOrderItem(publisherStatistics: Array<PublisherStatisticLocalModel>): void {
    const requestStatistics: any = {};
    _.forEach(publisherStatistics, (publisherStatistic: PublisherStatisticLocalModel) => {
      requestStatistics[publisherStatistic.reservationId] = {
        reservationName: publisherStatistic.reservationName,
        partialReservations: {}
      };

      _.forEach(publisherStatistic.partialReservations, (partialReservation: PublisherStatisticPartialReservationModel) => {
        requestStatistics[publisherStatistic.reservationId].partialReservations[partialReservation.partialReservationMonth] = {
          partialReservationId: partialReservation.partialReservationId,
          publishers: {}
        };

        _.forEach(partialReservation.publishers, (publisher: PublisherStatisticPublisherModel) => {
          requestStatistics
            [publisherStatistic.reservationId]
            .partialReservations[partialReservation.partialReservationMonth]
            .publishers[publisher.publisherName] = {
            editable: publisher.editable,
            emissions: publisher.emissions,
            exported: publisher.exported,
            partial_value: this.removeSpaces(`${publisher.partial_value}`),
            partition: {}
          };

          _.forEach(publisher.partition, (partition: {id: string, value: number }) => {
            requestStatistics
              [publisherStatistic.reservationId]
              .partialReservations[partialReservation.partialReservationMonth]
              .publishers[publisher.publisherName].partition[partition.id] = this.removeSpaces(`${partition.value}`);
          });
        });
      })
    });

    this.requestStatistics = _.merge({}, requestStatistics);
  }

  private removeSpaces(val: string): number {
    if (val && _.isString(val)) {
      const value = val.replace(',', '.').split(' ').join('');
      return +value;
    } else if (_.isNumber(val)) {
      return val;
    } else {
      return 0;
    }
  }
}
