<div *ngIf="!selectedReservation">
  <ep-calendar-reservation-list-item *ngFor="let reservation of reservationList" [item]="reservation"
                                     (onShowDetails)="onShowDetails($event)"></ep-calendar-reservation-list-item>
</div>

<div *ngIf="selectedReservation" [@animationLoading]>
  <a class="button-link" (click)="showMain()">Wstecz</a>

  <ep-calendar-reservation-list-item *ngFor="let reservation of selectedReservation.children"
                                     [item]="reservation"></ep-calendar-reservation-list-item>

  <a class="button-link" (click)="showMain()">Wstecz</a>
</div>
