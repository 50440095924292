<form [formGroup]="reportForm">
  <mat-form-field class="full-width">
    <mat-select placeholder="Typ" [formControl]="reportForm.controls['reportType']">
      <mat-option *ngFor="let type of typeOptions" [value]="type">
        {{ type.label }}
      </mat-option>
    </mat-select>

    <mat-error>
      <div *ngIf="reportForm.controls['reportType'].hasError('required')">Pole wymagane</div>
    </mat-error>
  </mat-form-field>

  <div *ngIf="reportForm.controls['reportType'].value">
    <mat-form-field *ngIf="canShowFormField('dateYear')">
      <mat-select placeholder="Rok" [formControl]="reportForm.controls['dateYear']" [(ngModel)]="dateYearModel">
        <mat-option *ngFor="let year of years" [value]="year">
          {{ year }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ep-autocomplete-field
      *ngIf="canShowFormField('titleGroup')"
      [(selected)]="reportForm.controls['titleGroup']"
      [options]="titleGroupOptions"
      (onChangeSearchText)="eventListeners.onChangeTitleGroupSearchText($event)"
      [optionsStatus]="titleGroupOptionsStatus"
      [disabled]="!!reportForm.controls['title'].value"
      placeholder="Grupa tytułów"></ep-autocomplete-field>

    <ep-autocomplete-field
      *ngIf="canShowFormField('title')"
      [(selected)]="reportForm.controls['title']"
      [options]="titleOptions"
      (onChangeSearchText)="eventListeners.onChangeTitleSearchText($event)"
      [optionsStatus]="titleOptionsStatus"
      [disabled]="!!reportForm.controls['titleGroup'].value"
      placeholder="Tytuł"></ep-autocomplete-field>

    <div class="padding-vertical-md" *ngIf="reportForm.controls['title'].value">
      <mat-slide-toggle [formControl]="reportByEditionRangeControl">Raportuj wg. numerów wydania zamiast dat</mat-slide-toggle>
    </div>
    <div *ngIf="isHandledByEdition && reportForm.controls['title'].value">
      <mat-form-field *ngIf="canShowFormField('editionFrom')">
        <mat-select placeholder="Edycja od" [formControl]="reportForm.controls['editionFrom']">
          <mat-option *ngFor="let titleEdition of titleEditionOptions" [value]="titleEdition">
            {{ titleEdition.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="canShowFormField('editionTo')">
        <mat-select placeholder="Edycja do" [formControl]="reportForm.controls['editionTo']">
          <mat-option *ngFor="let titleEdition of titleEditionOptions" [value]="titleEdition">
            {{ titleEdition.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="!isHandledByEdition">
      <mat-form-field *ngIf="canShowFormField('dateFrom')">
        <input matInput [matDatepicker]="dateFrom" placeholder="Data od" [formControl]="reportForm.controls['dateFrom']">
        <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
        <mat-datepicker #dateFrom></mat-datepicker>
      </mat-form-field>

      <mat-form-field *ngIf="canShowFormField('dateTo')">
        <input matInput [matDatepicker]="dateTo" placeholder="Data do" [formControl]="reportForm.controls['dateTo']">
        <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
        <mat-datepicker #dateTo></mat-datepicker>
      </mat-form-field>
    </div>

    <mat-form-field class="full-width" *ngIf="canShowFormField('status')">
      <mat-select placeholder="Status" [formControl]="reportForm.controls['status']">
        <mat-option *ngFor="let status of statusOptions" [value]="status">
          {{ status.label }}
        </mat-option>
      </mat-select>

      <mat-error>
        <div *ngIf="reportForm.controls['status'].hasError('required')">Pole wymagane</div>
      </mat-error>
    </mat-form-field>


    <!--<ep-autocomplete-field-->
    <!--*ngIf="canShowFormField('ebr')"-->
    <!--[(selected)]="reportForm.controls['ebr']"-->
    <!--[options]="ebrOptions"-->
    <!--[optionsStatus]="ebrOptionsStatus"-->
    <!--[showNoResultButton]="false"-->
    <!--placeholder="Numer zlecenia EBR"></ep-autocomplete-field>-->

    <mat-form-field class="full-width" *ngIf="canShowFormField('ebr')">
      <mat-select placeholder="Numer zlecenia EBR" [formControl]="reportForm.controls['ebr']">
        <mat-option *ngFor="let ebr of ebrOptions" [value]="ebr">
          {{ ebr.label }}
        </mat-option>
      </mat-select>

      <mat-error>
        <div *ngIf="reportForm.controls['ebr'].hasError('required')">Pole wymagane</div>
      </mat-error>
    </mat-form-field>

    <ep-autocomplete-field
      *ngIf="canShowFormField('client')"
      [(selected)]="reportForm.controls['client']"
      [options]="clientOptions"
      (onChangeSearchText)="eventListeners.onChangeClientSearchText($event)"
      [optionsStatus]="clientOptionsStatus"
      placeholder="Klient"></ep-autocomplete-field>

    <ep-autocomplete-field
      *ngIf="canShowFormField('salesman')"
      [(selected)]="reportForm.controls['salesman']"
      [options]="salesmanOptions"
      (onChangeSearchText)="eventListeners.onChangeSalesmanSearchText($event)"
      [optionsStatus]="salesmanOptionsStatus"
      placeholder="Sprzedawca"></ep-autocomplete-field>

    <ep-autocomplete-field
      *ngIf="canShowFormField('principal')"
      [(selected)]="reportForm.controls['principal']"
      [options]="principalOptions"
      (onChangeSearchText)="eventListeners.onChangePrincipalSearchText($event)"
      [showNoResultButton]="false"
      [optionsStatus]="principalOptionsStatus"
      placeholder="Zleceniodawca"></ep-autocomplete-field>

    <!--<ep-autocomplete-field-->
    <!--*ngIf="canShowFormField('rowVariable')"-->
    <!--[(selected)]="reportForm.controls['rowVariable']"-->
    <!--[options]="rowVariableOptions"-->
    <!--(onChangeSearchText)="eventListeners.onChangeRowVariableSearchText($event)"-->
    <!--[showNoResultButton]="false"-->
    <!--[optionsStatus]="rowVariableOptionsStatus"-->
    <!--placeholder="Zmienna w wierszu"></ep-autocomplete-field>-->

    <mat-form-field class="full-width" *ngIf="canShowFormField('rowVariable')">
      <mat-select placeholder="Zmienna w wierszu" [formControl]="reportForm.controls['rowVariable']">
        <mat-option *ngFor="let rowVariable of rowVariableOptions" [value]="rowVariable">
          {{ rowVariable.label }}
        </mat-option>
      </mat-select>

      <mat-error>
        <div *ngIf="reportForm.controls['rowVariable'].hasError('required')">Pole wymagane</div>
      </mat-error>
    </mat-form-field>

    <!--<ep-autocomplete-field-->
    <!--*ngIf="canShowFormField('columnVariable')"-->
    <!--[(selected)]="reportForm.controls['columnVariable']"-->
    <!--[options]="columnVariableOptions"-->
    <!--(onChangeSearchText)="eventListeners.onChangeColumnVariableSearchText($event)"-->
    <!--[showNoResultButton]="false"-->
    <!--[optionsStatus]="rowVariableOptionsStatus"-->
    <!--placeholder="Zmienna w kolumnie">-->

    <mat-form-field class="full-width" *ngIf="canShowFormField('columnVariable')">
      <mat-select placeholder="Zmienna w kolumnie" [formControl]="reportForm.controls['columnVariable']">
        <mat-option *ngFor="let columnVariable of columnVariableOptions" [value]="columnVariable">
          {{ columnVariable.label }}
        </mat-option>
      </mat-select>

      <mat-error>
        <div *ngIf="reportForm.controls['columnVariable'].hasError('required')">Pole wymagane</div>
      </mat-error>
    </mat-form-field>

    <!--<ep-autocomplete-field-->
    <!--*ngIf="canShowFormField('additionalFilter')"-->
    <!--[(selected)]="reportForm.controls['additionalFilter']"-->
    <!--[options]="additionalFilterOptions"-->
    <!--(onChangeSearchText)="eventListeners.onChangeAdditionalFilterSearchText($event)"-->
    <!--[showNoResultButton]="false"-->
    <!--[optionsStatus]="additionalFilterOptionsStatus"-->
    <!--placeholder="Filtr"></ep-autocomplete-field>-->

    <mat-form-field class="full-width" *ngIf="canShowFormField('additionalFilter')">
      <mat-select placeholder="Filtr" [formControl]="reportForm.controls['additionalFilter']">
        <mat-option *ngFor="let additionalFilter of additionalFilterOptions" [value]="additionalFilter">
          {{ additionalFilter.label }}
        </mat-option>
      </mat-select>

      <mat-error>
        <div *ngIf="reportForm.controls['additionalFilter'].hasError('required')">Pole wymagane</div>
      </mat-error>
    </mat-form-field>

    <!--<ep-autocomplete-field-->
    <!--*ngIf="canShowFormField('additionalFilterValue')"-->
    <!--[(selected)]="reportForm.controls['additionalFilterValue']"-->
    <!--[options]="additionalFilterValueOptions"-->
    <!--(onChangeSearchText)="eventListeners.onChangeAdditionalFilterValueSearchText($event)"-->
    <!--[showNoResultButton]="false"-->
    <!--[optionsStatus]="additionalFilterValueOptionsStatus"-->
    <!--placeholder="Wartość filtru"></ep-autocomplete-field>-->

    <mat-form-field class="full-width" *ngIf="canShowFormField('additionalFilterValue')">
      <mat-select placeholder="Wartość filtru" [formControl]="reportForm.controls['additionalFilterValue']">
        <mat-option *ngFor="let additionalFilterValue of additionalFilterValueOptions" [value]="additionalFilterValue">
          {{ additionalFilterValue.label }}
        </mat-option>
      </mat-select>

      <mat-error>
        <div *ngIf="reportForm.controls['additionalFilterValue'].hasError('required')">Pole wymagane</div>
      </mat-error>
    </mat-form-field>
  </div>
</form>
