import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as _ from 'lodash';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AutocompleteFieldOptionStatusEnum } from '../../../shared/component/autocomplete-field/autocomplete-field-option-status.enum';
import { IAutocompleteFieldOption } from '../../../shared/component/autocomplete-field/autocomplete-field-option.interface';
import { TreeItemModel } from '../../../shared/component/tree/tree-item.model';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { DictionaryService } from '../../dictionary/dictionary.service';
import { CreationDictionaryModel } from '../../dictionary/model/creation-dictionary.model';
import { DictionaryRequestModel } from '../../dictionary/model/dictionary-request.model';
import { Placement } from '../../placement/placement.model';
import { PlacementService } from '../../placement/placement.service';
import { PublisherDataPageRequest } from '../../publisher/model/publisher-data-page-request';
import { PublisherService } from '../../publisher/publisher.service';


@Component({
  selector: 'ep-select-placement-popup',
  templateUrl: './select-placement-popup.component.html',
  styleUrls: ['./select-placement-popup.component.scss']
})
export class SelectPlacementPopupComponent {
  placementItems: Array<TreeItemModel>;
  publisherList: Array<any>;
  selectedPublishers: Array<any>;
  selectedPlacements: Array<any>;
  selectedPlacementsLabel: string;
  isLoading: boolean = true;
  isLoadingInTree: boolean = false;
  isLoadingTreeChildrenItems: any = {};

  placementDataPageRequest = {
    filters: {
      creation: this.data.creation ? this.data.creation.id : null,
      placementId: null
    }
  };

  publisherDataPageRequest: PublisherDataPageRequest = new PublisherDataPageRequest({
    filters: {
      creation: this.data.creation ? this.data.creation.id : null
    }
  });

  creationRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  creationOptions: IAutocompleteFieldOption[] = [];
  creationOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  creationFilter: FormControl = new FormControl();
  creationFilterChangesFirstRun: boolean = true;
  creationFilterChangesSubscriber = this.creationFilter.valueChanges.pipe(distinctUntilChanged()).subscribe(creationFilterData => {
    if (_.isObject(creationFilterData) || !creationFilterData) {
      // this.selectedPublishers = [];
      // this.selectedPlacements = [];
      if (this.data.creation && this.data.creation.id && !this.creationFilterChangesFirstRun || !this.data.creation || !this.data.creation.id) {
        this.placementItems = [];
        this.placementDataPageRequest.filters.creation = creationFilterData ? creationFilterData.id : null;
        this.publisherDataPageRequest.filters.creation = creationFilterData ? creationFilterData.id : null;
        this.placementDataPageRequest.filters.placementId = null;
        this.getPublisherDataPage(this.publisherDataPageRequest);
      } else {
        this.creationFilterChangesFirstRun = false;
      }
    }
  });

  constructor(public dialogRef: MatDialogRef<SelectPlacementPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private toaster: ToasterService,
              private placementService: PlacementService,
              private publisherService: PublisherService,
              private dictionaryService: DictionaryService) {
    this.setUpPlacementTree();
    this.getCreations();
  }

  onNoClick(): void {
    this.dialogRef.close(this.data.placementTreeCopy);
  }

  getPublisherDataPage(publisherDataPageRequest?: PublisherDataPageRequest): void {
    this.publisherService.getList(publisherDataPageRequest)
      .pipe(map(data => data ? data['data'] : []))
      .subscribe(publishers => {
        this.publisherList = _.cloneDeep(publishers);
        this.placementItems = this.getPlacementTreeItems(this.publisherList, true, {
          selectedPublishers: _.cloneDeep(this.selectedPublishers),
          selectedPlacements: _.cloneDeep(this.selectedPlacements)
        }, null, null);

        this.isLoading = false;
      });
  }

  setUpPlacementTree() {
    this.selectedPublishers = _.cloneDeep(this.data.selectedPublishers);
    this.selectedPlacements = _.cloneDeep(this.data.selectedPlacements);
    if (this.data.placementTreeCopy) {
      this.placementItems = _.cloneDeep(this.data.placementTreeCopy);
      this.isLoading = false;
    } else {
      this.getPublisherDataPage(this.publisherDataPageRequest);
    }
  }

  getPlacements(publisherId: number, treeItemId, setSelectedChildren?: boolean) {
    this.placementDataPageRequest.filters.placementId = null;
    let placementTreeItems: Array<TreeItemModel> = [];
    this.placementService.getTreePlacements(publisherId, this.placementDataPageRequest.filters.creation).subscribe((placements: Array<Placement>) => {
      const foundParentItem: TreeItemModel = _.find(this.placementItems, (itemTree: TreeItemModel) => {
        if (itemTree.id === treeItemId) {
          return true;
        }
      });

      placementTreeItems = this.getPlacementTreeItems(placements, false, {
        selectedPublishers: _.cloneDeep(this.selectedPublishers),
        selectedPlacements: _.cloneDeep(this.selectedPlacements)
      }, setSelectedChildren, foundParentItem as TreeItemModel);
      this.placementItems = this.addChildrenToItem(this.placementItems, placementTreeItems, treeItemId);
      this.isLoadingTreeChildrenItems[treeItemId] = false;
      this.isLoadingInTree = false;
      this.selectedItems(null);
    });
  }

  getCreations(searchText?: string) {
    if (!searchText || searchText && !searchText['id']) {
      this.creationRequest.filters.name = searchText;

      this.creationOptions = [];
      this.dictionaryService.getCreations(this.creationRequest).subscribe(response => {
        if (response && response.length) {
          const creationOptions = [];
          _.each(response, (val: CreationDictionaryModel) => {
            creationOptions.push({
              id: val.id,
              label: val.name
            });
          });
          this.creationOptions = creationOptions;
          this.creationOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
        } else {
          this.creationOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
        }
        if (this.data.creation) {
          const foundCreation = _.find(this.creationOptions, {id: this.data.creation.id});
          if (foundCreation) {
            this.creationFilter.setValue(foundCreation);
          }
        }
      });
    }
  }

  onChangeCreationSearchText($event) {
    if (_.isString($event) || null) {
      this.getCreations($event);
    }
  }

  addChildrenToItem(items, childrenItems, treeItemId): Array<TreeItemModel> {
    items = _.map(items, (item: TreeItemModel) => {
      if (item.id === treeItemId) {
        item.children = childrenItems;
      } else {
        if (item.children && _.isArray(item.children) && item.children.length) {
          item.children = this.addChildrenToItem(item.children, childrenItems, treeItemId);
        }
      }

      return item;
    });
    return items;
  }

  getPlacementTreeItems(items: Array<any>, isPublisher?: boolean, selectedItems?: any, setSelectedChildren?: boolean, parentItem?: TreeItemModel): Array<TreeItemModel> {
    if (isPublisher) {
      items = _.map(items, (item: any) => {
        let itemData: TreeItemModel = {
          type: null,
          label: item.name,
          children: [],
          icon: 'web',
          item: {
            publisher: item
          },
          buttons: [],
          hasChildrenItem: true,
          parentItem: parentItem,
          onExpandAction: 'getPlacementsByPublisher'
        };

        let children: Array<TreeItemModel> = [];

        if (item.children && _.isArray(item.children) && item.children.length) {
          children = this.getPlacementTreeItems(item.children, false, selectedItems, setSelectedChildren, itemData);
        }
        itemData.children = children;

        if (selectedItems && selectedItems.selectedPublishers && _.isArray(selectedItems.selectedPublishers) && selectedItems.selectedPublishers.length) {
          itemData.selected = !!_.filter(selectedItems.selectedPublishers, publisher => publisher === item.publisherId).length;
        }
        item = new TreeItemModel(itemData);
        return item;
      });
    } else {
      items = _.map(items, (item: any) => {


        let itemData: TreeItemModel = {
          type: null,
          label: item.name,
          children: [],
          icon: item.kind === 'LEAF' ? null : 'folder',
          item: {
            placement: item
          },
          buttons: [],
          parentItem: parentItem,
          hasChildrenItem: !!item.children.length
        };

        let children: Array<TreeItemModel> = [];

        if (item.children && _.isArray(item.children) && item.children.length) {
          children = this.getPlacementTreeItems(item.children, false, selectedItems, setSelectedChildren, itemData);
        }
        itemData.children = children;

        if (selectedItems && selectedItems.selectedPlacements && _.isArray(selectedItems.selectedPlacements) && selectedItems.selectedPlacements.length) {
          itemData.selected = !!_.filter(selectedItems.selectedPlacements, placement => placement === item.id).length;
        }
        if (setSelectedChildren === true) {
          itemData.selected = true;
        }
        item = new TreeItemModel(itemData);
        return item;
      });
    }

    return items;
  }

  treeActions = {
    getPlacementsByPublisher: (publisherId, treeItemId, setSelectedChildren?: boolean) => {
      this.isLoadingTreeChildrenItems[treeItemId] = true;
      this.isLoadingInTree = true;
      this.getPlacements(publisherId, treeItemId, setSelectedChildren);
    }
  };

  selectedItems($event) {
    if ($event && $event.selectedItem.item && $event.selectedItem.item.publisher && $event.selectedItem.selected) {
      this.treeActions['getPlacementsByPublisher']($event.selectedItem.item.publisher.publisherId, $event.selectedItem.id, $event.selectedItem.selected);
    } else {
      this.selectedPublishers = this.getSelectedItemsBy(this.placementItems, 'publisherId', 'publisher');
      this.selectedPlacements = this.getSelectedItemsBy(this.placementItems, 'id', 'placement');
      this.selectedPlacementsLabel = this.getSelectedItemsBy(this.placementItems, 'label').join(', ');
    }
  }

  getAllPublisherPlacements(publisherId: number) {
    this.placementService.getList(publisherId, 1).subscribe(data => {
      this.selectedPlacements = _.concat(this.selectedPlacements, _.map(data, 'id'));
      // this.selectedPlacements
      // this.placementItems = this.addChildrenToItem(this.placementItems, placementTreeItems, treeItemId);
    });
  }

  getSelectedItemsBy(items: Array<TreeItemModel>, property: string, itemProperty?: string) {
    let a = _.map(items, (item: TreeItemModel) => {
      if (item.selected) {
        if (!itemProperty) {
          return item[property];
        } else {
          if (item.item[itemProperty]) {
            if (property) {
              return item.item[itemProperty][property]
            } else {
              return item.item[itemProperty]
            }
          }
        }
      }
    });
    _.each(items, (item: TreeItemModel) => {
      if (item.children && _.isArray(item.children) && item.children.length) {
        a = _.concat(a, this.getSelectedItemsBy(item.children, property, itemProperty));
      }
    });

    return _.compact(a);
  }

  treeAction($event) {
    if ($event.selectedItem.hasChildrenItem) {
      if ($event.actionName === 'getPlacementsByPublisher') {
        this.treeActions[$event.actionName]($event.selectedItem.item.publisher.publisherId, $event.selectedItem.id);
      }
    }
  }

  closeWithSelected() {
    this.selectedPlacementsLabel = this.getSelectedItemsBy(this.placementItems, 'label').join(', ');
    this.dialogRef.close({
      selectedPlacements: _.cloneDeep(this.selectedPlacements),
      selectedPublishers: _.cloneDeep(this.selectedPublishers),
      selectedPlacementsLabel: this.selectedPlacementsLabel,
      treeItems: _.cloneDeep(this.placementItems),
      creationId: this.placementDataPageRequest.filters.creation
    });
  }

  cancel() {
    this.dialogRef.close({
      selectedPlacements: _.cloneDeep(this.data.selectedPlacements),
      selectedPublishers: _.cloneDeep(this.data.selectedPublishers),
      selectedPlacementsLabel: this.getSelectedItemsBy(this.data.placementTreeCopy, 'label').join(', '),
      treeItems: _.cloneDeep(this.data.placementTreeCopy)
    });
  }

  ngOnDestroy() {
    if (this.creationFilterChangesSubscriber && this.creationFilterChangesSubscriber.unsubscribe) {
      this.creationFilterChangesSubscriber.unsubscribe();
    }
  }
}
