import { IDataPageRequest } from '../../../core/interface/data-page-request';
import { ReservationDataPageFilters } from './reservation-data-page-filters';

export interface IReservationDataPageRequest extends IDataPageRequest {

}

export class ReservationDataPageRequest implements IReservationDataPageRequest {
  public page: number;
  public limit: number;
  public filters: ReservationDataPageFilters;
  private offset: number;

  constructor(obj?: any) {
    this.page = obj && obj.page || 1;
    this.limit = obj && obj.limit || 200;
    if (obj && obj.filters) {
      this.filters = new ReservationDataPageFilters(obj.filters);
    }
  }

  setPageByOffset(offset) {
    this.page = offset + 1;
  }

  setPage(page: number) {
    this.page = page;
    this.offset = page - 1;
  }

  setLimit(limit: number) {
    this.limit = limit;
  }

  setFilters(filters) {
    this.filters = new ReservationDataPageFilters(filters);
  }
}
