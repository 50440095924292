import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material';
import * as _ from 'lodash';
import { AutocompleteFieldOptionStatusEnum } from '../../../shared/component/autocomplete-field/autocomplete-field-option-status.enum';

import { IAutocompleteFieldOption } from '../../../shared/component/autocomplete-field/autocomplete-field-option.interface';
import { IAutocompleteMultiselectOption } from '../../../shared/component/autocomplete-multiselect-field/autocomplete-multiselect-option.interface';
import { Client } from '../../client/client.model';
import { ClientService } from '../../client/client.service';
import { ClientDataPage } from '../../client/interface/client-data-page';
import { ClientDataPageFilters } from '../../client/interface/client-data-page-filters';
import { ClientDataPageRequest } from '../../client/interface/client-data-page-request';
import { DictionaryService } from '../../dictionary/dictionary.service';
import { DictionaryFiltersRequestModel } from '../../dictionary/model/dictionary-filters-request.model';
import { DictionaryRequestModel } from '../../dictionary/model/dictionary-request.model';
import { PrincipalDictionaryModel } from '../../dictionary/model/principal-dictionary.model';
import { SalesmanDictionaryWrapperModel } from '../../dictionary/model/salesman-dictionary-wrapper.model';
import { SalesmanDictionaryModel } from '../../dictionary/model/salesman-dictionary.model';
import { TitleGroupDictionaryModel } from '../../dictionary/model/title-group-dictionary.model';
import { MerchantService } from '../../merchant/merchant.service';
import { MerchantDataPageRequest } from '../../merchant/model/merchant-data-page-request';
import { Merchant } from '../../merchant/model/merchant.model';

import { PackageService } from '../../package/package.service';
import { PublisherService } from '../../publisher/publisher.service';
import { PublisherTreePopupComponent } from '../publisher-tree-popup/publisher-tree-popup.component';
import { ReportService } from '../report.service';
import {TitleEditionDictionaryModel} from '../../dictionary/model/title-edition-dictionary.model';

@Component({
  selector: 'ep-report-generate-form',
  templateUrl: './report-generate-form.component.html',
  styleUrls: ['./report-generate-form.component.css']
})

export class ReportGenerateFormComponent implements OnInit {
  reportForm: FormGroup;

  @Output() onValidationChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReportCreate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReportFileGenerate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReportChange: EventEmitter<any> = new EventEmitter<any>();

  isLoading: any = {publisher: true, client: true, placement: true, placementCategory: true, merchant: true};

  titleDataPageRequest: DictionaryRequestModel = new DictionaryRequestModel();
  merchantDataPageRequest: MerchantDataPageRequest = new MerchantDataPageRequest();
  clientDataPageRequest: ClientDataPageRequest = new ClientDataPageRequest();

  clientOptions: IAutocompleteFieldOption[] = [];
  creationOptions: IAutocompleteFieldOption[] = [];
  titleOptions: IAutocompleteFieldOption[] = [];
  titleEditionOptions: IAutocompleteFieldOption[] = [];
  titleGroupOptions: IAutocompleteFieldOption[] = [];
  statusOptions: IAutocompleteFieldOption[] = [];
  typeOptions: IAutocompleteFieldOption[] = [];
  merchantOptions: IAutocompleteFieldOption[] = [];
  ebrOptions: IAutocompleteFieldOption[] = [];

  clientOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;
  creationOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;
  placementOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;
  placementCategoryOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;
  titleOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;
  titleGroupOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;
  titleEditionOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  statusOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;
  typeOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;
  merchantOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  principalRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  principalOptions: IAutocompleteFieldOption[] = [];
  principalOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  salesmanRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  salesmanOptions: IAutocompleteFieldOption[] = [];
  salesmanOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  rowVariableOptions: IAutocompleteFieldOption[] = [];
  columnVariableOptions: IAutocompleteFieldOption[] = [];
  additionalFilterOptions: IAutocompleteFieldOption[] = [];
  additionalFilterValueOptions: IAutocompleteFieldOption[] = [];
  additionalFilterValueOptionsByFilter = {
    salesman: [],
    orderForm: [],
    campaignType: [],
    salesModel: [],
    format: [],
    principal: [],
    client: [],
    trade: [],
    brand: [],
    title: [],
    placementCategory: [],
    type: [],
    desktopOrMobile: [],
    identification: [],
    creation: [],
    priceList: [],
    titleEdition: []
  };

  public reportByEditionRangeControl: FormControl;

  isHandledByEdition: boolean;
  titleGroupTreeCopy: any;
  fieldFormAvailability: any;
  filtersData: any;
  years: any;
  dateYearModel: number;
  dataSubscribers: any = {
    getClientListData: (response: ClientDataPage) => {
      if (response && response.items && response.items.length) {
        _.each(response.items, (val: Client) => {
          if (!_.isArray(this.clientOptions)) {
            this.clientOptions = [];
          }

          this.clientOptions.push({
            id: val.id,
            label: val.name
          });
        });
        this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;

        this.additionalFilterValueOptionsByFilter.client = this.clientOptions;
      } else {
        this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getFiltersData: (data) => {
      this.filtersData = data;
    },
    getEbrListData: (identificationValues) => {
      _.each(identificationValues, (value, key) => {
        this.ebrOptions.push({
          id: key,
          label: value
        });
      });
    },
    getRowVariableListData: (rowVariables) => {
      _.each(rowVariables, (rowVariableKey: string, rowVariableValue: string) => {
        this.rowVariableOptions.push({
          id: rowVariableKey,
          label: rowVariableValue
        });
      });
    },
    getColumnVariableListData: (columnVariables) => {
      _.each(columnVariables, (columnVariableKey: string, columnVariableValue: string) => {
        this.columnVariableOptions.push({
          id: columnVariableKey,
          label: columnVariableValue
        });
      });
    },
    getAdditionalFilterListData: (additionalFilters) => {
      _.each(additionalFilters, (additionalFilterKey: string, additionalFilterValue: string) => {
        this.additionalFilterOptions.push({
          id: additionalFilterKey,
          label: additionalFilterValue
        });
      });
    },
    getAdditionalFilterTypeValuesData: (additionalFilterTypeValues) => {
      _.each(additionalFilterTypeValues, (value, key) => {
        this.additionalFilterValueOptionsByFilter.type.push({
          id: key,
          label: value
        });
      });
    },
    getAdditionalFilterDesktopMobileValuesData: (additionalFilterDesktopMobileValues) => {
      _.each(additionalFilterDesktopMobileValues, (value, key) => {
        this.additionalFilterValueOptionsByFilter.desktopOrMobile.push({
          id: key,
          label: value
        });
      });
    },
    getAdditionalFilterIdentificationValuesData: (additionalFilterIdentificationValues) => {
      _.each(additionalFilterIdentificationValues, (value, key) => {
        this.additionalFilterValueOptionsByFilter.identification.push({
          id: key,
          label: value
        });
      });
    },
    getAdditionalFilterOrderFormValuesData: (additionalFilterOrderFormValues) => {
      _.each(additionalFilterOrderFormValues, (value) => {
        this.additionalFilterValueOptionsByFilter.orderForm.push({
          id: value.id,
          label: value.name
        });
      });
    },
    getAdditionalFilterCampaignTypeValuesData: (additionalFilterCampaignTypeValues) => {
      _.each(additionalFilterCampaignTypeValues, (value) => {
        this.additionalFilterValueOptionsByFilter.campaignType.push({
          id: value.id,
          label: value.name
        });
      });
    },
    getAdditionalFilterCreationValuesData: (additionalFilterCreationValues) => {
      _.each(additionalFilterCreationValues, (value) => {
        this.additionalFilterValueOptionsByFilter.creation.push({
          id: value.id,
          label: value.name
        });
      });
    },
    getAdditionalFilterPriceListValuesData: (additionalFilterPriceListValues) => {
      _.each(additionalFilterPriceListValues, (value) => {
        this.additionalFilterValueOptionsByFilter.priceList.push({
          id: value.id,
          label: value.name
        });
      });
    },
    getAdditionalFilterSalesModelValuesData: (additionalFilterSalesModelValues) => {
      _.each(additionalFilterSalesModelValues, (value) => {
        this.additionalFilterValueOptionsByFilter.salesModel.push({
          id: value.id,
          label: value.name
        });
      });
    },
    getAdditionalFilterFormatValuesData: (additionalFilterFormatValues) => {
      _.each(additionalFilterFormatValues, (value) => {
        this.additionalFilterValueOptionsByFilter.format.push({
          id: value.id,
          label: value.name
        });
      });
    },
    getAdditionalFilterTradeValuesData: (additionalFilterFormatValues) => {
      _.each(additionalFilterFormatValues, (value) => {
        this.additionalFilterValueOptionsByFilter.trade.push({
          id: value.id,
          label: value.name
        });
      });
    },
    getAdditionalFilterBrandValuesData: (additionalFilterBrandValues) => {
      _.each(additionalFilterBrandValues, (value) => {
        this.additionalFilterValueOptionsByFilter.brand.push({
          id: value.id,
          label: value.name
        });
      });
    },
    getAdditionalFilterPlacementCategoryValuesData: (additionalFilterPlacementCategoryValues) => {
      if (additionalFilterPlacementCategoryValues && additionalFilterPlacementCategoryValues['data']) {
        additionalFilterPlacementCategoryValues = additionalFilterPlacementCategoryValues['data'];
      }

      _.each(additionalFilterPlacementCategoryValues, (value) => {
        this.additionalFilterValueOptionsByFilter.placementCategory.push({
          id: value.placementCategoryId,
          label: value.name
        });
      });
    },
    getPrincipalListData: (response?: Array<PrincipalDictionaryModel>): void => {
      if (response && response && response.length) {
        let options: Array<IAutocompleteMultiselectOption> = [];
        _.each(response, (val: PrincipalDictionaryModel) => {
          options.push({
            id: val.id,
            label: val.name
          });
        });

        this.principalOptions = options;
        this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;

        this.additionalFilterValueOptionsByFilter.principal = this.principalOptions;
      } else {
        this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getSalesmanListData: (response?: SalesmanDictionaryWrapperModel): void => {
      if (response && response.items && _.isArray(response.items) && response.items.length) {
        let salesmanOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response.items, (val: SalesmanDictionaryModel) => {
          salesmanOptions.push({
            id: val.id,
            label: val.name
          });
        });

        this.salesmanOptions = salesmanOptions;
        this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;

        this.additionalFilterValueOptionsByFilter.salesman = this.salesmanOptions;
      } else {
        this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getTitleListData: (response: Response) => {
      if (response) {
        _.each(response, (val: any) => {
          if (!_.isArray(this.titleOptions)) {
            this.titleOptions = [];
          }

          this.titleOptions.push({
            id: val.id,
            label: val.name
          });
        });
        this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;

        this.additionalFilterValueOptionsByFilter.title = this.titleOptions;
      } else {
        this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getTitleEditionData:  (response: Array<TitleEditionDictionaryModel>) => {
      this.titleEditionOptions = [];
      if (response) {
        _.each(response, (val: TitleEditionDictionaryModel) => {
          this.titleEditionOptions.push({
            id: val.id,
            label: `${val.editionNumber}\\${val.editionYear}`
          });
        });
        this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;

        this.additionalFilterValueOptionsByFilter.titleEdition = this.titleEditionOptions;
      } else {
        this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getTitleGroupList: (response: Array<TitleGroupDictionaryModel>) => {
      let titleGroupOptions = [];

      if (response) {
        _.each(response, (val: TitleGroupDictionaryModel) => {
          titleGroupOptions.push({
            id: val.id,
            label: val.name
          });
        });
        this.titleGroupOptions = titleGroupOptions;
        this.titleGroupOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.titleGroupOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getStatusList: (response: Array<any>) => {
      let statusOptions = [];
      if (response) {
        _.each(response, (val: any, key) => {
          statusOptions.push({
            id: key,
            label: val
          });
        });
        this.statusOptions = statusOptions;
        this.statusOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.statusOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getTypeList: (response: Array<any>) => {
      let typeOptions = [];
      if (response) {
        _.each(response, (val: any, key) => {
          typeOptions.push({
            id: key,
            label: val
          });
        });
        this.typeOptions = typeOptions;
        this.typeOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.typeOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getMerchantListData: (response: Response) => {
      if (response && response['data']) {
        _.each(response['data'], (val: Merchant) => {
          if (!_.isArray(this.merchantOptions)) {
            this.merchantOptions = [];
          }

          this.merchantOptions.push({
            id: val.id,
            label: val.username
          });
        });
        this.merchantOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.merchantOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    defaultErrorListData: (context, err) => {
      switch (context) {
        case 'client':
          this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.tooManyResults;
          break;
        case 'placement':
          this.placementOptionsStatus = AutocompleteFieldOptionStatusEnum.tooManyResults;
          break;
        case 'placementCategory':
          this.placementCategoryOptionsStatus = AutocompleteFieldOptionStatusEnum.tooManyResults;
          break;
        case 'title':
          this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.tooManyResults;
          break;
        case 'merchant':
          this.merchantOptionsStatus = AutocompleteFieldOptionStatusEnum.tooManyResults;
          break;
        default:

          break;
      }
    }
  };
  dataActions: any = {
    getClientList: (clientDataPageRequest?: ClientDataPageRequest): void => {
      this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.clientOptions = [];
      this.clientService.getList(clientDataPageRequest)
        .subscribe(
          response => this.dataSubscribers.getClientListData(response),
          err => this.dataSubscribers.defaultErrorListData('client', err));
    },
    getFilters: (): void => {
      this.reportService.getFilters().subscribe((data) => {
        this.dataSubscribers.getFiltersData(data);

        this.dataSubscribers.getRowVariableListData(data['13']['rowVariables']);
        this.dataSubscribers.getColumnVariableListData(data['13']['columnVariables']);
        this.dataSubscribers.getAdditionalFilterListData(data['13']['additionalFilter']);
      });
    },
    getTitleList: (dictionaryRequest?: DictionaryRequestModel): void => {
      this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.titleOptions = [];
      this.dictionaryService.getTitles(dictionaryRequest)
        .subscribe(this.dataSubscribers.getTitleListData,
          err => this.dataSubscribers.defaultErrorListData('title', err));
    },
    getTitleGroupList: (dictionaryRequest?: DictionaryRequestModel): void => {
      this.titleGroupOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.titleGroupOptions = [];
      this.dictionaryService.getTitleGroup(dictionaryRequest)
        .subscribe(this.dataSubscribers.getTitleGroupList,
          err => this.dataSubscribers.defaultErrorListData('titleGroup', err));
    },
    getTitleEdition: (titleId, dictionaryRequest?: DictionaryRequestModel): void => {
      this.titleEditionOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.titleEditionOptions = [];
      this.dictionaryService.getTitleEdition(titleId as number, dictionaryRequest)
        .subscribe(this.dataSubscribers.getTitleEditionData,
          err => this.dataSubscribers.defaultErrorListData('titleEdition', err));
    },
    getEbrList: (): void => {
      this.ebrOptions = [];
      this.reportService.getReportFilterIdentificationValues().subscribe(this.dataSubscribers.getEbrListData);
    },
    getPrincipalList: (principalRequest?: any): void => {
      this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getPrincipals(principalRequest)
        .subscribe(
          response => this.dataSubscribers.getPrincipalListData(response),
          err => this.dataSubscribers.defaultErrorListData('principal', err));
    },
    getSalesmanList: (salesModelRequest?: any): void => {
      this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getSalesmans(salesModelRequest)
        .subscribe(
          response => this.dataSubscribers.getSalesmanListData(response),
          err => this.dataSubscribers.defaultErrorListData('salesman', err));
    },
    getStatusesList: (request): void => {
      this.reportService.getStatuses(request)
        .subscribe(this.dataSubscribers.getStatusList,
          err => this.dataSubscribers.defaultErrorListData('statuses', err));
    },
    getTypeList: (request): void => {
      this.reportService.getTypes(request)
        .subscribe(this.dataSubscribers.getTypeList,
          err => this.dataSubscribers.defaultErrorListData('type', err));
    },
    getMerchantList: (merchantDataPageRequest?: MerchantDataPageRequest): void => {
      this.merchantOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.merchantOptions = [];
      this.merchantService.getList(merchantDataPageRequest)
        .subscribe(this.dataSubscribers.getMerchantListData,
          err => this.dataSubscribers.defaultErrorListData('merchant', err));
    },
    getAdditionalFilterTypeValues: () => {
      this.reportService.getReportFilterTypeValues().subscribe(this.dataSubscribers.getAdditionalFilterTypeValuesData);
    },
    getAdditionalFilterDesktopMobileValues: () => {
      this.reportService.getReportFilterDesktopMobileValues().subscribe(this.dataSubscribers.getAdditionalFilterDesktopMobileValuesData);
    },
    getAdditionalFilterIdentificationValues: () => {
      this.reportService.getReportFilterIdentificationValues().subscribe(this.dataSubscribers.getAdditionalFilterIdentificationValuesData);
    },
    getAdditionalFilterOrderFormValues: (): void => {
      this.dictionaryService.getOrderForms(null, null).subscribe(data => this.dataSubscribers.getAdditionalFilterOrderFormValuesData(data.items));
    },
    getAdditionalFilterCampaignTypeValues: (): void => {
      this.dictionaryService.getCampaignTypes(null).subscribe(data => this.dataSubscribers.getAdditionalFilterCampaignTypeValuesData(data));
    },
    getAdditionalFilterCreationValues: (): void => {
      this.dictionaryService.getCreations(null).subscribe(data => this.dataSubscribers.getAdditionalFilterCreationValuesData(data));
    },
    getAdditionalFilterPriceListValues: (): void => {
      this.dictionaryService.getPriceListNonStandard(null).subscribe(data => this.dataSubscribers.getAdditionalFilterPriceListValuesData(data));
    },
    getAdditionalFilterSalesModelValues: (): void => {
      this.dictionaryService.getSalesModels(null).subscribe(data => this.dataSubscribers.getAdditionalFilterSalesModelValuesData(data));
    },
    getAdditionalFilterFormatValues: (): void => {
      this.dictionaryService.getFormats(null).subscribe(data => this.dataSubscribers.getAdditionalFilterFormatValuesData(data));
    },
    getAdditionalFilterTradeValues: (): void => {
      this.dictionaryService.getTrades(null).subscribe(data => this.dataSubscribers.getAdditionalFilterTradeValuesData(data));
    },
    getAdditionalFilterBrandValues: (): void => {
      this.dictionaryService.getBrandsByTrade(null, null).subscribe(data => this.dataSubscribers.getAdditionalFilterBrandValuesData(data));
    },
    getAdditionalFilterPlacementCategoryValues: (): void => {
      this.packageService.getList().subscribe(data => this.dataSubscribers.getAdditionalFilterPlacementCategoryValuesData(data));
    },
    getAdditionalFilterValueList: (additionalFilter): void => {
      if (this.additionalFilterValueOptionsByFilter[additionalFilter.id]) {
        this.additionalFilterValueOptions = this.additionalFilterValueOptionsByFilter[additionalFilter.id];
      } else {
        this.additionalFilterValueOptions = [];
      }
    }
  };
  eventListeners: any = {
    onChangeClientSearchText: (text) => {
      if (text && (text.length >= 3) || !text) {
        this.clientDataPageRequest.setFilters(new ClientDataPageFilters({name: text}));
        this.dataActions.getClientList(this.clientDataPageRequest);
      } else {
        this.clientOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangePrincipalSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.principalRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getPrincipalList(this.principalRequest);
      } else {
        this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeSalesmanSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.salesmanRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getSalesmanList(this.salesmanRequest);
      } else {
        this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeTitleSearchText: (text) => {
      if (text && (text.length >= 3) || !text) {
        this.titleDataPageRequest.setFilters({name: text});
        this.dataActions.getTitleList(this.titleDataPageRequest);
      } else {
        this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeTitleGroupSearchText: (text) => {
      if (text && (text.length >= 3)) {
        const dictionaryRequest: DictionaryRequestModel = new DictionaryRequestModel({
          filters: {
            name: text
          }
        });

        this.dataActions.getTitleGroupList(dictionaryRequest);
      } else {
        this.titleGroupOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeMerchantSearchText: (text) => {
      if (text && (text.length >= 3) || !text) {
        this.merchantDataPageRequest.setFilters({name: text});
        this.dataActions.getMerchantList(this.merchantDataPageRequest);
      } else {
        this.merchantOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeRowVariableSearchText: (text) => {

    },
    onChangeColumnVariableSearchText: (text) => {

    },
    onChangeAdditionalFilterSearchText: (text) => {

    },
    onChangeAdditionalFilterValueSearchText: (text) => {

    },
    onChangeReportType: () => {
      this.onReportChange.emit();
      if (this.reportForm.controls.reportType.value) {
        const foundFilter: any = _.find(this.filtersData, (ava, key) => key.toString() === this.reportForm.controls.reportType.value.id);

        if (_.isArray(foundFilter)) {
          this.fieldFormAvailability = foundFilter;
        } else {
          this.fieldFormAvailability = foundFilter.baseFilters;

          if (foundFilter['rowVariables']) {
            this.fieldFormAvailability.push('rowVariable');
          }

          if (foundFilter['columnVariables']) {
            this.fieldFormAvailability.push('columnVariable');
          }

          if (foundFilter['additionalFilter']) {
            this.fieldFormAvailability.push('additionalFilter');
            this.fieldFormAvailability.push('additionalFilterValue');
          }
        }

        if (this.reportForm.controls.reportType.value.id === '2' || this.reportForm.controls.reportType.value.id === '3') {
          if (this.fieldFormAvailability.indexOf('dateYear') === -1) {
            this.fieldFormAvailability.splice(this.fieldFormAvailability.indexOf('dateFrom'), 1);
            this.fieldFormAvailability.splice(this.fieldFormAvailability.indexOf('dateTo'), 1);

            this.fieldFormAvailability.push('dateYear');
          }
        }
      }
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private publisherService: PublisherService,
    private clientService: ClientService,
    private packageService: PackageService,
    private merchantService: MerchantService,
    private reportService: ReportService,
    private dictionaryService: DictionaryService,
    private dialog: MatDialog) {
    this.fieldFormAvailability = [];
    this.reportByEditionRangeControl = new FormControl();
    this.reportByEditionRangeControl.valueChanges.subscribe((value) => {
      this.isHandledByEdition = value;
    })
  }

  ngOnInit() {
    this.createForm();
    this.dataActions.getTitleList();
    this.dataActions.getTitleGroupList();
    this.dataActions.getClientList();
    this.dataActions.getMerchantList();
    this.dataActions.getStatusesList();
    this.dataActions.getEbrList();
    this.dataActions.getTypeList();
    this.dataActions.getFilters();
    this.dataActions.getPrincipalList();
    this.dataActions.getSalesmanList();
    this.dataActions.getAdditionalFilterOrderFormValues();
    this.dataActions.getAdditionalFilterCampaignTypeValues();
    this.dataActions.getAdditionalFilterCreationValues();
    this.dataActions.getAdditionalFilterSalesModelValues();
    this.dataActions.getAdditionalFilterFormatValues();
    this.dataActions.getAdditionalFilterTypeValues();
    this.dataActions.getAdditionalFilterTradeValues();
    this.dataActions.getAdditionalFilterBrandValues();
    this.dataActions.getAdditionalFilterDesktopMobileValues();
    this.dataActions.getAdditionalFilterIdentificationValues();
    this.dataActions.getAdditionalFilterPlacementCategoryValues();
    this.dataActions.getAdditionalFilterPriceListValues();
  }

  createForm() {
    this.years = this.getYearOptions();
    this.dateYearModel = new Date().getFullYear();

    this.reportForm = this.formBuilder.group({
      client: [''],
      dateFrom: [new Date()],
      dateTo: [new Date()],
      dateYear: [],
      title: [''],
      reportType: [null, Validators.required],
      status: [null, Validators.required],
      principal: [],
      salesman: [],
      ebr: [],
      titleGroup: [],
      rowVariable: [],
      columnVariable: [],
      additionalFilter: [],
      additionalFilterValue: [],
      editionFrom: [],
      editionTo: []
    });

    this.reportForm.valueChanges.subscribe(val => {
      this.onReportChange.emit();
      if (!val.titleGroup && !val.title) {
        this.reportForm.controls['title'].setValidators(Validators.required);
        this.reportForm.controls['titleGroup'].setValidators(Validators.required);

        if (this.reportForm.valid) {
          this.reportForm.controls['title'].updateValueAndValidity();
          this.reportForm.controls['titleGroup'].updateValueAndValidity();
        }
      }

      if (val.titleGroup || val.title) {
        this.reportForm.controls['title'].clearValidators();
        this.reportForm.controls['titleGroup'].clearValidators();
      }

      if (val.title) {
        this.reportForm.controls['title'].clearValidators();
        this.reportForm.controls['titleGroup'].clearValidators();
      }

      this.onValidationChange.emit(this.reportForm.valid);
    });

    this.reportForm.controls['reportType'].valueChanges.subscribe(value => {
      this.clearFormValues();

      if (value && value.id == 6) {
        this.reportForm.controls['principal'].setValidators(Validators.required);
      } else {
        this.reportForm.controls['principal'].clearValidators();
      }

      this.reportForm.controls['principal'].updateValueAndValidity();

      if (value && value.id == 7) {
        this.reportForm.controls['client'].setValidators(Validators.required);
      } else {
        this.reportForm.controls['client'].clearValidators();
      }

      this.reportForm.controls['client'].updateValueAndValidity();

      if (value && (value.id == 9 || value.id == 11)) {
        this.reportForm.controls['salesman'].setValidators(Validators.required);
      } else {
        this.reportForm.controls['salesman'].clearValidators();
      }

      this.reportForm.controls['salesman'].updateValueAndValidity();

      if (value && value.id == 13) {
        this.reportForm.controls['rowVariable'].setValidators(Validators.required);
        this.reportForm.controls['columnVariable'].setValidators(Validators.required);
      } else {
        this.reportForm.controls['rowVariable'].clearValidators();
        this.reportForm.controls['columnVariable'].clearValidators();
        this.reportForm.controls['additionalFilter'].clearValidators();
        this.reportForm.controls['additionalFilterValue'].clearValidators();
      }

      this.reportForm.controls['rowVariable'].updateValueAndValidity();
      this.reportForm.controls['columnVariable'].updateValueAndValidity();
      this.reportForm.controls['additionalFilter'].updateValueAndValidity();
      this.reportForm.controls['additionalFilterValue'].updateValueAndValidity();

      this.onValidationChange.emit(this.reportForm.valid);
      this.eventListeners.onChangeReportType();
    });

    this.reportForm.controls['additionalFilter'].valueChanges.subscribe(value => {
      if (this.reportForm.controls['reportType'].value && this.reportForm.controls['reportType'].value.id != 13) {
        return;
      }

      if (value) {
        this.reportForm.controls['additionalFilterValue'].setValue(null);
        this.dataActions.getAdditionalFilterValueList(value);

        if (value.id && (value.id === 'value' || value.id === 'agencyCost' || value.id === 'technicalCost')) {
          this.reportForm.controls['additionalFilterValue'].clearValidators();
        } else {
          this.reportForm.controls['additionalFilterValue'].setValidators(Validators.required);
        }

        this.reportForm.controls['additionalFilterValue'].updateValueAndValidity();
      }
    });

    this.reportForm.controls['titleGroup'].valueChanges.subscribe(val => {
      if (val) {
        this.reportForm.controls['title'].setValue(null);
      }
    });

    this.reportForm.controls['title'].valueChanges.subscribe(val => {
      if (val) {
        this.reportForm.controls['titleGroup'].setValue(null);
        this.dataActions.getTitleEdition(val.id);
      } else {
        this.reportByEditionRangeControl.setValue(false);
      }
    });
  }

  clearFormValues() {
    this.reportForm.controls['client'].setValue(null);
    this.reportForm.controls['dateFrom'].setValue(new Date());
    this.reportForm.controls['dateTo'].setValue(new Date());
    this.reportForm.controls['dateYear'].setValue(null);
    this.reportForm.controls['title'].setValue(null);
    this.reportForm.controls['status'].setValue(null);
    this.reportForm.controls['principal'].setValue(null);
    this.reportForm.controls['salesman'].setValue(null);
    this.reportForm.controls['ebr'].setValue(null);
    this.reportForm.controls['titleGroup'].setValue(null);
    this.reportForm.controls['rowVariable'].setValue(null);
    this.reportForm.controls['columnVariable'].setValue(null);
    this.reportForm.controls['additionalFilter'].setValue(null);
    this.reportForm.controls['additionalFilterValue'].setValue(null);
    this.reportForm.controls['editionFrom'].setValue(null);
    this.reportForm.controls['editionTo'].setValue(null);

    this.reportForm.markAsUntouched();
  }

  getYearOptions() {
    let yearOptions = [];
    let nowYear = new Date().getFullYear();

    for (let i = -10; i <= 10; i++) {
      let yearOption = nowYear + i;

      yearOptions.push(yearOption);
    }

    return yearOptions;
  }

  getStartDateByYear(year) {
    return year + '-1-1';
  }

  getEndDateByYear(year) {
    return year + '-12-31';
  }

  getFormFilters() {
    const filters = {
      dateFrom: new DatePipe('pl-PL').transform(this.reportForm.controls['dateFrom'].value, 'yyyy-MM-dd'),
      dateTo: new DatePipe('pl-PL').transform(this.reportForm.controls['dateTo'].value, 'yyyy-MM-dd'),
      editionFrom: this.reportForm.controls['editionFrom'].value ? this.reportForm.controls['editionFrom'].value.id : undefined,
      editionTo: this.reportForm.controls['editionTo'].value ? this.reportForm.controls['editionTo'].value.id : undefined,
      client: this.reportForm.controls['client'].value ? this.reportForm.controls['client'].value.id : undefined,
      title: this.reportForm.controls['title'].value ? this.reportForm.controls['title'].value.id : undefined,
      titleGroup: this.reportForm.controls['titleGroup'].value ? this.reportForm.controls['titleGroup'].value.id : undefined,
      salesman: this.reportForm.controls['salesman'].value ? this.reportForm.controls['salesman'].value.id : undefined,
      reportType: this.reportForm.controls['reportType'].value ? this.reportForm.controls['reportType'].value.id : undefined,
      status: this.reportForm.controls['status'].value ? this.reportForm.controls['status'].value.id : this.reportForm.controls['status'].value,
      ebr: this.reportForm.controls['ebr'].value ? this.reportForm.controls['ebr'].value.id : null,
      principal: this.reportForm.controls['principal'].value ? this.reportForm.controls['principal'].value.id : undefined,
      pivotRow: this.reportForm.controls['rowVariable'].value ? this.reportForm.controls['rowVariable'].value.id : null,
      pivotColumn: this.reportForm.controls['columnVariable'].value ? this.reportForm.controls['columnVariable'].value.id : null,
      pivotFilter: this.reportForm.controls['additionalFilter'].value ? this.reportForm.controls['additionalFilter'].value.id : null,
      pivotFilterValue: this.reportForm.controls['additionalFilterValue'].value ? this.reportForm.controls['additionalFilterValue'].value.id : null,
    };

    if (this.isHandledByEdition) {
      filters['dateFrom'] = undefined;
      filters['dateTo'] = undefined;
    } else {
      filters['editionFrom'] = undefined;
      filters['editionTo'] = undefined;
    }

    if (this.reportForm.controls['dateYear'].value) {
      filters.dateFrom = this.getStartDateByYear(this.reportForm.controls['dateYear'].value);
      filters.dateTo = this.getEndDateByYear(this.reportForm.controls['dateYear'].value);
    }

    return filters;
  }

  openTitleTreePopup() {
    const dialogRef = this.dialog.open(PublisherTreePopupComponent, {
      width: '440px',
      data: {
        selectedTitleGroups: this.reportForm.controls['title'].value,
        placementTreeCopy: this.titleGroupTreeCopy
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe();
  }

  canShowFormField(name: string) {
    return this.fieldFormAvailability.includes(name);
  }

  createReport() {
    if (!this.reportForm.valid) {
      return;
    }

    const filters = this.getFormFilters();
    const data = {
      reportType: filters.reportType,
      filters: filters
    };

    this.onReportCreate.emit(data);
  }

  generateReportFile() {
    if (!this.reportForm.valid) {
      return;
    }

    const filters = this.getFormFilters();
    const request = {
      generate_report: filters
    };

    this.reportService.saveReport(request).subscribe(response => {
      if (response && response['data']) {
        const data = {
          fileUrl: response['data']['url'],
        };

        this.onReportFileGenerate.emit(data);
      } else {
        this.onReportFileGenerate.emit(null);
      }
    }, () => {
      this.onReportFileGenerate.emit(null);
    });
  }
}
