<mat-toolbar color="primary"></mat-toolbar>
<mat-toolbar color="primary">
  <div class="calendar-header">
    <span>{{ viewDate | calendarDate:(view + 'ViewTitle'):'pl' }}</span>
    <div class="filter-and-chevrons">
      <div>
        <button mat-button (click)="showFilterPopup()" [disabled]="isLoadingCalendarData">Filtry</button>
      </div>
      <div class="chevrons">
        <button mat-icon-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange($event)">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-icon-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange($event)">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>

    <!--<button mat-button (click)="view = 'month'">Month</button>-->
    <!--<button mat-button (click)="view = 'week'">Week</button>-->
    <!--<button mat-button (click)="view = 'day'">Day</button>-->
  </div>
</mat-toolbar>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="calendar-day-cell-stats">
    <div class="websites stat">
      <div class="stat-container"
           *ngIf="day.events && day.events[0] && day.events[0].itemData && day.events[0].itemData.website && day.events[0].itemData.website.count > 0">
        <i class="material-icons">photo_library</i>
        <span class="stat-count">
                    {{ day.events[0].itemData.website.count }}
                </span>
      </div>
    </div>
    <div class="placements stat">
      <div class="stat-container"
           *ngIf="day.events && day.events[0] && day.events[0].itemData && day.events[0].itemData.placement && day.events[0].itemData.placement.count > 0">
        <i class="material-icons">photo</i>
        <span class="stat-count">
                    {{ day.events[0].itemData.placement.count }}
                </span>
      </div>
    </div>
    <div class="packages stat">
      <div class="stat-container"
           *ngIf="day.events && day.events[0] && day.events[0].itemData && day.events[0].itemData.package && day.events[0].itemData.package.count > 0">
        <i class="material-icons">photo_size_select_large</i>
        <span class="stat-count">
            {{ day.events[0].itemData.package.count }}
        </span>
      </div>
    </div>
  </div>
  <div class="calendar-day-cell-info">
    <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
    <span class="cal-day-percentage">{{ day.events[0] ? day.events[0].occupancy : '' }}%</span>
  </div>
  <div class="progress-bar">
    <mat-progress-bar mode="determinate" [value]="day.events[0] ? day.events[0].occupancy : 0"></mat-progress-bar>
  </div>
</ng-template>

<div class="calendar">
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [weekStartsOn]="1"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      [cellTemplate]="customCellTemplate"
    >
    </mwl-calendar-month-view>

    <!--<mwl-calendar-month-view-->
    <!--*ngSwitchCase="'month'"-->
    <!--[viewDate]="viewDate"-->
    <!--[events]="events"-->
    <!--[refresh]="refresh"-->
    <!--[weekStartsOn]="1"-->
    <!--(dayClicked)="dayClicked($event.day)"-->
    <!--[cellTemplate]="customCellTemplate"-->
    <!--class="calendar-month-view">-->
    <!--</mwl-calendar-month-view>-->
    <!--<mwl-calendar-week-view-->
    <!--*ngSwitchCase="'week'"-->
    <!--[viewDate]="viewDate"-->
    <!--[events]="events"-->
    <!--[refresh]="refresh"-->
    <!--(eventClicked)="handleEvent('Clicked', $event.event)"-->
    <!--(eventTimesChanged)="eventTimesChanged($event)">-->
    <!--</mwl-calendar-week-view>-->
    <!--<mwl-calendar-day-view-->
    <!--*ngSwitchCase="'day'"-->
    <!--[viewDate]="viewDate"-->
    <!--[events]="events"-->
    <!--[refresh]="refresh"-->
    <!--(eventClicked)="handleEvent('Clicked', $event.event)"-->
    <!--(eventTimesChanged)="eventTimesChanged($event)">-->
    <!--</mwl-calendar-day-view>-->
  </div>
</div>
