import { getDatePeriodByMonth } from '../../../shared/utils/date-cool-helper';

export class CalendarDataFilters {
  date: any = {
    from: null,
    to: null
  };
  publisher: string;
  'package': string;

  constructor(obj?: any) {
    this.date.from = obj && obj.date && obj.date.from || null;
    this.date.to = obj && obj.date && obj.date.to || null;
    this.publisher = obj && obj.publisher || null;
    this.package = obj && obj.package || null;
  }

  setMonthPeriod(year: number, month: number) {
    this.date = getDatePeriodByMonth(year, month);
  }
}
