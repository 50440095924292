import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Publisher } from '../publisher/model/publisher.model';


@Injectable()
export class Placement {
  placementId: number;
  externalId: number;
  name: string;
  active: boolean;
  kind: string;
  hasChildren: boolean;
  parent: Placement;
  publisher: Publisher;
  children: Array<Placement>;

  constructor(obj?: any) {
    this.placementId = obj && obj.placementId || null;
    this.externalId = obj && obj.externalId || null;
    this.name = obj && obj.name || null;
    this.hasChildren = obj && obj.hasChildren || false;
    this.active = obj && obj.active || false;
    this.kind = obj && obj.kind || false;
    this.parent = obj && obj.parent ? new Placement(obj.parent) : null;
    this.publisher = obj && obj.publisher ? new Publisher(obj.publisher) : null;
    this.children = [];
    if (obj.children && _.isArray(obj.children) && obj.children.length) {
      _.each(obj.children, data => {
        this.children.push(new Placement(data));
      })
    }
  }
}
