import { Injectable, Optional } from '@angular/core';

@Injectable()
export class CreationDictionaryModel {
  id: number;
  translation: string;
  name: string;

  constructor(@Optional() obj?: any) {
    this.id = obj && obj.id || null;
    this.name = obj && obj.name || null;
    this.translation = obj && obj.translation || null;
  }
}
