import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../user/user.model';

@Injectable()
export class PlacementCategoryService {

  constructor(private http: HttpClient) {

  }

  public getList(): Observable<User> {
    let apiUrl = `${environment.apiUrl}/placement-categories`;

    return this.http.get(apiUrl).pipe(map((response: Response) => {
      return new User(response['data']);
    }));
  }
}
