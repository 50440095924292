import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import * as _ from 'lodash';
import { auditTime } from 'rxjs/operators';
import { AutocompleteFieldOptionStatusEnum } from './autocomplete-field-option-status.enum';
import { IAutocompleteFieldOption } from './autocomplete-field-option.interface';

@Component({
  selector: 'ep-autocomplete-field',
  templateUrl: './autocomplete-field.component.html',
  styleUrls: ['./autocomplete-field.component.scss']
})
export class AutocompleteFieldComponent implements OnInit {
  @Input() placeholder: string;
  @Input() showNoResultButton?: boolean;
  @Input() options: IAutocompleteFieldOption[] = [];
  @Input() selected: FormControl;

  @Input() set disabled($event) {
    this.isDisabled = $event;
  }

  @Input() isMultiple?: boolean;

  @Input() set optionsStatus($event) {
    this.setHintText($event);
    this.optionsStatusValue = $event;
  }

  @Output() onSelected?: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeSearchText?: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickNoResultAction?: EventEmitter<any> = new EventEmitter<any>();

  optionsStatusValue: number;
  autocompleteFieldOptionStatusEnum: any = AutocompleteFieldOptionStatusEnum;

  hintText: string;
  isLoading: boolean = true;
  isDisabled: boolean = false;

  constructor() {
  }

  ngOnInit() {
    if (!this.selected) {
      this.selected = new FormControl();
    }
    this.selected.valueChanges.pipe(auditTime(700)).subscribe(() => {
      this.onChangeSearchText.emit(this.selected.value);
    });
    this.isMultiple = (this.isMultiple);
  }

  setHintText(status) {
    this.isLoading = (status === AutocompleteFieldOptionStatusEnum.loading);

    if (status === AutocompleteFieldOptionStatusEnum.noResults) {
      this.hintText = 'Brak wyników spełniających kryteria';
      this.options = [];
    }
    if (status === AutocompleteFieldOptionStatusEnum.tooManyResults) {
      this.hintText = 'Za dużo wyników, podaj więcej danych';
      this.options = [];
    }
    if (status === AutocompleteFieldOptionStatusEnum.needMoreChars) {
      this.hintText = 'aby wyszukiwać wprowadź minimum 3 znaki';
    }
    if (status === AutocompleteFieldOptionStatusEnum.ok) {
      this.hintText = null;
    }
  }

  onSelectItem($event) {
    if ($event && $event.option && $event.option.value) {
      this.onSelected.emit($event.option.value);
      this.selected.setValue($event.option.value);
      this.onChangeSearchText.emit(null);
    }
  }

  displaySelectedLabel(item) {
    return (item) ? item.label : null;
  }

  onChangeSearchTextValue($event) {
    if (_.isString(this.selected.value)) {
      this.selected.setValue('');
    }
  }

  isObject(input) {
    return toString.call(input);
  }
}
