import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  blockView: boolean = false;

  constructor() {
  }

  onHttpStart() {
    this.blockView = true;
  }

  onHttpCompleted() {
    this.blockView = false;
  }
}
