import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Injectable()
export class ToasterService {

  constructor(private snackBar: MatSnackBar) {

  }

  public displayMessage(message) {
    this.openSnackBar(message, 'zamknij');
  }

  private openSnackBar(message: string, action: string, extraClass?: string) {
    const config = new MatSnackBarConfig();
    config.duration = 4000;
    config.panelClass = extraClass ? [extraClass] : undefined;

    this.snackBar.open(message, action, config);
  }
}
