import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BrandDictionaryModel } from '../dictionary/model/brand-dictionary.model';

@Injectable()
export class BrandService {
  public onBrandCreate$: EventEmitter<any>;

  constructor(private http: HttpClient) {
    this.onBrandCreate$ = new EventEmitter();
  }

  public save(request: any): any {
    let apiUrl = `${environment.apiUrl}/brands/`;

    return this.http.post(apiUrl, request).pipe(map((response: Response) => {
      if (response && response['data']) {
        response['data'] = new BrandDictionaryModel(response['data']);
      }
      return response;
    }));
  }
}
