import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

import { AutocompleteFieldOptionStatusEnum } from '../../../shared/component/autocomplete-field/autocomplete-field-option-status.enum';

import { IAutocompleteFieldOption } from '../../../shared/component/autocomplete-field/autocomplete-field-option.interface';

import { IAutocompleteMultiselectOption } from '../../../shared/component/autocomplete-multiselect-field/autocomplete-multiselect-option.interface';
import { ConfirmPopupComponent } from '../../../shared/popup/confirm-popup/confirm-popup.component';
import { IConfirmPopup } from '../../../shared/popup/confirm-popup/confirm-popup.interface';
import { ApiErrorManagerService } from '../../../shared/utils/api-error-manager.service';
import { FormExtrasUtil } from '../../../shared/utils/form-extras.util';
import { ToasterService } from '../../../shared/utils/toaster.service';
import { Client } from '../../client/client.model';
import { ClientService } from '../../client/client.service';
import { ClientDataPage } from '../../client/interface/client-data-page';
import { ClientDataPageFilters } from '../../client/interface/client-data-page-filters';
import { ClientDataPageRequest } from '../../client/interface/client-data-page-request';
import { IClient } from '../../client/interface/client.interface';
import { DictionaryService } from '../../dictionary/dictionary.service';
import { DictionaryFiltersRequestModel } from '../../dictionary/model/dictionary-filters-request.model';
import { DictionaryRequestModel } from '../../dictionary/model/dictionary-request.model';
import { PrincipalDictionaryModel } from '../../dictionary/model/principal-dictionary.model';
import { SalesmanDictionaryWrapperModel } from '../../dictionary/model/salesman-dictionary-wrapper.model';
import { SalesmanDictionaryModel } from '../../dictionary/model/salesman-dictionary.model';
import { TitleDictionaryModel } from '../../dictionary/model/title-dictionary.model';

import { FileService } from '../../file/file.service';
import { ProfileService } from '../../profile/profile.service';
import { ReservationDataPage } from '../../reservation/model/reservation-data-page';
import { ReservationDataPageFilters } from '../../reservation/model/reservation-data-page-filters';

import { ReservationDataPageRequest } from '../../reservation/model/reservation-data-page-request';
import { Reservation } from '../../reservation/model/reservation.model';
import { ReservationEditPopupComponent } from '../../reservation/reservation-edit-popup/reservation-edit-popup.component';
import { ReservationService } from '../../reservation/reservation.service';
import { IOrderDataPage, OrderDataPage } from '../model/order-data-page';
import { OrderDataPageFilters } from '../model/order-data-page-filters';
import { OrderDataPageRequest } from '../model/order-data-page-request';
import { OrderRequest } from '../model/order-request.model';
import { Order } from '../model/order.model';
import { OrderService } from '../order.service';
import { PublisherStatisticsPopupComponent } from './publisher-statistics-popup/publisher-statistics-popup.component';

enum FormTypeEnum {
  'new', 'clone', 'cloned', 'unstd', 'print'
}

interface StatisticsPartition {
  id: number,
  name: string,
  value: any
}

@Component({
  selector: 'ep-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss']
})

export class OrderFormComponent implements OnInit, OnDestroy {
  @Output() onSaved: EventEmitter<any> = new EventEmitter<any>();
  @Output() onValidationChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshOrder?: EventEmitter<any> = new EventEmitter<any>();
  @Input() dateFrom?: any;

  @Input()
  set orderItemData(orderItemData) {
    this.orderItemDataLocal = orderItemData;
    this.hasNiestandard = orderItemData ? !!_.find(orderItemData.reservations, {type: 'custom'}) : false;
    if (orderItemData) {
      this.requestStatistics = _.merge({}, orderItemData.publisherStatistics);
    }
  }

  get orderItemData() {
    return this.orderItemDataLocal;
  }

  @Input() isAdminView?: any;
  @Input() isCoordinatorView?: any;
  @Input() isManagerView?: any;
  @Input() isEditable?: boolean;

  private orderItemDataLocal: any;
  private requestStatistics: any;

  isMerchantView: any;

  hasNiestandard: boolean;
  orderForm: FormGroup;
  formType = FormTypeEnum;
  formTypeSelected: any = null;
  clientData: IClient[];
  orderData: IOrderDataPage;
  clientDataPageRequest: ClientDataPageRequest = new ClientDataPageRequest();
  orderDataPageRequest: OrderDataPageRequest = new OrderDataPageRequest();

  statusOptions: Array<any> = [];
  statusOptionsKeys: Array<any> = [];

  titleRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null, kind: 'digital'}});
  titleOptions: IAutocompleteFieldOption[] = [];
  titleOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  reservationRequest: ReservationDataPageRequest = new ReservationDataPageRequest({filters: {name: null, unusedOnly: 1}});
  reservationOptions: IAutocompleteFieldOption[] = [];
  reservationOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  principalRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  principalOptions: IAutocompleteFieldOption[] = [];
  principalOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  salesmanRequest: DictionaryRequestModel = new DictionaryRequestModel({filters: {name: null}});
  salesmanOptions: IAutocompleteFieldOption[] = [];
  salesmanOptionsStatus: AutocompleteFieldOptionStatusEnum = AutocompleteFieldOptionStatusEnum.ok;

  isLoading: any = {reservation: true, client: true, order: true};
  hintText: any = {reservation: null, client: null, order: null};
  orderCloneForm: FormGroup;
  selectedClientId = null;
  submittedForm: {} = {
    orderForm: false
  };
  selectedOrderCloneReservations: Reservation[];
  isClone: boolean;
  isEdit: boolean;
  publisherStatistics: Array<any> = [];
  publisherStatisticsFormControl: any;
  publisherPartitionStatisticsFormControl: any;
  publisherStatisticsTitle: any = {};

  years = _.map(new Array(21).fill(0), (no: number, key: number) => key + 2017);

  updateReservationFormControlDisabled: boolean = false;
  selectedReservations: Array<any>;

  public selectedTab: number;

  dataSubscribers: any = {
    getPrincipalListData: (response?: Array<PrincipalDictionaryModel>): void => {
      if (response && response && response.length) {
        let options: Array<IAutocompleteMultiselectOption> = [];
        _.each(response, (val: PrincipalDictionaryModel) => {
          options.push({
            id: val.id,
            label: val.name
          });
        });

        if (this.isEdit && this.orderItemData.principal && this.orderItemData.principal.id) {
          let currentItem: any = _.find(options, {id: this.orderItemData.principal.id});
          if (!currentItem) {
            currentItem = {
              id: this.orderItemData.principal.id,
              label: this.orderItemData.principal.name
            };
            options.push(currentItem);
          }
        }

        this.principalOptions = options;
        this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getSalesmanListData: (response?: SalesmanDictionaryWrapperModel): void => {
      if (response && response.items && _.isArray(response.items) && response.items.length) {
        let salesmanOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response.items, (val: SalesmanDictionaryModel) => {
          salesmanOptions.push({
            id: val.id,
            label: val.name
          });
        });
        if (this.isEdit && this.orderItemData.salesman && this.orderItemData.salesman.id) {
          let currentSalesman: IAutocompleteMultiselectOption = _.find(salesmanOptions, (salesmanItem: IAutocompleteMultiselectOption) => {
            if (salesmanItem.id === this.orderItemData.salesman.id) {
              return true;
            }
          });
          if (!currentSalesman) {
            currentSalesman = {
              id: this.orderItemData.salesman.id,
              label: this.orderItemData.salesman.name
            };
            salesmanOptions.push(currentSalesman);
          }

        } else if (this.orderItemData && !this.orderItemData.salesman && response.currentUser) {
          const currentSalesman = _.find(response.items, (salesmanItem: SalesmanDictionaryModel) => {
            if (salesmanItem.id === response.currentUser.id) {
              return true;
            }
          });
          if (currentSalesman) {
            this.salesmanOptions.push({
              id: currentSalesman.id,
              label: `${currentSalesman.name}`
            });
          } else {
            const currentSalesmanItemData = {
              id: response.currentUser.id,
              label: `${response.currentUser.firstName} ${response.currentUser.firstName}`
            };
            salesmanOptions.push(currentSalesmanItemData);
          }
        }

        this.salesmanOptions = salesmanOptions;
        this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    getTitleListData: (response?: TitleDictionaryModel): void => {
      if (response && _.isArray(response) && response.length) {
        let titleOptions: Array<IAutocompleteMultiselectOption> = [];
        _.each(response, (val: TitleDictionaryModel) => {
          titleOptions.push({
            id: val.id,
            label: val.name,
            additionalData: val
          });
        });

        this.titleOptions = titleOptions;
        this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
    },
    defaultErrorListData: (context) => {
      switch (context) {
        default:
          this[`${context}OptionsStatus`] = AutocompleteFieldOptionStatusEnum.tooManyResults;
          break;
      }
    }
  };
  dataActions: any = {
    getPrincipalList: (principalRequest?: any): void => {
      this.principalOptions = [];
      this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getPrincipals(principalRequest)
        .subscribe(
          response => this.dataSubscribers.getPrincipalListData(response),
          err => this.dataSubscribers.defaultErrorListData('principal', err));
    },
    getSalesmanList: (salesModelRequest?: any): void => {
      this.salesmanOptions = [];
      this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getSalesmans(salesModelRequest)
        .subscribe(
          response => this.dataSubscribers.getSalesmanListData(response),
          err => this.dataSubscribers.defaultErrorListData('salesman', err));
    },
    getTitleList: (salesModelRequest?: any): void => {
      this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
      this.dictionaryService.getTitles(salesModelRequest)
        .subscribe(
          response => this.dataSubscribers.getTitleListData(response),
          err => this.dataSubscribers.defaultErrorListData('title', err));
    }
  };
  eventListeners: any = {
    onChangePrincipalSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.principalRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getPrincipalList(this.principalRequest);
      } else {
        this.principalOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeSalesmanSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.salesmanRequest.setFilters(new DictionaryFiltersRequestModel({name: text}));
        this.dataActions.getSalesmanList(this.salesmanRequest);
      } else {
        this.salesmanOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onChangeTitleSearchText: (text) => {
      if (text && (text.length >= 3) || !text || text === '') {
        this.titleRequest.setFilters(new DictionaryFiltersRequestModel({name: text, kind: 'digital'}));
        this.dataActions.getTitleList(this.titleRequest);
      } else {
        this.titleOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onTitleSelect: (title, reservationId, publisherKeyId) => {
      const foundIndex = _.findIndex(this.publisherStatistics, {reservationId: reservationId});
      const foundTitle = _.find(this.publisherStatistics[foundIndex].publishers[publisherKeyId].partition, {name: title.externalId});
      if (!foundTitle) {
        const partition: StatisticsPartition = {
          name: title.externalId,
          value: this.publisherStatistics[foundIndex].publishers[publisherKeyId].value,
          id: this.publisherStatistics[foundIndex].publishers[publisherKeyId].partition.length
        };
        this.publisherStatistics[foundIndex].publishers[publisherKeyId].partition.push(partition);
        if (!this.publisherPartitionStatisticsFormControl[reservationId][publisherKeyId][partition.id]) {
          this.publisherPartitionStatisticsFormControl[reservationId][publisherKeyId][partition.id] = {
            externalId: title.externalId,
            formControlValue: {}
          };
        }
        this.publisherPartitionStatisticsFormControl[reservationId][publisherKeyId][partition.id].formControlValue = new FormControl();
        this.publisherPartitionStatisticsFormControl[reservationId][publisherKeyId][partition.id].formControlValue.setValue(partition.value);
      }
      setTimeout(() => {
        this.publisherStatisticsTitle[reservationId][publisherKeyId].setValue(null);
      });
    },
    onChangeReservationSearchText: (text) => {
      if (text && (text.length >= 3) || !text) {
        this.reservationRequest.setFilters(new ReservationDataPageFilters({name: text, unusedOnly: 1}));
        this.getReservationDataPage();
      } else {
        this.reservationOptionsStatus = AutocompleteFieldOptionStatusEnum.needMoreChars;
      }
    },
    onSelectReservations: ($event): void => {
      const paymentSchedule = {};
      let orderValue = 0;

      if ($event && $event.length) {
        let minDateFrom = null;
        let maxDateTo = null;

        _.each($event, reservation => {
          if (this.orderForm.controls['platform'].value !== 'print') {
            if (reservation.additionalData.dateFrom < minDateFrom || !minDateFrom) {
              minDateFrom = reservation.additionalData.dateFrom;
            }
            if (reservation.additionalData.dateTo > maxDateTo || !maxDateTo) {
              maxDateTo = reservation.additionalData.dateTo;
            }
            this.orderForm.controls['dateFrom'].setValue(minDateFrom);
            this.orderForm.controls['dateTo'].setValue(maxDateTo);
          }

          if (reservation.additionalData.paymentPartition.length) {
            const paymentPartitionList = reservation.additionalData.paymentPartition;

            for (const paymentPartition of paymentPartitionList) {
              const paymentDateFrom = paymentPartition['date_from'];

              if (paymentSchedule[paymentDateFrom] === undefined) {
                paymentSchedule[paymentDateFrom] = paymentPartition.value;
              } else {
                paymentSchedule[paymentDateFrom] += paymentPartition.value;
              }
            }
          }

          orderValue += reservation.additionalData.value;
        });

        this.orderForm.controls['reservation'].setValue($event);
      } else {
        this.orderForm.controls['reservation'].setValue(null);
      }

      this.selectedReservations = _.map($event, 'additionalData');
      this.orderItemData.value = orderValue;
      this.orderItemData.paymentSchedule = paymentSchedule;
    },
    onEncloseMainFile: ($event): void => {
      this.fileService.upload($event.formData).subscribe((response: Response) => {
        this.orderForm.controls['orderDocument'].setValue(response['data']);
      }, err => {
        this.errorManager.displayError(err.error);
      });
    },
    onEncloseAdditionalFile: ($event): void => {
      this.fileService.upload($event.formData).subscribe((response: Response) => {
        let additionalFiles = [];
        if (!this.orderForm.controls['additionalDocuments'].value || !_.isArray(this.orderForm.controls['additionalDocuments'].value)) {
          additionalFiles = [];
        } else if (_.isArray(this.orderForm.controls['additionalDocuments'].value)) {
          additionalFiles = this.orderForm.controls['additionalDocuments'].value;
        }
        additionalFiles.push(response['data']);
        this.orderForm.controls['additionalDocuments'].setValue(additionalFiles);
      }, err => {
        this.errorManager.displayError(err.error);
      });
    }
  };
  actions: any = {
    deleteFile: (file, target: string): void => {
      if (file && file.id) {
        this.fileService.deleteFile(file.id).subscribe((response: Response) => {
          if (target === 'orderDocument') {
            this.orderForm.controls['orderDocument'].setValue(null);
          } else if (target === 'additionalDocuments') {
            let additionalFiles;
            additionalFiles = this.orderForm.controls['additionalDocuments'].value;
            additionalFiles = _.map(additionalFiles, item => {
              if (item['id'] !== file.id) {
                return item;
              }
            });
            this.orderForm.controls['additionalDocuments'].setValue(_.compact(additionalFiles));
          }
        }, (err) => {
          this.errorManager.displayError(err.error);
        });
      }
    }
  };

  constructor(private formBuilder: FormBuilder,
              private clientService: ClientService,
              private orderService: OrderService,
              private fileService: FileService,
              private errorManager: ApiErrorManagerService,
              private reservationService: ReservationService,
              private toaster: ToasterService,
              private dictionaryService: DictionaryService,
              private profileService: ProfileService,
              private dialog: MatDialog) {
    this.dataActions.getTitleList(this.titleRequest);
    this.dataActions.getSalesmanList();
    this.dataActions.getPrincipalList();
  }

  ngOnInit() {
    this.isCoordinatorView = (this.profileService.currentUser && this.profileService.currentUser.type === 'coordinator');
    this.isMerchantView = (this.profileService.currentUser && this.profileService.currentUser.type === 'merchant');
    this.isAdminView = (this.profileService.currentUser && this.profileService.currentUser.type === 'admin');

    if (this.orderItemData) {
      this.selectedReservations = this.orderItemData.reservations;
      this.orderService.getStatuses(this.orderItemData.status).subscribe((response: Response) => {
        this.statusOptions = response['data'].statuses;
        this.statusOptionsKeys = Object.keys(this.statusOptions);
      });

      this.isEdit = true;
      this.formTypeSelected = this.formType.new;
      if (this.orderItemData.client) {
        this.selectedClientId = this.orderItemData.client.id;
      }

      this.orderForm = this.formBuilder.group({
        name: [this.orderItemData.name, Validators.required],
        reservation: [this.orderItemData.reservations, Validators.required],
        client: [this.orderItemData.client, Validators.required],
        dateFrom: [this.orderItemData.dateFrom ? new Date(this.orderItemData.dateFrom) : null],
        dateTo: [this.orderItemData.dateTo ? new Date(this.orderItemData.dateTo) : null],
        comment: [this.orderItemData.comment],
        kind: [this.orderItemData.kind, Validators.required],
        orderDocument: [this.orderItemData.orderDocument],
        additionalDocuments: [this.orderItemData.additionalDocuments],
        status: [this.orderItemData.status, this.orderItemData.status !== 'draft' ? Validators.required : null],
        platform: [this.orderItemData.platform ? this.orderItemData.platform : 'digital', Validators.required],
        pdfPriceVisible: [this.orderItemData.pdfPriceVisible],
        pdfDiscountVisible: [this.orderItemData.pdfDiscountVisible],
        pdfQuantityVisible: [this.orderItemData.pdfQuantityVisible],
        salesman: ['', Validators.required],
        principal: ['', Validators.required],
        principalPerson: [this.orderItemData.principalPerson, Validators.required]
      });


      if (this.orderItemData.platform === 'print') {

      } else {
        this.orderForm.controls['dateFrom'].setValidators(Validators.required);
        this.orderForm.controls['dateTo'].setValidators(Validators.required);
      }

      let currentItem = {};
      if (this.orderItemData.salesman) {
        currentItem['salesman'] = {
          id: this.orderItemData.salesman.id,
          label: this.orderItemData.salesman.name
        };

        this.orderForm.controls['salesman'].setValue(currentItem['salesman']);
      }

      if (this.orderItemData.principal) {
        currentItem['principal'] = {
          id: this.orderItemData.principal.id,
          label: this.orderItemData.principal.name
        };

        this.orderForm.controls['principal'].setValue(currentItem['principal']);
      }

      this.selectedOrderCloneReservations = this.orderItemData.reservations;
      this.orderForm.valueChanges.subscribe(val => {
        setTimeout(() => {
          this.onValidationChange.emit(this.orderForm.valid);
        }, 100);
      });

      this.getClientDataPage();
      this.getOrderDataPage();
      this.getReservationDataPage();

      if (this.isManagerView || this.isEditable === false) {
        this.disableForm();
      }
    }
  }

  disableForm() {
    FormExtrasUtil.disableAllFormFields(this.orderForm);
  }

  // createPublisherStatistics(statistics) {
  //   this.publisherStatistics = [];
  //   this.publisherStatisticsFormControl = {};
  //   this.publisherPartitionStatisticsFormControl = {
  //     externalId: null,
  //     formControlValue: null
  //   };
  //
  //   const currentStatistics = statistics || this.orderItemData.publisherStatistics;
  //   _.each(currentStatistics, (statData: any, reservationId) => {
  //     const reservationPublisherStatistics = {
  //       name: statData.reservationName,
  //       reservationId,
  //       partialReservations: []
  //     };
  //
  //     _.each(statData.partialReservations, (partialReservationsData: any, partialReservationMonth) => {
  //       reservationPublisherStatistics.partialReservations.push({
  //         partialReservationMonth,
  //         partialReservationId: partialReservationsData.partialReservationId,
  //         publishers: partialReservationsData.publishers
  //       })
  //     });
  //
  //     if (!this.publisherStatisticsFormControl[reservationId]) {
  //       this.publisherStatisticsFormControl[reservationId] = {};
  //     }
  //     if (!this.publisherPartitionStatisticsFormControl[reservationId]) {
  //       this.publisherPartitionStatisticsFormControl[reservationId] = {};
  //     }
  //
  //     if (!this.publisherStatisticsTitle[reservationId]) {
  //       this.publisherStatisticsTitle[reservationId] = {};
  //     }
  //
  //     reservationPublisherStatistics.partialReservations = _.map(reservationPublisherStatistics.partialReservations, partialReservation => {
  //       let i = 0;
  //
  //       partialReservation.publishers = _.map(partialReservation.publishers, publisher => {
  //         if (!this.publisherPartitionStatisticsFormControl[reservationId][i]) {
  //           this.publisherPartitionStatisticsFormControl[reservationId][i] = {};
  //         }
  //
  //         if (!this.publisherStatisticsTitle[reservationId][i]) {
  //           this.publisherStatisticsTitle[reservationId][i] = new FormControl();
  //         }
  //
  //         const partitions = [];
  //         let j = 0;
  //         _.each(publisher[Object.keys(publisher)[0]].partition, (partition, titleKey) => {
  //           partitions.push({
  //             name: titleKey,
  //             value: partition,
  //             id: j
  //           });
  //
  //           if (!this.publisherPartitionStatisticsFormControl[reservationId][i][j]) {
  //             this.publisherPartitionStatisticsFormControl[reservationId][i][j] = {
  //               externalId: null,
  //               formControlValue: null
  //             };
  //           }
  //           this.publisherPartitionStatisticsFormControl[reservationId][i][j].externalId = titleKey;
  //           this.publisherPartitionStatisticsFormControl[reservationId][i][j].formControlValue = new FormControl();
  //           this.publisherPartitionStatisticsFormControl[reservationId][i][j].formControlValue.setValue(partition);
  //           j++;
  //         });
  //
  //         let publisherData = {
  //           name: Object.keys(publisher)[0],
  //           emissions: publisher[Object.keys(publisher)[0]].emissions,
  //           value: publisher[Object.keys(publisher)[0]].value,
  //           partition: partitions,
  //           id: i
  //         };
  //         this.publisherStatisticsFormControl[reservationId][i] = new FormControl();
  //         this.publisherStatisticsFormControl[reservationId][i].setValue(publisherData.value);
  //
  //         i++;
  //
  //         return publisherData;
  //       });
  //       return partialReservation;
  //     });
  //
  //
  //     this.publisherStatistics.push(reservationPublisherStatistics);
  //   });
  //
  //   console.log(this.publisherStatistics);
  // }

  // createEmptyPublisherStatistics() {
  //   this.publisherStatisticsFormControl = {};
  //   this.publisherPartitionStatisticsFormControl = {
  //     externalId: null,
  //     formControlValue: null
  //   };
  //   let publisherStatistics = [];
  //   _.each(this.orderItemData.reservations, reservation => {
  //     let publisherStatistic;
  //
  //     if (!this.publisherStatisticsFormControl[reservation.id]) {
  //       this.publisherStatisticsFormControl[reservation.id] = {};
  //       publisherStatistic = {
  //         name: this.getReservationLabel(reservation),
  //         reservationId: reservation.id,
  //         publishers: []
  //       };
  //     }
  //     if (!this.publisherPartitionStatisticsFormControl[reservation.id]) {
  //       this.publisherPartitionStatisticsFormControl[reservation.id] = {};
  //     }
  //
  //     if (!this.publisherStatisticsTitle[reservation.id]) {
  //       this.publisherStatisticsTitle[reservation.id] = {};
  //     }
  //     let publisherStatisticItem;
  //     if (reservation.publishers && _.isArray(reservation.publishers) && reservation.publishers.length) {
  //       _.each(reservation.publishers, (publisher, publisherKey) => {
  //         publisherStatisticItem = {
  //           emissions: 0,
  //           id: publisherKey,
  //           value: reservation.value,
  //           name: publisherKey,
  //           partition: []
  //         };
  //         if (!this.publisherPartitionStatisticsFormControl[reservation.id][publisherKey]) {
  //           this.publisherPartitionStatisticsFormControl[reservation.id][publisherKey] = {};
  //         }
  //
  //         if (!this.publisherStatisticsTitle[reservation.id][publisherKey]) {
  //           this.publisherStatisticsTitle[reservation.id][publisherKey] = new FormControl();
  //         }
  //         this.publisherStatisticsFormControl[reservation.id][publisherKey] = new FormControl();
  //         this.publisherStatisticsFormControl[reservation.id][publisherKey].setValue(publisherStatisticItem.value);
  //
  //         publisherStatistic.publishers.push(publisherStatisticItem);
  //       });
  //     } else {
  //       publisherStatisticItem = {
  //         emissions: 0,
  //         id: 0,
  //         value: reservation.value,
  //         name: 0,
  //         partition: []
  //       };
  //
  //       if (!this.publisherPartitionStatisticsFormControl[reservation.id][0]) {
  //         this.publisherPartitionStatisticsFormControl[reservation.id][0] = {};
  //       }
  //
  //       if (!this.publisherStatisticsTitle[reservation.id][0]) {
  //         this.publisherStatisticsTitle[reservation.id][0] = new FormControl();
  //       }
  //       this.publisherStatisticsFormControl[reservation.id][0] = new FormControl();
  //       this.publisherStatisticsFormControl[reservation.id][0].setValue(publisherStatisticItem.value);
  //
  //       publisherStatistic.publishers.push(publisherStatisticItem);
  //
  //     }
  //
  //     publisherStatistics.push(publisherStatistic);
  //
  //   });
  //
  //   return publisherStatistics;
  // }

  boot() {
    this.createForm(this.formType.new);
    this.getClientDataPage();
    this.getOrderDataPage();
    this.orderForm.valueChanges.subscribe(val => {
      setTimeout(() => {
        this.onValidationChange.emit(this.orderForm.valid);
      }, 100);
    });
    this.orderCloneForm = this.formBuilder.group({
      order: ['', Validators.required]
    });

    if (this.orderForm) {
      if (this.formTypeSelected === FormTypeEnum.new) {
        this.orderForm.controls['platform'].setValue('digital');
      }
      if (this.formTypeSelected === FormTypeEnum.unstd) {
        this.orderForm.controls['platform'].setValue('digital');
      }
      if (this.formTypeSelected === FormTypeEnum.print) {
        this.orderForm.controls['platform'].setValue('print');
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.orderForm && changes['dateFrom']) {
      this.orderForm.controls['dateFrom'].setValue(new Date(changes['dateFrom'].currentValue));
      this.orderForm.controls['dateTo'].setValue(new Date(changes['dateTo'].currentValue));
    }
  }

  displayClientName(clientItem: Client): string {
    return clientItem && clientItem.name || '';
  }

  displayOrderName(orderItem: Order): string {
    return orderItem.name;
  }

  onSelectTypeChange($event): void {
    this.orderCloneForm = null;
    this.formTypeSelected = parseInt($event.value);
    this.isClone = !!($event.value === this.formType.clone + '');
    this.orderForm = null;
    this.selectedClientId = null;
    this.boot();
  }

  onChangeClientAutoComplete($event) {
    this.hintText['client'] = null;
    this.clientData = [];
    this.orderForm.controls['reservation'].setValue(null);
    if (this.orderForm.controls['client'].value.length >= 3 || this.orderForm.controls['client'].value.length === 0) {
      this.clientDataPageRequest.filters = new ClientDataPageFilters({name: this.orderForm.controls['client'].value});
      // this.clientDataPageRequest.setPage(1);
      this.getClientDataPage()
    } else {
      this.hintText['client'] = 'minimum 3 znaki, aby wyszukać';
    }
  }

  onClientSelect($event) {
    this.orderForm.controls['reservation'].setValue(null);

    this.selectedClientId = $event.option.value ? $event.option.value.id : null;

    if (this.selectedClientId) {
      this.getReservationDataPage();
    }
  }

  onOrderSelect($event): void {
    if ($event.option.value) {
      let selectedOrder = $event.option.value;
      this.orderForm = this.formBuilder.group({
        name: [selectedOrder.name, Validators.required],
        reservation: [selectedOrder.reservations, Validators.required],
        client: [selectedOrder.client, Validators.required],
        dateFrom: [selectedOrder.dateFrom ? new Date(selectedOrder.dateFrom) : null, Validators.required],
        dateTo: [selectedOrder.dateTo ? new Date(selectedOrder.dateTo) : null, Validators.required],
        comment: [selectedOrder.comment],
        orderDocument: [selectedOrder.orderDocument],
        kind: [selectedOrder.kind, Validators.required],
        additionalDocuments: [selectedOrder.additionalDocuments],
        platform: [selectedOrder.platform],
        pdfPriceVisible: [selectedOrder.pdfPriceVisible],
        pdfDiscountVisible: [selectedOrder.pdfDiscountVisible],
        pdfQuantityVisible: [selectedOrder.pdfQuantityVisible],
        salesman: ['', Validators.required],
        principal: ['', Validators.required],
        principalPerson: [selectedOrder.principalPerson, Validators.required]
      });
      if (this.orderForm.controls['platform'].value !== 'print') {
        this.orderForm.controls['dateFrom'].setValidators(Validators.required);
        this.orderForm.controls['dateTo'].setValidators(Validators.required);
      }

      let currentItem = {};
      if (selectedOrder.salesman) {
        currentItem['salesman'] = {
          id: selectedOrder.salesman.id,
          label: selectedOrder.salesman.name
        };

        this.orderForm.controls['salesman'].setValue(currentItem['salesman']);
      }

      if (selectedOrder.principal) {
        currentItem['principal'] = {
          id: selectedOrder.principal.id,
          label: selectedOrder.principal.name
        };

        this.orderForm.controls['principal'].setValue(currentItem['principal']);
      }

      this.selectedOrderCloneReservations = selectedOrder.reservations;
      this.selectedClientId = selectedOrder.client ? selectedOrder.client.id : null;
      if (selectedOrder.kind === 'standard') {
        this.formTypeSelected = this.formType.new;
      } else {
        this.formTypeSelected = this.formType.unstd;
      }
      this.orderForm.valueChanges.subscribe(val => {
        this.onValidationChange.emit(this.orderForm.valid);
      });
      this.onValidationChange.emit(this.orderForm.valid);
    }
  }

  onChangeOrderAutoComplete() {
    this.hintText['order'] = null;
    this.clientData = [];
    if (this.orderCloneForm.controls['order'].value.length >= 3 || this.orderCloneForm.controls['order'].value.length === 0) {
      this.orderDataPageRequest.filters = new OrderDataPageFilters({name: this.orderCloneForm.controls['order'].value});

      this.getOrderDataPage()
    } else {
      this.hintText['order'] = 'minimum 3 znaki, aby wyszukać';
    }
  }

  getClientDataPage() {
    this.isLoading['client'] = true;
    this.hintText['client'] = '';
    this.clientService.getList(this.clientDataPageRequest).subscribe((result: ClientDataPage) => {
      this.isLoading['client'] = false;
      this.clientData = result.items;
      if (!result.items.length) {
        this.hintText['client'] = 'Brak wyników spełniających kryteria';
      } else {
        this.hintText['client'] = null;
      }
    }, err => {
      this.isLoading['client'] = false;
      this.hintText['client'] = err.error.join('. ');
    });
  }

  getOrderDataPage() {
    this.isLoading['order'] = true;
    this.hintText['order'] = '';
    this.orderService.getList(this.orderDataPageRequest).subscribe((result: OrderDataPage) => {
      this.isLoading['order'] = false;
      this.orderData = result;
      if (!result) {
        this.hintText['order'] = 'Brak wyników spełniających kryteria';
      } else {
        this.hintText['order'] = null;
      }
    }, err => {
      this.isLoading['order'] = false;
      this.hintText['order'] = err.error.error.message;
    });
  }

  getReservationDataPage(forceUpdate?: boolean) {
    forceUpdate = true;
    this.reservationOptionsStatus = AutocompleteFieldOptionStatusEnum.loading;
    if (!this.orderItemData) {
      if (this.formTypeSelected === this.formType.unstd) {
        // this.reservationRequest.filters.type = 'custom';
      } else if (this.formTypeSelected === this.formType.new) {
        // this.reservationRequest.filters.type = 'single,package';
      } else if (this.formTypeSelected === this.formType.print) {
        // this.reservationRequest.filters.platform = this.orderForm.controls['platform'].value;
      }
    }

    if (this.orderItemData) {
      if (this.orderItemData.kind === 'niestandard' && this.orderItemData.platform !== 'print') {
        // this.reservationRequest.filters.type = 'custom';
      } else if (this.orderItemData.kind === 'standard' && this.orderItemData.platform !== 'print') {
        // this.reservationRequest.filters.type = 'single,package';
      } else if (this.orderItemData.platform === 'print') {
        // this.reservationRequest.filters.platform = this.orderItemData.platform;
      }
    }

    this.reservationRequest.filters.client = this.orderForm.controls['client'].value ? this.orderForm.controls['client'].value.id : null;
    this.reservationService.getList(this.reservationRequest).pipe(map((response: ReservationDataPage) => {
      const reservationOptions: Array<IAutocompleteMultiselectOption> = [];

      if (response && response.items && response.items.length) {
        _.each(response.items, (val: Reservation) => {
          let label: string = '';
          const dateFrom = new DatePipe('pl-PL').transform(val.dateFrom, 'dd.MM.yyyy');
          const dateTo = new DatePipe('pl-PL').transform(val.dateTo, 'dd.MM.yyyy');
          if (val.platform !== 'print') {
            label = `${dateFrom} - ${dateTo} ${val.comment ? val.comment : ''}`
          } else {
            label = `${val.editionNumber ? val.editionNumber + '/' : ''}${val.editionYear ? val.editionYear : ''} ${val.title ? val.title.name : ''}`
          }
          reservationOptions.push({
            id: val.id,
            label: `${label} (${val.platform[0]})`,
            additionalData: val
          });
        });


        this.reservationOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;
      } else {
        this.reservationOptionsStatus = AutocompleteFieldOptionStatusEnum.noResults;
      }
      const selectedItems = [];

      let currentReservations = [];
      if (this.isEdit && this.orderItemData.reservations && this.orderItemData.reservations.length) {
        currentReservations = this.orderItemData.reservations;
      } else {
        currentReservations = this.selectedReservations;
      }

      _.each(currentReservations, reservation => {
        const currentItem = _.find(reservationOptions, {id: reservation.id});
        if (!currentItem) {
          let label: string = '';
          let newCurrentItem: IAutocompleteMultiselectOption = {
            id: reservation.id,
            label: ''
          };
          let dateFrom = new DatePipe('pl-PL').transform(reservation.dateFrom, 'dd.MM.yyyy');
          let dateTo = new DatePipe('pl-PL').transform(reservation.dateTo, 'dd.MM.yyyy');

          if (this.orderItemData.kind === 'niestandard' && this.orderItemData.platform !== 'print' ||
            this.orderItemData.kind === 'standard' && this.orderItemData.platform !== 'print') {
            label = `${dateFrom} - ${dateTo} ${reservation.comment ? reservation.comment : ''}`
          } else if (this.orderItemData.platform === 'print') {
            label = `${reservation.editionNumber ? reservation.editionNumber + '/' : ''}${reservation.editionYear ? reservation.editionYear : ''} ${reservation.title ? reservation.title.name : ''}`
          }

          newCurrentItem.label = `${label} (${reservation.platform[0]})`;
          newCurrentItem.additionalData = reservation;
          reservationOptions.push(newCurrentItem);
          selectedItems.push(newCurrentItem);

        } else {
          selectedItems.push(currentItem);
        }
      });
      this.reservationOptionsStatus = AutocompleteFieldOptionStatusEnum.ok;

      if (!this.updateReservationFormControlDisabled || forceUpdate) {
        this.orderForm.controls['reservation'].setValue(selectedItems);
        this.updateReservationFormControlDisabled = true;
      }

      let a: Array<any> = _.sortBy(_.sortBy((_.sortBy(reservationOptions, function (item) {
        return item.additionalData.editionNumber;
      })), function (item: any) {
        return item.additionalData.editionYear;
      }), function (item: any) {
        return item.additionalData.dateFromInt;
      }, function (item: any) {
        return item.additionalData.dateToInt;
      });
      this.reservationOptions = a;
    }))
      .subscribe(
        d => d,
        err => {
          this[`reservationOptionsStatus`] = AutocompleteFieldOptionStatusEnum.tooManyResults;
        });
  }

  getReservationLabel(reservation) {
    let label = '';
    let kind = null;
    let platform = null;
    if (this.orderForm && this.orderForm.controls['kind']) {
      kind = this.orderForm && this.orderForm.controls['kind'].value;
    }
    if (this.orderForm && this.orderForm.controls['platform']) {
      platform = this.orderForm && this.orderForm.controls['platform'].value;
    }
    if (reservation) {
      let dateFrom = new DatePipe('pl-PL').transform(reservation.dateFrom, 'dd.MM.yyyy');
      let dateTo = new DatePipe('pl-PL').transform(reservation.dateTo, 'dd.MM.yyyy');
      if (kind === 'niestandard' && platform !== 'print' ||
        kind === 'standard' && platform !== 'print') {
        label = `${dateFrom} - ${dateTo} ${reservation.comment ? reservation.comment : ''}`
      } else if (platform === 'print') {
        label = `${reservation.editionNumber ? reservation.editionNumber + '/' : ''}${reservation.editionYear ? reservation.editionYear : ''} ${reservation.title ? reservation.title.name : ''}`
      }
    }

    return label;
  }

  saveDraft() {
    this.submittedForm['orderForm'] = true;
    const orderRequestData = {create_draft_order: new OrderRequest(this.getFormRequestObject()).getRequestData()};
    orderRequestData.create_draft_order.publisherStatistics = undefined;
    this.orderService.saveDraft(orderRequestData).subscribe(response => {
      this.toaster.displayMessage('Draft został utworzony');
      this.onSaved.emit({response: response['data'], downloadFile: true});
      this.orderService.onOrderCreateOrEdit$.emit(true);
    }, err => {
      this.errorManager.displayError(err.error);
    });
  }

  save() {
    this.submittedForm['orderForm'] = true;
    if (this.orderForm.valid) {
      const orderRequestData = {create_order: new OrderRequest(this.getFormRequestObject()).getRequestData()};
      orderRequestData.create_order.publisherStatistics = undefined;
      this.orderService.save(orderRequestData).subscribe(response => {
        this.toaster.displayMessage('Zlecenie zostało utworzone');
        this.onSaved.emit({response: response['data'], downloadFile: false});
        this.orderService.onOrderCreateOrEdit$.emit(true);
      }, err => {
        this.errorManager.displayError(err.error);
      });
    }
  }

  public updateOrder(type?: string, sendEmail?: boolean, sendToSAP?: boolean): void {
    this.submittedForm['orderForm'] = true;

    if (this.orderForm.valid) {
      const orderReqObj = new OrderRequest(this.orderItemData);
      orderReqObj.mergeData(this.getFormRequestObject());

      const orderRequestData = {edit_order: orderReqObj.getRequestData()};
      orderRequestData.edit_order.platform = undefined;

      if (type) {
        orderRequestData.edit_order.status = type;
      }

      if (sendEmail) {
        orderRequestData.edit_order.saveAndSend = 1;
      }

      orderRequestData.edit_order.validateStatistics = false;
      orderRequestData.edit_order.publisherStatistics = _.merge({}, this.requestStatistics);

      this.orderService.update(orderRequestData).subscribe(() => {
        if (sendToSAP) {
          this.orderService.orderExport(orderRequestData.edit_order.id).subscribe();
        }

        this.toaster.displayMessage('Zlecenie zostało zapisane');
        this.onSaved.emit(true);

        this.orderService.onOrderCreateOrEdit$.emit(true);
      }, err => {
        this.errorManager.displayError(err.error);
      });
    }
  }

  updateOrderAndSend() {
    this.submittedForm['orderForm'] = true;
    if (this.orderForm.valid) {
      const orderReqObj = new OrderRequest(this.orderItemData);

      orderReqObj.mergeData(this.getFormRequestObject());

      const orderRequestData = {edit_order: orderReqObj.getRequestData()};
      orderRequestData.edit_order.status = 'confirm';
      orderRequestData.edit_order.platform = undefined;

      orderRequestData.edit_order.validateStatistics = true;
      orderRequestData.edit_order.publisherStatistics = _.merge({}, this.requestStatistics);

      // if (this.publisherStatistics && this.publisherStatistics.length) {
      //   orderRequestData.edit_order.publisherStatistics = this.buildPublisherStatisticsForRequest();
      // }

      this.orderService.update(orderRequestData).subscribe(response => {
        this.orderService.orderExport(orderRequestData.edit_order.id).subscribe(() => {
        });
        this.toaster.displayMessage('Zlecenie zostało przekazane');
        this.onSaved.emit(true);
        this.orderService.onOrderCreateOrEdit$.emit(true);
      }, err => {
        this.errorManager.displayError(err.error);
      });
    }
  }

  removeSpaces(val: string): number {
    if (val && _.isString(val)) {
      const value = val.replace(',', '.').split(' ').join('');
      return +value;
    } else if (_.isNumber(val)) {
      return val;
    } else {
      return 0;
    }
  }

  getFormRequestObject() {
    let orderRequest: any;
    orderRequest = {
      name: this.orderForm.controls['name'].value,
      client: new Client({id: this.selectedClientId}),
      reservations: this.orderForm.controls['reservation'].value ? _.map(this.orderForm.controls['reservation'].value, 'id') : null,
      comment: this.orderForm.controls['comment'].value,
      dateFrom: this.orderForm.controls['dateFrom'].value,
      dateTo: this.orderForm.controls['dateTo'].value,
      kind: this.orderForm.controls['kind'].value,
      orderDocument: this.orderForm.controls['orderDocument'].value,
      additionalDocuments: this.orderForm.controls['additionalDocuments'].value,
      clone: this.isClone,
      platform: this.orderForm.controls['platform'].value,
      pdfPriceVisible: this.orderForm.controls['pdfPriceVisible'].value,
      pdfDiscountVisible: this.orderForm.controls['pdfDiscountVisible'].value,
      pdfQuantityVisible: this.orderForm.controls['pdfQuantityVisible'].value,
      principal: this.orderForm.controls['principal'].value.id,
      salesman: this.orderForm.controls['salesman'].value.id,
      principalPerson: this.orderForm.controls['principalPerson'].value
    };

    if (this.isEdit) {
      orderRequest.status = this.orderForm.controls['status'].value;
    }
    return orderRequest;
  }

  // buildPublisherStatisticsForRequest() {
  //   const publisherStatisticsRequest = {};
  //
  //   _.each(this.publisherStatistics, publisherReservationStatistic => {
  //     if (!publisherStatisticsRequest[publisherReservationStatistic.reservationId]) {
  //       publisherStatisticsRequest[publisherReservationStatistic.reservationId] = {
  //         reservationName: publisherReservationStatistic.name,
  //         publishers: []
  //       };
  //     }
  //     const publisherStatistics = {};
  //     _.each(publisherReservationStatistic.publishers, (publisher, publisherKey) => {
  //       publisherStatistics[publisher.name] = {
  //         emissions: publisher.emissions,
  //         value: this.removeSpaces(this.publisherStatisticsFormControl[publisherReservationStatistic.reservationId][publisher.id].value),
  //         partition: {}
  //       };
  //       _.each(publisher.partition, partition => {
  //         if ((this.publisherPartitionStatisticsFormControl[publisherReservationStatistic.reservationId] &&
  //           this.publisherPartitionStatisticsFormControl[publisherReservationStatistic.reservationId][publisher.id][partition.id] &&
  //           this.publisherPartitionStatisticsFormControl[publisherReservationStatistic.reservationId][publisher.id][partition.id].formControlValue)) {
  //           publisherStatistics[publisher.name].partition[partition.name] = this.removeSpaces(this.publisherPartitionStatisticsFormControl[publisherReservationStatistic.reservationId][publisher.id][partition.id].formControlValue.value)
  //
  //         }
  //       });
  //
  //       if (!publisherStatisticsRequest[publisherReservationStatistic.reservationId].publishers[publisherKey]) {
  //         publisherStatisticsRequest[publisherReservationStatistic.reservationId].publishers[publisherKey] = {};
  //       }
  //       const currentPublisherData = {};
  //       currentPublisherData[publisher.name] = publisherStatistics[publisher.name];
  //       publisherStatisticsRequest[publisherReservationStatistic.reservationId].publishers[publisherKey] = currentPublisherData;
  //     });
  //
  //
  //   });
  //
  //   return publisherStatisticsRequest;
  // }

  // openSelectTitlePopup(reservationId: any, statisticId: number, publisherStatistics: any, publisherIndex) {
  //   let data = {
  //     reservationId,
  //     publisherStatisticsTitles: this.publisherStatisticsTitle,
  //     statisticId,
  //     publisherStatistics
  //   };
  //
  //   let dialogRef = this.dialog.open(SelectTitlePopupComponent, {
  //     width: '540px',
  //     data: Object.assign({}, data),
  //     disableClose: true
  //   });
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       _.each(result.selectedTitles, selectedTitle => {
  //         this.eventListeners.onTitleSelect(selectedTitle, reservationId, statisticId)
  //       });
  //
  //       const selectedTitleExternalIds = _.map(result.selectedTitles, 'externalId');
  //       const currentSelectedTitleExternalIds = _.map(this.publisherPartitionStatisticsFormControl[reservationId][statisticId], 'externalId');
  //       _.pullAll(currentSelectedTitleExternalIds, selectedTitleExternalIds);
  //
  //       this.publisherStatistics = _.map(this.publisherStatistics, pubStat => {
  //         if (pubStat.reservationId === reservationId) {
  //           if (pubStat.publishers[publisherIndex] && pubStat.publishers[publisherIndex].partition) {
  //             const currentPartitionIds = _.map(pubStat.publishers[publisherIndex].partition, 'name');
  //             pubStat.publishers[publisherIndex].partition = _.map(pubStat.publishers[publisherIndex].partition, (partitionItem: any) => {
  //               const foundPartitionName = _.find(selectedTitleExternalIds, titleId => {
  //                 return (partitionItem.name === titleId);
  //               });
  //               if (foundPartitionName) {
  //                 return partitionItem;
  //               }
  //             });
  //             pubStat.publishers[publisherIndex].partition = _.compact(pubStat.publishers[publisherIndex].partition);
  //             if (this.publisherPartitionStatisticsFormControl[reservationId][publisherIndex]) {
  //               this.publisherPartitionStatisticsFormControl[reservationId][publisherIndex] = _.map(this.publisherPartitionStatisticsFormControl[reservationId][publisherIndex], (partitionItem: any) => {
  //                 const foundPartitionName = _.find(selectedTitleExternalIds, titleId => {
  //                   return (partitionItem.externalId === titleId);
  //                 });
  //                 if (foundPartitionName) {
  //                   return partitionItem;
  //                 }
  //               });
  //               this.publisherPartitionStatisticsFormControl[reservationId][publisherIndex] = _.compact(this.publisherPartitionStatisticsFormControl[reservationId][publisherIndex]);
  //
  //             }
  //           }
  //         }
  //         return pubStat;
  //       });
  //       this.createPublisherStatistics(this.buildPublisherStatisticsForRequest());
  //     }
  //   });
  // }

  openReservationEditPopup($event: Reservation): void {
    if ($event) {
      let dialogRef = this.dialog.open(ReservationEditPopupComponent, {
        width: '640px',
        data: {
          reservationItemData: $event
        },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe((updatedReservation: Reservation) => {
        if (!updatedReservation) {
          return;
        }

        if (this.orderItemData) {
          const reservationList = [];

          for (const selectedReservation of this.selectedReservations) {
            if (updatedReservation.id === selectedReservation.id) {
              reservationList.push(updatedReservation);
            } else {
              reservationList.push(selectedReservation);
            }
          }

          this.orderItemData.reservations = reservationList;
          this.selectedReservations = reservationList;
          this.orderForm.controls['reservation'].setValue(reservationList);

          this.updateOrderValuesAndDates(reservationList);
          this.getReservationDataPage(true);

        } else {
          this.getReservationDataPage(true);
        }
      });
    }
  }

  showPublishers(publishers: any) {
    return _.map(publishers, 'name').join(', ');
  }

  ngOnDestroy() {
    this.orderForm = null;
    this.orderItemData = null;
  }

  getOrder(id: number) {
    this.orderService.getById(id).subscribe((data: Order) => {
      this.orderItemData.reservations = data.reservations;
      this.selectedReservations = data.reservations;
      this.orderForm.controls['reservation'].setValue(data.reservations);

      this.getReservationDataPage(true);
    });
  }

  acceptOrder(): void {
    const confirmPopupData: IConfirmPopup = {
      title: 'Akceptacja',
      message: null
    };
    confirmPopupData.message = `Czy chcesz zaakceptować zlecenie?`;

    if (this.orderItemData.name) {
      confirmPopupData.message = `Czy chcesz zaakceptować zlecenie "${this.orderItemData.name}"?`;
    }

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '440px',
      data: confirmPopupData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.acceptOrder(this.orderItemData.id).subscribe(() => {
          this.toaster.displayMessage('Zlecenie zostało zaakceptowane');
          this.onSaved.emit(true);
          this.orderService.onOrderCreateOrEdit$.emit(true);
        }, err => {
          this.errorManager.displayError(err.error);
        });
      }
    });
  }

  rejectOrder(): void {
    const confirmPopupData: IConfirmPopup = {
      title: 'Odrzucenie',
      message: null
    };
    confirmPopupData.message = `Czy chcesz odrzucić zlecenie?`;

    if (this.orderItemData.name) {
      confirmPopupData.message = `Czy chcesz odrzucić zlecenie "${this.orderItemData.name}"?`;
    }

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '440px',
      data: confirmPopupData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.rejectOrder(this.orderItemData.id).subscribe(() => {
          this.toaster.displayMessage('Zlecenie zostało odrzucone');
          this.onSaved.emit(true);
          this.orderService.onOrderCreateOrEdit$.emit(true);
        }, err => {
          this.errorManager.displayError(err.error);
        });
      }
    });
  }

  public openPublisherStatisticsPopup() {
    if (this.requestStatistics) {
      this.orderItemDataLocal.publisherStatistics = _.merge({}, this.requestStatistics);
    }
    const data = {
      orderItem: this.orderItemData,
      isCoordinatorView: this.isCoordinatorView
    };

    const dialogRef = this.dialog.open(PublisherStatisticsPopupComponent, {
      width: '1200px',
      data: Object.assign({}, data),
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.requestStatistics) {
        this.requestStatistics = _.merge({}, result.requestStatistics);
      }
    });
  }

  private createForm(type) {
    if (type === FormTypeEnum.new || type === FormTypeEnum.unstd) {
      this.orderForm = this.formBuilder.group({
        name: ['', Validators.required],
        reservation: ['', Validators.required],
        client: ['', Validators.required],
        dateFrom: [this.dateFrom ? new Date(this.dateFrom) : new Date()],
        dateTo: [this.dateFrom ? new Date(this.dateFrom) : new Date()],
        comment: [''],
        kind: ['', Validators.required],
        orderDocument: [''],
        additionalDocuments: [[]],
        platform: ['digital', Validators.required],
        salesman: ['', Validators.required],
        principal: ['', Validators.required],
        pdfPriceVisible: [0],
        pdfDiscountVisible: [0],
        pdfQuantityVisible: [0],
        principalPerson: ['', Validators.required]
      });

      if (this.orderForm.controls['platform'].value !== 'print') {
        this.orderForm.controls['dateFrom'].setValidators(Validators.required);
        this.orderForm.controls['dateTo'].setValidators(Validators.required);
      }

      if (type === FormTypeEnum.new) {
        this.orderForm.controls['kind'].setValue('standard');
      }
      if (type === FormTypeEnum.unstd) {
        this.orderForm.controls['kind'].setValue('niestandard');
      }

      this.orderForm.controls['platform'].valueChanges.subscribe(v => {
        this.orderForm.controls['reservation'].setValue(null);
        this.getReservationDataPage();
      });

      this.orderForm.controls['reservation'].valueChanges.subscribe(selectedReservations => {
        this.selectedReservations = _.map(selectedReservations, 'additionalData');
      });
    }
  }

  private updateOrderValuesAndDates(reservationList): void {
    const paymentSchedule = {};
    let orderValue = 0;

    for (const reservation of reservationList) {
      if (reservation.paymentPartition.length) {
        const paymentPartitionList = reservation.paymentPartition;

        for (const paymentPartition of paymentPartitionList) {
          const paymentDateFrom = paymentPartition['date_from'];

          if (paymentSchedule[paymentDateFrom] === undefined) {
            paymentSchedule[paymentDateFrom] = parseFloat(paymentPartition.value);
          } else {
            paymentSchedule[paymentDateFrom] += parseFloat(paymentPartition.value);
          }
        }
      }

      orderValue += parseFloat(reservation.value);
    }

    this.orderItemData.value = orderValue;
    this.orderItemData.paymentSchedule = paymentSchedule;
  }
}
