import { Injectable, Optional } from '@angular/core';
import * as _ from 'lodash';

import { PaymentPartitionModel } from '../../../shared/model/payment-partition.model';

import { Client } from '../../client/client.model';
import { BrandDictionaryModel } from '../../dictionary/model/brand-dictionary.model';
import { CampaignTypeDictionaryModel } from '../../dictionary/model/campaign-type-dictionary.model';
import { FormatDictionaryModel } from '../../dictionary/model/format-dictionary.model';
import { OrderFormItemsDictionaryModel } from '../../dictionary/model/order-form-items-dictionary.model';
import { PaymentTypeDictionaryModel } from '../../dictionary/model/payment-type-dictionary.model';
import { PriceListDictionaryModel } from '../../dictionary/model/price-list-dictionary.model';
import { PrincipalDictionaryModel } from '../../dictionary/model/principal-dictionary.model';
import { SalesModelDictionaryModel } from '../../dictionary/model/sales-model-dictionary.model';
import { TitleDictionaryModel } from '../../dictionary/model/title-dictionary.model';
import { TradeDictionaryModel } from '../../dictionary/model/trade-dictionary.model';
import { DiscountModel } from '../../discount/model/discount.model';
import { Placement } from '../../placement/placement.model';
import { PlacementCategory } from '../../placementCategory/placementCategory.model';
import { Publisher } from '../../publisher/model/publisher.model';

export interface IReservation {
  id: number;
  identification: number;
  name: string;
  type: string;
  dateFrom: Date;
  dateFromInt: number;
  dateTo: Date;
  dateToInt: number;
  client: Client;
  placements: Array<Placement>;
  placementCategory: PlacementCategory;
  status: string;
  owner: object;
  emissionsNumber: number;
  emissionValue: number;
  editionNumber: number;
  editionYear: number;
  publisher?: Publisher,
  brand: BrandDictionaryModel;
  comment: string;
  trade: TradeDictionaryModel;
  format?: FormatDictionaryModel;
  formats: Array<FormatDictionaryModel>;
  technicalCosts: number;
  agencyFee: number;
  capping: number;
  targetUrl: string;
  salesModel: SalesModelDictionaryModel;
  campaignType: CampaignTypeDictionaryModel;
  orderForm: OrderFormItemsDictionaryModel;
  paymentType: PaymentTypeDictionaryModel;
  principal: PrincipalDictionaryModel;
  salesman: SalesModelDictionaryModel;
  title: TitleDictionaryModel;
  year: number;
  platform: string;
  value: number;
  creation: any;
  paymentPartition: Array<PaymentPartitionModel>
}

@Injectable()
export class Reservation implements IReservation {
  id: number;
  identification: number;
  name: string;
  type: string;
  dateFrom: Date;
  dateFromInt: number;
  dateTo: Date;
  dateToInt: number;
  client: Client;
  placements: Array<Placement>;
  placementCategory: PlacementCategory;
  status: string;
  owner: object;
  emissionsNumber: number;
  emissionValue: number;
  editionNumber: number;
  editionYear: number;
  publishers?: Array<Publisher>;
  brand: BrandDictionaryModel;
  comment: string;
  trade: TradeDictionaryModel;
  format?: FormatDictionaryModel;
  formats: Array<FormatDictionaryModel>;
  technicalCosts: number;
  agencyFee: number;
  capping: number;
  targetUrl: string;
  salesModel: SalesModelDictionaryModel;
  campaignType: CampaignTypeDictionaryModel;
  orderForm: OrderFormItemsDictionaryModel;
  paymentType: PaymentTypeDictionaryModel;
  principal: PrincipalDictionaryModel;
  salesman: SalesModelDictionaryModel;
  title: TitleDictionaryModel;
  printType: string;
  year: number;
  platform: string;
  value: number;
  priceListPrice: number;
  fixedPrice: number;
  creation: any;
  discounts: Array<DiscountModel>;
  materialsDeliveryTime?: string;
  priceList?: PriceListDictionaryModel;
  paymentPartition: Array<PaymentPartitionModel>;

  constructor(@Optional() obj?: any) {
    this.id = obj && obj.id || null;
    this.identification = obj && obj.identification || null;
    this.type = obj && obj.type || null;
    this.dateFrom = obj && obj.dateFrom ? new Date(obj.dateFrom) : null;
    this.dateFromInt = obj && obj.dateFrom ? this.dateFrom.getTime() : 0;
    this.dateTo = obj && obj.dateTo ? new Date(obj.dateTo) : null;
    this.dateToInt = obj && obj.dateTo ? this.dateTo.getTime() : 0;
    this.client = obj && obj.client || null;
    this.placementCategory = obj && obj.placementCategory || null;
    this.status = obj && obj.status || null;
    this.owner = obj && obj.owner || null;
    this.name = obj && obj.name || null;
    this.creation = obj && obj.creation || null;
    this.emissionsNumber = obj && obj.emissionsNumber ? obj && obj.emissionsNumber : obj && obj.emissionsNumber === 0 ? 0 : null;
    this.emissionValue = obj && obj.emissionValue ? obj && obj.emissionValue : obj && obj.emissionValue === 0 ? 0 : null;
    this.publishers = (obj && obj.publishers) ? [] : null;
    if (obj && obj.publishers && _.isArray(obj.publishers) && obj.publishers.length) {
      this.publishers = _.map(obj.publishers, publisher => new Publisher(publisher));
    }

    this.placements = (obj && obj.placements) ? [] : null;
    if (obj && obj.placements && _.isArray(obj.placements) && obj.placements.length) {
      this.placements = _.map(obj.placements, publisher => new Placement(publisher));
    }

    this.brand = (obj && obj.brand) ? new BrandDictionaryModel(obj.brand) : null;
    this.comment = obj && obj.comment ? obj.comment.replace(/(\\n)/g, '\n') : null;
    this.trade = (obj && obj.trade) ? new TradeDictionaryModel(obj.trade) : null;
    this.format = (obj && obj.format) ? new FormatDictionaryModel(obj.format) : null;
    this.formats = [];
    if (obj && obj.formats) {
      _.each(obj.formats, format => {
        this.formats.push(new FormatDictionaryModel(format));
      })
    }

    this.discounts = [];
    if (obj && obj.discounts) {
      _.each(obj.discounts, discount => {
        this.discounts.push(new DiscountModel(discount));
      })
    }
    this.printType = obj && obj.printType || null;
    this.technicalCosts = obj && obj.technicalCosts >= 0 ? obj.technicalCosts : null;
    this.agencyFee = obj && obj.agencyFee >= 0 ? obj.agencyFee : null;
    this.capping = obj && obj.capping ? obj && obj.capping : obj && obj.capping === 0 ? 0 : null;
    this.targetUrl = obj && obj.targetUrl || null;
    this.salesModel = (obj && obj.salesModel) ? new SalesModelDictionaryModel(obj.salesModel) : null;
    this.campaignType = (obj && obj.campaignType) ? new CampaignTypeDictionaryModel(obj.campaignType) : null;
    this.orderForm = (obj && obj.orderForm) ? new OrderFormItemsDictionaryModel(obj.orderForm) : null;
    this.paymentType = (obj && obj.paymentType) ? new PaymentTypeDictionaryModel(obj.paymentType) : null;
    this.principal = (obj && obj.principal) ? new PrincipalDictionaryModel(obj.principal) : null;
    this.salesman = (obj && obj.salesman) ? new SalesModelDictionaryModel(obj.salesman) : null;
    this.title = (obj && obj.title) ? new TitleDictionaryModel(obj.title) : null;
    this.year = obj && obj.year || null;
    this.platform = obj && obj.platform || null;
    this.value = obj && obj.value ? obj.value : obj && obj.value === 0 ? 0 : null;
    this.priceListPrice = obj && obj.priceListPrice ? obj.priceListPrice : obj && obj.priceListPrice === 0 ? 0 : null;
    this.fixedPrice = obj && obj.fixedPrice ? obj.fixedPrice : obj && obj.fixedPrice === 0 ? 0 : null;
    this.discounts = obj && obj.discounts ? obj.discounts : null;

    this.editionNumber = obj && obj.editionNumber ? obj.editionNumber : obj && obj.editionNumber === 0 ? 0 : null;
    this.editionYear = obj && obj.editionYear ? obj.editionYear : obj && obj.editionYear === 0 ? 0 : null;
    this.materialsDeliveryTime = obj && obj.materialsDeliveryTime || null;

    this.priceList = (obj && obj.priceList) ? new PriceListDictionaryModel(obj.priceList) : null;
    this.paymentPartition = (obj && obj.paymentPartition) ?
      _.map(obj.paymentPartition, (item: PaymentPartitionModel) => new PaymentPartitionModel(item)) : [];

  }

}
