<mat-form-field class="full-width">
  <mat-select
    placeholder="{{ placeholder }}"
    [multiple]="true"
    #select
    [formControl]="selectedControl"
    (valueChange)="setSelected($event)"
    (keydown)="false"
    (click)="onOpenSelect()">
    <div class="padding-horizontal-sm" *ngIf="!disableSearch">
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
      <mat-form-field class="full-width">
        <input matInput type="text" placeholder="Wpisz aby szukać" [formControl]="searchControl">
        <mat-hint class="error" align="start" *ngIf="hintText">{{hintText}}</mat-hint>
      </mat-form-field>
    </div>

    <mat-option *ngFor="let item of optionsValue;trackBy:identify" [value]="item" title="{{ item.label }}">
      {{ item.label }}
    </mat-option>
  </mat-select>
  <mat-error>
    <div>Pole wymagane</div>
  </mat-error>
</mat-form-field>
