import { IMerchantDataPageFiltersRequest } from '../interface/merchant-data-page-filters-request.interface';
import { IMerchantDataPageRequest } from '../interface/merchant-data-page-request.interface';
import { MerchantDataPageFilters } from './merchant-data-page-filters';

export class MerchantDataPageRequest implements IMerchantDataPageRequest {
  public filters: IMerchantDataPageFiltersRequest;

  constructor(obj?: any) {
    this.filters = (obj && obj.filters) ? new MerchantDataPageFilters(obj.filters) : null;
  }

  setFilters(filters) {
    this.filters = new MerchantDataPageFilters(filters);
  }
}
