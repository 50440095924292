import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IAlertPopup } from './alert-popup.interface';

@Component({
  selector: 'ep-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss']
})
export class AlertPopupComponent {

  constructor(public dialogRef: MatDialogRef<AlertPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IAlertPopup) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ok(): void {
    this.dialogRef.close(true);
  }
}
