<div class="mat-dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
  <div>Wybierz tytuły</div>
  <button mat-icon-button mat-button matDialogClose>
    <mat-icon class="icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div>
    <ep-order-publisher-statistics [orderItemData]="orderItem" [isCoordinatorView]="isCoordinatorView" (changeData)="onChangeOrderItem($event)"></ep-order-publisher-statistics>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button (click)="cancel()">Anuluj</button>
  <button mat-button class="mat-primary mat-raised-button" (click)="closeWithSelected()">Zaakceptuj</button>
</mat-dialog-actions>
