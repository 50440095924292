import { Injectable, Optional } from '@angular/core';
import { IClient } from './interface/client.interface';

@Injectable()
export class Client implements IClient {
  id: number;
  identification: number;
  name: string;
  postalCode: string;
  street: string;
  city: string;
  nip: string;


  constructor(@Optional() obj?: any) {
    this.id = obj && obj.id || null;
    this.identification = obj && obj.identification || null;
    this.name = obj && obj.name || null;
    this.postalCode = obj && obj.name || null;
    this.street = obj && obj.street || null;
    this.city = obj && obj.city || null;
    this.nip = obj && obj.nip || null;
  }

}
