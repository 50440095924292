import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { ApiErrorManagerService } from '../../shared/utils/api-error-manager.service';

@Component({
  selector: 'ep-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.scss'
  ]
})

export class LoginComponent implements OnInit {
  error: string = null;
  loginForm: FormGroup;

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private formBuilder: FormBuilder,
              private errorManager: ApiErrorManagerService) {
    this.createForm();
  }

  ngOnInit() {
    if (this.authenticationService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
  }

  login(): void {
    this.authenticationService.login(this.loginForm.controls['username'].value, this.loginForm.controls['password'].value, this.loginForm.controls['remember'].value).subscribe(response => {
      if (response.token) {
        this.router.navigate(['/']);
      }
    }, err => {
      this.errorManager.displayError([err.error.detail]);
    })
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: false
    });
  }
}
