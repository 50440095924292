import { CalendarDataFilters } from './calendar-data-filters';

export class CalendarDataRequest {
  public viewType: number;
  public filters: CalendarDataFilters;

  constructor(obj?: any) {
    this.viewType = obj && obj.viewType || 1;
    if (obj && obj.filters) {
      this.filters = new CalendarDataFilters(obj.filters);
    }
  }

  setFilters(filters) {
    this.filters = new CalendarDataFilters(filters);
  }
}
