import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Package } from '../../package/package.model';
import { Publisher } from '../../publisher/model/publisher.model';

@Component({
  selector: 'ep-calendar-filters-popup',
  templateUrl: './calendar-filters-popup.component.html',
  styleUrls: ['./calendar-filters-popup.component.scss']
})
export class CalendarFiltersPopupComponent {
  filters: any;
  packages: Package[];
  publishers: Publisher[];
  selectedPackageControl: FormControl = new FormControl();
  selectedPublisherControl: FormControl = new FormControl();

  constructor(public dialogRef: MatDialogRef<CalendarFiltersPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog) {

  }

  ngOnInit() {
    this.packages = this.data.filters.packages;
    this.publishers = this.data.filters.publishers;
    if (this.data.selectedFilters && this.data.selectedFilters.packages) {
      this.selectedPackageControl.setValue(this.data.selectedFilters.packages)
    }
    if (this.data.selectedFilters && this.data.selectedFilters.publishers) {
      this.selectedPublisherControl.setValue(this.data.selectedFilters.publishers)
    }

  }

  cancel(): void {
    this.dialogRef.close();
  }

  ok(): void {
    this.dialogRef.close({
      packages: this.selectedPackageControl.value,
      publishers: this.selectedPublisherControl.value
    })
  }
}
