import { Injectable, Optional } from '@angular/core';

@Injectable()
export class DiscountModel {
  id?: string;
  name: string;
  type: string;
  percentValue: number;
  amountValue: number;

  constructor(@Optional() obj?: any) {
    this.id = obj && obj.id || null;
    this.name = obj && obj.name || null;
    this.type = obj && obj.hasOwnProperty('type') ? obj.type : null;
    this.percentValue = obj && obj.hasOwnProperty('percentValue') ? obj.percentValue : null;
    this.amountValue = obj && obj.hasOwnProperty('amountValue') ? obj.amountValue : null;
  }
}
