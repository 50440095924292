<!--<div class="padding-lg">-->
<!--<div class="ep-background-1 mat-elevation-z1 padding-lg margin-bottom-lg">-->
<!--Dodaj klienta-->
<!--</div>-->
<!--<div class="ep-background-1 mat-elevation-z1 padding-lg margin-bottom-lg">-->
<form [formGroup]="clientAddForm">
  <h2 class="mat-body-1">Kontakt</h2>
  <div class="login-content" fxLayout="column" fxLayoutAlign="start stretch">
    <mat-form-field class="full-width">
      <input matInput placeholder="Nazwa klienta" required
             [formControl]="clientAddForm.controls['name']"/>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input matInput placeholder="Ulica nr" required
             [formControl]="clientAddForm.controls['street']"/>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input matInput placeholder="Kod pocztowy" required
             [formControl]="clientAddForm.controls['postalCode']"/>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input matInput placeholder="Miasto" required
             [formControl]="clientAddForm.controls['city']"/>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input matInput placeholder="NIP" required
             [formControl]="clientAddForm.controls['nip']"/>
    </mat-form-field>
  </div>
</form>
<!--</div>-->
<!--</div>-->
