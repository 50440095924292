<div class="mat-dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
  <div>Edytuj zlecenie</div>
  <button mat-icon-button mat-button matDialogClose>
    <mat-icon class="icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <ep-order-form (onValidationChange)="onValidationChange($event)"
                 (onSaved)="onFormSaved($event)"
                 [isCoordinatorView]="isCoordinatorView"
                 [isManagerView]="isManagerView"
                 [isEditable]="isEditable"
                 [orderItemData]="orderData"
                 *ngIf="orderData"></ep-order-form>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button matDialogClose>Anuluj</button>
  <button
    mat-button
    class="mat-primary mat-raised-button"
    disabled="{{!isFormValid}}"
    (click)="updateOrder('draft')"
    *ngIf="data.order.status === 'draft' && !isManagerView">Zapisz zmiany
  </button>

  <button
    mat-button
    class="mat-primary mat-raised-button"
    disabled="{{!isFormValid}}"
    *ngIf="data.order.status === 'draft' && !isManagerView"
    (click)="updateOrder('reserved')">Zatwierdź zlecenie
  </button>

  <button
    mat-button
    class="mat-primary mat-raised-button"
    disabled="{{!isFormValid}}"
    (click)="updateOrder()"
    *ngIf="data.order.status !== 'draft' && !isManagerView">Zapisz zmiany
  </button>

  <ng-container *ngIf="isCoordinatorView">
    <button
      mat-button
      class="mat-primary mat-raised-button"
      disabled="{{!isFormValid}}"
      (click)="updateOrder(null, false, true)">
      Zapisz i wyślij
    </button>

    <button
      mat-button
      class="mat-primary mat-raised-button"
      disabled="{{!isFormValid}}"
      (click)="updateOrder(null, false)"
      *ngIf="data.order.isActionRequired">
      Zatwierdź i wyślij
    </button>
  </ng-container>

  <ng-container *ngIf="!isCoordinatorView">
    <button
      mat-button
      class="mat-primary mat-raised-button"
      disabled="{{!isFormValid}}"
      (click)="updateOrder(null, true)">
      Zapisz i wyślij
    </button>
  </ng-container>

  <button
    mat-button
    class="mat-primary mat-raised-button"
    (click)="acceptOrder()"
    *ngIf="data.order.status === 'not_accepted' && isManagerView">Zaakceptuj
  </button>

  <button
    mat-button
    class="mat-primary mat-raised-button"
    (click)="rejectOrder()"
    *ngIf="data.order.status !== 'not_accepted' && isManagerView">Odrzuć
  </button>

  <button
    mat-button
    class="mat-primary mat-raised-button"
    (click)="print()">Pobierz wydruk
  </button>

</mat-dialog-actions>
