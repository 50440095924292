import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';

import * as _ from 'lodash';

import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { IDataPageRequest } from '../../core/interface/data-page-request';
import { objectToParams } from '../../shared/utils/object-to-params';
import { ReservationDataPage } from './model/reservation-data-page';

@Injectable()
export class ReservationService {

  public onCreateOrEdit$: EventEmitter<any>;

  public static removeSpaces(val: string): number {
    if (val && _.isString(val)) {
      const value = val.replace(',', '.').split(' ').join('');
      return +value;
    } else if (_.isNumber(val)) {
      return val;
    } else {
      return 0;
    }
  }

  constructor(private http: HttpClient) {
    this.onCreateOrEdit$ = new EventEmitter();
  }

  public getList(request: IDataPageRequest): any {
    let params = objectToParams(request, 'yyyy-MM-dd');
    let apiUrl = `${environment.apiUrl}/reservations/?${params}`;

    return this.http.get(apiUrl).pipe(
      map((response: Response) => {
        return new ReservationDataPage(response);
      }));

  }

  public savePackage(request): any {
    let requestData = {
      'create_placement_category_reservation': request
    };

    let apiUrl = `${environment.apiUrl}/reservations/package`;

    return this.http.post(apiUrl, requestData);
  }

  public saveSingle(request): any {
    let requestData = {
      'create_placement_reservation': request
    };

    let apiUrl = `${environment.apiUrl}/reservations/single`;

    return this.http.post(apiUrl, requestData);
  }

  public savePrint(request): any {
    let requestData = {
      'create_print_reservation': request
    };

    let apiUrl = `${environment.apiUrl}/reservations/print`;
    return this.http.post(apiUrl, requestData);
  }

  public updatePrint(request): any {
    let requestData = {
      'edit_print_reservation': request
    };

    let apiUrl = `${environment.apiUrl}/reservations/print`;

    return this.http.put(apiUrl, requestData);
  }

  public updateReservation(request): any {
    let requestData = {
      'edit_reservation': request
    };

    let apiUrl = `${environment.apiUrl}/reservations/`;

    return this.http.put(apiUrl, requestData);
  }

  public cloneReservation(id: number): any {
    const apiUrl = `${environment.apiUrl}/reservations/clone/${id}`;

    return this.http.put(apiUrl, {});
  }

  public getTypeList(): any {
    let apiUrl = `${environment.apiUrl}/reservations/types`;

    return this.http.get(apiUrl).pipe(map(data => {
      return data['data'];
    }));
  }

  public getPrintTypeList(): any {
    let apiUrl = `${environment.apiUrl}/reservations/print/types`;

    return this.http.get(apiUrl).pipe(map(data => {
      return data['data'];
    }));
  }

  public getStatuses(status?: string): any {
    let params: string = '';
    if (status) {
      params = `?status=${status}`
    }
    let apiUrl = `${environment.apiUrl}/reservations/statuses/${params}`;

    return this.http.get(apiUrl).pipe(map(data => {
      if (data && data['data'] && data['data'].currentStatus) {
        data['data'].statuses[Object.keys(data['data'].currentStatus)[0]] = data['data'].currentStatus[Object.keys(data['data'].currentStatus)[0]];
      }
      return data;
    }));
  }

  public deleteReservation(id: number): any {
    let apiUrl = `${environment.apiUrl}/reservations/${id}`;

    return this.http.delete(apiUrl);
  }
}
