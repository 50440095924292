<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%;">
  <div class="login mat-elevation-z12">
    <div class="login-header" fxLayout="column" fxLayoutAlign="center center">
      <img style="width: 45%;" src="assets/EDIPRESSEpolska2_logo_White_vector.svg">
    </div>

    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <div class="login-content" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-form-field>
          <input matInput placeholder="Login" type="text" name="username" required
                 [formControl]="loginForm.controls['username']">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Hasło" type="password" name="password" required
                 [formControl]="loginForm.controls['password']">
        </mat-form-field>

        <div class="extra-options" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-checkbox class="remember-me" name="remember" [formControl]="loginForm.controls['remember']">Zapamiętaj
          </mat-checkbox>
        </div>

        <button color="primary" mat-raised-button [disabled]="!loginForm.valid">ZALOGUJ</button>
      </div>
    </form>
  </div>
</div>
