import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError as observableThrowError } from 'rxjs';

import { catchError } from 'rxjs/operators';


import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { ApiErrorManagerService } from '../../utils/api-error-manager.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private router: Router, private errorManager: ApiErrorManagerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authentication = this.injector.get(AuthenticationService);

    let customReq = request.clone({
      headers: request.headers.set('Authorization', ``)
    });

    if (authentication.credentials && authentication.credentials.token) {
      customReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${authentication.credentials.token}`)
      });
    }

    return next
      .handle(customReq).pipe(
        catchError(response => {
          if (response instanceof HttpErrorResponse) {
            if (response.status === 401) {
              authentication.logout().subscribe(isLoggedOut => {

              });
            } else if (response.status === 403) {
              this.errorManager.displayError([response.error.detail]);
            }
          }

          return observableThrowError(response);
        }));
  }
}
