export class ReservationDataPageFilters {
  identification: number;
  client: string;
  salesman: string;
  owner: string;
  comment: string;
  dateFrom: Date;
  dateTo: Date;
  status: string;
  type: any;
  kind: string;
  placementCategory?: string;
  placement?: string;
  clientId?: number;
  publisher?: string;
  title?: string;
  unusedOnly?: number;
  editionNumber?: number;
  editionYear?: number;
  platform?: string;
  name?: string;

  constructor(obj?: any) {
    this.identification = obj && obj.identification || null;
    this.client = obj && obj.client || null;
    this.salesman = obj && obj.salesman || null;
    this.owner = obj && obj.owner || null;
    this.comment = obj && obj.comment || null;
    this.dateFrom = obj && obj.dateFrom ? new Date(obj.dateFrom) : null;
    this.dateTo = obj && obj.dateTo ? new Date(obj.dateTo) : null;
    this.status = obj && obj.status || null;
    this.type = obj && obj.type || null;
    this.kind = obj && obj.kind || null;
    this.placementCategory = obj && obj.placementCategory || null;
    this.placement = obj && obj.placement || null;
    this.clientId = obj && obj.clientId || null;
    this.publisher = obj && obj.publisher || null;
    this.title = obj && obj.title || null;
    this.unusedOnly = obj && obj.unusedOnly ? 1 : 0;
    this.platform = obj && obj.platform || null;
    this.editionNumber = obj && obj.editionNumber || null;
    this.editionYear = obj && obj.editionYear || null;
    this.name = obj && obj.name || null;
  }

  setDateFromToBeginDay() {
    let dateFrom = new Date(this.dateFrom);
    dateFrom.setHours(0, 0, 0, 0);

    this.dateFrom = dateFrom;
  }

  setDateToToEndDay() {
    let dateTo = new Date(this.dateTo);
    dateTo.setHours(23, 59, 59, 999);

    this.dateTo = dateTo;
  }
}
