import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ep-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Output() onEncloseFile: EventEmitter<any> = new EventEmitter<any>();
  @Input() buttonLabel?: string;

  constructor() {
  }

  ngOnInit() {
  }

  fileChange(event) {
    let files = event.target.files;
    if (files.length > 0) {
      let formData: FormData = new FormData();
      formData.append('create_file[file]', files[0], files[0].name);

      this.onEncloseFile.emit({formData: formData, fileName: files[0].name});
      formData = null;
    }
  }
}
