import * as _ from 'lodash';
import { IAutocompleteFieldOption } from '../../../shared/component/autocomplete-field/autocomplete-field-option.interface';
import { DiscountCalculateBasePriceDigitalRequestModel } from '../../discount/model/discount-calculate-base-price-digital-request.model';

export class BasePriceDigitalCalculate {
  private publisher: number;
  private orderForm: number;
  private salesModel: number;
  private type: string;
  private emissionNumber: number;
  private creation: number;
  private priceList: number;

  constructor() {

  }

  setCreation(creation: IAutocompleteFieldOption) {
    this.creation = creation ? creation.id : null;
  }

  setEmissionNumber(emissionNumber: number) {
    this.emissionNumber = emissionNumber ? +emissionNumber : null;
  }

  setOrderForm(orderForm: IAutocompleteFieldOption) {
    this.orderForm = orderForm ? orderForm.id : null;
  }

  setPriceList(priceListId: number) {
    this.priceList = priceListId;
  }

  setPublisher(publisher: any) {
    if (publisher && _.isArray(publisher) && publisher.length) {
      this.publisher = publisher[0];
    }
  }

  setSalesModel(salesModel: IAutocompleteFieldOption) {
    this.salesModel = salesModel ? salesModel.id : null;
  }

  setType(type: string) {
    this.type = type ? `${type}` : null;
  }

  validateRequest(type: string): boolean {
    if (type === 'placement') {
      return !!(this.orderForm && this.salesModel && this.type && this.emissionNumber);
    }
    if (type === 'package') {
      return !!(this.orderForm && this.salesModel && this.type && this.emissionNumber && this.creation);
    }
    if (type === 'custom') {
      return !!(this.publisher && this.orderForm && this.salesModel && this.type && this.priceList);
    }
  }

  getRequest(): DiscountCalculateBasePriceDigitalRequestModel {
    return {
      publisher: this.publisher,
      orderForm: this.orderForm,
      salesModel: this.salesModel,
      type: this.type,
      emissionNumber: this.emissionNumber,
      creation: this.creation,
      priceListId: this.priceList
    }
  }
}
